import React, { useEffect, useState } from "react";
import { Button } from "../../components";
import { useNavigate } from "react-router-dom";
import { db } from "../../config/firebase";
import {
  collection,
  query,
  where,
  onSnapshot,
  orderBy,
} from "firebase/firestore";
import JobListing from "./JobListing";
import JobView from "./jobView/JobView";
import { auth } from "../../config/firebase";
import { onAuthStateChanged } from "firebase/auth";
import { useAuthState } from "react-firebase-hooks/auth";

const Jobs = () => {
  const navigate = useNavigate();
  const [userAuthState] = useAuthState(auth);
  const [openJobView, setOpenJobView] = useState(false);
  const [postedJobs, setPostedJobs] = useState(null);
  const [jobLoading, setJobLoading] = useState();
  const [openedJobId, setOpenedJobId] = useState(null);
  const [sortValue, setSortValue] = useState("");

  const fetchJobData = () => {
    setJobLoading(true);
    let q = query(
      collection(db, "jobsPosted"),
      where("companyName", "==", auth?.currentUser?.displayName),
      where("isDeleted", "==", false)
    );

    if (sortValue?.fieldValue) {
      q = query(q, orderBy(sortValue?.fieldName, sortValue?.fieldValue));
    } else {
      q = query(q, orderBy("jobPostedDate", "desc"));
    }
    const querySnapshot = onSnapshot(q, (querySnapshot) => {
      const jobData = [];
      querySnapshot.forEach((doc) => {
        jobData.push({ ...doc.data() });
      });
      setPostedJobs(jobData);
    });
    setJobLoading(false);
    return querySnapshot;
  };

  useEffect(() => {
    userAuthState && fetchJobData();
  }, [sortValue, userAuthState]);

  return (
    <div className="main-container w-full mx-auto md:ml-20 mt-1 flex flex-col lg-px-10">
      <div className="card p-2 pr-0 w-full relative lg:h-[90vh] overflow-y-hidden">
        <div className="application-filter-bar flex gap-2 h-[10%] pr-6">
          <div className="left-item w-full flex items-center gap-3 py-2 px-4 overflow-x-auto scrollbar-none">
            <h1 className="heading1 ml-2">All Posted Jobs</h1>
          </div>
          <div className="right-item">
            <div className="invite-candidate flex items-center justify-center py-2">
              <Button
                width="200px"
                text=""
                handleClick={() => navigate("/newJob")}
              >
                <div> Add new Job </div>
              </Button>
            </div>
          </div>
        </div>
        <div className="w-[100%] overflow-x-scroll h-[90%] px-4 py-2 scrollable">
          <JobListing
            postedJobs={postedJobs}
            jobLoading={jobLoading}
            setOpenJobView={setOpenJobView}
            setOpenedJobId={setOpenedJobId}
            setSortValue={setSortValue}
          />
        </div>
      </div>
      {openJobView && (
        <JobView
          jobId={openedJobId}
          postedJobs={postedJobs}
          openJobView={openJobView}
          setOpenJobView={setOpenJobView}
        />
      )}
    </div>
  );
};

export default Jobs;
