import React, { useEffect, useState } from "react";

const ExamQuestionPanel = ({ questions, qusNum, loading }) => {
  const [question, setQuestion] = useState();
  useEffect(() => {
    setQuestion(questions[qusNum]);
  }, [questions, qusNum]);
  return (
    <div className="w-[50%] h-[95%] px-12 border-r-2 border-[#CCC]">
      {loading ? (
        <div className="w-full h-full flex justify-center items-center">
          <p className="font-bold text-2xl text-[#bbb]">Loading...</p>
        </div>
      ) : (
        <div className="h-full space-y-2">
          <p className="font-medium text-[#333]">
            Question
            <span className="ml-2">
              {qusNum + 1}/{questions?.length}
            </span>
          </p>
          <p className="text-[#666] mb-1">
            Type :
            <span className="capitalize ml-2">
              {question?.type === "mcq"
                ? question?.type?.toUpperCase()
                : question?.type}
            </span>
          </p>
          {question?.questionType === "code" && (
            <p className="text-[#666] mb-4">
              Programming Language :
              <span className="capitalize ml-2">
                {JSON.parse(question?.language)
                  ?.map((lang) => lang.value)
                  ?.join(", ")}
              </span>
            </p>
          )}

          <div className="w-full h-full overflow-y-auto scrollbar-none">
            <div>
              <span className="capitalize text-[#444] font-medium">
                {question?.questionName}
              </span>
            </div>
            <div className="text-[#666]">
              <span>{question?.statement}</span>
            </div>
            {question?.questionImage && (
              <div className="w-full my-5 h-[75%] bg-gray-100 rounded-lg text-[#999] flex justify-center items-center relative">
                {`Question${qusNum + 1} - Image`}
                <img
                  src={question?.questionImage}
                  alt=""
                  className="absolute h-full w-full"
                />
              </div>
            )}
            {question?.type === "code" && (
              <div className="flex flex-col gap-2">
                <div className="flex gap-2 text-sm">
                  {question?.sampleInput && (
                    <div
                      className={`w-full rounded-md flex flex-col gap-1 bg-[#F5F5F5] px-4 py-2`}
                    >
                      <span className="font-semibold">Sample Input - </span>
                      <pre>{question?.sampleInput}</pre>
                    </div>
                  )}
                  {question?.sampleOutput && (
                    <div
                      className={`w-full rounded-md flex flex-col gap-1 bg-[#F5F5F5] px-4 py-2`}
                    >
                      <span className="font-semibold">Sample Output - </span>
                      <pre>{question?.sampleOutput}</pre>
                    </div>
                  )}
                </div>
                {question?.constraints && (
                  <div
                    className={`w-full rounded-md flex flex-col gap-1 bg-[#F5F5F5] px-4 py-2`}
                  >
                    <span className="font-semibold">Constraints - </span>
                    <pre>{question?.constraints}</pre>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ExamQuestionPanel;
