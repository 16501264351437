import React, { useEffect, useState } from "react";
import { Button, TextInput } from "../../components";
import { Timestamp, doc, updateDoc } from "firebase/firestore";
import { db } from "../../config/firebase";
import { FaTrash } from "react-icons/fa6";

const EducationDetails = ({ setStep, userDb }) => {
  const initialEducationalDetails = {
    grade: "",
    educationClass: "",
    institute: "",
    educationBoard: "",
    course: "",
    specialization: "",
    courseType: "",
    marks: "",
    eduFromDate: "",
    eduToDate: "",
  };

  const [education, setEducation] = useState([]);
  const [newEducation, setNewEducation] = useState(initialEducationalDetails);
  const [deleting, setDeleting] = useState(false);
  const [addEducationOpen, setAddEducationOpen] = useState(true);

  const classes = [
    "Class 10th",
    "Class 12th",
    "Graduation",
    "Post Graduation",
    "Diploma",
    "Certification",
    "Vocational",
  ];
  const schoolBoards = [
    "CBSE",
    "CISCE(ICSE/ISC)",
    "National Open Board",
    "State Boards",
  ];

  useEffect(() => {
    const setInitailProjects = async () => {
      userDb?.education && setEducation(userDb.education);
      userDb?.education?.length >0 ? setAddEducationOpen(false):setAddEducationOpen(true)
      // console.log(userDb?.education)
    };
    setInitailProjects();
  }, [userDb]);
  const handleSave = async (e) => {
    e.preventDefault();

    const newEducationDetails = {
      institute: newEducation.institute,
      grade: newEducation.grade,
      educationBoard:
        newEducation.grade === "Class 10th" ||
        newEducation.grade === "Class 12th"
          ? newEducation.educationBoard
          : null,
      course:
        newEducation.grade === "Class 10th" ||
        newEducation.grade === "Class 12th"
          ? null
          : newEducation.course,
      specialization:
        newEducation.grade === "Class 10th" ||
        newEducation.grade === "Class 12th"
          ? null
          : newEducation.specialization,
      courseType:
        newEducation.grade === "Class 10th" ||
        newEducation.grade === "Class 12th"
          ? null
          : newEducation.courseType,
      marks: newEducation.marks,
      duration: {
        from: Timestamp.fromDate(new Date(newEducation.eduFromDate)),
        pursuing: newEducation.pursuing ? newEducation.pursuing : false,
        to:
          newEducation.pursuing === true
            ? null
            : Timestamp.fromDate(new Date(newEducation.eduToDate)),
      },
    };
    try {
      await updateDoc(doc(db, "candidateProfile", userDb.id), {
        education: [...education, newEducationDetails],
      });
      setNewEducation(initialEducationalDetails);
      setAddEducationOpen(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleDelete = async (index) => {
    const updatedEducation = education.filter((_, i) => i !== index);
    setDeleting(index);

    try {
      await updateDoc(doc(db, "candidateProfile", userDb.id), {
        education: updatedEducation,
      });
      setDeleting(false);
    } catch (error) {
      console.log(error);
      setDeleting();
    }
  };

  return (
    <div className="space-y-8">
      {education?.length > 0 && (
        <div className="grid md:grid-cols-2 gap-x-4 gap-y-2">
          {education?.map((edu, index) => (
            <div
              key={index}
              className={`relative bg-gray-200 border-2 border-gray-300 py-2 px-4 rounded-xl 
                ${deleting === index && "opacity-50"} 
              `}
            >
              <div className="absolute top-4 right-4">
                <div className="flex items-center gap-2">
                  {/* <FaEdit
                      className="text-blue-400 cursor-pointer"
                      onClick={() => {
                        // setProjects(projects.filter((proj) => proj !== project));
                      }}
                    /> */}
                  <FaTrash
                    className="text-red-400 cursor-pointer"
                    onClick={() => {
                      handleDelete(index);
                    }}
                  />
                </div>
              </div>
              <h1 className="font-semibold text-lg capitalize">{edu.grade}</h1>
              <div className="text-sm flex">
                <span className="pr-2">{edu?.institute}</span>
                {edu?.duration?.to && (
                  <span className="pl-2 border-l-2 border-gray-400">
                    {new Date(
                      edu?.duration?.to?.seconds * 1000
                    ).toLocaleDateString("en-in", {
                      month: "short",
                      year: "numeric",
                    })}
                  </span>
                )}
              </div>
            </div>
          ))}
        </div>
      )}
      {addEducationOpen && (
        <div className="relative border-2 rounded-lg py-5 px-5 md:px-10 flex flex-col gap-4">
          <div className="absolute flex gap-5 items-center top-[-10px] left-5 ">
            <div className="text-sm bg-white px-2">Education Details </div>
          </div>
          <form onSubmit={handleSave} className="space-y-4">
            <div className="grid md:grid-cols-3 gap-y-4 gap-x-8">
              <div className="flex flex-col w-full">
                <label className="font-medium text-xs leading-[12px] mb-2">
                  Grade*
                </label>
                <TextInput
                  type="select"
                  placeholderText="Select Education"
                  inputName="grade"
                  selectOptions={classes}
                  inputValue={newEducation.grade}
                  isRequired={true}
                  handleChange={(e) => {
                    setNewEducation({
                      ...newEducation,
                      grade: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="flex flex-col w-full">
                <label className="font-medium text-xs leading-[12px] mb-2">
                  University/Institute*
                </label>
                <TextInput
                  type="text"
                  placeholderText="University/Institute Name"
                  inputName="institute"
                  inputValue={newEducation.institute}
                  isRequired={true}
                  handleChange={(e) => {
                    setNewEducation({
                      ...newEducation,
                      institute: e.target.value,
                    });
                  }}
                />
              </div>

              {newEducation.grade === "Class 10th" ||
              newEducation.grade === "Class 12th" ? (
                <div className="flex flex-col w-full">
                  <label className="font-medium text-xs leading-[12px] mb-2">
                    Board*
                  </label>
                  <TextInput
                    type="select"
                    placeholderText="Select Board"
                    inputName="board"
                    selectOptions={schoolBoards}
                    isRequired={true}
                    inputValue={newEducation.educationBoard}
                    handleChange={(e) => {
                      setNewEducation({
                        ...newEducation,
                        educationBoard: e.target.value,
                      });
                    }}
                  />
                </div>
              ) : (
                <>
                  <div className="flex flex-col w-full">
                    <label className="font-medium text-xs leading-[12px] mb-2">
                      Course*
                    </label>
                    <TextInput
                      type="text"
                      placeholderText="Enter Course Name"
                      inputName="course"
                      isRequired={true}
                      inputValue={newEducation.course}
                      handleChange={(e) => {
                        setNewEducation({
                          ...newEducation,
                          course: e.target.value,
                        });
                      }}
                    />
                  </div>
                  <div className="flex flex-col w-full">
                    <label className="font-medium text-xs leading-[12px] mb-2">
                      Specialization*
                    </label>
                    <TextInput
                      type="text"
                      placeholderText="Enter Specialization"
                      inputName="specialization"
                      isRequired={true}
                      inputValue={newEducation.specialization}
                      handleChange={(e) => {
                        setNewEducation({
                          ...newEducation,
                          specialization: e.target.value,
                        });
                      }}
                    />
                  </div>
                  <div className="flex flex-col w-full">
                    <label className="font-medium text-xs leading-[12px] mb-2">
                      Course type
                    </label>
                    <TextInput
                      type="select"
                      placeholderText="Select Course Type"
                      inputName="courseType"
                      selectOptions={[
                        "Full Time",
                        "Part Time",
                        "Correspondence",
                        "Distance Learning",
                      ]}
                      inputValue={newEducation.courseType}
                      handleChange={(e) => {
                        setNewEducation({
                          ...newEducation,
                          courseType: e.target.value,
                        });
                      }}
                    />
                  </div>
                </>
              )}
              <div className="flex flex-col w-full">
                <label className="font-medium text-xs leading-[12px] mb-2">
                  Marks
                </label>
                <TextInput
                  type="text"
                  placeholderText="Marks in percentage"
                  inputName="marks"
                  innerLabel="%"
                  isRequired={true}
                  inputValue={newEducation.marks}
                  handleChange={(e) => {
                    setNewEducation({
                      ...newEducation,
                      marks: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="flex md:col-span-3 flex-col w-full">
                <label className="font-medium text-xs leading-[12px] mb-2">
                  Duration
                </label>
                <div className="w-full grid grid-cols-3">
                  <div className="">
                    <TextInput
                      type="month"
                      innerLabel="From"
                      isRequired={true}
                      inputName="eduFromDate"
                      handleChange={(e) => {
                        setNewEducation({
                          ...newEducation,
                          eduFromDate: e.target.value,
                        });
                      }}
                    />
                  </div>

                  <div className={newEducation.pursuing && "opacity-50"}>
                    <TextInput
                      type="month"
                      innerLabel="To"
                      inputName="eduToDate"
                      isRequired={!newEducation.pursuing}
                      isDisabled={newEducation.pursuing}
                      handleChange={(e) => {
                        setNewEducation({
                          ...newEducation,
                          eduToDate: e.target.value,
                        });
                      }}
                    />
                  </div>
                  <div className="flex items-center ml-4 space-x-3">
                    <label
                      htmlFor="pursuing"
                      className="cursor-pointer font-medium"
                    >
                      Currently Pursuing
                    </label>
                    <input
                      id="pursuing"
                      className="w-5 h-5 mr-2 rounded-md border-2 border-gray-300 bg-gray-200 focus:ring-0 cursor-pointer"
                      type="checkbox"
                      name="pursuing"
                      onChange={(e) => {
                        setNewEducation({
                          ...newEducation,
                          pursuing: e.target.checked,
                        });
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="flex w-full justify-end gap-4">
              <Button
                text=""
                width="120px"
                version="secondary"
                handleClick={() => {
                  setAddEducationOpen(false);
                }}
              >
                Cancel
              </Button>
              <Button text="" width="120px" version="primary" type="submit">
                Save
              </Button>
            </div>
          </form>
        </div>
      )}
      {!addEducationOpen && (
        <div>
          <Button
            text=""
            width="10rem"
            version="small-outline-primary"
            handleClick={() => setAddEducationOpen(true)}
          >
            <div className="flex items-center">
              <span className="text-xl font-semibold mr-2">+</span>
              <span className="text-sm">Add Education</span>
            </div>
          </Button>
        </div>
      )}

      <div className="flex w-full items-center justify-between gap-8">
        <div>
          <Button
            version="secondary"
            text="Previous"
            width="160px"
            handleClick={() => setStep((prev) => prev - 1)}
          />
        </div>
        <div className="flex gap-4">
          <Button
            version="secondary"
            handleClick={() => setStep((prev) => prev + 1)}
            text="Next"
            width="160px"
          />
        </div>
      </div>
    </div>
  );
};

export default EducationDetails;
