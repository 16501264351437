import React, { useState } from "react";
import { TextInput, Button } from "../../../components";
import { FaTrash } from "react-icons/fa6";

const EducationDetails = () => {
  const educationFields = [
    {
      name: "institute",
      label: "Institute/School",
      required: true,
      type: "text",
      placeholder: "Institute/School Name",
      grade: "all",
    },
    {
      name: "board",
      label: "Board",
      type: "text",
      placeholder: "School Board",
      grade: "school",
    },
    {
      name: "course",
      label: "Course/Degree",
      type: "text",
      placeholder: "Course/Degree",
      grade: "institute",
    },
    {
      name: "specialization",
      label: "Specialization",
      type: "text",
      placeholder: "Specialization",
      grade: "institute",
    },
    {
      name: "marks",
      label: "Marks",
      type: "number",
      placeholder: "Marks",
      innerLabel: "%",
      grade: "all",
    },
  ];

  const uniqueId = new Date().getTime();

  const [education, setEducation] = useState([{ id: uniqueId }]);
  const [pursuing, setPursuing] = useState([]);
  const [schoolObject, setSchoolObject] = useState([]);

  return (
    <div className="space-y-6">
      <div className="font-semibold">Educational Details</div>
      {education?.map((edu, index) => {
        return (
          <div className="space-y-4" key={edu.id}>
            <div className="flex w-fit items-center">
              <div className="w-[40px] h-[40px] flex justify-center items-center text-sm rounded-md border-2 border-gray-300">
                0{index + 1}
              </div>
              <div className="border-t-2 border-gray-300 w-[300px] h-1 -z-10" />
              <div
                className="w-[40px] h-[40px] flex justify-center items-center rounded-md border-[2px] border-gray-300 cursor-pointer"
                onClick={() => {
                  const newDetail = education.filter((_, i) => i !== index);
                  setEducation(newDetail);
                }}
              >
                <FaTrash />
              </div>
            </div>
            <div className="grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
              <div>
                <label htmlFor="grade" className="text-xs font-medium">
                  Grade*
                </label>
                <TextInput
                  type="select"
                  inputName="grade"
                  placeholderText="Select Grade"
                  isRequired={true}
                  selectOptions={[
                    "Class 10th",
                    "Class 12th",
                    "Graduation",
                    "Post Graduation",
                    "Diploma",
                    "Certification",
                    "Vocational",
                  ]}
                  handleChange={(e) => {
                    e.target.value === "Class 10th" ||
                    e.target.value === "Class 12th"
                      ? setSchoolObject([...schoolObject, index])
                      : setSchoolObject([
                          ...schoolObject.filter((i) => i !== index),
                        ]);
                  }}
                />
              </div>

              {educationFields.map((field) => (
                <div
                  key={field.name}
                  className={
                    field.grade === "all" ||
                    (schoolObject.includes(index)
                      ? field.grade === "school"
                      : field.grade === "institute")
                      ? ""
                      : "hidden"
                  }
                >
                  <label htmlFor={field.name} className="text-xs font-medium">
                    {field.label}*
                  </label>
                  <TextInput
                    type={field.type}
                    inputName={field.name}
                    placeholderText={field.placeholder}
                    isRequired={
                      field.grade === "all" ||
                      (schoolObject.includes(index)
                        ? field.grade === "school"
                        : field.grade === "institute")
                        ? true
                        : false
                    }
                    selectOptions={field.selectedOption}
                    innerLabel={field.innerLabel}
                  />
                </div>
              ))}

              <div className="md:col-span-2">
                <label htmlFor="duration" className="text-xs font-medium">
                  Duration*
                </label>
                <div className="flex items-center w-full border-2 border-gray-200 rounded-xl overflow-hidden">
                  <TextInput
                    type="month"
                    innerLabel="From"
                    inputName="eduFromDate"
                    isRequired={true}
                  />
                  <div className={pursuing.includes(index) ? "opacity-30" : ""}>
                    <TextInput
                      type="month"
                      innerLabel="To"
                      inputName="eduToDate"
                      isRequired={pursuing.includes(index) ? false : true}
                    />
                  </div>
                </div>
              </div>

              <div className="flex items-center ml-4 space-x-3">
                <label
                  htmlFor="pursuing"
                  className="cursor-pointer font-medium"
                >
                  Currently Pursuing
                </label>
                <input
                  id="pursuing"
                  className="w-5 h-5 mr-2 rounded-md border-2 border-gray-300 bg-gray-200 focus:ring-0 cursor-pointer"
                  type="checkbox"
                  name="pursuing"
                  onChange={(e) => {
                    if (e.target.checked) {
                      setPursuing([...pursuing, index]);
                    } else {
                      setPursuing(pursuing.filter((i) => i !== index));
                    }
                  }}
                />
              </div>
            </div>
          </div>
        );
      })}

      <div className="mt-4 flex space-x-3">
        <Button
          text=""
          version="outline-primary"
          handleClick={() => {
            setEducation([...education, { id: uniqueId }]);
          }}
        >
          <div className="flex items-center gap-2">
            <span className="font-semibold text-lg">+</span>
            Add Education Detail
          </div>
        </Button>
      </div>
    </div>
  );
};

export default EducationDetails;
