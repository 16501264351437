import React from 'react';

const ProgressBorder = ({ progress, width, height, borderRadius }) => {
  return (
    <svg className='absolute top-0 left-0'  width={width} height={height} viewBox={`0 0 ${width} ${height}`}>
      <defs>
        <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="0%">
          <stop offset="{progress}%" style={{stopColor: '#8A24F1', stopOpacity: 1}} />
        </linearGradient>
      </defs>
      <circle
        cx={borderRadius}
        cy={borderRadius}
        r={borderRadius - 2}
        fill="transparent"
        stroke="url(#gradient)"
        strokeWidth="4"
        strokeDasharray={`${(progress / 100) * (2 * Math.PI * borderRadius)} ${
          2 * Math.PI * borderRadius
        }`}
        strokeLinecap="butt"
        transform={`rotate(-90, ${borderRadius}, ${borderRadius})`}
      />
    </svg>
  );
};

export default ProgressBorder;