// this email is sent to candiate who can not cleared the round

const emailTemplate = ({candidateName,companyName,}) => {
    //content to be changed
    const subject =  `Assessment not cleared at ${companyName}`
    const body = `
    Hey <b>${candidateName}!</b><br><br>
    Thank you very much for taking the time to apply at ${companyName}. We appreciate your interest in the company and the job.<br><br>

    We regret to inform you that we shall not be able to proceed with your application at this time, as we have selected the candidate whom we believe most closely matches the current requirements of the position.<br><br>

    We will definitely keep your resume in our talent database, and in case we have a job opening that better fits your profile, we will make sure to get in touch with you.<br><br>

    Again, thank you for your time. We wish you good luck in your future endeavors! <br><br>

    <b>Please follow our LinkedIn page for future Job Updates<b><br>
    LinkedIn - <a href="https://www.linkedin.com/company/talentigo/">https://www.linkedin.com/company/talentigo</a> <br><br>

    Thanks & Regards,<br>
    <b>${companyName}.</b>
    `
    return { subject, body }
}

export default emailTemplate