import React, { useEffect, useRef, useState } from "react";
import { Button, TextInput } from "../../components";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../config/firebase";
import { toast } from "react-toastify";

const ProfessionalDetails = ({ userDb, setStep }) => {
  const [loading, setLoading] = useState(false);
  const workExperience = useRef("");
  const positionTitle = useRef("");
  const currentJobTitle = useRef("");
  const currentEmployer = useRef("");
  const currentSalary = useRef("");
  const expectedSalary = useRef("");
  useEffect(() => {
    const setInitailDetails = async () => {
      if (userDb) {
        workExperience.current.value = userDb?.workExperience
          ? userDb?.workExperience
          : "";
        positionTitle.current.value = userDb?.positionTitle
          ? userDb?.positionTitle
          : "";
        currentJobTitle.current.value = userDb?.currentJobTitle
          ? userDb?.currentJobTitle
          : "";
        currentEmployer.current.value = userDb?.currentEmployer
          ? userDb?.currentEmployer
          : "";
        currentSalary.current.value = userDb?.currentSalary
          ? userDb?.currentSalary
          : "";
        expectedSalary.current.value = userDb?.expectedSalary
          ? userDb?.expectedSalary
          : "";
      }
    };
    setInitailDetails();
  }, [userDb]);
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      await updateDoc(doc(db, "candidateProfile", userDb.id), {
        workExperience: Number(workExperience.current.value),
        positionTitle: positionTitle.current.value,
        currentJobTitle: currentJobTitle.current.value,
        currentEmployer: currentEmployer.current.value,
        currentSalary: currentSalary.current.value,
        expectedSalary: expectedSalary.current.value,
      });
      toast.success("Success! Your details have been updated.");
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  return (
    <form className="flex flex-col gap-6" onSubmit={handleSubmit}>
      <div className="relative border-2 rounded-lg py-5 px-10 flex flex-col gap-4">
        <div className="absolute top-[-10px] left-5 text-sm bg-white px-2">
          Professional Details
        </div>
        <div className="grid md:grid-cols-2 gap-y-4 gap-x-8">
          <div className="flex flex-col w-full">
            <label className="font-medium text-xs leading-[12px] mb-2">
              Work Experience
            </label>
            <TextInput
              type="number"
              placeholderText="Total Work Experience (in years)"
              inputName="workExperience"
              inputRef={workExperience}
            />
          </div>
          <div className="flex flex-col w-full">
            <label className="font-medium text-xs leading-[12px] mb-2">
              Position Title
            </label>
            <TextInput
              type="text"
              placeholderText="Position Title"
              inputName="positionTitle"
              inputRef={positionTitle}
            />
          </div>
          <div className="flex flex-col w-full">
            <label className="font-medium text-xs leading-[12px] mb-2">
              Current Job Title
            </label>
            <TextInput
              type="text"
              placeholderText="Current Job Title"
              inputName="currentJobTitle"
              inputRef={currentJobTitle}
            />
          </div>
          <div className="flex flex-col w-full">
            <label className="font-medium text-xs leading-[12px] mb-2">
              Current Employer
            </label>
            <TextInput
              type="text"
              placeholderText="Current Employer"
              inputName="currentEmployer"
              inputRef={currentEmployer}
            />
          </div>
          <div className="flex flex-col w-full">
            <label className="font-medium text-xs leading-[12px] mb-2">
              Current Salary
            </label>
            <TextInput
              type="text"
              placeholderText="Current salary (per month)"
              inputName="expSalary"
              inputRef={currentSalary}
            />
          </div>
          <div className="flex flex-col w-full">
            <label className="font-medium text-xs leading-[12px] mb-2">
              Expected Salary
            </label>
            <TextInput
              type="text"
              placeholderText="Expected salary (per month)"
              inputName="expSalary"
              inputRef={expectedSalary}
            />
          </div>
        </div>
      </div>
      <div className="flex w-full items-center justify-between gap-8">
        <Button
          version="secondary"
          handleClick={() => setStep((prev) => prev - 1)}
          text="Previous"
          width="160px"
          isDisabled={loading}
        />
        <div className="flex gap-4">
          <Button
            type="submit"
            version="primary"
            text={loading ? "Please Wait" : "Save"}
            width="160px"
            isDisabled={loading}
          />
          <Button
            version="secondary"
            handleClick={() => setStep((prev) => prev + 1)}
            text="Next"
            width="160px"
            isDisabled={loading}
          />
        </div>
      </div>
    </form>
  );
};

export default ProfessionalDetails;
