import { fetchSignInMethodsForEmail } from 'firebase/auth'
import React, { useEffect, useState } from 'react'
import { Button } from '../../components';
import { auth } from '../../config/firebase'
import { Link } from 'react-router-dom'

const ApplyStep3 = () => {

    const [localStorageData, setLocalStorageData] = useState();
    useEffect(() => {
        const storedUserString = localStorage.getItem("user");
        const storedUser = JSON.parse(storedUserString);
        setLocalStorageData(storedUser);
      }, []);
      
    const [authExists, setAuthExists] = useState(false);
    useEffect(() => {
        const checkUserExists = async () => {
            try {
                await fetchSignInMethodsForEmail(auth, localStorageData?.email).then(result => {
                    setAuthExists(result.length > 0);
                })
            } catch (error) {
            console.error('Error checking user existence:', error);
            }
        };
        checkUserExists();
    },[localStorageData?.email]);

  return (
    <div className="flex items-center justify-center h-full w-full">
        <div className='flex items-center justify-center'>
            {authExists ?(
                <div className='flex flex-col gap-4 items-center justify-center text-center'>
                    <h1 className='text-3xl font-semibold'>
                        Successfully Applied !
                    </h1>
                    <p className='text-xl'>Welcome Back</p>
                    <p>
                        Please Login to track your Candidatature
                    </p>  
                    <Link to='/candidateLogin'>
                        <Button
                            text='One Click Login'
                        />
                    </Link>
                </div>
            ) : (
                <div className='flex flex-col gap-4 items-center justify-center text-center'>
                    <h1 className='text-3xl font-semibold'>
                        Successfully Applied !
                    </h1>
                    <p>
                        Please Register to track your Candidatature
                    </p>
                    <Link to='/candidateRegistration'>
                        <Button
                            text='One Click Sign Up'
                        />
                    </Link>
                </div>
            )}
        </div>
    </div>
  )
}

export default ApplyStep3