import React, { useEffect, useState } from "react";
import SmallCards from "./SmallCards";
// import VacancyStats from "./VacancyStats";
// import CandidateFunnel from "./CandidateFunnel";
import ScoreDistribution from "./ScoreDistribution";
import Recents from "./Recents";
import { auth } from "../../config/firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import {
  doc,
  getDoc,
  query,
  onSnapshot,
  collection,
  where,
} from "firebase/firestore";
import { db } from "../../config/firebase";

const Dashboard = () => {
  const [loading, setLoading] = useState(false);
  const [userAuth] = useAuthState(auth);

  // fetching AssessmentTest Collection Data
  const [assessmentTestDetail, setAssessmentTestDetail] = useState([]);

  useEffect(() => {
    const getAssessmentTest = () => {
      const q = query(
        collection(db, "assessmentTests"),
        where("attempted", "==", true)
        // where("appliedAtCompany", "==", auth.currentUser?.displayName)
      );
      onSnapshot(q, (querySnapshot) => {
        const assessmentData = [];
        querySnapshot.forEach((doc) => {
          assessmentData.push({ ...doc.data(), id: querySnapshot.docs[0].id });
        });

        setAssessmentTestDetail(assessmentData);
      });
    };

    userAuth && getAssessmentTest();
  }, [userAuth]);

  // fetching candidate application data

  const [applications, setApplications] = useState();
  useEffect(() => {
    if (assessmentTestDetail.length >= 1) {
      setLoading(true);

      const fetchDocData = () => {
        try {
          const q = query(
            collection(db, "candidateApplications"),
            where("appliedAtCompany", "==", auth.currentUser?.displayName)
          );
          onSnapshot(q, (querySnapshot) => {
            let assessmentTests = [];
            querySnapshot.forEach((testDoc) => {
              function formatDate(date) {
                const month = date.getMonth() + 1;
                const day = date.getDate();
                const year = date.getFullYear();
                return `${month}/${day}/${year}`;
              }
              assessmentTests.push({
                ...testDoc.data(),
                appliedAt: formatDate(testDoc.data().appliedAt.toDate()),
                appliedAtMillisecond: testDoc
                  .data()
                  .appliedAt.toDate()
                  .getTime(),
                id: querySnapshot.docs[0].id,
              });
            });

            const addProfileDetail = async () => {
              let testsWithProfileName = [];
              for (let i = 0; i < assessmentTests.length; i++) {
                const getProfileData = await getDoc(
                  doc(
                    db,
                    "candidateProfile",
                    assessmentTests[i].candidateProfileRef.id
                  )
                );
                let candidateName = getProfileData?.data().name;
                testsWithProfileName.push({
                  ...assessmentTests[i],
                  name: candidateName,
                });
              }
              setApplications(testsWithProfileName);
            };

            addProfileDetail();
          });
        } catch (error) {}
      };

      auth.currentUser?.displayName && fetchDocData();

      setLoading(false);
    }
  }, [assessmentTestDetail, userAuth]);

  return (
    <div className="main-container dashboard-container mx-auto md:ml-20 mt-0 h-full">
      <div className="w-full h-full overflow-y-scroll scrollable pr-2 space-y-3 py-2">
        <SmallCards applications={applications} />

        {/* <VacancyStats
          // candidateApplicationDetail={candidateApplicationDetail}
          assessmentTestDetail={assessmentTestDetail}
          applications={applications}
        /> */}

        {/* <CandidateFunnel
          // candidateApplicationDetail={candidateApplicationDetail}
        /> */}

        <ScoreDistribution applications={applications} />

        <Recents applications={applications} />
      </div>
    </div>
  );
};

export default Dashboard;
