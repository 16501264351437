import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { Button } from "../../../components";
import { UploadSingleQuestion } from "./UploadSingleQuestion";
import UploadCSV from "./UploadCSV";
// import DropBox from './DropBox';
// import CreateAssessmentType from '../assessmentType/CreateAssessmentType';

const LibQuestionUpload = ({
  questionSheetId,
  assessment,
  assessmentNames,
  setUpdateQuestions,
  // admin
}) => {
  const [uploadSuccessModule, setUploadSuccessModule] = useState();
  useEffect(
    () =>
      setUploadSuccessModule(document.getElementById("uploadSuccessModule")),
    []
  );

  const closeSuccessModule = () => {
    // setProfile(''); setQuestionType(''); setQuestionStatment(''); setQuestionDifficulty("");
    // setOptionA(''); setOptionB(''); setOptionC(''); setOptionD(''); setTime(''); setAnswer('');
    uploadSuccessModule?.classList.remove("flex");
    uploadSuccessModule?.classList.add("hidden");
  };
//  console.log(admin)
  return (
    <div className="main-container relative lg:h-[90vh] mx-auto md:ml-20 mt-2 flex flex-col lg:flex-row gap-3 rounded-3xl overflow-hidden">
      {/* upload success module */}
      <div
        id="uploadSuccessModule"
        className="z-10 absolute top-0 left-0 hidden justify-center items-center w-full h-full bg-[#000]/10 backdrop-blur-[2px]"
      >
        <div className="card common-popup py-10 w-[95%] flex flex-col justify-center items-center gap-5">
          <div className="w-20 h-20 bg-[#57AD55]/80 rounded-full flex justify-center items-center">
            <p className="text-white text-4xl">✓</p>
          </div>
          <h1 className="text-lg">Question was Successfully added !</h1>
          <Link to="/quesUpload">
            <Button text=" OK " width="6rem" handleClick={closeSuccessModule} />
          </Link>
        </div>
      </div>

      {/* {assessmentNamesLoading ? (
                <div className='flex z-10 h-full w-full absolute top-0 left-0 bg-[#000]/10 backdrop-blur-[4px] justify-center items-center'>
                    <div className='flex flex-col justify-center items-center h-1/3 w-1/3 gap-8 card p-10'>
                        <p className='text-2xl font-bold text-[#888]'>
                            Loading . . .
                        </p>
                    </div>
                </div>
            ) : assessmentNames.length === 0 && (
                <div className='flex z-10 h-full w-full absolute top-0 left-0 bg-[#000]/10 backdrop-blur-[4px] justify-center items-center'>
                    <CreateAssessmentType
                        questionSheetId={questionSheetId}
                    />
                </div>
            )} */}

      <div className="w-full lg:w-[60%] h-full card p-6">
        <UploadSingleQuestion
          questionSheetId={questionSheetId}
          assessmentName={assessment}
          assessmentNames={assessmentNames}
          uploadSuccessModule={uploadSuccessModule}
          setUpdateQuestions={setUpdateQuestions}
          // admin={admin} 
        />
      </div>
      <div className="w-full card lg:w-[40%] h-[30rem] lg:h-full">
        <UploadCSV
          questionSheetId={questionSheetId}
          assessmentName={assessment}
          assessmentNames={assessmentNames}
          uploadSuccessModule={uploadSuccessModule}
          // admin={admin} 
        />
      </div>
    </div>
  );
};

export default LibQuestionUpload;
