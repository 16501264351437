import React from "react";
import { TextInput } from "../../../components";
import UploadLogo from "./UploadLogo";

const CompanyDetail = ({ logoUrl, setLogoUrl }) => {
  const detailsFields = [
    {
      name: "companyName",
      label: "Company Name",
      type: "text",
      required: true,
      placeholder: "eg. Talentigo",
      span: 1,
    },
    {
      name: "employeSize",
      label: "Company Employee Size",
      type: "select",
      required: true,
      options: [
        "0-1 Employees",
        "2-10 Employees",
        "11-50 Employees",
        "51-200 Employees",
        "201-500 Employees",
        "501-1000 Employees",
        "1000+ Employees",
      ],
      placeholder: "Select Employee Size",
      span: 1,
    },
    {
      name: "email",
      label: "Official Email Id",
      type: "email",
      required: true,
      placeholder: "eg.johnmatthew@gmail.com",
      span: 2,
    },
    {
      name: "website",
      label: "Company Website",
      type: "url",
      required: true,
      placeholder: "eg. www.exampleUrl.com",
      span: 2,
    },
  ];

  return (
    <div className="space-y-3">
      <div className="text-xl font-semibold">Fill Company Details</div>
      <div className="grid grid-cols-2 gap-4">
        <div className="row-span-2">
          <UploadLogo url={logoUrl} setUrl={setLogoUrl} />
        </div>

        {detailsFields.map((field, index) => (
          <div
            key={field.name}
            style={{ gridColumn: `span ${field.span} / span ${field.span}` }}
          >
            <label htmlFor={field.name} className="text-xs font-medium">
              {field.label}
              {field.required && <sup>*</sup>}
            </label>
            <TextInput
              type={field.type}
              selectOptions={field.options}
              placeholderText={field.placeholder}
              inputName={field.name}
            />
          </div>
        ))}
        <div className="col-span-2 flex gap-4 items-center ">
          <input type="checkbox" name="acceptTerms" id="acceptTerms" />
          <label htmlFor="acceptTerms" className="text-xs">
            I accept terms of use and privacy policy
          </label>
        </div>
      </div>
    </div>
  );
};

export default CompanyDetail;
