import React, { useState, useCallback, useEffect } from "react";
import Papa from "papaparse";
import { useDropzone } from "react-dropzone";
import { Timestamp } from "firebase/firestore";
import axios from "axios";
// import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { db } from "../../../config/firebase";
import { collection, addDoc } from "firebase/firestore";
import { auth } from "../../../config/firebase";

// import {Button, TextInput} from '../../components'
import { Button, TextInput } from "../../../components";
import { toast } from "react-toastify";

const UploadCSV = ({
  assessmentName,
  assessmentNames,
  questionSheetId,
  uploadSuccessModule,
  // admin,
  setOpenBulkQuestion,
  setAddedQuestions,
  setAddedQuestionsId,
  openBulkQuestion,
}) => {
  const [profile, setProfile] = useState(assessmentName);
  const [files, setFiles] = useState([]);

  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [collectionName, setCollectionName] = useState();

  const navigate = useNavigate();
  const location = useLocation();
  const urlparams = new URLSearchParams(location.search);
  const libraryTypeParam = urlparams.get("libraryType");
  const asessmentLibtypeParam = urlparams.get("type");

  useEffect(() => {
    setCollectionName(
      libraryTypeParam === "Custom"
        ? "customQuestionLibrary"
        : asessmentLibtypeParam === "custom"
        ? "customQuestionLibrary"
        : "questionLibrary"
    );
  }, [libraryTypeParam, asessmentLibtypeParam]);

  const onDrop = useCallback((acceptedFiles) => {
    setFiles((prev) => [...prev, ...acceptedFiles]);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: { "text/csv": [] },
  });

  const fileList = files.map((file) => (
    <div key={file.path} className="text-lg text-center">
      <p>File - {file.path}</p>
      <p>Size - {file.size} bytes</p>
      <div className="mt-4">
        <Button
          version="secondary"
          text="Clear"
          handleClick={(e) => {
            e.stopPropagation();
            setFiles([]);
          }}
          width="140px"
        />
      </div>
    </div>
  ));

  const handleUpload = (e) => {
    setError("");
    e.preventDefault();
    // if (!profile || !files) return setError("All fields are required");
    // setLoading(true)
    let parsedData;
    const reader = new FileReader();
    reader.onload = async ({ target }) => {
      const csv = Papa.parse(target.result);
      console.log(csv.data.length);
      parsedData = csv?.data.slice(1);
      if (!parsedData) return setError("All fields are required");
      //  console.log(parsedData);

      const transdata = parsedData.map((data, i) => {
        const obj = {
          category: data[11]
            ? data[11].split(",").map((data) => data.trim())
            : [],
          skills: data[12]
            ? data[12].split(",").map((data) => data.trim())
            : [],
          topic: data[13] ? data[13].split(",").map((data) => data.trim()) : [],
          options: [data[2], data[3], data[4], data[5]].length
            ? [data[2], data[3], data[4], data[5]]
            : [],
          difficulty: Number(data[9] ? data[9] : ""),
          statement: data[0] ? data[0] : "",
          type: data[10] ? data[10].toLowerCase() : "",
          correctOption:  !data[8]?"":data[8]?.toLowerCase()==="a" ? data[2] :data[8].toLowerCase()==="b"?data[3]:data[8].toLowerCase()==="c"?data[4]:data[5],
          answerSummary: data[7] ? data[7] : "",
          isDeleted: false,
          createdAt: Timestamp.now(),
          // updatedAt: Timestamp.now(),

          // todo   cann't get admin name while creating assessment
          addedBy: openBulkQuestion ? "" : auth?.currentUser.displayName,
          questionName: data[1] ? data[1] : "",
          time: Number(data[6] ? data[6]*60 : 0),
        };
        return obj;
      });
      // console.log(transdata.slice(0, -1));
      openBulkQuestion && setAddedQuestions(transdata.slice(0, -1));
      // setOpenBulkQuestion(false)
      let questId = [];

      for (let i = 0; i < transdata.length - 1; i++) {
        try {
          // console.log("working",i,collectionName,transdata[i])
          const res = await addDoc(
            collection(db, collectionName),
            transdata[i]
          );
          // navigate("/questionLibrary");
          // toast.success("Doument Added")
          questId.push(res?.id);
          setLoading(false);
        } catch (error) {
          console.log(i, error.message);
          toast.error("error", error.message);
          setLoading(false);
        }
      }
      openBulkQuestion && setAddedQuestionsId(questId);
      openBulkQuestion
        ? setOpenBulkQuestion(false)
        : navigate("/questionLibrary");

      // const questionsData = JSON.stringify([questionSheetId,profile,parsedData])
      // const response = await axios.post(process.env.REACT_APP_BULK_QUESTION_UPLOAD,questionsData)
      setLoading(false);
      // console.log(response?.data.status)

      uploadSuccessModule?.classList.remove("hidden");
      uploadSuccessModule?.classList.add("flex");
    };
    // parsedData?.length &&
    reader?.readAsText(files[0]);
  };
  console.log(collectionName);
  return (
    <div className="relative w-full h-full flex flex-col items-center justify-center rounded-xl">
      <div
        {...getRootProps({
          className:
            "dropzone flex flex-col cursor-pointer w-full h-full flex flex-col p-5 items-center",
        })}
      >
        <input {...getInputProps()} />

        <h1 className="heading2 text-center">
          Upload Bulk Questions{" "}
          <span className="text-sm lowercase font-normal ml-1">
            (.csv format)
          </span>
        </h1>
        {/* <div onClick={(e) => e.stopPropagation()} className="w-3/4 mt-5">
          <label className="text-xs mb-1 text-[#888]">Assessment Type</label>
          <TextInput
            type="select"
            idName="jobProfile"
            inputName="applyFor"
            isRequired={true}
            placeholderText="Select Assessment Type"
            inputValue={profile}
            handleChange={(e) => setProfile(e.target.value)}
            selectOptions={assessmentNames}
          />
        </div> */}
        <div className="w-full h-3/5 flex flex-col items-center justify-center">
          {fileList.length > 0 ? (
            fileList[0]
          ) : (
            <>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="mb-5 w-16 h-16 text-[#888]"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5m-13.5-9L12 3m0 0l4.5 4.5M12 3v13.5"
                />
              </svg>

              <p className="text-center flex flex-col gap-2 text-[#666]">
                <span className="text-[#55F] font-medium hover:underline">
                  Choose
                </span>
                <span>OR</span>
                <span className="text-sm">drop a .csv file here</span>
              </p>
            </>
          )}
        </div>
        <div className="flex flex-col justify-center w-3/4">
          <div
            className="text-xs text-center mb-4"
            onClick={(e) => e.stopPropagation()}
          >
            <a href="/questions.csv">
              <span className="text-[#55F] text-sm font-medium hover:underline">
                Click here{" "}
              </span>
              to download supported CSV format file
            </a>
          </div>
          {error && (
            <div className="text-red-500 text-center text-sm mb-2">{error}</div>
          )}

          <div className="upload-wrapper" onClick={(e) => e.stopPropagation()}>
            <Button
              text="Upload"
              handleClick={handleUpload}
              loading={loading}
              isDisabled={files.length === 0}
              width="140px"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UploadCSV;
