import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Button } from "../../../components";
import { FaHeart, FaRegHeart } from "react-icons/fa";

const JobView = ({
  jobDetails,
  candidateApplications,
  userDetails,
  handleFav,
}) => {
  const jobDetailsFields = [
    { name: "jobType", label: "Job Type" },
    {
      name: "workExperiance",
      label: "Work Experiance",
    },
    {
      name: "salary",
      label: "Salary Range",
    },
    {
      name: "numberOfPosition",
      label: "Number of Positions",
    },
    {
      name: "jobPrefence",
      label: "Job Preference",
    },
    {
      name: "address",
      label: "Address",
    },
  ];

  const applyJob = () => {
    //   fuction to handle applying to job
    // - thankyou for applying email,
    // - add apply data for candidateApplication
    // - add schedule details for first round and email,
    // - add data to candidate profile, appliedJobs
  };

  const [applied, setApplied] = useState(false);
  const [appliedDetails, setAppliedDetails] = useState();
  useEffect(() => {
    if (candidateApplications && jobDetails) {
      if (
        candidateApplications?.filter(
          (app) => app?.appliedJobId === jobDetails?.id
        )?.length
      ) {
        setAppliedDetails(
          candidateApplications?.filter(
            (app) => app?.appliedJobId === jobDetails?.id
          )[0]
        );
        setApplied(true);
      } else {
        setAppliedDetails();
        setApplied(false);
      }
    }
  }, [candidateApplications, jobDetails]);

  if (!jobDetails) {
    return (
      <div className="border-l-[3px] border-gray-300 w-2/3 h-[98%] overflow-y-scroll scrollable p-4 px-6">
        {/*  */}
      </div>
    );
  }

  return (
    <div className="relative border-l-[3px] border-gray-300 w-2/3 h-[98%] overflow-y-scroll scrollable p-4 px-6">
      <div
        className="absolute top-4 right-4 flex gap-2 items-center cursor-pointer"
        onClick={(e) => handleFav(e, jobDetails?.id)}
      >
        {userDetails?.favJobs?.includes(jobDetails?.id) ? (
          <>
            <span className="text-sm">Unmark as Favourite</span>
            <FaHeart className="h-6 w-6 text-[#8A24F1]" />
          </>
        ) : (
          <>
            <span className="text-sm">Mark as Favourite</span>
            <FaRegHeart className="h-6 w-6" />
          </>
        )}
      </div>
      <div className="space-y-2">
        <h1 className="text-2xl font-semibold capitalize">
          {jobDetails?.positionTitle}
        </h1>
        <Link
          // to={companyDetails?.companyUrl}
          target="_blank"
          className="flex items-center gap-2"
        >
          <div className="w-14 h-14 bg-[#8A24F1]/20 rounded-full flex items-center justify-center border-2 border-gray-200 overflow-hidden">
            {/* {companyDetails?.logoUrl ? (
              <img src={companyDetails?.logoUrl} alt="companyLogo" />
            ) : ( */}
            <span className="text-[#8A24F1]/50 font-semibold text-3xl">
              {jobDetails?.companyName?.slice(0, 1)}
            </span>
            {/* )} */}
          </div>
          <div>
            <div className="text-[#8A24F1] font-medium text-xl">
              {jobDetails?.companyName}
            </div>
            <div className="flex">
              <div className="font-medium text-sm text-[#666]">
                {`${jobDetails?.address?.city}, ${jobDetails?.address?.country}`}
              </div>
            </div>
          </div>
        </Link>

        <div className="py-3">
          {applied ? (
            <div className="text-sm flex gap-2 items-center bg-green-100 text-green-400 w-fit px-4 p-2 rounded-lg">
              <span className="">Already Applied on -</span>
              <span className="font-semibold">
                {new Date(
                  appliedDetails?.appliedAt?.seconds * 1000
                )?.toLocaleDateString("en-IN", {
                  year: "numeric",
                  month: "short",
                  day: "numeric",
                })}
              </span>
            </div>
          ) : (
            <Button handleClick={applyJob}>Apply Now</Button>
          )}
        </div>

        {applied && (
          <div> ------- Application --------- Tracking ---------- </div>
        )}

        <h2 className="text-lg font-medium">Job Details</h2>
        <ul className="flex flex-col gap-1">
          {jobDetailsFields?.map((item) => (
            <li className="flex gap-3" key={item?.name}>
              <span className="text-gray-500 font-medium">
                {item?.label}
                {" : "}
              </span>
              <span className="font-medium">
                {jobDetails &&
                  (item?.name === "workExperiance"
                    ? `${jobDetails?.workExperiance} Years`
                    : item?.name === "salary"
                    ? `
                        ${Math?.floor(jobDetails?.salary?.min / 1000)}k
                        ${" - "}
                        ${Math.floor(jobDetails?.salary?.max / 1000)}k
                        ${" / month"}`
                    : item?.name === "jobPrefence"
                    ? jobDetails?.jobPrefence === "wfh"
                      ? "Remote"
                      : "On Site"
                    : item?.name === "address"
                    ? `${jobDetails?.address?.city}, ${jobDetails?.address?.state}`
                    : jobDetails[item?.name])}
              </span>
            </li>
          ))}
        </ul>
        <div>
          <h3 className="font-semibold">Job Description</h3>
          <p className="text-[#676767]">{jobDetails?.jobDescription}</p>
        </div>
      </div>
    </div>
  );
};

export default JobView;
