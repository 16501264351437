// this email is sent to candiaate when he apply to job
// email contain welcome message and link to register 

const emailTemplate = ({jobTitle, companyName, candidateName}) => {
    const registrationLink = `${process.env.REACT_APP_URL}candidateRegistration`
    const subject =  `Thanks for your interest for position of ${jobTitle} at ${companyName}!`
    const body = `
    Dear ${candidateName},
    </b><br>
    Thank you for your interest in the <b>${jobTitle}</b> position at <b>${companyName}!</b> We received your application through Talentigo and we're excited to learn more about you.
    <br/><br/>
    To continue your application and explore the next steps in our hiring process, please register on Talentigo. 
    <br/><br/>
    This will allow you to:
    <ul>
    <li>Access your application details and update your profile.</li>
    <li>Learn more about the role and responsibilities.</li>
    <li>Complete any pre-screening assessments (if applicable).</li>
    <li>Stay updated on the progress of your application.</li>
    </ul>
    <br/>
    <b>Click here to register : </b> <a href=${registrationLink}>${registrationLink}</a>
    <br/><br/>
    We look forward to reviewing your application and getting to know you better.
    <b>Please follow our LinkedIn page for Future Job Updates</b> <br>
    LinkedIn - <a href='https://www.linkedin.com/company/talentigo/'>Linkedin-Talentigo</a> <br><br>
    <br/><br/>
    Best regards,<br/>
    Team Talentigo
    `
    return { subject, body }
}

export default emailTemplate