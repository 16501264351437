import React, { useState } from "react";
// import axios from "axios";

import { Button, TextInput, Topbar1 } from "../../components";
import { collection, onSnapshot, query, where } from "firebase/firestore";
import { db } from "../../config/firebase";
import { useNavigate } from "react-router-dom";

const ExamLogin = () => {
  const [userEmail, setUserEmail] = useState("");
  const [code, setCode] = useState("");
  const [mailError, setMailError] = useState("");
  const [passError, setPassError] = useState("");
  const [otherError, setOtherError] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  //   const [loginButton, setLoginButton] = useState();
  //   useEffect(() => setLoginButton(document.getElementById("loginButton")), []);

  //   const params = new URLSearchParams(window.location.search);
  //   const companyName = params.get("key");

  //   document.addEventListener("keypress", (e) => {
  //     if (e.keyCode === 13) {
  //       loginButton?.click();
  //     }
  //   });

  const handleSubmit = async (e) => {
    e.preventDefault();
    //reset error messages
    setMailError("");
    setPassError("");
    setOtherError("");

    // let candidateData;
    // const fetchCandidate = async () => {
    //     setLoading(true)
    //     const fetchData = `${companyName}|${userEmail}`
    //     const candidateResponse = await axios.post(process.env.REACT_APP_EXAM_LOGIN ,fetchData);
    //     if(candidateResponse?.data?.status === 'success'){
    //         candidateData = candidateResponse?.data
    //     }else if(candidateResponse?.data?.status === 'emailError'){
    //         setMailError("Invalid Candidate Email")
    //     }else if(candidateResponse?.data?.status === 'linkError'){
    //         setOtherError("Something went wrong. Please check your exam link")
    //     }
    //     setLoading(false)
    // }

    // if(userEmail && code){
    //     await fetchCandidate()
    // }

    // if (candidateData) {
    //     if (candidateData.candidate.ExamCode !== code) {
    //         // Invalid Exam Code
    //          setPassError("Invalid Exam Code");
    //     } else if (new Date(candidateData.time) - new Date() < -100000 || new Date(candidateData.time) - new Date() > 100000) {
    //         // System clock not correct
    //         setOtherError("Please set your system date & time correctly")
    //     } else {
    //         if (candidateData.candidate.Attempt){
    //             // already attempted
    //             setOtherError("You have already attempted the test.");
    //         } else if(window.innerWidth <= 820){
    //             // Device width check
    //             setOtherError("Please join via a Laptop/PC.");
    //         } else {
    //             //saving exam Details to local storage
    //             let candidateDetails = {
    //                 dataSheetId : candidateData.dataSheetID,
    //                 questionSheetId : candidateData.questionSheetId,
    //                 uid :candidateData.candidate?.UID,
    //                 emailId : userEmail,
    //                 name : candidateData.candidate?.Name,
    //                 code : candidateData.candidate?.ExamCode,
    //                 exam : candidateData.candidate?.ApplyFor,
    //                 hrEmail : candidateData.candidate?.HrEmail,
    //                 maxViolations : candidateData.candidate?.maxViolations,
    //                 maxQuestions : candidateData.candidate?.maxQuestions,
    //                 questionsType : candidateData.candidate?.quesType,
    //                 toughness : candidateData.candidate?.toughness,
    //                 examDate : new Date(candidateData.candidate?.Date).toDateString(),
    //                 examTime : new Date(candidateData.candidate?.Time).toLocaleTimeString(),
    //                 inviteSchedule : candidateData.candidate?.inviteSchedule,
    //                 expireDateTime : candidateData.candidate?.expireDateTime,
    //                 timeNow : candidateData.time,
    //                 isCandidateLogin : true,
    //                 companyName:companyName
    //             }
    //             localStorage.removeItem('refresh')
    //             localStorage.setItem('candidateDetails', JSON.stringify(candidateDetails));
    //             window.location.href = "/start"
    //         }
    //     }
    // }

    //fetchSchedule from firebase db 'assessmentTests' Based on email and exam code
    const fetchSchedule = async () => {
      setLoading(true);
      let q = query(
        collection(db, "assessmentTests"),
        where("candidateEmail", "==", userEmail),
        where("examCode", "==", code)
      );

      try {
        onSnapshot(q, (querySnapshot) => {
          const data = [];
          querySnapshot.forEach((doc) => {
            data.push({ id: doc.id, ...doc.data() });
          });
          if (!data.length)
            return setOtherError("Invalid Email Id or Exam Code");
          const schedule = data[0];

          if (schedule?.attempted)
            return setOtherError("You have already attempted the test.");

          if (window.innerWidth <= 820)
            return setOtherError("Please join via a Laptop/PC.");

          // saving schedule Details to local storage
          let scheduleDetails = {
            ...schedule,
            isCandidateLogin: true,
          };

          localStorage.removeItem("refresh");
          localStorage.setItem(
            "scheduleDetails",
            JSON.stringify(scheduleDetails)
          );
          if (!schedule?.attempted) {
            window.location.href = "/start";
          }
        });
      } catch (error) {
        console.log(error);
      }
    };

    if (userEmail && code) {
      await fetchSchedule();
      setLoading(false);
    }

    if (userEmail === "") {
      setMailError("Please fill your email Id");
    }
    if (code === "") {
      setPassError("Please fill the Assessment Code");
    }
  };

  return (
    <div className="right-item w-[70%] h-full mx-auto flex flex-col justify-center">
      <div className="flex flex-col">
        <div className="left-logo mb-12">
          <Topbar1 />
        </div>
        <span className="font-medium text-lg leading-[27px] mb-3">
          Welcome to
        </span>
        <h1 className="heading1 mb-10">Technical Skills Assessment Test</h1>
      </div>
      <div className="w-full">
        <form onSubmit={handleSubmit}>
          <div className="mb-[14px]">
            <label
              htmlFor="email"
              className="font-medium text-sm leading-[12px] mb-3"
            >
              Email Id<sup>*</sup>
            </label>
            <TextInput
              type="email"
              placeholderText="Enter your Email id"
              error={mailError && "error"}
              inputName="email"
              isRequired={true}
              inputValue={userEmail}
              handleChange={(e) => setUserEmail(e.target.value)}
            />
            {
              <div className="font-medium text-xs leading-3 h-3 text-[#f00] mt-1">
                {mailError}
              </div>
            }
          </div>
          <div className="mb-[14px]">
            <label
              htmlFor="examCode"
              className="font-medium text-sm leading-[12px] mb-3"
            >
              Assessment Code<sup>*</sup>
            </label>

            <TextInput
              type="password"
              placeholderText="Assessment Code"
              error={passError && "error"}
              inputName="examCode"
              inputValue={code}
              handleChange={(e) => setCode(e.target.value)}
              isRequired={true}
            />

            {
              <div className="font-medium text-xs leading-3 h-3 text-[#f00] mt-1">
                {passError}
              </div>
            }
          </div>

          <div className="flex items-start gap-4 flex-col">
            <>
              <Button
                width="10rem"
                text="Proceed"
                type="submit"
                inputId="loginButton"
                loading={loading}
              />
              {otherError && (
                <div className="font-medium text-sm leading-4 text-[#f00]">
                  {otherError}
                </div>
              )}
            </>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ExamLogin;
