import React, { useEffect, useState } from "react";
import { Button, TextInput } from "../../components";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../config/firebase";

const Skills = ({ setStep, userDb }) => {
  // const [userAuthState] = useAuthState(auth);
  const [skills, setSkills] = useState([]);

  useEffect(() => {
    const setInitailSkills = async () => {
      userDb?.skills && setSkills(userDb.skills);
    };
    setInitailSkills();
  }, [userDb]);

  const handleAdd = async (e) => {
    e.preventDefault();
    const newSkill = e.target.skill.value;
    const newSkills = Array.from(new Set([...skills, newSkill]));
    e.target.reset();

    try {
      await updateDoc(doc(db, "candidateProfile", userDb.id), {
        skills: newSkills
      });
    } catch (error) {
      console.log(error);
    }
  };

  const removeSkill = async (skill) => {
    const newSkills = skills.filter((skillName) => skillName !== skill);
    try {
      await updateDoc(doc(db, "candidateProfile", userDb.id), {
        skills: newSkills
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <form
        onSubmit={handleAdd}
        className="relative border-2 rounded-lg py-5 px-5 md:px-10 flex flex-col gap-4"
      >
        <div className="absolute top-[-10px] left-5 text-sm bg-white px-2">
          Key Skills
        </div>
        <p className="font-semibold">Add your key skills</p>
        <div className="flex flex-wrap gap-x-3 gap-y-2">
          {skills?.map((skill, i) => {
            return (
              <div
                key={i}
                className="flex items-center gap-4 font-semibold text-sm bg-gray-200 border-2 border-gray-300 rounded-full pl-5 p-2"
              >
                <div>{skill}</div>
                <span
                  className="cursor-pointer flex items-center justify-center text-xl bg-gray-400 text-white rounded-full w-5 h-5 hover:bg-red-400 font-light"
                  onClick={() => removeSkill(skill)}
                >
                  ×
                </span>
              </div>
            );
          })}
        </div>
        <div className="border-2 border-gray-300 rounded-full overflow-hidden md:w-2/3 lg:w-1/2">
          <TextInput
            type="text"
            inputName="skill"
            placeholderText="Enter your  area of expertise/specialization"
          />
        </div>
        <div>{/* todo - suggestion to be added here */}</div>
      </form>
      <div className="flex w-full items-center justify-between gap-8">
        <div>
          <Button
            version="secondary"
            text="Previous"
            width="160px"
            handleClick={() => setStep((prev) => prev - 1)}
          />
        </div>
        <div className="flex gap-4">
          <Button
            version="secondary"
            handleClick={() => setStep((prev) => prev + 1)}
            text="Next"
            width="160px"
          />
        </div>
      </div>
    </>
  );
};

export default Skills;
