import axios from "axios";
import nextRoundScheduleTemplate from "../../utils/emailTemplates/nextRoundSchedule";
import roundNotClearedTemplate from "../../utils/emailTemplates/roundNotCleared";
import finalRoundCandidateTemplate from "../../utils/emailTemplates/finalRoundCleared";
import finalRoundAdminTemplate from "../../utils/emailTemplates/finalRoundClearedAdmin";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { db } from "../../config/firebase";

export const handleFullScreen = () => {
  if (document.documentElement.mozRequestFullScreen) {
    document.documentElement.mozRequestFullScreen();
  } else if (document.documentElement.webkitRequestFullscreen) {
    document.documentElement.webkitRequestFullscreen();
  }
};

export const fetchCompanyDetails = async (company) => {
  const q = query(
    collection(db, "adminProfile"),
    where("companyName", "==", company)
  );

  const querySnapshot = await getDocs(q);

  let documentData = null;

  querySnapshot.forEach((doc) => {
    documentData = { id: doc.id, ...doc.data() };
  });

  return documentData;
};

export const fetchQuestion = async (qeusId) => {
  const docRef = doc(db, "questionLibrary", qeusId);
  const docSnap = await getDoc(docRef);
  const customDocRef = doc(db, "customQuestionLibrary", qeusId);
  const customDocSnap = await getDoc(customDocRef);

  if (docSnap.exists()) {
    return { id: docSnap.id, ...docSnap.data() };
  } else if (customDocSnap.exists()) {
    return { id: customDocSnap.id, ...customDocSnap.data() };
  }
};

export const sendEmail = async ({
  type,
  candidateDetails,
  companyName,
  companyEmail,
  positionTitle,
  examCode,
  scheduleDate,
  expDate,
}) => {
  const dateFormat = {
    day: "numeric",
    month: "long",
    year: "numeric",
  };
  const timeFormat = { hour: "numeric", minute: "2-digit", hour12: true };

  const data = {
    candidateName: candidateDetails?.name,
    candidateEmail: candidateDetails?.email,
    examCode,
    jobTitle: positionTitle,
    companyName,
    companyEmail,
    startDate: {
      date: scheduleDate?.toLocaleDateString("en-IN", dateFormat),
      time: scheduleDate?.toLocaleTimeString("en-IN", timeFormat),
    },
    expDate: {
      date: expDate?.toLocaleDateString("en-IN", dateFormat),
      time: expDate?.toLocaleTimeString("en-IN", timeFormat),
    },
  };
  let subject, body;
  if (type === "pass") {
    ({ subject, body } = nextRoundScheduleTemplate(data));
  } else if (type === "passLast") {
    ({ subject, body } = finalRoundCandidateTemplate(data));
  } else if (type === "passLastAdmin") {
    ({ subject, body } = finalRoundAdminTemplate(data));
  } else if (type === "fail") {
    ({ subject, body } = roundNotClearedTemplate(data));
  }

  // emil sending logic
  const emailTo =
    type === "passLastAdmin" ? companyEmail : candidateDetails?.email;
  const emailData = `${emailTo}|${subject}|${body}`;
  const mailApi = process.env.REACT_APP_EMAIL_API;
  const emailResponse = await axios.post(mailApi, emailData);
  return emailResponse;
};
