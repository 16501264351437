import React from "react";

import { Logo } from "..";

const TopBar2 = ({ name, email, hideDetails, handleLogout }) => {
  return (
    <div className="bg-white py-3.5 backdrop-blur-[50px] shadow-[0_0_20px_rgba(0,0,0,0.05)]">
      <div className="flex justify-between w-[95%] mx-auto">
        <Logo width="10rem" />

        {!hideDetails && (
          <div className="flex justify-center items-center gap-6 select-none">
            <div className="flex flex-col items-end">
              <span className="capitalize font-medium text-base">
                Hello, {name}
              </span>
              <span className="text-xs text-[#919397]">{email}</span>
            </div>
            {/* <div className='bg-gray-300 rounded-full w-[50px] h-[50px]' /> */}
          </div>
        )}
      </div>
    </div>
  );
};

export default TopBar2;
