import React, { useEffect } from "react";
import { collection, query, where, onSnapshot } from "firebase/firestore";
import { db } from "../../../config/firebase";
import Select from "react-select";

const PositionTitle = ({ postedJobs, setPostedJobs }) => {
  const fetchJobData = () => {
    // setJobLoading(true);
    const q = query(
      collection(db, "jobsPosted"),
      where("companyName", "==", "Testing"),
      where("isDeleted", "==", false)
    );
    const querySnapshot = onSnapshot(q, (querySnapshot) => {
      const jobData = [];
      querySnapshot.forEach((doc) => {
        jobData.push({ ...doc.data() });
      });
      let selectData = jobData.map((item) => {
        const jobOpeningId = item.jobOpeningId;
        const jobPositions = item.positionTitle;
        return {
          jobId: jobOpeningId,
          value: jobPositions,
          label: jobPositions.charAt(0).toUpperCase() + jobPositions.slice(1),
        };
      });
      setPostedJobs(selectData);
    });

    return querySnapshot;
  };
  useEffect(() => {
    fetchJobData();
  }, []);

  return (
    <div>
      <div className="text-sm"> Job Position*</div>
      <div className="grid md: grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
        <Select
          className="basic-single"
          classNamePrefix="select"
          name="jobPosition"
          isClearable={true}
          required={true}
          placeholder="Select Job Position"
          options={postedJobs}
        />
      </div>
    </div>
  );
};

export default PositionTitle;
