// this email is sent to candiate when he cleared the final round

const emailTemplate = ({candidateName, jobTitle, companyName}) => {
    //content to be changed
    const subject =  `Congratulations, ${candidateName}! You've Aced the Interview for ${jobTitle} at ${companyName}!`
    const body = `
    Hello <b>${candidateName}</b>,<br>

    We're thrilled to inform you that you've impressed us throughout the interview process for the <b>${jobTitle}</b> position at <b>${companyName}</b>! Congratulations!<br><br>

    The Hiring Team from ${companyName} will be in touch with you shortly.<br><br>

    <b>Please follow our LinkedIn page for Future Job Updates</b> <br>
    LinkedIn - <a href='https://www.linkedin.com/company/talentigo/'>Linkedin-Talentigo</a> <br><br>
        
    Best regards,<br>
    <b>${companyName}</b>
    `
    return { subject, body }
}

export default emailTemplate