import React, { useEffect, useState } from "react";
import { IoWarningOutline } from "react-icons/io5";
import { Popup } from "../../components";
import QuestionModule from "./QuestionModule";
const QuestionAnalyse = ({ results }) => {
  const [questionModuleOpen, setQuestionModuleOpen] = useState(false);
  const [openedQuestionIndex, setOpenedQuestionIndex] = useState(null);
  const [openedQuestion, setOpenedQuestion] = useState(null);

  const [showViolationsName, setShowViolationsName] = useState();

  useEffect(() => {
    setOpenedQuestion(results?.resultDetails[openedQuestionIndex]);
  }, [openedQuestionIndex, results]);

  return (
    <>
      {questionModuleOpen && (
        <Popup
          closePopup={() => {
            setQuestionModuleOpen(false);
          }}
        >
          <QuestionModule
            ques={openedQuestion}
            index={openedQuestionIndex}
            setIndex={setOpenedQuestionIndex}
            totalQuestions={results?.resultDetails?.length}
          />
        </Popup>
      )}

      <div className="py-5 px-4 md:p-6 lg:h-[70vh] w-full overflow-y-auto scrollable">
        <h1 className="font-bold capitalize mb-2">Question wise analyse</h1>
        <div className="bg-[#E9EAEB]/60 p-4 pl-6 font-medium backdrop-blur-lg text-sm rounded-t-lg">
          <div className="flex">
            <div className="w-1/5 md:w-[15%] flex items-center">S.No.</div>
            <div className="w-1/5 md:w-[20%] flex items-center">Type</div>
            <div className="w-1/5 md:w-[20%] flex items-center">Difficulty</div>
            <div className="w-1/5 md:w-[20%] flex items-center">Status</div>
            <div className="w-1/5 md:w-[15%] flex items-center">
              Violation Count
            </div>
            <div className="hidden md:flex w-[10%] justify-center items-center">
              Action
            </div>
          </div>
        </div>
        {results?.resultDetails?.map((resultQues, index) => {
          return (
            <div
              key={index}
              className="relative bg-[#fff] even:bg-[#F9FAFB] text-[#444] text-xs p-4 pl-6 md:py-[10px] font-medium"
            >
              <div
                id={index}
                // onClick={() => questionModuleOpen(index)}
                className="lg:hidden absolute top-0 left-0 w-full h-full cursor-pointer"
              />
              <div className="flex">
                <div className="w-1/5 md:w-[15%] flex items-center pl-2">
                  {index + 1}
                </div>
                <div className="w-1/5 md:w-[20%] flex items-center capitalize">
                  {resultQues?.question?.type === "mcq"
                    ? "MCQ"
                    : resultQues?.question?.type}
                </div>
                <div className="w-1/5 md:w-[20%] flex items-center capitalize">
                  {["easy", "medium", "hard"][resultQues?.question?.difficulty]}
                </div>
                <div className="w-1/5 md:w-[20%] flex items-center capitalize">
                  <div
                    className={
                      resultQues?.evaluation === "correct"
                        ? "correct-txt"
                        : resultQues?.evaluation === "incorrect"
                        ? "incorrect-txt"
                        : resultQues?.evaluation === "pending"
                        ? "text-[#FFB800]"
                        : ""
                    }
                  >
                    {resultQues?.evaluation}
                  </div>
                </div>
                <div className="relative w-1/5 md:w-[15%] flex items-center">
                  {resultQues?.violations?.length === 0 ? (
                    "-"
                  ) : (
                    <div
                      className="flex items-center gap-2 text-[#FFB800] text-sm cursor-pointer"
                      onMouseOver={() => setShowViolationsName(index)}
                      onMouseLeave={() => setShowViolationsName()}
                    >
                      <IoWarningOutline className="w-5 h-5" />
                      {resultQues?.violations?.length}
                    </div>
                  )}
                  {showViolationsName === index && (
                    <div className="absolute -top-8 -left-6 capitalize bg-white shadow-md  p-2 rounded-md border-2 border-gray-100">
                      {resultQues?.violations?.join(", ")}
                    </div>
                  )}
                </div>
                <div className="hidden md:flex w-[10%] justify-center items-center">
                  <span
                    className="table-view-btn"
                    onClick={() => {
                      setQuestionModuleOpen(true);
                      setOpenedQuestionIndex(index);
                    }}
                  >
                    View
                  </span>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default QuestionAnalyse;
