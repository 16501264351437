import React from "react";
import { Button } from "..";

const SuccessFeedback = ({ closeSuccessModule }) => {
  const handleClick = () => {
    closeSuccessModule();
  };
  return (
    <div className="card py-10 my-20 mx-auto w-1/2 flex flex-col justify-center items-center gap-8">
      <img src="/img/working.png" alt="" className="w-20 h-20" />

      <h1 className="text-xl font-medium text-[#444]">
        Thank you for your valuable feedback
      </h1>

      <Button text=" OK " width="8rem" handleClick={handleClick} />
    </div>
  );
};

export default SuccessFeedback;
