import React, { useEffect, useRef, useState } from "react";
import { FaRegEdit } from "react-icons/fa";
import { HiDotsHorizontal } from "react-icons/hi";
import { FaAngleDown } from "react-icons/fa";
import { LuClipboard, LuClipboardCheck } from "react-icons/lu";
import { FaRegShareFromSquare } from "react-icons/fa6";
import { Tooltip } from "react-tooltip";
import { Button } from "../../../components";
import JobDescription from "./JobDescription";
import JobDetails from "./JobDetails";
import JobTimeline from "./JobTimeline";
import UpdateJobStatus from "./UpdateJobStatus";
import JobMenu from "./JobMenu";
import { useNavigate } from "react-router-dom";

const JobView = ({ openJobView, setOpenJobView, postedJobs, jobId }) => {
  const [openEditStatus, setOpenEditStatus] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [jobData, setJobData] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    if (jobId) {
      const data = postedJobs?.find((job) => job.jobOpeningId === jobId);
      setJobData(data);
    }
  }, [postedJobs, jobId]);

  const [tab, setTab] = useState("details");
  const [copied, setCopied] = useState(false);
  const copyLink = async (link) => {
    try {
      await navigator.clipboard.writeText(
        `${process.env.REACT_APP_URL}${link}`
      );
      setCopied(true);
      setTimeout(() => setCopied(false), 3000);
    } catch (err) {
      console.error("Failed to copy: ", err);
    }
  };

  const shareLink = async (link) => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: "Link to Apply for - " + jobData?.positionTitle,
          url: process.env.REACT_APP_URL + link,
        });
      } catch (error) {
        console.log("Error sharing link: ", error);
      }
    } else {
      console.log("Web Share API not supported");
    }
  };

  const jobViewRef = useRef(null);
  const menuPopupRef = useRef(null);
  const editStatusPopupRef = useRef(null);

  useEffect(() => {
    if (openJobView || menuOpen || openEditStatus) {
      const handleClickOutside = (event) => {
        jobViewRef.current.contains(event.target) || setOpenJobView(false);
        menuPopupRef.current.contains(event.target) || setMenuOpen(false);
        editStatusPopupRef.current.contains(event.target) ||
          setOpenEditStatus(false);
      };
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }
  }, [openJobView, menuOpen, openEditStatus]);

  return (
    <div className="absolute top-0 left-0 w-full h-screen bg-black/20 backdrop-blur-[2px] flex justify-end z-50">
      <div
        ref={jobViewRef}
        className="relative w-full md:w-[80%] lg:w-1/2 h-[100vh] bg-white shadow-md rounded-l-xl overflow-hidden"
      >
        <div className="absolute top-4 right-6 flex items-center gap-8">
          <div className="relative" ref={menuPopupRef}>
            <div
              className="text-2xl bg-gray-200 rounded-md px-2 flex items-center justify-center cursor-pointer"
              onClick={() => setMenuOpen(!menuOpen)}
            >
              {menuOpen ? <FaAngleDown /> : <HiDotsHorizontal />}
            </div>
            {menuOpen && (
              <JobMenu
                setMenuOpen={setMenuOpen}
                jobData={jobData}
                setOpenJobView={setOpenJobView}
              />
            )}
          </div>
          <div
            className="text-2xl bg-gray-200 rounded-full h-8 w-8 flex items-center justify-center cursor-pointer"
            onClick={() => setOpenJobView(false)}
          >
            ×
          </div>
        </div>

        {/* Header Start  */}
        <div className="w-full h-[24%] p-10 flex gap-4">
          <div className="space-y-3">
            <div className="font-semibold text-2xl capitalize">
              {jobData?.positionTitle}
            </div>

            <div className="flex gap-5 items-center">
              <Button
                text=""
                width="120px"
                version={
                  jobData?.jobStatus === "in-progress"
                    ? "outline-green"
                    : jobData?.jobStatus === "closed"
                    ? "outline-red"
                    : jobData?.jobStatus === "on hold"
                    ? "outline-yellow"
                    : "outline-gray"
                }
              >
                <span className="capitalize">{jobData?.jobStatus}</span>
              </Button>
              <div className="relative" ref={editStatusPopupRef}>
                <div
                  className="flex items-center cursor-pointer"
                  onClick={() => setOpenEditStatus(!openEditStatus)}
                >
                  <FaRegEdit className="w-6 h-6" />
                  <FaAngleDown
                    className={`w-4 h-4 transition duration-300 ${
                      openEditStatus ? "" : "-rotate-90"
                    }`}
                  />
                </div>
                {openEditStatus && (
                  <div className="absolute top-8 left-0 h-screen flex justify-center z-50">
                    <UpdateJobStatus
                      setOpenEditStatus={setOpenEditStatus}
                      jobData={jobData}
                    />
                  </div>
                )}
              </div>

              <div className="border-l-2 border-gray-400 text-gray-500 h-full p-2 px-4">
                Posted on{" "}
                {new Date(
                  jobData?.jobPostedDate?.seconds * 1000
                ).toLocaleDateString("en-in", {
                  day: "numeric",
                  month: "short",
                  year: "numeric",
                })}
              </div>
            </div>
            <div className="flex gap-4 text">
              <div className="flex">
                <button
                  data-tooltip-id="shareTooltip"
                  className="flex items-center justify-center gap-1 px-4 border-2 rounded-l-xl bg-gray-200 border-gray-300 hover:bg-gray-200/60"
                  onClick={() => shareLink(jobData?.applyLink)}
                >
                  <FaRegShareFromSquare className="w-6 h-6" />
                </button>
                <button
                  data-tooltip-id="copyTooltip"
                  className={`flex items-center justify-center gap-1 px-4 border-2 border-l-0 rounded-r-xl
                      ${
                        copied
                          ? "bg-green-500 hover:bg-green-500/90 text-white border-green-500"
                          : "bg-gray-200 border-gray-300 hover:bg-gray-200/60"
                      }
                    `}
                  onClick={() => copyLink(jobData?.applyLink)}
                >
                  {copied ? (
                    <LuClipboardCheck className="w-6 h-6" />
                  ) : (
                    <LuClipboard className="w-6 h-6" />
                  )}
                </button>
                <Tooltip id="shareTooltip" place="bottom" className="text-xs">
                  Share Job Link
                </Tooltip>
                <Tooltip id="copyTooltip" place="bottom" className="text-xs">
                  {copied ? "Copied" : "Copy Job Link"}
                </Tooltip>
              </div>
              <Button
                text="View Applicants"
                handleClick={() =>
                  navigate(
                    `/applicants?tab=applied&jobId=${jobData?.jobOpeningId}`
                  )
                }
              />
            </div>
          </div>
        </div>
        {/* Header End  */}

        {/* tab navigator start */}
        <div className="flex px-5 relative h-[4%] border-b-4 border-gray-300 border-box">
          {["details", "job description", "timeline"].map((item) => (
            <div
              key={item}
              className={`z-10 px-4 py-1 cursor-pointer font-semibold -mb-1 capitalize
              ${
                tab === item
                  ? "border-b-4 border-[#8A24F1] text-[#8A24F1]"
                  : "text-[#888]"
              }`}
              onClick={() => setTab(item)}
            >
              {item}
            </div>
          ))}
        </div>
        {/* tab navigator end */}

        {/* tab Pages view start  */}
        <div className="w-full h-[72%] overflow-y-scroll scrollable">
          {tab === "details" && <JobDetails jobData={jobData} />}
          {tab === "job description" && <JobDescription jobData={jobData} />}
          {tab === "timeline" && <JobTimeline jobData={jobData} />}
        </div>
        {/* tab Pages view end  */}
      </div>
    </div>
  );
};

export default JobView;
