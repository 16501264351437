import React from "react";
import { FaCircleCheck } from "react-icons/fa6";

const Done = () => {
  return (
    <div className="w-full flex justify-center items-center text-center mt-3">
      <div>
        <div className="flex justify-center items-centerss">
          <FaCircleCheck className="text-[#892bcc] w-16 h-16" />
        </div>

        <div className="text-xl font-semibold mt-4">All Done !</div>
        <div className="mt-3">Unlock a world of possibilities !</div>
        <div>Login to your new account and start using all the features.</div>
        <div className="mt-2">Good Luck</div>
      </div>
    </div>
  );
};

export default Done;
