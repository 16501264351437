import React from "react";

import { Button, Timer } from "../../components";

const ExamTopPanel = ({
  exam,
  duration,
  qusNum,
  timerPause,
  timeUp,
  alertSubmit,
  loading,
}) => {
  return (
    <div className="w-[95%] mx-auto mt-[100px] mb-6 py-8 px-[3%] card flex justify-between items-center">
      <div>
        <h1 className="heading2 mb-2">technical skills assesment test</h1>

        <span className="font-medium">Assessment :</span>

        <span className="text-[#919397] capitalize ml-1">{exam}</span>
      </div>

      <div className="flex items-center">
        <Timer
          loading={loading}
          duration={duration}
          qusNum={qusNum}
          timerPause={timerPause}
          timeUp={timeUp}
        />

        <Button text="Finish Test" handleClick={alertSubmit} />
      </div>
    </div>
  );
};

export default ExamTopPanel;
