import React, { useState } from "react";
import { Link } from "react-router-dom";

// import examFunctions from "../exam/examFunctions";
import { handleFullScreen } from "../exam/examFunctions";
import { Button, Checkbox } from "../../components";
import { Timestamp, doc, updateDoc } from "firebase/firestore";
import { db } from "../../config/firebase";

const InstructionsMainPanel = ({ assessemntDetails, scheduleDetails }) => {
  const [checked, setChecked] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleStart = async () => {
    setLoading(true);
    localStorage.removeItem("refresh");
    handleFullScreen();

    // adding attempted data to db
    const scheduleRef = doc(db, "assessmentTests", scheduleDetails?.id);
    try {
      await updateDoc(scheduleRef, {
        attempted: true,
        attemptedAt: Timestamp.now(),
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }

    //   //adding attempted data to google sheet (exam-schedule)
    //   let sheetId = details.dataSheetId
    //   let uid = details.uid
    //   let attemptData = `${sheetId}|${uid}`
    //   let api = process.env.REACT_APP_UPDATE_ATTEMPTED_API
    //   axios.post(api,attemptData)
  };

  return (
    <div className="w-[95%] mx-auto px-16 py-14 card mb-10">
      <h1 className="heading2">Assessment Instructions</h1>
      <p className="text-[#f50] my-5">
        Please read all the instructions carefully before you start
      </p>

      <ul className="listDisc gap-5">
        <li>This is a skill-based Assessment.</li>
        <li>
          This Assessment contains {assessemntDetails?.questions?.length}{" "}
          Questions based on -{" "}
          <span className="capitalize font-medium">
            •{" "}
            {assessemntDetails?.skills
              ?.map((skill) => skill.skillName)
              ?.join(" • ")}
          </span>
          .
        </li>
        <li>
          Each question is Time-based, failing to submit the answer in time will
          automatically result in moving to the next question.
        </li>
        <li>
          You'll not be able to get back to previous questions, so mark the
          Answers Carefully.
        </li>
        <li>A warning will be triggered if you try the following : </li>
        <ol className="listCircle gap-5">
          <li>Exiting the full-screen mode of the browser.</li>
          <li>
            Moving Cursor outside the window or hovering the cursor on other
            Tabs.
          </li>
          <li>Switching the Tab or opening other windows.</li>
          <li>Opening Browsers developer / Inspect tools.</li>
          <li>Minimizing or resizing the window.</li>
          <li>Recording or taking Screenshots during the assessment.</li>
        </ol>
        <li>
          You will get 3 warnings, violating these warnings will result in the
          Termination of the test directly.
        </li>
        <li>You should have a stable internet connection.</li>
        <li>
          The microphone should remain Unmuted and there should be no Background
          Noise.
        </li>
        <li>
          The web camera should be ON and working at all times while taking the
          test.
        </li>
        <li>
          Violating the given instructions will result in the termination of the
          test.
        </li>
      </ul>
      <Checkbox
        checkId="acceptInstructions"
        handleChange={() => setChecked(!checked)}
        labelText="I agree and will make sure not to violate any of the above-given instructions."
      />

      <Link to="/examPage" className="inline-block">
        <Button
          isDisabled={checked ? false : true}
          loading={loading}
          handleClick={handleStart}
          text="Start Assessment"
          width="fit-content"
        />
      </Link>
    </div>
  );
};

export default InstructionsMainPanel;
