import React, { useRef, useState, useEffect } from "react";

import { languageOptions } from "../../../constant/LanguageOptions";
import { query, collection, onSnapshot, addDoc } from "firebase/firestore";
import { db } from "../../../config/firebase";
import { doc, getDoc, updateDoc } from "firebase/firestore";

import { Button, TextInput, SelectInput } from "../../../components";
import CreatableSelect from "react-select/creatable";
import { Timestamp } from "firebase/firestore";
import { toast } from "react-toastify";
import { auth } from "../../../config/firebase";
export const SingleQuestionUpload = ({
  // admin,
  setAddedQuestionsId,
  setAddedQuestions,
  // setAddedQuestions,
  setOpenSingleQuestion,
  assessmentDetails,
  addedQuestions,
  setQuestionUpdate
}) => {
  const qusUploadFormRef = useRef(null);
  const [questionType, setQuestionType] = useState();
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);

  const [questionLibCategoryData, setQuestionLibCategoryData] = useState();
  const [selectedCategory, setSelectedCategory] = useState();
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [skills, setSkills] = useState([]);
  const [selectedSkill, setSelectedSkill] = useState([]);
  const [topics, setTopics] = useState([]);
  const [selectedTopic, setSelectedTopic] = useState([]);

  const [formDetail, setFormDetail] = useState({
    questionName: "",
    statment: "",
    answerSummary: "",
    difficulty: "",
    time: "",
    answer: "",
    sampleInput: "",
    sampleOutput: "",
    defaultCode: "",
    constraints: "",
    testCases: "",
    expectedOutput: ""
  });

  // const [documentData, setDocumentData] = useState();

  useEffect(() => {
    if (assessmentDetails?.id) {
      setQuestionType({
        label:
          assessmentDetails.type.charAt(0).toUpperCase() +
          assessmentDetails.type.slice(1),
        value: assessmentDetails.type
      });
      setSelectedCategory(
        assessmentDetails.category?.map((data) => {
          return { label: data, value: data };
        })
      );
      setSelectedSkill(
        assessmentDetails.skills?.map((data) => {
          return { label: data, value: data };
        })
      );

      setSelectedTopic(
        assessmentDetails.topic?.map((data) => {
          return { label: data, value: data };
        })
      );

      const alphabat = ["A", "B", "C", "D"];
      const correctAnswer =
        assessmentDetails.options &&
        assessmentDetails.options
          .map((data, i) => {
            if (data === assessmentDetails.correctOption) {
              return alphabat[i];
            } else {
              return null;
            }
          })
          ?.filter((data) => data !== null);

      // console.log(correctAnswer);
      setFormDetail({
        questionName: assessmentDetails.questionName,
        statment: assessmentDetails.statement,
        answerSummary: assessmentDetails.answerSummary,
        difficulty: {
          value: assessmentDetails.difficulty,
          label:
            assessmentDetails.difficulty === 1
              ? "Easy"
              : assessmentDetails.difficulty === 2
              ? "Medium"
              : assessmentDetails.difficulty === 3
              ? "Hard"
              : assessmentDetails.difficulty
        },

        time: assessmentDetails.time,
        // answer: assessmentDetails.correctOption,
        answer: correctAnswer[0] ? correctAnswer[0] : "",
        optionA: assessmentDetails.options[0],
        optionB: assessmentDetails.options[1],
        optionC: assessmentDetails.options[2],
        optionD: assessmentDetails.options[3],
        sampleInput: assessmentDetails.sampleInput,
        sampleOutput: assessmentDetails.sampleOutput,
        defaultCode: assessmentDetails.defaultCode,
        constraints: assessmentDetails.constraints,
        testCases: assessmentDetails.testCases,
        expectedOutput: assessmentDetails.expectedOutput,
        programmingLanguage: {
          label: assessmentDetails.difficulty,
          value: assessmentDetails.difficulty
        }
      });

      // set form value through ref
      if (qusUploadFormRef) {
        // qusUploadFormRef.current.questionName.value =
        //   assessmentDetails.questionName;
        // qusUploadFormRef.current.statment.value = assessmentDetails.statement;
        // qusUploadFormRef.current.statment.value = assessmentDetails.statement;
        // qusUploadFormRef.current.answerSummary.value =
        //   assessmentDetails?.answerSummary
        //     ? assessmentDetails?.answerSummary
        //     : "";
        // qusUploadFormRef.current.difficulty.value = assessmentDetails.difficulty;
        // qusUploadFormRef.current.difficulty.value = {
        //   label: assessmentDetails.difficulty,
        //   value: assessmentDetails.difficulty
        // };
        // qusUploadFormRef.current.optionA.value = assessmentDetails.;
        // qusUploadFormRef.current.time.value = assessmentDetails.time;
      }

      // answerSummary
    }
  }, [assessmentDetails]);
  // console.log(formDetail);

  useEffect(() => {
    const fetchQuestionCategoryData = async () => {
      const q = query(collection(db, "questionLibraryCategories"));
      onSnapshot(q, (querySnapshot) => {
        const categorydata = [];
        querySnapshot.forEach((doc) => {
          categorydata.push({ id: doc.id, ...doc.data() });
        });

        setQuestionLibCategoryData(categorydata);
        setCategoryOptions(
          categorydata?.map((data) => {
            return {
              label: data.name.charAt(0).toUpperCase() + data.name.slice(1),
              value: data.name
            };
          })
        );
      });
    };

    fetchQuestionCategoryData();
  }, []);

  useEffect(() => {
    const catSkill = questionLibCategoryData?.filter((data) =>
      selectedCategory?.some((selectedCat) => selectedCat.value === data.name)
    );
    const allSkillData = catSkill?.map((data) => data.skills).flat();
    const uniqueSkillData =
      allSkillData &&
      Object.values(
        allSkillData?.reduce((acc, current) => {
          // Use the skill as the key to check uniqueness
          const key = current.skill;
          // If the skill hasn't been encountered yet, add it to the accumulator
          if (!acc[key]) {
            acc[key] = { ...current };
          }
          // Merge topics if the skill has already been encountered
          else if (current.topics) {
            acc[key].topics = [
              ...new Set([...acc[key].topics, ...current.topics])
            ];
          }
          return acc;
        }, {})
      );
    setSkills(catSkill && uniqueSkillData);

    if (selectedSkill) {
      const topic = catSkill
        ?.map((data) => data.skills)
        .flat()
        .filter((skil) =>
          selectedSkill?.some((selskil) => selskil.value === skil.skill)
        )
        .map((topic) => topic.topics)
        .flat();
      setTopics(topic);
    }
  }, [selectedCategory, selectedSkill, questionLibCategoryData, selectedTopic]);

  const optionInputs = [
    {
      placeholder: "Option A",
      name: "optionA"
    },
    {
      placeholder: "Option B",
      name: "optionB"
    },
    {
      placeholder: "Option C",
      name: "optionC"
    },
    {
      placeholder: "Option D",
      name: "optionD"
    }
  ];

  const createNewCategory = async (inputValue) => {
    const newValue = {
      value: inputValue.toLowerCase(),
      label: inputValue
    };
    setSelectedCategory([newValue]);
    const createNewCategoryData = {
      createdAt: Timestamp.now(),
      name: inputValue,
      skills: []
    };
    try {
      await addDoc(
        collection(db, "questionLibraryCategories"),
        createNewCategoryData
      );
    } catch (error) {
      console.log(error.message);
    }
  };

  const createNewSkill = async (inputValue) => {
    const newValue = {
      value: inputValue.toLowerCase(),
      label: inputValue
    };
    setSelectedSkill([...selectedSkill, newValue]);
    const catSkill = questionLibCategoryData?.filter((data) =>
      selectedCategory?.some((selectedCat) => selectedCat.value === data.name)
    );
    console.log(catSkill);
    // let updateSkillValue = [];
    for (let i = 0; i < catSkill.length; i++) {
      const createNewSkillData = {
        modifiedAt: Timestamp.now(),
        skills: [
          ...catSkill[i].skills,
          { skill: inputValue.toLowerCase(), topics: [] }
        ]
      };
      try {
        const userRef = doc(db, "questionLibraryCategories", catSkill[i].id);
        await updateDoc(userRef, createNewSkillData);
      } catch (error) {
        console.error("Error updating document: ", error);
      }
    }
  };

  const createNewTopic = async (inputValue) => {
    const newValue = {
      value: inputValue.toLowerCase(),
      label: inputValue
    };
    setSelectedTopic([...selectedTopic, newValue]);
    const catSkill = questionLibCategoryData?.filter((data) =>
      selectedCategory?.some((selectedCat) => selectedCat.value === data.name)
    );
    for (let i = 0; i < catSkill.length; i++) {
      let updateTopics = [];
      for (let j = 0; j < catSkill[i].skills.length; j++) {
        if (
          selectedSkill.some(
            (data) => data.value === catSkill[i].skills[j].skill
          )
        ) {
          const existingSkills =
            catSkill[i].skills[j].topics?.length > 0
              ? [catSkill[i].skills[j].topics, inputValue.toLowerCase()]
              : [inputValue];
          // console.log(existingSkills);
          updateTopics.push({
            skill: catSkill[i].skills[j].skill,
            topics: existingSkills.flat()
          });
        } else {
          // let existingSkill =
          updateTopics.push({
            skill: catSkill[i].skills[j].skill,
            topics: catSkill[i].skills[j].topics
          });
        }
      }
      console.log({ modifiedAt: Timestamp.now(), skills: updateTopics });
      try {
        const userRef = doc(db, "questionLibraryCategories", catSkill[i].id);
        await updateDoc(userRef, {
          modifiedAt: Timestamp.now(),
          skills: updateTopics
        });
      } catch (error) {
        console.error("Error updating document: ", error);
      }
    }
  };

  const handleUpload = async (e) => {
    e.preventDefault();
    const formData = qusUploadFormRef?.current;

    setError("");
    setLoading(true);

    const dbQuestionData = {
      category: selectedCategory?.map((data) => data.value),
      skills: selectedSkill.map((data) => data.value),
      topic: selectedTopic.map((data) => data.value),
      options:
        questionType.value === "mcq"
          ? optionInputs.map((option) => formData[option.name]?.value)
          : "",
      difficulty: Number(formData?.difficulty.value),
      statement: formData?.statment?.value,
      type: questionType.value,
      correctOption:
        questionType.value === "mcq"
          ? formData[`option${formData?.answer?.value}`]?.value
          : "",
      answerSummary:
        questionType.value === "subjective"
          ? formData?.answerSummary?.value
          : "",
      isDeleted: false,
      createdAt: Timestamp.now(),
      // updatedAt: Timestamp.now(),
      addedBy: auth?.currentUser.displayName,
      questionName: formData?.questionName?.value,
      time: Number(formData?.time?.value)
    };

    const UpdateddbQuestionData = {
      category: selectedCategory?.map((data) => data.value),
      skills: selectedSkill.map((data) => data.value),
      topic: selectedTopic.map((data) => data.value),
      options:
        questionType.value === "mcq"
          ? optionInputs.map((option) => formData[option.name]?.value)
          : "",
      difficulty: Number(formData?.difficulty.value),
      statement: formData?.statment?.value,
      type: questionType.value,
      correctOption:
        questionType.value === "mcq"
          ? formData[`option${formData?.answer?.value}`]?.value
          : "",
      answerSummary:
        questionType.value === "subjective"
          ? formData?.answerSummary?.value
          : "",
      isDeleted: false,
      createdAt: assessmentDetails.createdAt,
      updatedAt: Timestamp.now(),
      addedBy: assessmentDetails.addedBy,
      questionName: formData?.questionName?.value,
      time: Number(formData?.time?.value)
    };

    if (assessmentDetails?.id) {
      //  check collection Name to update question lies in questions
      console.log("working", addedQuestions);

      try {
        const docRef = doc(db, "customQuestionLibrary", assessmentDetails.id);
        const res = await updateDoc(docRef, UpdateddbQuestionData);
        // console.log('Document updated successfully!');
        // console.log("working", res);

        // console.log("working", res);
        // console.log(addedQuestions)
        // console.log( addedQuestions.map((data) => {
        //   if (data.id === assessmentDetails.id) {
        //     console.log(data.id,assessmentDetails.id)
        //     return { ...UpdateddbQuestionData, id: assessmentDetails.id };
        //   } else {
        //     return data;
        //   }
        // }))
        setLoading(false);

        // setAddedQuestions(
        //   addedQuestions.map((data) => {
        //     if (data.id === assessmentDetails.id) {
        //       return { ...UpdateddbQuestionData, id: assessmentDetails.id };
        //     } else {
        //       return data;
        //     }
        //   })
        // );

        // setAddedQuestions([])

        toast.success("Success! Question Updated");

        // setQuestionUpdate(true);
      } catch (error) {
        // console.error('Error updating document:', error);
        toast.error(`Error! Unable to Updte Question. (${error.message})`);
      }
      // finally {
      // }
    }
    //  else if (assessmentDetails?.editQuestionOnAssessementDocId) {
    //   console.log(UpdateddbQuestionData);
    //   try {
    //     const uploadQues = await addDoc(
    //       collection(db, "customQuestionLibrary"),
    //       dbQuestionData
    //     );
    //     console.log(uploadQues?.id);
    //     if (uploadQues?.id) {
    //       const existquestion = assessmentDetails.questions;

    //       const docRef = doc(db,"assessments",assessmentDetails?.editQuestionOnAssessementDocId);
    //       await updateDoc(docRef, {questions: [...existquestion, uploadQues?.id],totalQuestions:  Number(existquestion.length+1)  });
    //       setOpenSingleQuestion(false);
    //     }
    //   } catch (error) {}
    //   setLoading(false);
    // }
    else {
      try {
        const uploadQues = await addDoc(
          collection(db, "customQuestionLibrary"),
          dbQuestionData
        );
        setAddedQuestionsId((prev) => [...prev, uploadQues?.id]);
        setAddedQuestions((prev) => [
          ...prev,
          { ...dbQuestionData, id: uploadQues?.id }
        ]);
        setOpenSingleQuestion(false);
        toast.success("Success! Question Added");
        setLoading(false);
      } catch (error) {
        console.log(error.message);
        toast.error(`Error! Unable to Add Question. (${error.message})`);
        setLoading(false);
      }
      //  finally {
      // }
    }
  };
  // console.log(assessmentDetails);
  console.log(addedQuestions);
  // console.log(admin);
  return (
    <div className="w-[40rem]">
      <form
        ref={qusUploadFormRef}
        className="flex flex-col justify-center items-center gap-2"
        onSubmit={handleUpload}
      >
        <div className="w-full px-8">
          <h1 className="heading2">
            {" "}
            {assessmentDetails?.editDocId
              ? "Update a Question"
              : "Add a Question"}
          </h1>
        </div>
        <div className="flex flex-col gap-2 w-full px-8">
          <div className="flex flex-col sm:flex-row gap-2">
            <div className="w-full">
              <label className="text-xs mb-1 text-[#888]">
                Question Type<sup>*</sup>
              </label>

              <SelectInput
                selectOptions={[
                  { value: "mcq", label: "MCQ" },
                  { value: "subjective", label: "Subjective" },
                  { value: "code", label: "Code" }
                ]}
                inputName="type"
                value={questionType}
                handleChange={(selectedValue) => setQuestionType(selectedValue)}
                placeholderText="Question Type"
                isClearable
                isRequired
              />
            </div>
            <div className="w-full">
              <label className="text-xs mb-1 text-[#888]">
                Category<sup>*</sup>
              </label>

              <CreatableSelect
                styles={{ backgroundColor: "lightblue" }}
                name="category"
                options={categoryOptions}
                value={selectedCategory}
                onChange={(selectedValue) => setSelectedCategory(selectedValue)}
                placeholder="Select Category"
                isClearable
                isMulti
                // menuIsOpen={selectedCategory}
                onCreateOption={(inputValue) => createNewCategory(inputValue)}
              />
            </div>
          </div>
          <div>
            <div className="flex flex-col sm:flex-row gap-2">
              <div className={`w-full ${selectedCategory ? "" : "opacity-40"}`}>
                <label className="text-xs mb-1 text-[#888]">
                  Skills<sup>*</sup>
                </label>
                <CreatableSelect
                  options={skills?.map((catskil) => {
                    return {
                      label:
                        catskil.skill?.charAt(0).toUpperCase() +
                        catskil.skill?.slice(1),
                      value: catskil.skill
                    };
                  })}
                  value={selectedSkill}
                  onChange={(selectedValue) => setSelectedSkill(selectedValue)}
                  placeholder="Select Skill"
                  isClearable
                  isMulti
                  isDisabled={!selectedCategory}
                  onCreateOption={(inputValue) => createNewSkill(inputValue)}
                />
              </div>
              <div
                className={`w-full ${
                  selectedSkill.length > 0 ? "" : "opacity-40"
                }`}
              >
                <label className="text-xs mb-1 text-[#888]">
                  Topic<sup>*</sup>
                </label>
                <CreatableSelect
                  options={topics?.map((skil) => {
                    return {
                      label: skil?.charAt(0).toUpperCase() + skil?.slice(1),
                      value: skil
                    };
                  })}
                  value={selectedTopic}
                  isMulti
                  onChange={(selectedValue) => setSelectedTopic(selectedValue)}
                  placeholder="Select Topic"
                  isClearable
                  isDisabled={!selectedSkill.length > 0}
                  onCreateOption={(inputValue) => createNewTopic(inputValue)}
                />
              </div>
            </div>

            <div className="mt-5">
              <label className="text-xs mb-1 text-[#888]">
                Question Name <sup>*</sup>{" "}
              </label>
              <TextInput
                type="text"
                inputName="questionName"
                textRows={1}
                isRequired={true}
                inputValue={formDetail.questionName}
                handleChange={(e) => {
                  setFormDetail({
                    ...formDetail,
                    [e.target.name]: e.target.value
                  });
                }}
                placeholderText="Question Name"
              />
            </div>

            <div className="mt-4">
              <label className="text-xs mb-1 text-[#888]">
                {questionType?.value === "code"
                  ? "Problem Statment"
                  : "Question Statement"}
                <sup>*</sup>
              </label>
              <TextInput
                type="textbox"
                textRows="4"
                inputName="statment"
                placeholderText={
                  questionType?.value === "code"
                    ? "Problem Statment"
                    : "Question Statement"
                }
                isRequired={true}
                inputValue={formDetail.statment}
                handleChange={(e) => {
                  setFormDetail({
                    ...formDetail,
                    [e.target.name]: e.target.value
                  });
                }}
              />
            </div>
            {questionType?.value === "mcq" ? (
              <div className="mt-2">
                <label className="text-xs mb-1 text-[#888]">
                  Options<sup>*</sup>
                </label>
                <div className="flex flex-col gap-2 my-2">
                  {[0, 2].map((i) => {
                    return (
                      <div key={i} className="flex flex-col sm:flex-row gap-2">
                        {optionInputs.slice(i, i + 2).map((optionInput) => {
                          return (
                            <TextInput
                              key={optionInput.placeholder}
                              placeholderText={optionInput.placeholder}
                              inputName={optionInput.name}
                              inputValue={formDetail[optionInput.name]}
                              handleChange={(e) => {
                                setFormDetail({
                                  ...formDetail,
                                  [e.target.name]: e.target.value
                                });
                              }}
                              innerLabel={
                                optionInput.placeholder.slice(-1) + "."
                              }
                            />
                          );
                        })}
                      </div>
                    );
                  })}
                </div>
              </div>
            ) : questionType?.value === "subjective" ? (
              <div>
                <label className="text-xs mb-1 text-[#888]">
                  Answer Summary<sup>*</sup>
                </label>
                <TextInput
                  type="textbox"
                  textRows="4"
                  placeholderText="A brief Summary of the answer"
                  inputName="answerSummary"
                  isRequired={questionType?.value === "subjective"}
                  inputValue={formDetail.answerSummary}
                  handleChange={(e) => {
                    setFormDetail({
                      ...formDetail,
                      [e.target.name]: e.target.value
                    });
                  }}
                />
              </div>
            ) : questionType?.value === "code" ? (
              <>
                <div className="flex flex-col md:flex-row gap-2">
                  <div className="w-full">
                    <label className="text-xs mb-1 text-[#888]">
                      Sample Input<sup>*</sup>
                    </label>
                    <TextInput
                      type="textbox"
                      textRows="2"
                      placeholderText="Sample input"
                      inputName="sampleInput"
                      isRequired={questionType.value === "code"}
                      inputValue={formDetail.sampleInput}
                      handleChange={(e) => {
                        setFormDetail({
                          ...formDetail,
                          [e.target.name]: e.target.value
                        });
                      }}
                      //   inputValue={sampleInput}
                      //   handleChange={(e) => setSampleInput(e.target.value)}
                    />
                  </div>
                  <div className="w-full">
                    <label className="text-xs mb-1 text-[#888]">
                      Sample output<sup>*</sup>
                    </label>
                    <TextInput
                      type="textbox"
                      textRows="2"
                      placeholderText="Sample output"
                      inputName="sampleOutput"
                      isRequired={questionType.value === "code"}
                      inputValue={formDetail.sampleOutput}
                      handleChange={(e) => {
                        setFormDetail({
                          ...formDetail,
                          [e.target.name]: e.target.value
                        });
                      }}
                      //   inputValue={sampleOutput}
                      //   handleChange={(e) => setSampleOutput(e.target.value)}
                    />
                  </div>
                </div>
                <div className="flex flex-col md:flex-row gap-2">
                  <div className="w-full">
                    <label className="text-xs mb-1 text-[#888]">
                      Default Code
                    </label>
                    <TextInput
                      type="textbox"
                      textRows="2"
                      placeholderText="Default Code"
                      inputName="defaultCode"
                      inputValue={formDetail.defaultCode}
                      handleChange={(e) => {
                        setFormDetail({
                          ...formDetail,
                          [e.target.name]: e.target.value
                        });
                      }}
                      //   inputValue={defaultCode}
                      //   handleChange={(e) => setDefaultCode(e.target.value)}
                    />
                  </div>
                  <div className="w-full">
                    <label className="text-xs mb-1 text-[#888]">
                      Constraints
                    </label>
                    <TextInput
                      type="textbox"
                      textRows="2"
                      placeholderText="constraints"
                      inputName="constraints"
                      inputValue={formDetail.constraints}
                      handleChange={(e) => {
                        setFormDetail({
                          ...formDetail,
                          [e.target.name]: e.target.value
                        });
                      }}
                      //   inputValue={constraints}
                      //   handleChange={(e) => setConstraints(e.target.value)}
                    />
                  </div>
                </div>
                <div className="flex flex-col md:flex-row gap-2">
                  <div className="w-full">
                    <label className="text-xs mb-1 text-[#888]">
                      Test Cases
                    </label>
                    <TextInput
                      type="textbox"
                      textRows="2"
                      placeholderText="Test Cases"
                      inputName="testCases"
                      inputValue={formDetail.testCases}
                      handleChange={(e) => {
                        setFormDetail({
                          ...formDetail,
                          [e.target.name]: e.target.value
                        });
                      }}
                      //   inputValue={testCases}
                      //   handleChange={(e) => setTestCases(e.target.value)}
                    />
                  </div>
                  <div className="w-full">
                    <label className="text-xs mb-1 text-[#888]">
                      Expected Output
                    </label>
                    <TextInput
                      type="textbox"
                      textRows="2"
                      placeholderText="Expected Output"
                      inputName="expectedOutput"
                      inputValue={formDetail.expectedOutput}
                      handleChange={(e) => {
                        setFormDetail({
                          ...formDetail,
                          [e.target.name]: e.target.value
                        });
                      }}
                      //   inputValue={expectedOutput}
                      //   handleChange={(e) => setExpectedOutput(e.target.value)}
                    />
                  </div>
                </div>
              </>
            ) : (
              <></>
            )}
            <div className="flex gap-2 mb-4 w-full flex-col sm:flex-row">
              {questionType?.value === "mcq" ? (
                <div className="w-[50%] mt-2">
                  <label className="text-xs mb-1 text-[#888]">
                    Correct Option<sup>*</sup>
                  </label>
                  <TextInput
                    type="select"
                    idName="answer"
                    inputName="answer"
                    placeholderText="Select Correct Answer"
                    isRequired={true}
                    inputValue={formDetail.answer}
                    handleChange={(e) => {
                      setFormDetail({
                        ...formDetail,
                        [e.target.name]: e.target.value
                      });
                    }}
                    // inputValue={answer}
                    // handleChange={(e) => setAnswer(e.target.value)}
                    selectOptions={["A", "B", "C", "D"]}
                  />
                </div>
              ) : questionType?.value === "code" ? (
                <div className="w-full">
                  <label className="text-xs mb-1 text-[#888]">
                    Programming Language
                  </label>
                  <SelectInput
                    placeholderText={`Select programming language`}
                    selectOptions={languageOptions}
                    inputName="programmingLanguage"
                    value={formDetail?.programmingLanguage}
                    handleChange={(e) => {
                      setFormDetail({
                        ...formDetail,
                        programmingLanguage: e
                      });
                    }}
                    // handleChange={(selectedOption) =>
                    //   setLanguage(selectedOption)
                    // }
                    isClearable={true}
                    isSearchable={true}
                  />
                </div>
              ) : (
                <></>
              )}
            </div>
            <div className="flex flex-col md:flex-row gap-2 mt-3">
              <div className="w-full">
                <label className="text-xs my-1 text-[#888]">
                  Question Difficulty<sup>*</sup>
                </label>

                <SelectInput
                  selectOptions={[
                    { value: 1, label: "Easy" },
                    { value: 2, label: "Medium" },
                    { value: 3, label: "Hard" }
                  ]}
                  placeholderText="Question difficulty"
                  // defaultValue={}
                  inputName="difficulty"
                  value={formDetail.difficulty}
                  // value={{
                  //   value: formDetail.difficulty,
                  //   label:
                  //     formDetail.difficulty === 1
                  //       ? "Easy"
                  //       : formDetail.difficulty === 2
                  //       ? "Medium"
                  //       : formDetail.difficulty === 3
                  //       ? "Hard"
                  //       : formDetail.difficulty
                  // ?formDetail.difficulty:"Question Difficulty"
                  // }}
                  handleChange={(e) => {
                    setFormDetail({
                      ...formDetail,
                      difficulty: e
                    });
                  }}
                  isClearable
                  isRequired
                />
              </div>
              <div className="w-full">
                <label className="text-xs my-1 text-[#888]">
                  Alloted Time<sup>*</sup> (seconds)
                </label>
                <TextInput
                  type="number"
                  inputName="time"
                  placeholderText="Question Duration (in sec)"
                  inputValue={formDetail.time}
                  handleChange={(e) => {
                    setFormDetail({
                      ...formDetail,
                      [e.target.name]: e.target.value
                    });
                  }}
                  isRequired={true}
                />
              </div>
            </div>
            {/* {error && (
              <div className="text-red-500 text-center text-sm">{error}</div>
            )} */}
            <div className="w-full flex mt-4 items-center justify-end gap-4">
              {/* <Button text="Preview" version="outline-primary" /> */}
              <Button
                type="submit"
                text={
                  assessmentDetails?.id
                    ? "Update Question "
                    : "Add Question"
                }
                width="10rem"
                loading={loading}
              />
            </div>
            {/* </div> */}
          </div>
        </div>
      </form>
    </div>
  );
};
