import React, { useEffect, useRef, useState } from "react";
import { auth, db } from "../../config/firebase";
import {
  EmailAuthProvider,
  RecaptchaVerifier,
  linkWithCredential,
  sendEmailVerification,
  signInWithPhoneNumber,
  updateProfile,
} from "firebase/auth";
import { Link, useNavigate } from "react-router-dom";
import { Button, Logo, TextInput } from "../../components";
import { validateEmail } from "../../utils/validate";
import { validatePhoneNumber } from "../../utils/validate";
import {
  collection,
  query,
  where,
  getDocs,
  updateDoc,
  addDoc,
} from "firebase/firestore";
import { IoMdArrowRoundBack } from "react-icons/io";
import axios from "axios";
import registerEmailTemplate from "../../utils/emailTemplates/registrationSucess";

const CandidateRegister = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [otp, setOtp] = useState("");
  const [showOtpModal, setShowOtpModal] = useState(false);
  const recaptchaRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [otpLoading, setOtpLoading] = useState(false);
  const [confirmationResult, setConfirmationResult] = useState(null);
  const [nameError, setNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const [resendOtpCounter, setResendOtpCounter] = useState(0);

  useEffect(() => {
    const userString = localStorage.getItem("user");
    const localStorageUser = JSON.parse(userString);
    if (localStorageUser) {
      setName(localStorageUser.name);
      setEmail(localStorageUser.email);
      setPhone(localStorageUser.phone);
    }
  }, []);

  const inputFields = [
    {
      name: "name",
      label: "Full Name",
      type: "text",
      state: name,
      setState: setName,
      placeholder: "Full Name",
    },
    {
      name: "email",
      label: "Email Id",
      type: "email",
      state: email,
      setState: setEmail,
      placeholder: "Email Id",
    },
    {
      name: "phone",
      label: "Phone Number",
      type: "tel",
      state: phone,
      setState: setPhone,
      placeholder: "Phone Number",
    },
  ];

  // resend otp count-down function
  useEffect(() => {
    const intervalId = setInterval(() => {
      let counts = resendOtpCounter - 1;
      setResendOtpCounter(counts > 0 ? counts : 0);
    }, 1000);
    return () => clearInterval(intervalId);
  }, [resendOtpCounter]);

  const signup = async (e) => {
    e.preventDefault();
    setNameError("");
    setEmailError("");
    setPhoneError("");
    setError("");

    if (name === "") setNameError("Name is required");
    if (email === "") setEmailError("Email is required");
    if (!validateEmail(email)) setEmailError("Invalid Email");
    if (phone === "") setPhoneError("Phone number required");
    if (!validatePhoneNumber(phone)) setPhoneError("Invalid Mobile number");

    if (name === "" || !validateEmail(email) || !validatePhoneNumber(phone))
      return;

    const phoneNumber = `+91${phone}`;
    setLoading(true);
    // recaptchaRef.current.innerHTML = ""; // Clear the container
    if (!window.recaptchaVerifier) {
      window.recaptchaVerifier = new RecaptchaVerifier(
        auth,
        recaptchaRef.current,
        {
          size: "invisible",
        }
      );
    }
    window.recaptchaVerifier.render();
    const appVerifier = window.recaptchaVerifier;
    try {
      // Send OTP to phone number
      const result = await signInWithPhoneNumber(
        auth,
        phoneNumber,
        appVerifier
      );
      setResendOtpCounter(60);
      setConfirmationResult(result);

      // Display OTP verification modal
      setShowOtpModal(true);
      setLoading(false);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const sendEmail = async () => {
    const { subject, body } = registerEmailTemplate({ candidateName: name });
    const emailData = `${email}|${subject}|${body}`;
    const mailApi = process.env.REACT_APP_EMAIL_API;
    const emailResponse = await axios.post(mailApi, emailData);
    return emailResponse;
  };

  const submitOTP = async (e) => {
    e.preventDefault();
    setOtpLoading(true);

    const password = (Math.random() + 1).toString(36).substring(6);

    const emailCredential = EmailAuthProvider.credential(email, password);
    try {
      // Verify OTP
      await confirmationResult.confirm(otp);

      // Link the phone authentication credential to the email/password account
      await linkWithCredential(auth.currentUser, emailCredential);

      // Update user profile with display name
      await updateProfile(auth.currentUser, { displayName: name });

      // send verification email -
      await sendEmailVerification(auth.currentUser).catch((err) =>
        console.log("error - ", err)
      );

      // send registration success email to candidate
      await sendEmail();

      //check if the candidate is there in candidate profile and add uid to candidateProfile
      const userQuery = query(
        collection(db, "candidateProfile"),
        where("email", "==", email)
      );
      const candidateDetails = {
        name,
        email,
        phoneNumber: "+91" + phone,
        uid: auth.currentUser?.uid,
      };

      await getDocs(userQuery).then(async (querySnapshot) => {
        if (querySnapshot.empty) {
          await addDoc(collection(db, "candidateProfile"), candidateDetails);
        } else {
          querySnapshot.forEach((doc) => {
            updateDoc(doc.ref, {
              uid: auth.currentUser?.uid,
            });
          });
        }
      });

      // close the OTP modal and navigate to onboarding process
      setShowOtpModal(false);

      // navigate to dashboard
      navigate("/candidateDashboard");
    } catch (error) {
      setError(
        error.message === "Firebase: Error (auth/provider-already-linked)."
          ? "Your Account already exist, Please Login"
          : error.message
      );
      setShowOtpModal(false);
      setOtp("");
    }
    setOtpLoading(false);
  };

  return (
    <div className="admin-main">
      <div className="admin-card flex justify-center items-center h-full sm:py-5 w-full gradient2">
        <div className="h-full w-full flex flex-col justify-center items-center p-10 sm:px-14 md:px-20 gap-2">
          <Logo />
          {showOtpModal ? (
            <>
              <div className="flex ">
                <h1>Enter Otp</h1>
              </div>
              <form className="flex flex-col gap-4 w-full" onSubmit={submitOTP}>
                <div className="flex flex-col mb-[14px]">
                  <label
                    htmlFor="otp"
                    className="font-medium text-sm leading-[12px] mb-1"
                  >
                    Enter OTP sent to your phone
                  </label>
                  <TextInput
                    type="text"
                    placeholderText="Enter OTP"
                    idName="otp"
                    inputValue={otp}
                    handleChange={(e) => setOtp(e.target.value)}
                    isRequired={true}
                    isDisabled={otpLoading}
                  />
                </div>

                <div className="flex flex-col items-center justify-center gap-2">
                  <Button
                    text="Submit OTP"
                    type="submit"
                    loading={otpLoading}
                  />
                </div>
              </form>
              <div className="flex w-full items-center">
                <button
                  className="flex items-center gap-2 w-[50%]"
                  onClick={() => setShowOtpModal(false)}
                >
                  <IoMdArrowRoundBack className="w-4 h-4" />
                  <span className="font-medium text-sm">
                    Change
                    <span className="ml-1">({phone})</span> ?
                  </span>
                </button>
                <button
                  className="w-[50%] flex justify-end items-baseline"
                  onClick={signup}
                  disabled={resendOtpCounter > 0 ? true : false}
                >
                  <span
                    className={`font-medium ${
                      resendOtpCounter === 0
                        ? "hover:underline"
                        : "text-gray-400"
                    }`}
                  >
                    Resend Otp{resendOtpCounter > 0 ? "?" : ""}
                  </span>
                  {resendOtpCounter > 0 ? (
                    <div className="text-sm flex">
                      <div className="font-medium w-6 text-right">
                        {resendOtpCounter}
                      </div>
                      sec
                    </div>
                  ) : (
                    ""
                  )}
                </button>
              </div>
            </>
          ) : (
            <>
              <h1>Create an account</h1>
              <form className="flex flex-col w-full gap-2" onSubmit={signup}>
                {inputFields?.map((field) => {
                  return (
                    <div className="flex flex-col" key={field.name}>
                      <label
                        htmlFor={field.name}
                        className="font-medium text-sm leading-[12px] mb-2"
                      >
                        {field.label}
                      </label>
                      <TextInput
                        type={field.type}
                        idName={field.name}
                        placeholderText={field.placeholder}
                        innerLabel={field.name === "phone" ? "+91" : ""}
                        inputValue={field.state}
                        // isRequired={true}
                        isDisabled={loading}
                        handleChange={(e) => field.setState(e.target.value)}
                      />
                      <div className="text-sm h-4 text-red-500">
                        {field.name === "name"
                          ? nameError
                          : field.name === "email"
                          ? emailError
                          : field.name === "phone"
                          ? phoneError
                          : ""}
                      </div>
                    </div>
                  );
                })}
                <div className="flex flex-col items-center justify-center gap-2">
                  {
                    <div className="font-medium text-xs h-3 text-[#f00]">
                      {error}
                    </div>
                  }
                  <Button
                    width="200px"
                    text="Sign Up"
                    type="submit"
                    loading={loading}
                    // isDisabled={loading}
                  />
                </div>
              </form>
            </>
          )}
          <div ref={recaptchaRef} />
          <div className="mt-2 flex flex-col justify-center items-center gap-2 w-full">
            <p className="text-sm">OR</p>
            <div className="flex justify-center gap-2 w-full">
              Already have an account?
              <Link
                to="/candidateLogin"
                className="font-medium hover:underline"
              >
                Login
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CandidateRegister;
