import React, { useState, useCallback, useEffect } from "react";
import { storage } from "../../../config/firebase";
import {
  deleteObject,
  getDownloadURL,
  ref,
  uploadBytesResumable,
} from "firebase/storage";
import { Timestamp, updateDoc, doc } from "firebase/firestore";
import { db } from "../../../config/firebase";
import { useDropzone } from "react-dropzone";
import { MdDelete } from "react-icons/md";
import { FiRepeat } from "react-icons/fi";
import { FaImage } from "react-icons/fa6";
import ProgressBorder from "../../../components/ProgressBorder";

const UploadLogo = ({ url, setUrl, uploadedLogoUrl, adminDocId }) => {
  const width = 140;
  const height = 140;

  const [selectedFile, setSelectedFile] = useState();
  const [error, setError] = useState("");
  const [progress, setProgress] = useState(0);
  const [uploading, setUploading] = useState(false);
  const [fileRef, setFileRef] = useState();
  const [imageStatus, setImageStatus] = useState("");

  //    useEffect(()=>{setUrl(url)},[url])
  useEffect(() => {
    if (uploadedLogoUrl) {
      //  from candidate resume
      // const storage = getStorage();
      const storageRef = ref(storage, uploadedLogoUrl);
      setFileRef(storageRef);

      // const fileId = registerDetail?.url.split("logo_")[1].split("?")[0];
      //   const fileRef = ref(storage, `companyLogo/logo_${fileId}`);
      //   setFileRef(fileRef);
      setUrl(uploadedLogoUrl);
      setUploading(false);
      setSelectedFile(true);
    }
  }, [uploadedLogoUrl]);

  // useEffect(() => {
  //   const deleteFile = async () => {
  //     // try {
  //     // await deleteObject(fileRef);

  //     if (adminDocId) {
  //       // console.log(adminDocId);
  //       try {
  //         const documentRef = doc(db, "adminProfile", adminDocId);
  //         await updateDoc(documentRef, { logoUrl: "" });
  //         console.log("doc Update");
  //         setImageStatus("");
  //       } catch (error) {
  //         console.log(error.message);
  //       }
  //     }
  //     // } catch (error) {
  //     //   throw error;
  //     // }
  //   };

  //   const updateFile = async () => {
  //     if (adminDocId) {
  //       // console.log(adminDocId);
  //       try {
  //         const documentRef = doc(db, "adminProfile", adminDocId);
  //         await updateDoc(documentRef, { logoUrl: url });
  //         console.log("doc Update");
  //         setImageStatus("");
  //       } catch (error) {
  //         console.log(error.message);
  //       }
  //     }
  //   };
  //   if (imageStatus === "delete") deleteFile();
  //   if (imageStatus === "update") updateFile();
  // }, [url]);

  const onDrop = useCallback(
    (acceptedFiles) => {
      setUrl("");
      setSelectedFile(acceptedFiles[0]);
      uploadFile(acceptedFiles[0]);
    },
    [adminDocId]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      "image/png": [],
      "image/jpeg": [],
    },
  });

  const handleDelete = async () => {
    // return console.log('delete',adminDocId)
    setUrl();
    setImageStatus("delete");
    try {
      await deleteObject(fileRef);

      if (adminDocId) {
        //  console.log(adminDocId)
        try {
          const documentRef = doc(db, "adminProfile", adminDocId);
          await updateDoc(documentRef, { logoUrl: "" });
          // console.log("doc Update");
        } catch (error) {
          console.log(error.message);
        }
      }
    } catch (error) {
      throw error;
    }
  };
  const uploadFile = async (file) => {
    // console.log('update 0' , adminDocId)
    // return console.log('update return', adminDocId)
    const logoId = (Math.random() + 1).toString(36).substring(4);
    if (!file) return;
    let isCancelled = false;

    try {
      setUploading(true);
      const fileRef = ref(storage, `companyLogo/logo_${logoId}`);
      setFileRef(fileRef);

      //upload progress
      const uploadTask = uploadBytesResumable(fileRef, file);

      // Function to cancel the upload
      const cancelUpload = () => {
        isCancelled = true;
        uploadTask.cancel();
        setUploading(false);
        setProgress(0);
        setSelectedFile(null);
      };
      // Expose cancelUpload function for external use
      window.cancelUpload = cancelUpload;

      // console.log('update', adminDocId)

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          if (isCancelled) return; // Check if upload is cancelled
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setProgress(progress);
        },
        (error) => {
          if (isCancelled) return; // Check if upload is cancelled
          console.error(error);
          setError(error.message);
          setUploading(false);
        },

        async () => {
          if (isCancelled) return; // Check if upload is cancelled
          // Upload completed successfully
          const uploadedUrl = await getDownloadURL(uploadTask.snapshot.ref);
          // setLogoUrl(url);
          setUrl(uploadedUrl);
          // console.log(uploadedUrl);
          // console.log( 'update 2',adminDocId);
          setImageStatus("update");
          if (adminDocId) {
            // console.log(adminDocId)
            try {
              const documentRef = doc(db, "adminProfile", adminDocId);
              await updateDoc(documentRef, { logoUrl: uploadedUrl });
              // console.log("doc Update");
            } catch (error) {
              console.log(error.message);
            }
          }
          setUploading(false);
          setProgress(0);
        }
      );
    } catch (error) {
      console.error(error);
      setError(error.message);
    }
  };

  return (
    <div className="w-full h-full">
      <label htmlFor="logo" className="font-medium text-sm">
        Company Logo
      </label>
      {selectedFile || uploading ? (
        <div className="flex gap-4 items-center">
          <div
            style={{
              width,
              height,
            }}
            className="relative bg-gray-100 flex rounded-full overflow-hidden"
          >
            <ProgressBorder
              progress={progress}
              width={width}
              height={height}
              borderRadius={width / 2}
            />
            <div className="w-full h-full flex items-center justify-center">
              {uploading && (
                <div className="text-[#999] text-sm font-bold">Uploading</div>
              )}
              {url && <img src={url} className="w-full h-full" alt="" />}
            </div>
          </div>
          {url && (
            <div className="space-y-4">
              {/* <div
                onClick={() => {
                  handleDelete();
                  setSelectedFile();
                }}
                className="cursor-pointer  flex gap-2 items-center"
              >
                <FiRepeat className="w-5 h-5 text-[#8A24F1]" /> Retry
              </div> */}
              <div
                onClick={() => {
                  handleDelete();
                  setSelectedFile();
                }}
                className="cursor-pointer flex gap-2 items-center"
              >
                <MdDelete className="w-6 h-6 text-red-500" /> Remove
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className="ml-5 rounded-full cursor-pointer flex items-center justify-center bg-gray-200 border-2 boreder-gray-300 w-[120px] h-[120px]">
          <div
            {...getRootProps({
              className:
                "dropzone relative h-full w-full flex flex-col items-center justify-center",
            })}
          >
            <input {...getInputProps()} />
            <FaImage className="w-8 h-8 text-gray-400" />
            <span className="absolute bottom-6 text-sm font-semibold text-gray-400 text-center">
              Upload Logo
            </span>
          </div>
        </div>
      )}

      <div className="text-[#f00] text-sm ">{error}</div>
    </div>
  );
};

export default UploadLogo;
