import React, { useRef, useEffect, useState } from "react";
import { Button } from "../../../components";
import { FaAngleDown } from "react-icons/fa";
import { HiDotsHorizontal } from "react-icons/hi";
import { TextInput } from "../../../components";
import QuestionMenu from "./QuestionMenu";
import { UploadSingleQuestion } from "../questionUpload/UploadSingleQuestion";
// import { useReactToPrint } from 'react-to-print';

const QuestionView = ({
  setViewQuestion,
  question,
  viewQuestion,
  libraryType
}) => {
  // const [category,correctOption,createdAt,difficulty,options,statement,time,topic,type] = question;
  // const quetionDetails = [
  //   {label:"",value:"category"}
  // ]
  const [menuOpen, setMenuOpen] = useState(false);
  const [questionEditOpen, setQuestionEditOpen] = useState(false);

  const questionViewRef = useRef(null);
  const menuPopupRef = useRef(null);
  const contentToPrintRef = useRef(null);
  useEffect(() => {
    if (viewQuestion) {
      const handleClickOutside = (event) => {
        questionViewRef.current.contains(event.target) ||
          setViewQuestion(false);
        menuPopupRef.current.contains(event.target) || setMenuOpen(false);
        // editStatusPopupRef.current.contains(event.target) ||
        //   setOpenEditStatus(false);
      };
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }
  }, [viewQuestion, menuOpen]);
  console.log(question, libraryType);
  // const handlePrint = useReactToPrint({
  //   documentTitle: "Print This Document",
  //   onBeforePrint: () => console.log("before printing..."),
  //   onAfterPrint: () => console.log("after printing..."),
  //   removeAfterPrint: true,
  // });
  return (
    <div className="absolute top-0 left-0 w-full h-screen bg-black/20 backdrop-blur-[2px] flex justify-end z-50">
      <div
        ref={(element) => {
          // Assign both refs to the same DOM element
          questionViewRef.current = element;
          contentToPrintRef.current = element;
        }}
        className="relative w-full md:w-[80%] lg:w-1/2 h-[100vh] bg-white shadow-md rounded-l-xl overflow-hidden text-sm font-medium"
      >
        {/* <button onClick={() => {
        handlePrint(null, () => contentToPrintRef.current);
      }}>
        PRINT
      </button> */}
        {/* <div className="bg-[#F8F8F8] "> */}
        <div className="bg-[#F8F8F8] h-[70px] w-full">
          <div className="space-y-3  p-5 ">
            <div className="flex  items-center h-[40px] gap-6">
              <div className="font-semibold text-xl capitalize">
                {questionEditOpen ? " Update Question" : "Question View"}
              </div>
            </div>
            <div className="absolute top-2 right-6 flex items-center gap-8">
              <div className="relative" ref={menuPopupRef}>
                <div
                  className="text-2xl bg-gray-300 rounded-md px-2 flex items-center justify-center cursor-pointer"
                  onClick={() => setMenuOpen(!menuOpen)}
                >
                  {menuOpen ? <FaAngleDown /> : <HiDotsHorizontal />}
                </div>
                {menuOpen && (
                  <QuestionMenu
                    setMenuOpen={setMenuOpen}
                    question={question}
                    setViewQuestion={setViewQuestion}
                    libraryType={libraryType}
                    contentToPrintRef={contentToPrintRef}
                    setQuestionEditOpen={setQuestionEditOpen}
                  />
                )}
              </div>
              <div
                className="text-2xl bg-gray-200 rounded-full h-8 w-8 flex items-center justify-center cursor-pointer"
                onClick={() => {
                  questionEditOpen
                    ? setQuestionEditOpen(false)
                    : setViewQuestion(false);
                }}
              >
                ×
              </div>
            </div>
          </div>
        </div>
        {/* <div className="space-y-3">
            <div className="flex items-center gap-6">
              <div className="font-semibold text-2xl capitalize">
              Question View
              </div>
            </div>
            </div> */}

        {/* <div className="w-full border-y-2 border-black mt-4"></div> */}
        {questionEditOpen ? (
          <div className="w-full h-4/6 overflow-y-auto scroll scrollable">
            <UploadSingleQuestion
              updateQuestion={{
                ...question,
                libraryType: libraryType,
                editDocid: question.id
              }}
              setQuestionEditOpen={setQuestionEditOpen}
            />
          </div>
        ) : (
          <div className="grid grid-cols-2 gap-2 p-5  my-1">
            <div>
              <div className="text-gray-500 "> Category </div>
              {/* <div className="text-sm font-medium flex ">{question.category.map((data,i)=><p key={i} >{data}</p>)}</div> */}
              <div>{question.category.join(" , ")}</div>
            </div>
            {/* <div>createdAt:{question.createdAt}</div> */}
            <div>
              <div className="text-gray-500 "> Difficulty </div>
              <div> {question.difficulty}</div>
            </div>
            <div>
              <div className="text-gray-500 "> Type </div>
              <div> {question.type}</div>
            </div>
            <div>
              <div className="text-gray-500 "> Skills</div>
              <div>{question.skills.join(" , ")}</div>
            </div>
            <div>
              <div className="text-gray-500 ">Topic </div>
              <div>{question.topic.join(" , ")}</div>
            </div>
            <div>
              <div className="text-gray-500 ">Alloted Time </div>
              <div>{` ${Math.floor(question.time / 60)} min ${
                question.time % 60
              }  sec`}</div>
            </div>
            {question.type !== "mcq" && (
              <div className="col-span-2">
                <div className="text-gray-500 "> Statement</div>
                <div>{question.statement}</div>
              </div>
            )}

            {/* <div>
                <div>Sample Input</div>
                <div>.....</div>
              </div> */}
            {question.type === "code" && (
              <div className="col-span-2 grid grid-cols-2">
                <div>
                  <div className="text-gray-500 ">Sample Input</div>
                  <div>{question.sampleInput}</div>
                </div>
                <div>
                  <div className="text-gray-500 ">Sample Output</div>
                  <div>{question.sampleOutput}</div>
                </div>
                <div>
                  <div className="text-gray-500 ">Default Code</div>
                  <div>{question.defaultCode}</div>
                </div>
                <div>
                  <div className="text-gray-500 ">Constraints</div>
                  <div>{question.constraints}</div>
                </div>
                <div>
                  <div className="text-gray-500 ">Test Cases</div>
                  <div>{question.testCases}</div>
                </div>
                <div>
                  <div className="text-gray-500 ">Expected Output</div>
                  <div>{question.expectedOutput}</div>
                </div>
                <div>
                  <div className="text-gray-500 ">Programming Language</div>
                  <div>{question.language.label}</div>
                </div>
              </div>
            )}
            {question.type === "mcq" && (
              <div className="col-span-2 ">
                <div className="my-1">Preview :</div>
                <div className="grid grid-cols-2 border-[2px] p-3 gap-3 ">
                  <div className="col-span-2">
                    <div className="text-gray-500 mb-1">Question. </div>
                    <div>{question.statement}</div>
                  </div>
                  <div className="col-span-2  text-gray-500 my-1">Options</div>
                  {question.options.map((data, i) => {
                    const alphabet = ["A.", "B.", "C.", "D."];
                    return (
                      <div key={i} className="">
                        {/* {data} */}
                        <div
                          className={` flex items-center  justify-between gap-3  h-10 text-sm rounded-md ${
                            question.correctOption === data
                              ? "bg-[#F1EAF9]"
                              : "bg-[#d7cece37]"
                          } `}
                        >
                          <div className="flex">
                            <div className="pl-3 mr-2">{alphabet[i]}</div>
                            <div>
                              {data.charAt(0).toUpperCase() + data.slice(1)}
                            </div>
                          </div>

                          {question.correctOption === data && (
                            <div className="text-[#8A24F1] pr-4">Correct</div>
                          )}
                        </div>
                        {/* <TextInput  inputValue={data}  style={{ backgroundColor: 'lightblue' }}  isDisabled={true} innerLabel={alphabet[i]} /> */}
                      </div>
                    );
                  })}
                  {/* <div>A. {question.options[0]}</div>
              <div>B. {question.options[1]}</div>
              <div>C. {question.options[2]}</div>
              <div>D. {question.options[3]}</div> */}
                  {/* <div>
                  <div className="text-gray-500 ">Answer </div>
                  <div
                  // className={` ${question.type === "mcq" ? "" : "col-span-2"} `}
                  >
                    {question.correctOption}
                  </div>
                </div> */}
                </div>
              </div>
            )}

            {question?.answerSummary && (
              <div className="col-span-2">
                <div className="text-gray-500 ">Answer Summary </div>
                <div
                // className="col-span-2"
                >
                  {question.answerSummary}
                </div>
              </div>
            )}

            {/* <div>
            <Button text="Back" handleClick={() => setViewQuestion(false)} />
          </div> */}
            {/* } */}
          </div>
        )}
      </div>
    </div>
  );
};

export default QuestionView;
