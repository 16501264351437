import { Link } from "react-router-dom";

const Recents = ({ applications }) => {
  const allCandidateAttemptForAssessment = applications
    ?.filter((data) => data.assessmentsResults?.length > 0)
    .sort((a, b) => b.appliedAtMillisecond - a.appliedAtMillisecond);
  const candidateAssements = allCandidateAttemptForAssessment
    ?.map((candidateData) => {
      const allAssessResult = candidateData.assessmentsResults?.map((data) => {
        return {
          ...data,
          candidateProfileRef: candidateData.candidateProfileRef,
          name: candidateData.name,
          appliedPosition: candidateData.appliedPosition,
        };
      });

      return allAssessResult;
    })
    .flat();

  const passedCandidateAssements = allCandidateAttemptForAssessment?.filter(
    (candidate) => candidate.result === "pass"
  );

  const sortCandidateAssessment = candidateAssements?.sort(
    (a, b) =>
      b.correctAnswers / b.totalQuestions - a.correctAnswers / a.totalQuestions
  );

  const cards = [
    {
      name: "recentPassed",
      label: "Recent Passed Candidates",
      assessmentsList: passedCandidateAssements,
    },
    {
      name: "topScorers",
      label: "Top Scorers",
      assessmentsList: sortCandidateAssessment,
    },
  ];

  const LoadingComponent = () => (
    <div className="w-full h-full space-y-2 py-2">
      {[1, 2, 3, 4, 5].map((item) => (
        <div
          key={item}
          className="bg-[#666]/10 animate-pulse w-full h-16 rounded-lg"
        ></div>
      ))}
    </div>
  );

  return (
    <div className="grid lg:grid-cols-2 gap-3 w-full min-h-[400px]">
      {cards?.map(({ name, label, assessmentsList }) => (
        <div className="card p-6" key={name}>
          <div className="flex justify-between">
            <h3 className="text-lg font-semibold">{label}</h3>
            <Link to="/applicants" className="text-xs pt-2 hover:underline">
              View All
            </Link>
          </div>

          {!applications ? (
            <LoadingComponent />
          ) : assessmentsList?.length === 0 ? (
            <div className="flex justify-center items-center w-full h-full ">
              <span className="text-xl font-semibold text-gray-400">
                No Data Exist
              </span>
            </div>
          ) : (
            assessmentsList?.slice(0, 6)?.map((candidateApp, i) => {
              const totalWidth =
                candidateApp.correctAnswers *
                (140 / candidateApp.totalQuestions);

              return (
                <div
                  className="flex justify-between mt-4 border-b-2 pb-2 "
                  key={i}
                >
                  <div>
                    <div className="text-sm font-semibold">
                      {candidateApp.name}
                    </div>
                    <div className="text-xs">
                      ({candidateApp.appliedPosition})
                    </div>
                  </div>
                  <div className="flex flex-col gap-1">
                    <div className="relative">
                      <div className="w-[140px] bg-[#F1EAF9] h-[8px] rounded-lg"></div>
                      <div
                        className="absolute top-0 left-0  bg-[#8A24F1] h-[8px] rounded-lg"
                        style={{ width: `${totalWidth}px` }}
                      ></div>
                    </div>
                    <div className="text-xs text-right mt-1 flex items-center gap-2 justify-end">
                      <span>Total score</span>
                      <span>{candidateApp.score}/100</span>
                    </div>
                  </div>
                </div>
              );
            })
          )}
        </div>
      ))}
    </div>
  );
};

export default Recents;
