import React, { useEffect, useRef, useState } from "react";
import { IoIosSearch } from "react-icons/io";
const ColumnEditMenu = ({
  openColumnEdit,
  setOpenColumnEdit,
  columnList,
  setColumnList,
}) => {
  const [updatedList, setUpdatedList] = useState();
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    setUpdatedList(columnList);
  }, [columnList]);

  const columnEditRef = useRef(null);
  useEffect(() => {
    if (openColumnEdit) {
      const handleClickOutside = (event) => {
        columnEditRef?.current?.contains(event.target) ||
          setOpenColumnEdit(false);
      };
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }
  }, [openColumnEdit]);

  return (
    <div
      ref={columnEditRef}
      className={`absolute top-0 w-[300px] h-full bg-white border-2 border-gray-100 
        shadow-md rounded-r-2xl  transition-all duration-200
        ${openColumnEdit ? "left-0" : "left-[-300px] opacity-0"}
      `}
    >
      <div className=" w-full h-full p-6 space-y-2">
        <div className="flex items-center justify-center w-full rounded-lg border-2 border-gray-200 bg-gray-100 px-2">
          <IoIosSearch className="w-7 h-7 text-gray-400" />
          <input
            type="text"
            placeholder="Search"
            className="w-full border-0 bg-transparent focus:ring-0 text-gray-600"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </div>
        <div className="relative h-full overflow-y-scroll scrollable pb-10">
          {columnList
            ?.filter(
              (column) =>
                column.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
                column.label.toLowerCase().includes(searchQuery.toLowerCase())
            )
            .map((column, index) => (
              <div
                key={column.name}
                className="flex items-center justify-between px-4 py-2 hover:bg-gray-100"
              >
                <div className="flex items-center gap-2">
                  <input
                    type="checkbox"
                    name={column.name}
                    id={column.name}
                    className="w-5 h-5 mr-2 rounded-md border-2 border-gray-300 bg-gray-200 focus:ring-0  cursor-pointer"
                    defaultChecked={column.open}
                    onChange={() => {
                      const updatedColumns = columnList;
                      updatedColumns[index].open = !updatedColumns[index].open;
                      setUpdatedList(updatedColumns);
                    }}
                  />
                  <label
                    htmlFor={column.name}
                    className="capitalize cursor-pointer text-sm"
                  >
                    {column.label}
                  </label>
                </div>
              </div>
            ))}
          <div className="sticky -bottom-2 w-full px-6 mt-2">
            <button
              className="bg-gray-200/70 backdrop-blur-sm border-2 border-gray-300 shadow-md py-2 flex items-center justify-center w-full rounded-lg font-semibold text-gray-600"
              onClick={() => {
                setColumnList(updatedList);
                setOpenColumnEdit(false);
              }}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ColumnEditMenu;
