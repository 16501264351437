import React from "react";

const JobRow = ({
  index,
  columnList,
  setOpenJobView,
  jobData,
  setOpenedJobId,
}) => {
  return (
    <>
      <div
        className="flex w-full overflow-x-auto py-5 pl-5 text-xs font-medium even:bg-[#E9EAEB]/60 cursor-pointer"
        onClick={() => {
          setOpenJobView(true);
          setOpenedJobId(jobData.jobOpeningId);
        }}
      >
        <div className="w-[50px] flex items-center">{index + 1}.</div>
        {columnList
          // .filter((column) => column.open)
          .map((column) => {
            const width = column.open ? column.width : "0";
            const opacity = column.open ? "1" : "0";
            return (
              <div
                key={column.name}
                className="flex items-center gap-1 capitalize transition-all duration-200"
                style={{ width, opacity }}
              >
                {column.open &&
                  (column.name === "jobPostedDate" ||
                  column.name === "lastDateToApply" ||
                  column.name === "targetDate"
                    ? new Date(
                        jobData[column.name]?.seconds * 1000
                      ).toLocaleDateString("en-in", {
                        day: "numeric",
                        month: "short",
                        year: "numeric",
                      })
                    : column.name === "jobPrefence"
                    ? `work from ${
                        jobData[column.name] === "wfh"
                          ? "home"
                          : jobData[column.name] === "hybrid"
                          ? "hybrid"
                          : "office"
                      }`
                    : jobData[column.name])}
              </div>
            );
          })}
      </div>
    </>
  );
};

export default JobRow;
