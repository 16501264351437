import React,{useEffect, useState} from 'react'
import axios from 'axios';

import { TextInput,Button, RadioInput} from '../../components'
import { auth } from '../../config/firebase';

const Settings = () => {

  const [loading, setLoading] = useState(false)
  const [saveLoading, setSaveLoading] = useState(false)
  const [success, setSuccess] = useState('')
  const [update, setUpdate] = useState()
  const [maxViolations, setMaxViolations] = useState()
  const [maxQuestions, setMaxQuestions] = useState()

  const [newMaxViolations, setNewMaxViolations] = useState()
  const [newMaxQuestions, setNewMaxQuestions] = useState()

  useEffect(() => {
    const fetchAdmin = async () => {
      if(auth?.currentUser.email){
        setLoading(true)
        const response = await axios.post(process.env.REACT_APP_ADMIN_LOGIN ,auth?.currentUser.email);
        const maxQus = response?.data?.admin?.maxQuestions
        const maxViol = response?.data?.admin?.maxViolations
        setMaxViolations(maxViol)
        setNewMaxViolations(maxViol)
        setMaxQuestions(maxQus)
        setNewMaxQuestions(maxQus)
        setLoading(false)
      }
    }
    fetchAdmin()
  },[update])
  

  const [showMaxViolations, setShowMaxViolations] = useState()
  useEffect(() => setShowMaxViolations(newMaxViolations ? true : false),[newMaxViolations])

  const [showMaxQuestions, setShowMaxQuestions] = useState()
  useEffect(() => setShowMaxQuestions(maxQuestions ? true : false),[maxQuestions])

  const saveSettings = async() => {
    setSaveLoading(true)
    const settingData = JSON.stringify([auth?.currentUser.email,newMaxViolations,newMaxQuestions])
    const response = await axios.post(process.env.REACT_APP_SAVE_SETTINGS,settingData)
    if(response?.data.message === 'success'){
      setSuccess("Your Settings has been updated")
    }
    setTimeout(() => {
      setSuccess("")
    },3000)
    setUpdate(update+1)
    setSaveLoading(false)
}

  return (
    <div className='main-container'>
      <div className='card w-full h-full md:h-[90vh] mx-auto md:ml-20 mt-2 flex flex-col md:flex-row px-4 md-px-10 gap-5 py-8'>
        <div className='w-full h-full flex flex-col justify-between md:px-10'>
          <div className='flex flex-col gap-8'>
            <div className='flex flex-col gap-4'>
              <h2 className='heading2'>Violations Managment</h2>
              {loading ? (
                <>
                  <div className='w-1/2 h-[2rem] bg-[#aaa]/10 rounded-lg'/>
                  <div className='w-1/3 h-[2rem] bg-[#bbb]/10 rounded-lg'/>
                  <div className='w-1/2 h-[2rem] bg-[#ccc]/10 rounded-lg'/>
                </>
              ) : (
                <div className='flex flex-col gap-4 w-full text-xs sm:text-sm'>
                  <div className='flex items-center gap-3'>
                    <RadioInput
                      inputName='violationSetting'
                      checked={showMaxViolations ? true : false}
                      iniputId='violationSetting1'
                      text=''
                      border='none'
                      handleChange={() => {
                        setShowMaxViolations(true)
                        setNewMaxViolations(maxViolations)
                      }}
                    />
                    <label htmlFor='violationSetting1'>
                      The assessment must get terminated when the candidate reaches the maximum allowable number of violations.
                    </label>
                  </div>                
                    <div className={`ml-10 flex gap-4 items-center ${!showMaxViolations && 'text-[#ccc]' }`}>
                      <div>
                        <TextInput
                          width='5rem'
                          type='number'
                          inputValue={newMaxViolations}
                          handleChange={(e) => setNewMaxViolations(e.target.value)}
                        />
                      </div>
                      
                      <label>
                        Maximum number of violations allowed.
                      </label>
                    </div>
                  
                          
                  <div className='flex items-center gap-3'>
                    <RadioInput
                      inputName='violationSetting'
                      checked={showMaxViolations ? false : true}
                      iniputId='violationSetting2'
                      text=''
                      border='none'
                      handleChange={() => {
                        setShowMaxViolations(false)
                          setNewMaxViolations("")
                      }}
                    />
                      <label htmlFor='violationSetting2'>
                        Assessment should not terminate, all violations should be recorded.
                      </label>
                  </div>                    
                </div>
              )}            
            </div>

            {/* <div className='flex flex-col gap-4 mt-6'>
              <h2 className='heading2'>Questions Managment</h2>
              {loading ? (
                <>
                  <div className='w-1/3 h-[2rem] bg-[#aaa]/10 rounded-lg'/>
                  <div className='w-1/2 h-[2rem] bg-[#bbb]/10 rounded-lg'/>
                </>
              ) : (
                <div className='flex flex-col gap-4 text-xs sm:text-sm'>
                  <div className='flex items-center gap-3'>
                    <RadioInput
                      inputName='questionSetting'
                      checked={showMaxQuestions ? true : false}
                      iniputId='questionSetting1'
                      text=''
                      border='none'
                      handleChange={() => {
                        setShowMaxQuestions(true)
                        setNewMaxQuestions(maxQuestions)
                      }}
                    />
                    <label htmlFor='questionSetting1'>
                      Fixed number of questions for each assessment.
                    </label>
                  </div>
                    <div className={`ml-10 flex gap-4 items-center ${!showMaxQuestions && 'text-[#ccc]' }`}>
                      <div>
                        <TextInput
                          width='5rem'
                          type='number'
                          inputValue={newMaxQuestions}
                          handleChange={(e) => setNewMaxQuestions(e.target.value)}
                        />
                      </div>
                      <label>
                        Number of questions for each assessment.
                      </label>
                    </div>                      
                  <div className='flex items-center gap-3'>
                    <RadioInput
                      inputName='questionSetting'
                      checked={showMaxQuestions ? false : true}
                      iniputId='questionSetting2'
                      text=''
                      border='none'
                      handleChange={() => {
                        setShowMaxQuestions(false)
                        setNewMaxQuestions("")
                      }}
                    />
                    <label htmlFor='questionSetting2'>
                      Different number of questions for each assessment.
                    </label>
                  </div>                    
                </div>
              )}            
            </div> */}
          </div>
          <div className='mt-5'>
              {success &&(
                <div className='text-xs mb-4 text-green-600'>
                  {success}
                </div>
              )}
              {saveLoading ? (
                <div className='text-[#ccc] font-semibold'>
                    Loading ...
                </div>                        
              ) : (
                !loading && (
                  <Button
                    text = 'Save Settings'  
                    width='12rem'
                    handleClick={saveSettings}
                  />
                )              
              )} 
          </div>
        </div>

          {/* <div className='w-full md:w-1/2 h-full py-5 px-10 flex flex-col'>

          </div> */}

      </div>
    </div>
  )
}

export default Settings