import React, { useEffect, useState } from "react";
import axios from "axios";
import Editor from "@monaco-editor/react";

import { defineTheme } from "../../constant/defineTheme";
// import { languageOptions } from '../../constant/LanguageOptions';
import {
  Button,
  RadioInput,
  SelectInput,
  TestCasesResult,
} from "../../components";
const ExamOptionsPanel = ({
  handleNext,
  questions,
  qusNum,
  alertSkip,
  loading,
  code,
  setCode,
}) => {
  const [question, setQuestion] = useState();
  const [subjectiveAnswer, setSubjectiveAnswer] = useState("");
  const [lastQuestion, setLastQuestion] = useState(false);

  const [language, setLanguage] = useState();
  const [theme, setTheme] = useState("cobalt");
  const [runRemaining, setRunRemaining] = useState();
  const [testCases, setTestCases] = useState();
  const [defaultCode, setDefaultCode] = useState();
  const [expectedOutput, setExpectedOutput] = useState();

  useEffect(() => {
    defineTheme("night-owl").then((_) =>
      setTheme({ value: "night-owl", label: "Night Owl" })
    );
  }, []);

  useEffect(() => setQuestion(questions[qusNum]), [questions, qusNum]);
  useEffect(() => setRunRemaining(3), [question]);
  useEffect(() => {
    if (question && question.type === "code") {
      if (question?.language !== 0) {
        setLanguage(JSON.parse(question?.language)[0]);
      }
      setTestCases(
        JSON.parse(question?.testCases).map((testCase) => testCase.test.trim())
      );
      setDefaultCode(question?.defaultCode);
      setExpectedOutput(
        JSON.parse(question?.testCases).map((testCase) =>
          testCase.expectedOutput.trim()
        )
      );
    }
  }, [question, qusNum]);

  const [runProcessing, setRunProcessing] = useState(false);
  const [submitProcessing, setSubmitProcessing] = useState(false);
  const [runResultModule, setRunResultModule] = useState(false);
  const runCode = () => {
    setRunRemaining(runRemaining - 1);
    compileCode("run");
  };

  const submitAnswer = (e) => {
    e.preventDefault();

    const form = document.getElementById("examForm");
    let userAnswer =
      question?.type === "code" ? code : form[`q${qusNum + 1}`]?.value;

    if (userAnswer === "") {
      alertSkip();
    } else {
      if (question?.type === "code") {
        compileCode("submit");
      } else {
        nextFunction();
      }
    }
  };

  const compileCode = (type) => {
    type === "run" ? setRunProcessing(true) : setSubmitProcessing(true);
    let submissions = testCases
      ? testCases.map((test, index) => ({
          language_id: language.id,
          source_code: btoa(code),
          stdin: btoa(test),
          expected_output: btoa(expectedOutput[index]),
        }))
      : {
          language_id: language.id,
          source_code: btoa(code),
          expected_output: btoa(expectedOutput[0]),
        };

    const options = {
      method: "POST",
      url: process.env.REACT_APP_RAPID_API_URL_BATCH,
      params: { base64_encoded: "true", fields: "*" },
      headers: {
        "content-type": "application/json",
        "Content-Type": "application/json",
        "X-RapidAPI-Host": process.env.REACT_APP_RAPID_API_HOST,
        "X-RapidAPI-Key": process.env.REACT_APP_RAPID_API_KEY,
      },
      data: { submissions: submissions },
    };

    axios
      .request(options)
      .then(function (response) {
        const tokens = response.data.map((data) => data.token);
        checkStatus(tokens, type);
      })

      // ToDo - catch block is to be optimized for handling stage 1 errors
      .catch((err) => {
        // let error = err.response ? err.response.data : err;
        // // get error status
        // let status = err.response.status;
        // console.log("status", status);
        // if (status === 429) {
        //     console.log("too many requests", status);
        //     // showErrorToast(
        //     //   `Quota of 100 requests exceeded for the Day! Please read the blog on freeCodeCamp to learn how to setup your own RAPID API Judge0!`,
        //     //   10000
        //     // );
        //     //   nextFunction()
        // }
        type === "run" ? setRunProcessing(false) : setSubmitProcessing(false);
        console.log("error in request 1 - ", err);
        type === "submit" && handleNext("error");
      });
  };

  const checkStatus = async (tokens, type) => {
    const tokenString = tokens?.join(",");
    const options = {
      method: "GET",
      url: `${process.env.REACT_APP_RAPID_API_URL_BATCH}?tokens=${tokenString}`,
      params: { base64_encoded: "true", fields: "*" },
      headers: {
        "X-RapidAPI-Host": process.env.REACT_APP_RAPID_API_HOST,
        "X-RapidAPI-Key": process.env.REACT_APP_RAPID_API_KEY,
      },
    };
    try {
      let response = await axios.request(options);

      let output = response.data.submissions;
      let statusIds = output?.map((data) => data.status.id);

      // Processed - we have a result
      if (statusIds.includes(1) || statusIds.includes(2)) {
        // still processing
        setTimeout(() => {
          checkStatus(tokens, type);
        }, 2000);
        return;
      } else {
        type === "run" ? setRunProcessing(false) : setSubmitProcessing(false);

        //code for displaying results for running code
        type === "run" && showRunResult(output);

        //code for saving output data from api response

        let testCasesResult = statusIds.map((id) =>
          id === 3 ? "pass" : "fail"
        );
        let evaulate = testCasesResult.includes("fail")
          ? "incorrect"
          : "correct";
        type === "submit" && nextFunction(output, evaulate);
      }
    } catch (err) {
      // ToDo - catch block is to be optimized for handling stage 2 errors
      console.log("error in request 2 - ", err);
      type === "run" ? setRunProcessing(false) : setSubmitProcessing(false);
      type === "submit" && nextFunction("error");
    }
  };

  const [outputDetails, setOutputDetails] = useState();
  const showRunResult = (output) => {
    setOutputDetails(output);
    setRunResultModule(true);
  };

  const nextFunction = (output, evaulate) => {
    if (qusNum + 1 >= questions?.length) {
      setLastQuestion(true);
    }
    setSubjectiveAnswer("");
    setCode("");
    // setLanguage();
    handleNext(output, evaulate);
  };

  const [closeTestCases, setCloseTestCases] = useState(0);

  return (
    <div className="relative w-[50%] px-8 h-full flex flex-col justify-between">
      {
        // code run results module
        <div
          className={`${
            runResultModule ? "flex" : "hidden"
          } absolute w-full h-full left-0 top-0 items-center justify-center backdrop-blur-sm z-10`}
        >
          <div className="relative card w-[90%] p-6">
            <div
              onClick={() => {
                setRunResultModule(false);
                setCloseTestCases((prev) => prev + 1);
              }}
              className="flex justify-center items-center absolute top-4 right-4 rounded-full w-6 h-6 bg-[#F9FAFB] cursor-pointer"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                className="w-5 h-5"
              >
                <path d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z" />
              </svg>
            </div>
            <TestCasesResult
              outputDetails={outputDetails}
              closeTestCases={closeTestCases}
            />
          </div>
        </div>
      }
      {loading ? (
        <div className="w-full h-full flex justify-center items-center">
          <p className="font-bold text-2xl text-[#bbb]">Loading...</p>
        </div>
      ) : (
        <>
          <>
            <p className="font-medium text-[#333] mb-3">
              {question?.type === "subjective"
                ? "Your Answer - "
                : question?.questionType === "code"
                ? ""
                : "Select any one -"}
            </p>
            <div className="w-full overflow-y-auto scrollbar-none mb-2 h-full">
              <form
                method="post"
                id="examForm"
                className="h-full flex flex-col gap-4"
              >
                {question?.type === "subjective" ? (
                  <div className="flex flex-col gap-5">
                    <textarea
                      className="border-[3px] border-[#ECECEC] rounded-[10px] bg-gray-50 focus:ring-0"
                      rows="14"
                      name={`q${qusNum + 1}`}
                      placeholder="Enter your answer here ..."
                      value={subjectiveAnswer}
                      onChange={(e) => setSubjectiveAnswer(e.target.value)}
                    />
                  </div>
                ) : question?.type === "code" ? (
                  <>
                    <div className="h-full">
                      {/* {question?.language === 0 && (
                        <>
                          <span>Select a programming language -</span>
                          <SelectInput
                            placeholderText={`Select programming language`}
                            selectOptions={languageOptions}
                            handleChange={(selectedOption) =>
                              setLanguage(selectedOption)
                            }
                            isSearchable={true}
                          />
                        </>
                      )} */}

                      <div className="h-full">
                        <h3>Your Code Here - </h3>
                        <div className="h-[95%] rounded-xl bg-[#011627] overflow-hidden">
                          <Editor
                            className="pt-3"
                            language={language?.value}
                            value={code}
                            theme={theme.value}
                            defaultValue={defaultCode}
                            onChange={(value) => setCode(value)}
                          />
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <div className="flex flex-col gap-5">
                    {["A", "B", "C", "D"]?.map((option, index) => (
                      <RadioInput
                        key={index}
                        inputName={`q${qusNum + 1}`}
                        inputValue={index}
                        iniputId={`q${qusNum + 1}${option}`}
                        assessmentInput={true}
                        text={<span>{question?.options[index]}</span>}
                      />
                    ))}
                  </div>
                )}
              </form>
            </div>
          </>

          <div className="flex justify-between">
            <div>
              {question?.type === "code" && (
                <Button
                  text=""
                  version="green"
                  width="6rem"
                  handleClick={runCode}
                  isDisabled={runRemaining < 1}
                  loading={runProcessing}
                >
                  <p className="flex gap-1 items-center">
                    Run
                    {runRemaining > 0 && (
                      <span className="text-xs">({runRemaining})</span>
                    )}
                  </p>
                </Button>
              )}
            </div>

            {lastQuestion ? (
              <div className="text-[#f60] font-medium text-center mx-auto hidden">
                This is the last Question <br />
                Please press "Finish Test" Button at the top, To submit your
                Assessment.
              </div>
            ) : (
              <Button
                text="Submit & Next"
                inputId="next"
                handleClick={submitAnswer}
                loading={submitProcessing}
              />
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default ExamOptionsPanel;
