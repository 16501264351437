import React, { useState, useEffect } from "react";

import { Topbar2 } from "../../components";
import InstructionsTopPanel from "./InstructionsTopPanel";
import InstructionsMainPanel from "./InstructionsMainPanel";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../config/firebase";

const ExamInstructions = () => {
  const [scheduleDetails, setScheduleDetails] = useState();
  useEffect(() => {
    const schedule = JSON.parse(localStorage.getItem("scheduleDetails"));
    if (schedule) {
      setScheduleDetails(schedule);
    }
  }, []);

  const [candidateDetails, setCandidateDetails] = useState();
  const [assessmentId, setAssessmentId] = useState();
  const [applicationRound, setApplicationRound] = useState(0);
  const [assessemntDetails, setAssessemntDetails] = useState();

  useEffect(() => {
    const candidateId = scheduleDetails?.candidateId;
    const fetcheCandidateProfileDetails = async () => {
      const docRef = doc(db, "candidateProfile", candidateId);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setCandidateDetails(docSnap.data());
        localStorage.setItem(
          "candidateDetails",
          JSON.stringify({ id: docSnap.id, ...docSnap.data() })
        );
      } else {
        console.log("No such document!");
      }
    };
    if (candidateId) fetcheCandidateProfileDetails();
  }, [scheduleDetails]);

  useEffect(() => {
    const applicationId = scheduleDetails?.applicationId;
    const fetchApplicationDetails = async () => {
      const docRef = doc(db, "candidateApplications", applicationId);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setApplicationRound(docSnap.data()?.round ? docSnap.data()?.round : 0);
        localStorage.setItem(
          "applicationDetails",
          JSON.stringify({ id: docSnap.id, ...docSnap.data() })
        );
      } else {
        console.log("No Application found!");
      }
    };

    if (applicationId) fetchApplicationDetails();
  }, [scheduleDetails]);

  useEffect(() => {
    const jobId = scheduleDetails?.jobId;
    const fetchJobDetails = async () => {
      const docRef = doc(db, "jobsPosted", jobId);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setAssessmentId(docSnap.data()?.rounds[applicationRound]?.id);
        localStorage.setItem(
          "jobDetails",
          JSON.stringify({ id: docSnap.id, ...docSnap.data() })
        );
      } else {
        console.log("No Jobs found!");
      }
    };
    if (jobId) fetchJobDetails();
  }, [scheduleDetails, applicationRound]);

  useEffect(() => {
    const id = scheduleDetails?.assessmentId || assessmentId;
    const fetchAssessmentDetails = async () => {
      const docRef = doc(db, "assessments", id);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setAssessemntDetails(docSnap.data());
        localStorage.setItem(
          "assessmentDetails",
          JSON.stringify({ id: docSnap.id, ...docSnap.data() })
        );
      } else {
        console.log("No such document!");
      }
    };

    if (id) fetchAssessmentDetails();
  }, [scheduleDetails, assessmentId]);

  return (
    <div className="w-full overflow-y-auto scrollable">
      <div className="fixed w-full z-20">
        {/* <Topbar2 name={examDetails.name} email={examDetails.emailId} /> */}
        <Topbar2
          name={candidateDetails?.name}
          email={candidateDetails?.email}
        />
      </div>

      <div className="sticky z-10 top-[-52px]">
        <InstructionsTopPanel assessmentDetails={assessemntDetails} />
      </div>

      <div>{/* <InstructionsMainPanel details={examDetails} /> */}</div>
      <div>
        <InstructionsMainPanel
          assessemntDetails={assessemntDetails}
          scheduleDetails={scheduleDetails}
        />
      </div>
    </div>
  );
};

export default ExamInstructions;
