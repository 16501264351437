import React from "react";

const JobDescription = ({jobData}) => {
  return (
    <div className="space-y-5 w-full p-6 lg:px-16">
      <h1 className="text-xl font-semibold">Job Description</h1>
      <div className="text-sm text-justify">
        {jobData?.jobDescription}
      </div>

      <h1 className="text-xl font-semibold my-5">Requirement</h1>
      <div className="text-sm text-justify">
        {jobData?.jobRequirement}
        Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nisi corrupti amet libero non consequatur optio voluptate veniam? Voluptatum quaerat, vel quidem illo nemo molestias perspiciatis saepe repudiandae similique repellat voluptates.
      </div>
    </div>
  )
}

export default JobDescription;
