import React, { useEffect, useState } from "react";
import Popup from "../../../components/popup/Popup";
import { useNavigate } from "react-router-dom";
import { FaRegEdit } from "react-icons/fa";
import { SingleQuestionUpload } from "./SingleQuestionUpload";
import { Button } from "../../../components";
import QuesRow from "../assessmentView.jsx/QuesRow";
import { Timestamp, addDoc, collection, updateDoc } from "firebase/firestore";
import { db } from "../../../config/firebase";
import NewAssessmentPopup from "./NewAssessmentPopup";
import Preview from "../assessmentView.jsx/Preview";
import {
  doc,
  getDoc,
  onSnapshot,
  query,
  where,
  documentId,
} from "firebase/firestore";
import { toast } from "react-toastify";
import UploadCSV from "../../questionLibrary/questionUpload/UploadCSV";
import { auth } from "../../../config/firebase";

const CustomAssessment = ({ assessmentDetails }) => {
  const [showNewAssessmentModule, setShowNewAssessmentModule] = useState(false);
  const [updatingAssesmentData, setUpdatingAssessmentData] = useState();

  // console.log(updatingAssesmentData);
  const columns = [
    {
      name: "sno",
      label: "S.No",
      width: "5%",
      open: true,
      sort: true,
      sortType: "",
    },
    {
      name: "questionName",
      label: "Question",
      width: "30%",
      open: true,
      sort: true,
      sortType: "",
    },
    {
      name: "type",
      label: "Type",
      width: "15%",
      open: true,
      sort: true,
      sortType: "",
    },
    {
      name: "time",
      label: "Duration",
      width: "15%",
      open: true,
      sort: true,
      sortType: "",
    },
    {
      name: "difficulty",
      label: "Difficulty",
      width: "10%",
      open: true,
      sort: true,
      sortType: "",
    },
    {
      name: "skills",
      label: "Skills",
      width: "20%",
      open: true,
      sort: true,
      sortType: "",
    },
    {
      name: "action",
      label: "Actions",
      width: "10%",
      open: true,
      sort: true,
      sortType: "",
    },
  ];

  const [addedQuestionsId, setAddedQuestionsId] = useState([]);
  const [addedQuestions, setAddedQuestions] = useState([]);
  const [openSingleQuestion, setOpenSingleQuestion] = useState(false);
  const [openQuestion, setOpenQuestion] = useState(false);
  const [duration, setDuration] = useState(0);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [error, setError] = useState();
  const [showSuccessModule, setShowSuccessModule] = useState(false);
  const navigate = useNavigate();

  const [deleteQuestionId, setDeleteQuestionId] = useState(false);
  const [questionUpdate, setQuestionUpdate] = useState(false);
  const [assessmentQuestionData, setAssessmentQuestionData] = useState();
  const [openBulkQuestion, setOpenBulkQuestion] = useState(false);

  useEffect(() => {
    const totalDuration =
      addedQuestions &&
      addedQuestions.reduce((acc, cur) => {
        return acc + cur.time;
      }, 0);
    setDuration(totalDuration);
  }, [addedQuestions]);

  const handleCreateAssessment = async () => {
    if (addedQuestions.length === 0) {
      return setError("Please add question to create assessment.");
    }

    const skillsData = addedQuestions
      .map((ques) => {
        return ques?.skills?.map((skill) => {
          return { skillName: skill, type: ques?.type };
        });
      })
      .flat();

    const skills = skillsData.reduce((acc, curr) => {
      const found = acc.find(
        (item) => item.skillName === curr.skillName && item.type === curr.type
      );
      if (found) {
        found.questions++;
      } else {
        acc.push({ ...curr, questions: 1 });
      }
      return acc;
    }, []);

    if (assessmentDetails.editDocId) {
      const updateAssessmentData = {
        // ...assessmentDetails,
        domain: assessmentDetails?.domain,
        experience: assessmentDetails?.experience,
        name: assessmentDetails?.name,
        position: assessmentDetails?.position,
        type: assessmentDetails?.type,
        totalQuestions: addedQuestions.length,
        skills: skills,
        duration: duration,
        questions: addedQuestionsId,
        createdAt: assessmentQuestionData.createdAt,
        updatedAt: Timestamp.now(),
        createdBy: "admin",
        companyName: auth?.currentUser.displayName,
        isDeleted: false,
        availability: "Ready to use",
      };
      try {
        setSubmitLoading(true);
        const docRef = doc(db, "assessments", assessmentDetails.editDocId);
        await updateDoc(docRef, updateAssessmentData);
        // await addDoc(collection(db, "assessments"), assessmentData);
        setShowSuccessModule(true);
      } catch (error) {
        console.log(error);
      } finally {
        setSubmitLoading(false);
      }
    } else {
      const assessmentData = {
        // ...assessmentDetails,
        domain: assessmentDetails?.domain,
        experience: assessmentDetails?.experience,
        name: assessmentDetails?.name,
        position: assessmentDetails?.position,
        type: assessmentDetails?.type,
        totalQuestions: addedQuestions.length,
        skills: skills,
        duration: duration,
        questions: addedQuestionsId,
        createdAt: Timestamp.fromDate(new Date()),
        createdBy: "admin",
        companyName: auth?.currentUser.displayName,
        isDeleted: false,
        availability: "Ready to use",
      };
      try {
        setSubmitLoading(true);
        await addDoc(collection(db, "assessments"), assessmentData);
        setShowSuccessModule(true);
      } catch (error) {
        console.log(error);
      } finally {
        setSubmitLoading(false);
      }
    }
  };

  useEffect(() => {
    const fetchDocumentData = async () => {
      try {
        // Replace 'your_collection' with the name of your collection
        const docRef = doc(db, "assessments", assessmentDetails.editDocId);
        // const unsubscribe = onSnapshot(docRef, (snapshot) => {
        //   const newData = snapshot.docs.map(doc => ({
        //     id: doc.id,
        //     ...doc.data()
        //   }));
        //   setUpdatingAssessmentData({...newData,editDocId: assessmentDetails.editDocId})
        //   // setData(newData);
        // });

        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          // Document exists, you can access its data
          const data = docSnap.data();
          setAddedQuestionsId(data.questions);
          setAssessmentQuestionData({
            ...data,
            editDocId: false,
            editQuestionOnAssessementDocId: assessmentDetails.editDocId,
          });

          let q = query(
            collection(db, "customQuestionLibrary"),
            where("isDeleted", "==", false)
            // orderBy("jobPostedDate", "desc")
          );

          const favJobsId = data?.questions?.length ? data?.questions : ["id"];
          q = query(q, where(documentId(), "in", favJobsId));

          const querySnapshot = onSnapshot(q, (querySnapshot) => {
            const jobData = [];

            querySnapshot.forEach((doc) => {
              jobData.push({ id: doc.id, ...doc.data() });
            });
            setAddedQuestions(jobData);
          });

          // if (data.questions.length > 0) {
          //   let quesData = [];

          //   for (let i = 0; i < data.questions.length; i++) {
          //     const qeusId = data.questions[i];
          //     // const fetchData = async (qeusId) => {
          //     const docRef = doc(db, "questionLibrary", qeusId);
          //     const docSnap = await getDoc(docRef);

          //     if (docSnap.exists()) {
          //       const newdata = {
          //         id: docSnap.id,
          //         ...docSnap.data(),
          //         editDocId: assessmentDetails.editDocId
          //       };
          //       quesData.push(newdata);
          //       // setQuestions((prev) => [...prev, data]);
          //     } else {
          //       const customDocRef = doc(db, "customQuestionLibrary", qeusId);
          //       const customDocSnap = await getDoc(customDocRef);

          //       if (customDocSnap.exists()) {
          //         const newdata = {
          //           id: customDocSnap.id,
          //           ...customDocSnap.data(),
          //           editDocId: assessmentDetails.editDocId
          //         };
          //         quesData.push(newdata);
          //         // setQuestions((prev) => [...prev, data]);
          //       }
          //     }
          //     // };
          //   }

          //   setAddedQuestions(quesData);
          //   setQuestionUpdate(false);
          //   // console.log("code will work" ,data.questions)
          // }

          // setAddedSkillObjects(data.skills);
          // setTotalQuestions({
          //   value: data.totalQuestions,
          //   label: `${data.totalQuestions} Questions `
          // });
          // setTotalSelectedQuestions(data.totalQuestions);
        } else {
          // Document does not exist
          console.log("No such document!");
        }
      } catch (error) {
        console.error("Error getting document:", error);
      }
    };

    if (assessmentDetails?.editDocId) {
      fetchDocumentData();
    }
  }, [assessmentDetails]);

  // delete the Question from assessment...................

  // useEffect(() => {
  //   const deleteQuestionFromAssessment = async () => {
  //     const deletedQuetion = assessmentQuestionData.questions.filter(
  //       (delData) => delData !== deleteQuestionId
  //     );

  //     const updatedObject = {
  //       ...assessmentQuestionData,
  //       questions: deletedQuetion,
  //       updatedAt: Timestamp.now(),
  //       totalQuestions: deletedQuetion.length
  //     };
  //     console.log("deleting", updatedObject, deletedQuetion);
  //     try {
  //       const docRef = doc(db, "assessments", assessmentDetails.editDocId);
  //       await updateDoc(docRef, updatedObject);
  //       // console.log('Document updated successfully!');
  //       toast.success("Success! Question Deleted");
  //       setQuestionUpdate(true);
  //     } catch (error) {
  //       // console.error('Error updating document:', error);
  //       toast.error(`Error! Unable to delete Question. (${error.message})`);
  //     }
  //     setDeleteQuestionId(false);
  //   };
  //   if (deleteQuestionId) {
  //     deleteQuestionFromAssessment();
  //   }
  // }, [deleteQuestionId]);

  // console.log("assessmentDetails", assessmentDetails);
  // console.log("assessmentQuestionData", assessmentQuestionData);
  return (
    <div className="w-full h-full flex flex-col gap-4">
      {showSuccessModule && (
        <Popup
          closePopup={() => {
            setShowSuccessModule(false);
            navigate("/assessments");
          }}
        >
          <div className="flex items-center justify-center flex-col gap-2 w-80">
            <img src="/img/working.png" className="w-24 mb-4" alt="" />
            <h1 className="text-2xl font-semibold">Congratulations</h1>
            <p className="font-medium text-center">
              {assessmentDetails?.editDocId
                ? "The Assessment has been Updated successfully."
                : "The Assessment has been created successfully."}
            </p>
          </div>
        </Popup>
      )}
      <div className="bg-gray-100 shadow-md rounded-lg flex items-center px-6 text-sm justify-between w-fit gap-6">
        {["position", "experience", "domain", "type"].map((item) => (
          <div
            className="font-medium capitalize flex flex-wrap gap-1"
            key={item}
          >
            <span className="text-gray-500">{item} : </span>
            <span className="">
              {assessmentDetails &&
                (item === "type"
                  ? assessmentDetails[item] === "library"
                    ? "Question Library"
                    : "Custom Questions"
                  : assessmentDetails[item])}
            </span>
          </div>
        ))}

        <span className="hover:bg-gray-300 p-2 rounded-full cursor-pointer">
          <FaRegEdit
            className="w-5 h-5"
            onClick={() => setShowNewAssessmentModule(true)}
          />
        </span>
      </div>

      {addedQuestions?.length > 0 && (
        <div className="max-h-[85%]">
          <div className="flex w-full overflow-x-auto  py-5 pl-5 bg-[#E9EAEB] font-medium text-sm text-black md:rounded-t-xl">
            <div className="w-[50px]"></div>
            {columns?.map((column) => {
              const width = column.open ? column.width : "0";
              const opacity = column.open ? "1" : "0";
              return (
                <div
                  key={column.name}
                  className="flex items-center gap-1 transition-all duration-200"
                  style={{ width, opacity }}
                >
                  {column.open && (
                    <div className="flex items-center">{column.label}</div>
                  )}
                </div>
              );
            })}
          </div>
          <div className="w-full h-[80%] overflow-y-scroll scrollable">
            {addedQuestions?.map((question, index) => (
              <QuesRow
                key={index}
                index={index}
                columnList={columns}
                question={question}
                openQuestion={openQuestion}
                setOpenQuestion={setOpenQuestion}
                deleteFunction={true}
                editFunction={true}
                setDeleteQuestionId={setDeleteQuestionId}
                setQuestionUpdate={setQuestionUpdate}
                setAddedQuestions={setAddedQuestions}
                setAddedQuestionsId={setAddedQuestionsId}
              />
            ))}
          </div>
        </div>
      )}
      <div className="flex items-center gap-4 mt-4 z-50 w-full">
        <Button
          version="outline-primary"
          handleClick={() => setOpenSingleQuestion(true)}
        >
          + Add a Question
        </Button>
        {!assessmentDetails?.editDocId && (
          <Button
            version="outline-primary"
            handleClick={() => setOpenBulkQuestion(true)}
          >
            + Add Bulk Questions
          </Button>
        )}
      </div>

      <div className="flex items-center gap-4 mt-4 z-50 w-full">
        <Button
          version="outline-primary"
          handleClick={() => setOpenSingleQuestion(true)}
        >
          + Add a Question
        </Button>
        {!assessmentDetails?.editDocId && (
          <Button
            version="outline-primary"
            handleClick={() => setOpenBulkQuestion(true)}
          >
            + Add Bulk Questions
          </Button>
        )}
      </div>

      {openSingleQuestion && (
        <Popup closePopup={() => setOpenSingleQuestion(false)}>
          <div className="overflow-y-auto">
            <SingleQuestionUpload
              setAddedQuestionsId={setAddedQuestionsId}
              assessmentDetails={
                assessmentDetails.editDocId
                  ? assessmentQuestionData
                  : assessmentDetails
              }
              // admin={admin}
              setOpenSingleQuestion={setOpenSingleQuestion}
              setAddedQuestions={setAddedQuestions}
              addedQuestions={addedQuestions}
            />
          </div>
        </Popup>
      )}
      {openBulkQuestion && (
        <Popup closePopup={() => setOpenBulkQuestion(false)}>
          <div className="w-[40vw] h-[70vh]">
            <UploadCSV
              setOpenBulkQuestion={setOpenBulkQuestion}
              setAddedQuestions={setAddedQuestions}
              setAddedQuestionsId={setAddedQuestionsId}
              openBulkQuestion={openBulkQuestion}
            />
          </div>
        </Popup>
      )}
      <div className="absolute bottom-4 left-0 w-full px-4 flex items-center justify-between">
        <div className="relative flex items-center gap-8 font-medium">
          {error && (
            <div className="text-xs text-red-400 absolute -top-14 bg-white p-2 rounded-xl border-2">
              {error}
              <div className="absolute bg-white w-4 h-4 -bottom-2 left-8 rotate-45 border-b-2 border-r-2" />
            </div>
          )}

          {/* <div>Skills : 0</div> */}
          <div>No. of Questions : {addedQuestions?.length}</div>
          <div>
            Total Duration : {Math.floor(duration / 60)}
            {duration % 60 === 0 ? "minutes" : ` min ${duration % 60} sec`}
          </div>
        </div>
        {/* {!assessmentDetails?.editDocId && ( */}
        <div className="flex gap-2">
          <Button
            version="secondary"
            text="Cancel"
            width="200px"
            handleClick={() => navigate("/assessments")}
          />
          <Button
            text={
              assessmentDetails?.editDocId
                ? "Update Assessment"
                : "Create Assessment"
            }
            width="240px"
            handleClick={handleCreateAssessment}
            loading={submitLoading}
          />
        </div>
        {/* )} */}
      </div>
      {showNewAssessmentModule && (
        <NewAssessmentPopup
          assessmentDetails={assessmentDetails}
          setShowNewAssessmentModule={setShowNewAssessmentModule}
        />
      )}
      {/* {assessmentDetails?.editDocId && (
        <Preview assessment={updatingAssesmentData} />
      )} */}
    </div>
  );
};

export default CustomAssessment;
