import React, { useState, useEffect } from "react";
import { doc, getDoc } from "firebase/firestore";
import { useLocation } from "react-router-dom";
import { db } from "../../config/firebase";
import ApplyStep1 from "./Step1";
import ApplyStep2 from "./Step2";
import ApplyStep3 from "./Step3";
import JobDetails from "./JobDetails";
import axios from "axios";

const ApplyLayout = ({ step }) => {
  const [jobData, setJobData] = useState("");
  const [loading, setLoading] = useState("");
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const jobIdParam = params.get("jobId");
    const getDataFromFirestore = async () => {
      const docId = jobIdParam;
      if (!docId) return;
      const documentRef = doc(db, "jobsPosted", docId);
      setLoading(true);
      try {
        const docSnapshot = await getDoc(documentRef);
        if (docSnapshot.exists()) {
          setJobData({ ...docSnapshot.data() });
        } else {
          setJobData("");
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };
    getDataFromFirestore();
  }, [location]);

  const [companyDetails, setCompanyDetails] = useState();
  useEffect(() => {
    const fetchCompanyDetailsApi =
      process.env.REACT_APP_FETCH_COMPANY_INFO_BY_NAME;
    const fetchCompanyDetails = async () => {
      try {
        setLoading(true);
        const details = await axios.post(fetchCompanyDetailsApi, "Testing");
        setCompanyDetails(details.data.admin);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };
    fetchCompanyDetails();
  }, [jobData]);

  return (
    <div className="flex justify-center w-full h-screen p-20 gap-4">
      <div className="card shadow-md w-2/3 overflow-hidden">
        {step === 1 && <ApplyStep1 jobData={jobData} />}
        {step === 2 && <ApplyStep2 jobData={jobData} />}
        {step === 3 && <ApplyStep3 />}
      </div>
      <JobDetails
        jobData={jobData}
        companyDetails={companyDetails}
        loading={loading}
      />
    </div>
  );
};

export default ApplyLayout;
