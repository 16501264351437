import React,{useEffect, useState} from 'react'

const TestCasesResult = ({outputDetails, closeTestCases}) => {

    const [showTestCases, setShowTestCases] = useState()
    const expandTestCases = e => e.target.id === showTestCases ? setShowTestCases() : setShowTestCases(e.target.id)
    
    useEffect(() => {
        setShowTestCases()
    },[closeTestCases])

    return (
        <div className='px-4 py-1'>
            <span className='font-medium py-1'>Test Cases Results : </span> 
            {
                outputDetails?.map((outputDetail,index) => {
                    let statusId = outputDetail?.status.id
                    return (
                        <div
                            key={index}
                            className={`
                                relative border-x-2 border-b-2 text-sm py-2 px-4 flex flex-col gap-1
                                ${index === 0 && "border-t-2 rounded-t-xl"}
                                ${index === outputDetails?.length-1 && "rounded-b-xl"}
                            `}
                        >
                            <div
                                id={index}
                                className='absolute top-0 left-0 h-full w-full cursor-pointer'
                                onClick={e => expandTestCases(e)}
                            />
                            <div className='w-full flex justify-between gap-2 font-medium'>
                                <span>
                                    Test Case { index + 1 }
                                </span>
                                <div className='flex gap-4 items-center'>
                                    {
                                        statusId === 3 ?
                                            <div className='flex items-center gap-1 text-[#57AD55]'>
                                                <span>
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="w-4 h-4">
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                                    </svg>
                                                </span>
                                                <span className='text-xs'>
                                                    Success
                                                </span>
                                            </div>
                                        :
                                            <div className='flex items-center gap-1 text-[#EA4335]'>
                                                <span>
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="w-4 h-4">
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                                    </svg>
                                                </span>
                                                <span className='text-xs'>
                                                    Failed
                                                </span>
                                            </div>
                                    }
                                    <div className={`${Number(showTestCases) === index ? '' : 'rotate-[-90deg]'} transition-all`}>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2.5} stroke="currentColor" className="w-5 h-5">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                                        </svg> 
                                    </div>                            
                                </div>                                                
                            </div>
                            <div className={`mt-1 gap-4 ${Number(showTestCases) === index ? 'flex' : 'hidden'}`}>
                                <div className='w-full flex gap-1'>
                                    <div className='flex flex-col w-full'>
                                        Output :
                                        <div className='py-2 px-2 rounded-md bg-[#444]'>
                                            {
                                                statusId === 6 ? 
                                                    <pre className='text-xs text-[#EA4335]'>
                                                        {atob(outputDetail?.compile_output)}
                                                    </pre>
                                                : statusId === 4 ? 
                                                    <div className='flex justify-between items-center'>
                                                        <pre className='text-xs text-[#EA4335]'>
                                                            {atob(outputDetail.stdout) !== null
                                                                ? atob(outputDetail.stdout) : null}

                                                        </pre>
                                                    </div>
                                                : statusId === 3 ?
                                                    <pre className='text-xs text-[#4d4]'>
                                                        {atob(outputDetail.stdout) !== null
                                                            ? atob(outputDetail.stdout) : null}
                                                    </pre>
                                                : statusId === 5 ? 
                                                    <pre className='text-[#EA4335]'>
                                                        Time Limit Exceeded
                                                    </pre>
                                                : <pre className='text-[#EA4335] text-xs'>
                                                    {atob(outputDetail?.stderr)}
                                                </pre>
                                            }
                                        </div>
                                    </div>
                                    { statusId === 4 &&
                                        <div className='flex flex-col w-full'>
                                            Expected :
                                            <div className='py-2 px-2 rounded-md bg-[#444]'>                                             
                                                <pre className='text-xs text-[#ccc]'>
                                                    {atob(outputDetail?.expected_output)}
                                                </pre>
                                            </div>
                                        </div>
                                    }
                                </div>
                                <div className='w-full'>
                                    <p className="text-sm">
                                        Status:
                                        <span className="font-semibold px-2">
                                            {/* {outputDetail?.status?.id} -  */}
                                            {outputDetail?.status?.description}
                                        </span>
                                    </p>
                                    <p className="text-sm">
                                        Memory:
                                        <span className="font-semibold px-2">
                                            {outputDetail?.memory}
                                        </span>
                                    </p>
                                    <p className="text-sm">
                                        Time:
                                        <span className="font-semibold px-2">
                                            {outputDetail?.time}
                                        </span>
                                     </p>
                                </div>    
                            </div>
                        </div>
                    )
                })
            }
        </div>
    )
}

export default TestCasesResult