import { collection, query, where, orderBy } from "firebase/firestore";
import { db } from "../../config/firebase";

export const fetchQuery = (
  adminName,
  filterWithJobId,
  sortValue,
) => {
  let q = query(
    collection(db, "candidateApplications"),
    where("appliedAtCompany", "==", adminName || "")
  );

       

  if (sortValue.fieldName === "appliedAt") {
    // console.log(sortValue.fieldName)
    q = query(q, orderBy(sortValue.fieldName, sortValue.fieldValue));
  }
  if (filterWithJobId) {
    q = query(q, where("appliedJobId", "==", filterWithJobId));
  }

  return q;
};
