import React from "react";
import { doc, updateDoc, Timestamp } from "firebase/firestore";

import { db } from "../../../config/firebase";
import { Button, RadioInput } from "../../../components";

const UpdateJobStatus = ({ setOpenEditStatus, jobData }) => {
  const statusArr = ["in-progress", "closed", "on hold", "inactive"];
  const [buttonDisabled, setButtonDisabled] = React.useState(true);

  const changeStatus = async (e) => {
    e.preventDefault();
    const selectedStatus = e.target.status.value;

    const timelineData = [
      ...jobData.timeline,
      { name: selectedStatus, time: Timestamp.fromDate(new Date()) },
    ];

    // update job status in firebase
    const documentId = jobData?.jobOpeningId;
    const documentRef = doc(db, "jobsPosted", documentId);
    try {
      await updateDoc(documentRef, {
        jobStatus: selectedStatus,
        timeline: timelineData,
      });
      setOpenEditStatus(false);
    } catch (error) {
      console.error("Error updating document:", error.message);
    }
  };
  return (
    <div className="relative border-2 border-gray-200 card shadow-md h-fit w-[20rem] py-4 px-8">
      <h1 className="text-lg font-semibold">Change Status</h1>
      <form onSubmit={changeStatus} className="mt-2 w-full">
        <label htmlFor="status" className="text-sm">
          Select Job Status
        </label>
        <div className="grid grid-cols-2 gap-3">
          {statusArr.map((status) => (
            <RadioInput
              key={status}
              text={status}
              inputName="status"
              inputValue={status}
              iniputId={status}
              defaultChecked={jobData?.jobStatus === status}
              handleChange={() =>
                jobData?.jobStatus === status
                  ? setButtonDisabled(true)
                  : setButtonDisabled(false)
              }
            />
          ))}
        </div>
        <div className="flex justify-end gap-4 mt-6">
          <Button
            version="secondary"
            text="Cancel"
            handleClick={() => setOpenEditStatus(false)}
          />
          <Button
            version="primary"
            type="submit"
            text="Update"
            isDisabled={buttonDisabled}
          />
        </div>
      </form>
    </div>
  );
};

export default UpdateJobStatus;
