import React from "react";

const SideNavigation = ({ step, setStep, stepsName }) => {
  return (
    <div className="w-full h-full flex flex-col justify-start items-start lg:gap-8">
      {stepsName.map((stepName, index) => (
        <div
          key={index}
          className="flex items-center gap-4 cursor-pointer"
          onClick={() => {
            setStep(index + 1);
          }}
        >
          <div
            className={`font-semibold hidden sm:flex capitalize
                ${step === index + 1 ? "text-[#8A24F1]" : ""}
            `}
          >
            {stepName}
          </div>
        </div>
      ))}
    </div>
  );
};

export default SideNavigation;
