import { useState } from "react";
import { BsLayoutThreeColumns } from "react-icons/bs";
import ColumnEditMenu from "../jobs/ColumnEditMenu";
import { LuChevronsUpDown } from "react-icons/lu";
import { IoIosArrowUp } from "react-icons/io";
import { IoIosArrowDown } from "react-icons/io";
import QuestionRow from "./QuestionRow";
import QuestionView from "./questionView/QuestionView";
const QuestionsListing = ({ questions, loading, setSortValue ,libraryType}) => {
  const [viewQuestionData, setViewQuestionData] = useState();
  const [viewQuestion, setViewQuestion] = useState(false);

  const columns = [
    {
      name: "questionName",
      label: "Question",
      width: "500px",
      open: true,
      sort: true,
      sortType: "",
    },
    {
      name: "type",
      label: "Type",
      width: "180px",
      open: true,
      sort: true,
      sortType: "",
    },
    {
      name: "time",
      label: "Time",
      width: "200px",
      open: true,
      sort: true,
      sortType: "",
    },
    {
      name: "difficulty",
      label: "Difficulty",
      width: "180px",
      open: true,
      sort: true,
      sortType: "",
    },
    {
      name: "createdAt",
      label: "Created",
      width: "250px",
      open: true,
      sort: true,
      sortType: "",
    },
  ];

  const [openColumnEdit, setOpenColumnEdit] = useState(false);
  const [columnList, setColumnList] = useState(columns);
  // console.log(viewQuestion)
  // console.log(viewQuestionData)
  // console.log(questions)

  return (
    <div>
      <div className={`w-fit h-full flex flex-col`}>
        <div className="flex w-fit overflow-x-auto py-5 pl-5   bg-[#E9EAEB] font-medium text-sm text-black md:rounded-t-xl">
          <div className="w-[50px] flex items-center">
            <BsLayoutThreeColumns
              className="w-5 h-5 cursor-pointer"
              onClick={(e) => {
                console.log(e.target);
                setOpenColumnEdit(!openColumnEdit);
              }}
            />
          </div>
          {columnList.map((column, index) => {
            const width = column.open ? column.width : "0";
            const opacity = column.open ? "1" : "0";
            return (
              <div
                key={index}
                className="flex items-center gap-1 transition-all duration-200"
                style={{ width, opacity }}
              >
                {column.open && (
                  <div className="flex ic">
                    <span>{column.label}</span>
                    {column.sort && (
                      <div
                        className="ml-1 cursor-pointer"
                        onClick={() => {
                          setSortValue({
                            fieldName: column.name,
                            fieldValue: column.sortType
                              ? column.sortType
                              : "asc",
                          });
                          columnList[index].sortType =
                            column.sortType === ""
                              ? "desc"
                              : column.sortType === "desc"
                              ? "asc"
                              : "desc";
                          columnList.map((_, i) => {
                            if (index !== i) {
                              columnList[i].sortType = "";
                            }
                            return "";
                          });
                        }}
                      >
                       
                       {/* code to apply sorting  */}

                        {/* {column.sortType === "desc" ? (
                          <IoIosArrowUp className="w-4 h-4" />
                        ) : column.sortType === "asc" ? (
                          <IoIosArrowDown className="w-4 h-4" />
                        ) : (
                          <LuChevronsUpDown className="w-4 h-4" />
                        )} */}
                      </div>
                    )}
                  </div>
                )}
              </div>
            );
          })}
        </div>

        <div className=" w-[100%] overflow-y-scroll scrollable h-96">
          {loading ? (
            <div className="fixed w-[90vw] text-center font-semibold text-xl text-gray-400 mt-10">
              Loading...
            </div>
          ) : questions?.length === 0 ? (
            <div className="fixed w-[90vw] text-center font-semibold text-xl text-gray-400 mt-10">
              No Questions Added Here
            </div>
          ) : (
            questions?.map((question, index) => (
              <QuestionRow
                key={questions.id}
                index={index}
                columnList={columnList}
                // setOpenJobView={setOpenJobView}
                setViewQuestionData={setViewQuestionData}
                setViewQuestion={setViewQuestion}
                viewQuestion={viewQuestion}
                question={question}
                // setOpenedJobId={setOpenedJobId}
              />
            ))
          )}

          <ColumnEditMenu
            openColumnEdit={openColumnEdit}
            setOpenColumnEdit={setOpenColumnEdit}
            columnList={columnList}
            setColumnList={setColumnList}
          />
        </div>
      </div>
      {viewQuestion && (
        // <div className="absolute  right-0 top-0 w-full  z-50   backdrop-blur-sm  shadow-md rounded-xl">
        <QuestionView
          question={viewQuestionData}
          setViewQuestion={setViewQuestion}
          libraryType={libraryType}
          viewQuestion={viewQuestion}
        />
        // </div>
      )}
    </div>
  );
};

export default QuestionsListing;
