import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Button, TextInput } from "../../components";

const companyQuestion = [
  {
    questionNo: 1,
    questionStatement:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Officiis suscipit nobis fuga quisquam id culpa nisi sit nesciunt similique expedita?",
    userResponse: "",
  },
  {
    questionNo: 2,
    questionStatement:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quo nisi soluta similique nostrum perferendis asperiores officia eos deleniti ipsum reprehenderit nulla ad nam, aspernatur facilis?",
    userResponse: "",
  },
  {
    questionNo: 3,
    questionStatement:
      "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Eos esse cupiditate minus? Veritatis?",
    userResponse: "",
  },
];

const ApplyStep2 = ({ jobData }) => {
  const [localStorageData, setLocalStorageData] = useState();
  const [questions, setQuestions] = useState(companyQuestion);
  const [error, setError] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const storedUserString = localStorage.getItem("user");
    const storedUser = JSON.parse(storedUserString);
    setLocalStorageData(storedUser);
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    const errorArray = questions.find(
      (emptyres) => emptyres.userResponse === ""
    );
    if (errorArray) {
      return setError(
        `Please enter your response of question No ${errorArray.questionNo}`
      );
    }

    const sheetId = "1Z6WQ4bDxFWNLrVNDYFaDH3gKewaujgtYjvUUnsLYzHQ";
    const updateCompanySheetApi =
      "https://script.google.com/macros/s/AKfycbwYFxGeAbeOxRoeu9JXxGBXDkvwC1Suvym9RUQzZMd2sHYroQ5J9aalE3H3KPj6B9UC/exec";
    const stringifyQuestion = JSON.stringify(questions);
    const sheetData = JSON.stringify([
      sheetId,
      localStorageData?.email,
      localStorageData?.jobProfile,
      stringifyQuestion,
    ]);
    try {
      const response = await axios.post(updateCompanySheetApi, sheetData);
      // ToDo - check if the response is success
      navigate("/candidateApply/step3");
    } catch (error) {
      console.log(error.message);
      setError(error.message);
    }
  };

  return (
    <div className="w-full h-full p-8 px-12 space-y-5 bg-gray-300 overflow-y-scroll scrollable">
      <h3 className="text-xl font-semibold">
        Questions form{" "}
        {jobData?.companyName ? `to ${jobData?.companyName}` : ""}
      </h3>
      <form onSubmit={handleSubmit} className="space-y-2">
        {questions.map((question, index) => {
          return (
            <div key={question.questionNo} className="space-y-2">
              <label
                htmlFor={question.questionNo}
                className="capitalize text-sm"
              >
                <span className="font-semibold">
                  Question {question.questionNo}.{" "}
                </span>
                <p>{question.questionStatement}</p>
              </label>
              <TextInput
                type="textbox"
                idName={question.questionNo}
                placeholderText={`Please enter your response for question ${question.questionNo}.`}
                inputValue={question.userResponse}
                handleChange={(e) => {
                  const updatedData = questions;
                  updatedData[index].userResponse = e.target.value;
                  setQuestions([...updatedData]);
                }}
              />
            </div>
          );
        })}
        <div>
          <div style={{ color: "red" }}>{error}</div>
          <div className="text-sm text-red-500 w-full h-5">{/* {error} */}</div>
          <Button type="submit" text="Apply" width="10rem" />
        </div>
      </form>
    </div>
  );
};

export default ApplyStep2;
