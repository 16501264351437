import React, { useState } from "react";
import { auth } from "../../config/firebase";
import { Link } from "react-router-dom";

const ProfileMenu = () => {
  const [openProfileMenu, setOpenProfileMenu] = useState(false);
  return (
    <div className="">
      <div
        className="flex gap-2 p-1 pr-4 border-2 border-gray-200 rounded-xl cursor-pointer"
        onClick={() => setOpenProfileMenu(!openProfileMenu)}
      >
        <div className="w-10 h-10 bg-gray-400 rounded-xl" />
        <div className="flex flex-col text-sm">
          <span className="capitalize font-semibold">
            {auth?.currentUser?.displayName}
          </span>
          <span className="text-gray-500 font-medium">
            {auth?.currentUser?.email?.slice(0, 14)}
            {auth?.currentUser?.email?.length > 14 && "..."}
          </span>
        </div>
      </div>

      {openProfileMenu && (
        <div className="absolute flex top-[72px] right-0 w-fit card flex-col border-2 border-gray-200">
          <div className="p-4 flex flex-col gap-4 profile-icons">
            <Link
              // to="/"
              // onClick={() => setIsprofileMenuOpen(false)}
              className="flex gap-3 cursor-pointer"
            >
              <div className="w-14 h-14">
                <img
                  src="/img/adminProfile.png"
                  alt="admin"
                  className="border-2 rounded-full"
                />
              </div>

              <div className="flex flex-col justify-center gap-1">
                <p className="font-medium capitalize">
                  {auth?.currentUser?.displayName}
                </p>
                <p className="w-fit bg-[#01C851]/[8%] p-1 rounded-md px-2 text-[#01C851] text-xs">
                  {auth?.currentUser?.email}
                </p>
              </div>
            </Link>
            <Link
              to="/candidateDetailsForm"
              className="border text-sm border-[#DBDBDB] p-[6px] mx-2 rounded-lg flex justify-center items-center hover:border-opacity-0 hover:font-medium hover:bg-[#DBDBDB]/30 cursor-pointer"
            >
              Edit Profile
            </Link>
          </div>

          <div className="h-1 w-[90%] border-t-2 border-[#f5f5f5] m-auto"></div>
          <div className="p-4 flex flex-col gap-4 text-sm">
            <Link
              // to="/profile"
              // onClick={() => setIsprofileMenuOpen(false)}
              className="flex items-center justify-between cursor-pointer hover:font-medium"
            >
              <div className="flex items-center gap-3">
                <div className="bg-[#EFEFEF] text-[#6F6F6F] p-2 rounded-full">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={2}
                    stroke="currentColor"
                    className="w-4 h-4"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M15.75 5.25a3 3 0 013 3m3 0a6 6 0 01-7.029 5.912c-.563-.097-1.159.026-1.563.43L10.5 17.25H8.25v2.25H6v2.25H2.25v-2.818c0-.597.237-1.17.659-1.591l6.499-6.499c.404-.404.527-1 .43-1.563A6 6 0 1121.75 8.25z"
                    />
                  </svg>
                </div>
                <p>Change Password</p>
              </div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={2}
                stroke="currentColor"
                className="w-3 h-3"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M8.25 4.5l7.5 7.5-7.5 7.5"
                />
              </svg>
            </Link>
            {/* <Link
                to="/settings"
                //   onClick={() => setIsprofileMenuOpen(false)}
                className="flex items-center justify-between cursor-pointer hover:font-medium"
                >
                <div className="flex items-center gap-3">
                    <div className="bg-[#EFEFEF] text-[#6F6F6F] p-2 rounded-full">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={2}
                        stroke="currentColor"
                        className="w-4 h-4"
                    >
                        <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M9.594 3.94c.09-.542.56-.94 1.11-.94h2.593c.55 0 1.02.398 1.11.94l.213 1.281c.063.374.313.686.645.87.074.04.147.083.22.127.324.196.72.257 1.075.124l1.217-.456a1.125 1.125 0 011.37.49l1.296 2.247a1.125 1.125 0 01-.26 1.431l-1.003.827c-.293.24-.438.613-.431.992a6.759 6.759 0 010 .255c-.007.378.138.75.43.99l1.005.828c.424.35.534.954.26 1.43l-1.298 2.247a1.125 1.125 0 01-1.369.491l-1.217-.456c-.355-.133-.75-.072-1.076.124a6.57 6.57 0 01-.22.128c-.331.183-.581.495-.644.869l-.213 1.28c-.09.543-.56.941-1.11.941h-2.594c-.55 0-1.02-.398-1.11-.94l-.213-1.281c-.062-.374-.312-.686-.644-.87a6.52 6.52 0 01-.22-.127c-.325-.196-.72-.257-1.076-.124l-1.217.456a1.125 1.125 0 01-1.369-.49l-1.297-2.247a1.125 1.125 0 01.26-1.431l1.004-.827c.292-.24.437-.613.43-.992a6.932 6.932 0 010-.255c.007-.378-.138-.75-.43-.99l-1.004-.828a1.125 1.125 0 01-.26-1.43l1.297-2.247a1.125 1.125 0 011.37-.491l1.216.456c.356.133.751.072 1.076-.124.072-.044.146-.087.22-.128.332-.183.582-.495.644-.869l.214-1.281z"
                        />
                        <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                        />
                    </svg>
                    </div>
                    <p>Settings</p>
                </div>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={2}
                    stroke="currentColor"
                    className="w-3 h-3"
                >
                    <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M8.25 4.5l7.5 7.5-7.5 7.5"
                    />
                </svg>
                </Link> */}
            <div
              className="flex items-center gap-3 text-[#E5383B] hover:font-semibold cursor-pointer font-medium"
              onClick={() => auth.signOut()}
            >
              <div className="bg-[#E5383B]/10 p-2 rounded-full">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={2}
                  stroke="currentColor"
                  className="w-5 h-5"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15m3 0l3-3m0 0l-3-3m3 3H9"
                  />
                </svg>
              </div>
              <p>Logout</p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProfileMenu;
