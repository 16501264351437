import {
  collection,
  doc,
  getDoc,
  onSnapshot,
  query,
  where,
} from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { db } from "../../config/firebase";
import ProfileLeftPanel from "./ProfileLeftPanel";
import ProfileRightPanel from "./ProfileRightPanel";
import CandidateView from "./candidateDetails/CandidateView";

const CandidateProfile = () => {
  const location = useLocation();
  const urlparams = new URLSearchParams(location.search);
  const idParam = urlparams.get("id");

  const [applicationId, setApplicationId] = useState("");
  useEffect(() => {
    if (idParam) {
      setApplicationId(idParam);
    }
  }, [idParam]);

  // fetch application details with id
  const [applicationDetails, setApplicationDetails] = useState();
  const [candidateDetails, setCandidateDetails] = useState();
  const [jobDetails, setJobDetails] = useState();
  const [schedulesDetails, setSchedulesDetails] = useState();

  // fetch application details with id
  useEffect(() => {
    const fetchApplicationDetails = async () => {
      const docRef = doc(db, "candidateApplications", applicationId);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setApplicationDetails({ id: docSnap.id, ...docSnap.data() });
      } else {
        console.log("No Application found!");
      }
    };
    if (applicationId) fetchApplicationDetails();
  }, [applicationId]);

  // fetch candidate details with candidateProfileRef in application details
  useEffect(() => {
    const candidateRef = applicationDetails?.candidateProfileRef;
    const fetcheCandidateProfileDetails = async () => {
      const docSnap = await getDoc(applicationDetails?.candidateProfileRef);

      if (docSnap.exists()) {
        setCandidateDetails(docSnap.data());
        localStorage.setItem(
          "candidateDetails",
          JSON.stringify({ id: docSnap.id, ...docSnap.data() })
        );
      } else {
        console.log("No such document!");
      }
    };
    if (candidateRef) fetcheCandidateProfileDetails();
  }, [applicationDetails]);

  // fetch jobdetails with jobId in application details
  useEffect(() => {
    const jobId = applicationDetails?.appliedJobId;
    const fetchJobDetails = async () => {
      const docRef = doc(db, "jobsPosted", jobId);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setJobDetails({ id: docSnap.id, ...docSnap.data() });
        setRounds(
          { ...docSnap.data() }.rounds?.filter(
            (round) => round?.roundType !== "interview"
          )
        );
      } else {
        console.log("No Job found!");
      }
    };
    if (jobId) fetchJobDetails();
  }, [applicationDetails]);

  // ToDo - fetch schedule details with application id
  useEffect(() => {
    const fetchSchedulesDetails = async () => {
      let q = query(
        collection(db, "assessmentTests"),
        where("applicationId", "==", applicationId)
      );

      const querySnapshot = onSnapshot(q, (querySnapshot) => {
        const schedules = [];
        querySnapshot.forEach((doc) => {
          schedules.push({ id: doc.id, ...doc.data() });
        });
        setSchedulesDetails(schedules);
      });
      return querySnapshot;
    };
    if (applicationId) fetchSchedulesDetails();
  }, [applicationId]);

  // round details with jobdetails
  const [rounds, setRounds] = useState();
  const [selectedRound, setSelectedRound] = useState(0);
  const [results, setResults] = useState();
  const [notAttempted, setNotAttempted] = useState(false);

  useEffect(() => {
    if (applicationDetails?.assessmentsResults) {
      setResults(applicationDetails?.assessmentsResults[selectedRound]);
      if (applicationDetails?.assessmentsResults.length < selectedRound + 1) {
        setNotAttempted(true);
      } else {
        setNotAttempted(false);
      }
    } else {
      setNotAttempted(true);
    }
  }, [applicationDetails, selectedRound]);

  const [schedule, setScheudle] = useState();
  useEffect(() => {
    if (schedulesDetails) {
      const sch =
        selectedRound === 0
          ? schedulesDetails.filter(
              (sch) => !sch.hasOwnProperty("assessmentId")
            )[0]
          : schedulesDetails?.find(
              (sch) => sch?.assessmentId === rounds[selectedRound]?.id
            );
      setScheudle(sch);
    }
  }, [schedulesDetails, rounds, selectedRound]);

  const [candidateProfileView, setCandidateProfileView] = useState(false);

  return (
    <div className="main-container mx-auto md:ml-20 mt-2 lg:h-[90vh] flex flex-col lg:flex-row gap-3 rounded-[25px] overflow-hidden">
      {candidateProfileView && (
        <CandidateView
          candidateDetails={candidateDetails}
          applicationDetails={applicationDetails}
          candidateProfileView={candidateProfileView}
          setCandidateProfileView={setCandidateProfileView}
        />
      )}
      <div className="w-full lg:w-1/4">
        <ProfileLeftPanel
          candidateDetails={candidateDetails}
          jobDetails={jobDetails}
          schedule={schedule}
          applicationDetails={applicationDetails}
          results={results}
          setCandidateProfileView={setCandidateProfileView}
        />
      </div>

      <div className="w-full lg:w-3/4">
        <ProfileRightPanel
          rounds={rounds}
          selectedRound={selectedRound}
          setSelectedRound={setSelectedRound}
          candidateDetails={candidateDetails}
          applicationDetails={applicationDetails}
          results={results}
          notAttempted={notAttempted}
        />
      </div>
    </div>
  );
};

export default CandidateProfile;
