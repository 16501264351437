import React, { useState } from "react";
import { BsLayoutThreeColumns } from "react-icons/bs";
import ColumnEditMenu from "../jobs/ColumnEditMenu";
import ATSrows from "./ATSrows";
import { LuChevronsUpDown } from "react-icons/lu";
import { IoIosArrowUp } from "react-icons/io";
import { IoIosArrowDown } from "react-icons/io";

const ATSlisting = ({
  candidates,
  loading,
  setSortValue,
  sortValue,
  setShowScheduleModule,
  setCandidateToSchedule,
  setCandidateToReschedule,
}) => {
  const columns = [
    // {
    //   name: "candidateId",
    //   label: "Candidate ID",
    //   width: "150px",
    //   open: true,
    // },
    {
      name: "name",
      label: "Name",
      width: "150px",
      open: true,
      sort: false,
    },
    {
      name: "email",
      label: "Email Id",
      width: "200px",
      open: true,
      sort: false,
    },
    {
      name: "positionTitle",
      label: "Position Title",
      width: "160px",
      open: true,
      sort: false,
    },
    {
      name: "appliedPosition",
      label: "Applied Position",
      width: "160px",
      open: true,
      sort: true,
      sortType: "",
    },
    {
      name: "appliedAt",
      label: "Applied On",
      width: "150px",
      open: true,
      sort: true,
      sortType: "asc",
    },
    {
      name: "source",
      label: "Source",
      width: "120px",
      open: true,
      sort: false,
    },
    {
      name: "candidateOwner",
      label: "Candidate Owner",
      width: "150px",
      open: true,
      sort: false,
    },
    {
      name: "phoneNumber",
      label: "Phone Number",
      width: "150px",
      open: true,
      sort: false,
    },
    // {
    //   name: "resumeUrl",
    //   label: "Resume",
    //   width: "120px",
    //   open: true,
    // },
    {
      name: "expectedSalary",
      label: "Expected Salary",
      width: "150px",
      open: true,
      sort: false,
    },
    {
      name: "currentSalary",
      label: "Current Salary",
      width: "150px",
      open: true,
      sort: false,
    },
    {
      name: "workExperience",
      label: "Work Experience",
      width: "150px",
      open: true,
    },
    {
      name: "schedule",
      label: "Schedule",
      width: "150px",
      open: true,
    },
  ];
  const [columnList, setColumnList] = useState(columns);
  const [openColumnEdit, setOpenColumnEdit] = useState(false);

  return (
    <>
      <div className="w-fit h-full flex flex-col">
        <div className="flex w-full overflow-x-auto py-5 pl-5 bg-[#E9EAEB] font-medium text-sm text-black md:rounded-t-xl">
          <div className="w-[50px] flex items-center">
            <BsLayoutThreeColumns
              className="w-5 h-5 cursor-pointer"
              onClick={() => setOpenColumnEdit(!openColumnEdit)}
            />
          </div>
          {columnList.map((column, index) => {
            const width = column.open ? column.width : "0";
            const opacity = column.open ? "1" : "0";
            return (
              <div
                key={index}
                className="flex items-center gap-1 transition-all duration-200"
                style={{ width, opacity }}
              >
                {column.open && (
                  <div className="flex">
                    <span>{column.label}</span>{" "}
                    {column.sort && (
                      <div
                        className="ml-1 cursor-pointer"
                        onClick={() => {
                          setSortValue({
                            fieldName: column.name,
                            fieldValue: column.sortType
                              ? column.sortType
                              : "asc",
                          });
                          columnList[index].sortType =
                            column.sortType === ""
                              ? "desc"
                              : column.sortType === "desc"
                              ? "asc"
                              : "desc";
                          columnList.map((_, ind) => {
                            if (index !== ind) {
                              columnList[ind].sortType = "";
                            }
                            return "";
                          });
                        }}
                      >
                        {column.sortType === "desc" ? (
                          <IoIosArrowUp className="w-4 h-4" />
                        ) : column.sortType === "asc" ? (
                          <IoIosArrowDown className="w-4 h-4" />
                        ) : (
                          <LuChevronsUpDown className="w-4 h-4" />
                        )}
                      </div>
                    )}
                  </div>
                )}
              </div>
            );
          })}
        </div>

        <div className="h-full overflow-y-scroll scrollable">
          {loading ? (
            <div className="fixed w-[90vw] text-center font-semibold text-xl text-gray-400 mt-10">
              Loading...
            </div>
          ) : candidates?.length === 0 ? (
            <div className="fixed w-[90vw] text-center font-semibold text-xl text-gray-400 mt-10">
              No Candidates to Show
            </div>
          ) : (
            candidates?.map((candidate, index) => (
              <ATSrows
                key={index}
                index={index}
                columnList={columnList}
                candidate={candidate}
                setShowScheduleModule={setShowScheduleModule}
                setCandidateToSchedule={setCandidateToSchedule}
                setCandidateToReschedule={setCandidateToReschedule}
              />
            ))
          )}

          <ColumnEditMenu
            openColumnEdit={openColumnEdit}
            setOpenColumnEdit={setOpenColumnEdit}
            columnList={columnList}
            setColumnList={setColumnList}
          />
        </div>
      </div>
    </>
  );
};

export default ATSlisting;
