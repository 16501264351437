import React from "react";
import { IoWarningOutline } from "react-icons/io5";

const QuestionModule = ({ ques, index, setIndex, totalQuestions }) => {
  return (
    <div className="relative w-[500px] card p-4 flex flex-col gap-4 min-h-[95%] overflow-y-scroll scrollable">
      <div className="relative w-full h-full">
        <h1 className="capitalize font-semibold mb-2">
          question no. {index + 1}
        </h1>
        <div className="flex gap-8 items-center text-sm">
          <div>
            Type{" : "}
            <span className="capitalize font-medium">
              {ques?.question?.type === "mcq" ? "MCQ" : ques?.question?.type}
            </span>
          </div>
          <div>
            Difficulty{" : "}
            <span className="capitalize font-medium">
              {["easy", "medium", "hard"][ques?.question?.difficulty]}
            </span>
          </div>
        </div>
        {ques && (
          <div className="w-full h-full scrollbar-none overflow-y-auto pb-20">
            <div className="bg-[#FCFCFC] py-2 px-4 rounded-lg">
              <div className="text-[#444] text-sm space-y-1">
                <div className="text-sm flex gap-1">
                  <span className="font-medium">Title :</span>
                  <span>{ques?.question?.questionName}</span>
                </div>
                <div className="text-sm flex flex-col">
                  <span className="font-medium">Statement :</span>
                  <span>{ques?.question?.statement}</span>
                </div>
                {ques?.question?.statmentImage && (
                  <img
                    src={ques?.question?.statmentImage}
                    alt=""
                    className="h-3/4 w-3/4 mx-auto"
                  />
                )}

                {/* {ques.questionStatment.split("/n").map((element, index) => (
                  <pre key={index} className="text-sm leading-6">
                    {element.split("<code>")[0]}
                    {element.split("<code>")[1] && (
                      <div className=" w-full border-2 p-2 my-2 px-4 rounded-lg overflow-x-auto">
                        <div className="flex items-center relative">
                          <p
                            className={`font-medium ${
                              showCodeId ? "hidden" : ""
                            }`}
                          >
                            Code -{" "}
                          </p>
                          <p
                            id={question.num}
                            className={`absolute right-2 cursor-pointer text-blue-400 ${
                              showCodeId ? "top-2" : ""
                            }`}
                            onClick={(e) => expandCode(e)}
                          >
                            {showCodeId ? "hide" : "show"}
                          </p>
                        </div>
                        <pre
                          className={`${
                            Number(showCodeId) === Number(question?.num)
                              ? ""
                              : "hidden"
                          }`}
                        >
                          {element.split("<code>")[1]}
                        </pre>
                      </div>
                    )}
                  </pre>
                ))} */}
              </div>
            </div>

            {ques?.question?.type === "subjective" ? (
              <div className="flex flex-col gap-4">
                <div
                  className={`py-2 mt-2 px-5 rounded-lg flex gap-2 flex-col bg-[#E0FFE0] ${
                    ques?.question?.evaluation === "correct"
                      ? "bg-[#E0FFE0]"
                      : ques?.question?.evaluation === "incorrect"
                      ? "bg-[#FFEFEF]"
                      : "bg-[#F9FAFB]"
                  }`}
                >
                  <span className="font-medium text-sm">Answer Summary : </span>
                  <pre className="">
                    {ques?.question?.answerSummary
                      ? ques?.question?.answerSummary
                      : "No Answer Summary provided"}
                  </pre>
                </div>
                {/* <div
                  className={`py-2 px-5 rounded-lg flex gap-2 flex-col 
                              ${
                                evaluationArray[qusIndex] === "correct"
                                  ? "bg-[#E0FFE0]"
                                  : evaluationArray[qusIndex] === "incorrect"
                                  ? "bg-[#FFEFEF]"
                                  : "bg-[#F9FAFB]"
                              }`}
                >
                  <div className="flex items-center justify-between">
                    <span className="font-medium text-sm">
                      User Response :{" "}
                    </span>
                    <span
                      className={`text-sm capitalize ${
                        evaluationArray[qusIndex] === "correct"
                          ? "text-[#57AD55]"
                          : evaluationArray[qusIndex] === "incorrect"
                          ? "text-[#EA4335]"
                          : evaluationArray[qusIndex] === "pending"
                          ? "text-[#FFB800]"
                          : ""
                      }`}
                    >
                      {evaluationArray[qusIndex]}
                    </span>
                  </div>
                  <pre>
                    {evaluationArray[qusIndex] === "unattempted"
                      ? "This question is not attempted by the candidate"
                      : answerArray[qusIndex]?.indexOf("subjective") !== -1 &&
                        subjectiveAnswersArray[
                          answerArray[qusIndex]?.slice(10)
                        ]}
                  </pre>
                </div> */}
                {/* {evaluateLoading ? (
                  <div className="w-full flex justify-center font-medium text-[#888]">
                    Please wait
                  </div>
                ) : evaluationArray[qusIndex] !== "pending" &&
                  evaluationArray[qusIndex] !== "" ? (
                  <></>
                ) : (
                  <div className="flex justify-between items-center px-5">
                    <Button
                      width="40%"
                      version="outline-green"
                      text=""
                      handleClick={() => evaluate("correct", qusIndex)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-5 h-5 mr-2"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M4.5 12.75l6 6 9-13.5"
                        />
                      </svg>
                      Correct
                    </Button>

                    <Button
                      width="40%"
                      version="outline-red"
                      text=""
                      handleClick={() => evaluate("incorrect", qusIndex)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-5 h-5 mr-2"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                      Incorrect
                    </Button>
                  </div>
                )} */}
              </div>
            ) : (
              <div className="flex flex-col gap-3 mt-2 text-sm text-[#555] mb-6">
                {ques?.question?.options?.map((option, index) => (
                  <div
                    key={option}
                    className={`py-2 px-5 rounded-lg flex items-center justify-between ${
                      ques?.question?.correctOption === option
                        ? "bg-[#E0FFE0]"
                        : ques?.userAnswer === option
                        ? "bg-[#FFEFEF]"
                        : "bg-[#F9FAFB]"
                    }`}
                  >
                    <div className="flex items-center gap-2 w-full">
                      <span className="font-medium">
                        {["A", "B", "C", "D"][index]}.
                      </span>
                      <div className="flex gap-2 items-center justify-between w-full">
                        <span>{option}</span>
                        <span>
                          {ques?.userAnswer === option &&
                            (ques?.evaluation === "correct" ? (
                              <div className="text-[#57AD55] font-medium flex">
                                <span className="text-sm mx-1">✓</span> Correct
                              </div>
                            ) : (
                              <div className="text-[#EA4335] font-medium flex items-center">
                                <p className="text-2xl mr-2">×</p> Incorrect
                              </div>
                            ))}
                        </span>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
            {ques?.violations?.length > 0 && (
              <div className="mt-4 py-3 border-t-2 flex items-center gap-3 text-sm">
                <div className="flex gap-1 text-[#FFB800] text-sm items-center">
                  <IoWarningOutline className="w-5 h-5" />
                  {ques?.violations?.length > 1
                    ? "Violations :"
                    : "Violation :"}
                </div>
                <span className="capitalize text-sm">
                  {ques?.violations.join(", ")}
                </span>
              </div>
            )}
          </div>
        )}

        <div className="absolute w-full bottom-0 flex justify-between text-[#666] px-2">
          <div>
            {index > 0 && (
              <div className="relative flex items-center justify-center border-[3px] p-[6px] rounded-full cursor-pointer bg-[#FCFCFC]/60 backdrop-blur-sm">
                <div
                  className="absolute w-full h-full left-0 top-0"
                  onClick={() => {
                    // setShowCodeId();
                    setIndex(index - 1);
                  }}
                />
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={3}
                  stroke="currentColor"
                  className="w-5"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M15.75 19.5L8.25 12l7.5-7.5"
                  />
                </svg>
              </div>
            )}
          </div>
          <div>
            {index < totalQuestions - 1 && (
              <div className="relative flex items-center justify-center border-[3px] p-[6px] rounded-full cursor-pointer bg-[#FCFCFC]/60 backdrop-blur-sm">
                <div
                  className="absolute w-full h-full left-0 top-0"
                  onClick={() => {
                    // setShowCodeId();
                    setIndex(index + 1);
                  }}
                />
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={2.5}
                  stroke="currentColor"
                  className="w-5"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M8.25 4.5l7.5 7.5-7.5 7.5"
                  />
                </svg>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuestionModule;
