import React, { useEffect, useState } from "react";
import { auth } from "../../../config/firebase";
import { Link } from "react-router-dom";
import { signInWithEmailAndPassword } from "firebase/auth";
import { sendPasswordResetEmail } from "firebase/auth";
import { Logo, Button, TextInput } from "../../../components";

const AdminLogin = () => {
  document.title = "Talentigo | Login";
  const [userEmail, setUserEmail] = useState("");
  const [pass, setPass] = useState("");
  const [mailError, setMailError] = useState("");
  const [passError, setPassError] = useState("");
  const [otherError, setOtherError] = useState("");
  const [loading, setLoading] = useState(false);
  const [passwordType, setPasswordType] = useState("password");

  const handleSubmit = async (e) => {
    e.preventDefault();
    //reset error messages
    setMailError("");
    setPassError("");
    setOtherError("");
    if (!userEmail) return setMailError("Please enter your email");
    if (!pass) return setPassError("Please enter your password");
    setLoading(true);
    try {
      await signInWithEmailAndPassword(auth, userEmail, pass);

      const adminDetails = {
        isAdminLogedIn: true,
        loginTime: Date.now(),
        uid: auth.currentUser.uid,
      };
      localStorage.setItem("adminLogin", JSON.stringify(adminDetails));
      window.location.href = "/admin";
    } catch (err) {
      setOtherError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleForgotPassword = async () => {
    if (!userEmail) return setMailError("Please enter your email");
    try {
      await sendPasswordResetEmail(auth, userEmail);
      alert("Password reset email sent!");
    } catch (err) {
      setOtherError(err.message);
    }
  };

  return (
    <div className="admin-main">
      <div className="admin-card flex justify-center items-center h-full sm:py-5 w-full gradient2">
        <div className="h-full w-full flex flex-col justify-center items-center p-10 sm:px-14 md:px-20 gap-4">
          {/* <div className='ribbon-box'>{process.env.REACT_APP_VERSION}</div> */}
          <Logo />
          <form onSubmit={handleSubmit} className="flex flex-col gap-4 w-full">
            <h1>Login to your account</h1>
            <div className="flex flex-col mb-[14px]">
              <label
                htmlFor="email"
                className="font-medium text-sm leading-[12px] mb-2"
              >
                Email id
              </label>
              <TextInput
                type="email"
                placeholderText="Enter your Email id"
                inputName="email"
                isRequired={true}
                inputValue={userEmail}
                handleChange={(e) => setUserEmail(e.target.value)}
              />
              {mailError && (
                <div className="font-medium text-xs leading-3 h-3 text-[#f00] mt-1">
                  {mailError}
                </div>
              )}
            </div>
            <div className="flex flex-col forgot-field password-field">
              <label
                htmlFor="password"
                className="font-medium text-sm leading-[12px] mb-2"
              >
                Password
              </label>

              <TextInput
                type={passwordType}
                placeholderText="Enter your Password"
                inputName="password"
                isRequired={true}
                inputValue={pass}
                handleChange={(e) => setPass(e.target.value)}
              />

              {passError && (
                <div className="font-medium text-xs leading-3 h-3 text-[#f00] mt-1">
                  {passError}
                </div>
              )}
              <Link
                className="forgot-txt text-sm w-full font-medium text-right hover:underline mt-1"
                onClick={handleForgotPassword}
              >
                Forgot Password
              </Link>
              <img
                src={`./img/eye-${
                  passwordType === "text" ? "open" : "close"
                }.png`}
                alt="eye-icon"
                onClick={() =>
                  passwordType === "password"
                    ? setPasswordType("text")
                    : setPasswordType("password")
                }
              />
            </div>

            <div className="flex flex-col items-center justify-center gap-2 mt-4">
              {
                <div className="font-medium text-xs leading-3 h-3 text-[#f00]">
                  {otherError}
                </div>
              }
              <Button
                version="primary"
                text="Login"
                type="submit"
                width="15rem"
                inputId="loginButton"
                loading={loading}
              />
            </div>
          </form>
          <div className="mt-4 flex flex-col justify-center items-center gap-4 w-full">
            <p className="text-sm">OR</p>
            <div className="flex justify-center gap-1 w-full">
              Don’t have any account?
              <Link to="/adminRegister" className="font-medium hover:underline">
                Register
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminLogin;
