import React, { useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Button, TextInput, UploadResume } from "../../components";
import { validateEmail } from "../../utils/validate";
import { validatePhoneNumber } from "../../utils/validate";
import { Timestamp, addDoc, collection } from "firebase/firestore";
import { auth, db } from "../../config/firebase";
import { FaRegUser } from "react-icons/fa";
import { MdOutlineEmail, MdOutlineLocalPhone } from "react-icons/md";
import emailTemplate from "../../utils/emailTemplates/jobApplied";
import scheduleEmailTemplate from "../../utils/emailTemplates/firstRoundSchedule";
import { fetchSignInMethodsForEmail } from "firebase/auth";

const ApplyStep1 = ({ jobData }) => {
  const navigate = useNavigate();
  const nameRef = useRef();
  const emailRef = useRef();
  const phoneRef = useRef();
  const [resumeUrl, setResumeUrl] = useState("");
  const [loading, setLoading] = useState(false);
  const [nameError, setNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [otherError, setOtherError] = useState("");
  const [resumeError, setResumeError] = useState("");
  const [dragging, setDragging] = useState(false);
  const [localStorageData, setLocalStorageData] = useState();


  useEffect(() => {
    const storedUserString = localStorage.getItem("user");
    const storedUser = JSON.parse(storedUserString);
    setLocalStorageData(storedUser);
  }, []);

  const inputFields = [
    {
      name: "name",
      ref: nameRef,
      type: "text",
      placeholder: "Full Name",
      innerLabel: <FaRegUser className="text-gray-400 w-5 h-5" />
    },
    {
      name: "email",
      ref: emailRef,
      type: "email",
      placeholder: "Email Id",
      innerLabel: <MdOutlineEmail className="text-gray-400 w-5 h-5" />
    },
    {
      name: "phone",
      ref: phoneRef,
      type: "tel",
      placeholder: "Phone Number",
      innerLabel: <MdOutlineLocalPhone className="text-gray-400 w-5 h-5" />
    }
  ];

  const sendEmail = async (type, examCode, scheduleDate, expDate) => {
    let subject;
    let body;
    const dateFormat = {
      day: "numeric",
      month: "long",
      year: "numeric"
    };
    const timeFormat = { hour: "numeric", minute: "2-digit", hour12: true };
    if (type === "schedule") {
      ({ subject, body } = scheduleEmailTemplate({
        candidateName: nameRef.current?.value,
        candidateEmail: emailRef.current?.value,
        examCode: examCode,
        jobTitle: jobData?.positionTitle,
        companyName: jobData?.companyName,
        startDate: {
          date: scheduleDate?.toLocaleDateString("en-IN", dateFormat),
          time: scheduleDate?.toLocaleTimeString("en-IN", timeFormat)
        },
        expDate: {
          date: expDate?.toLocaleDateString("en-IN", dateFormat),
          time: expDate?.toLocaleTimeString("en-IN", timeFormat)
        }
      }));
    } else {
      ({ subject, body } = emailTemplate({
        jobTitle: jobData?.positionTitle,
        companyName: jobData?.companyName,
        candidateName: nameRef.current?.value
      }));
    }

    const emailData = `${emailRef.current.value}|${subject}|${body}`;
    const mailApi = process.env.REACT_APP_EMAIL_API;
    const emailResponse = await axios.post(mailApi, emailData);
    return emailResponse;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // error Reset
    setNameError("");
    setEmailError("");
    setPhoneError("");
    setOtherError("");

    const name = nameRef.current.value;
    const email = emailRef.current.value;
    const phone = phoneRef.current.value;

    if (name === "") setNameError("Name is required");
    if (!validateEmail(email)) setEmailError("Enter a valid Email Id");
    if (email === "") setEmailError("Email is required");
    if (!validatePhoneNumber(phone))
      setPhoneError("Enter a valid 10 digit phone no.");
    if (phone === "") setPhoneError("Phone is required");
    if (resumeUrl === "") setResumeError("Resume is required");
    if (
      name === "" ||
      !validateEmail(email) ||
      !validatePhoneNumber(phone) ||
      resumeUrl === ""
    )
      return;

    let authExists;
    try {
      await fetchSignInMethodsForEmail(auth, email).then((result) => {
        authExists = result.length > 0;
      });
    } catch (error) {
      console.error("Error checking user existence:", error);
    }

    if (authExists) {
      // apply function for register candidate
      // - add apply data to candidateApplications with candidateProfileRef of registered user
      // - add schedule data
      // - schedule email
    } else {
      // apply function for unregistered candidate
    }

    setLoading(true);

    const candidateDetails = {
      name,
      email,
      phoneNumber: "+91" + phone,
      resumeUrl
    };

    try {
      //saving data to firebase db (candidate Profile)
      const profileRef = await addDoc(
        collection(db, "candidateProfile"),
        candidateDetails
      );

      const applicationDetails = {
        appliedAt: Timestamp.fromDate(new Date()),
        appliedAtCompany: jobData?.companyName,
        appliedJobId: jobData?.jobOpeningId,
        appliedPosition: jobData?.positionTitle,
        candidateProfileRef: profileRef,
        source: "by candidate",
        resumeUrl
      };

      //saving data to firebase db (candidate Applications)
      const applicatoinRef = await addDoc(
        collection(db, "candidateApplications"),
        applicationDetails
      );

      // email to candidate for successful application and option to register
      await sendEmail("successApply");

      // saving schedule data to firebase (assessmentTests)

      const examCode = (Math.random() + 1).toString(36).substring(6);
      const scheduleDate = new Date();
      const expDate = new Date(scheduleDate.getTime() + 1000 * 60 * 60 * 48);
      const scheduleDetails = {
        applicationId: applicatoinRef?.id,
        attempted: false,
        candidateEmail: email,
        candidateId: profileRef?.id,
        companyName: jobData?.companyName,
        createdAt: Timestamp.fromDate(new Date()),
        updatedAt: Timestamp.fromDate(new Date()),
        examCode,
        scheduleDate: Timestamp.fromDate(scheduleDate),
        expDate: Timestamp.fromDate(expDate),
        jobId: jobData?.jobOpeningId
      };

      await addDoc(collection(db, "assessmentTests"), scheduleDetails);
      // email to candidate for schedule details
      await sendEmail("schedule", examCode, scheduleDate, expDate);

      // navigate(`/candidateApply/step3`);

      // check either to navigate for login page or regiter page

      // storing details to local storage
      localStorage.setItem(
        "user",
        JSON.stringify({ name, email, phone, jobData: jobData?.positionTitle })
      );

      
        await fetchSignInMethodsForEmail(auth, localStorageData?.email).then(
          (result) => {

            if (result.length > 0) {
              navigate("/candidateLogin");
            } else {
              navigate("/candidateRegistration");
            }
          }
        );
      
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div
      className={`relative w-full h-full p-10 px-16 space-y-10`}
      onDragOver={() => setDragging(true)}
      onDrop={() => setDragging(false)}
    >
      <h1 className="text-2xl font-semibold">
        Apply {jobData?.companyName ? `to ${jobData?.companyName}` : ""}
      </h1>
      <form onSubmit={handleSubmit} className="w-full xl:w-2/3">
        {inputFields.map((field) => {
          return (
            <div key={field.name}>
              <label htmlFor={field.name} className="capitalize text-sm">
                {field.name}*
              </label>
              <TextInput
                innerLabel={field.innerLabel}
                type={field.type}
                idName={field.name}
                inputRef={field.ref}
                placeholderText={field.placeholder}
                isRequired={true}
                isDisabled={loading}
              />
              <div className="text-xs mt-1 text-red-500 h-4">
                {field.name === "name"
                  ? nameError
                  : field.name === "email"
                  ? emailError
                  : field.name === "phone"
                  ? phoneError
                  : ""}
              </div>
            </div>
          );
        })}
        <div>
          <UploadResume
            setResumeUrl={setResumeUrl}
            dragging={dragging}
            setDragging={setDragging}
          />
          <div className="text-xs mt-1 text-red-500 ">{resumeError}</div>
        </div>
        <div>
          <div className="text-sm text-red-500 w-full h-4 my-2">
            {otherError}
          </div>
          <Button
            type="submit"
            loading={loading}
            text={loading ? "Please Wait" : "Next"}
            isDisabled={loading}
            width="10rem"
          />
        </div>
      </form>
    </div>
  );
};

export default ApplyStep1;
