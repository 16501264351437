import React, { useState } from "react";
import { TextInput, Button } from "../../../components";
import { FaTrash } from "react-icons/fa6";

const ExperienceDetails = () => {
  const experianceFields = [
    {
      name: "occupation",
      label: "Occupation/Title",
      required: true,
      type: "text",
      placeholder: "Occupation / Title",
    },
    {
      name: "companyName",
      label: "Company Name",
      required: true,
      type: "text",
      placeholder: "Company Name",
    },
    {
      name: "ctc",
      label: "Salary",
      required: true,
      type: "text",
      placeholder: "Salary",
    },
  ];
  const uniqueId = new Date().getTime();

  const [experiances, setExperiances] = useState([{ id: uniqueId }]);
  const [working, setWorking] = useState([]);

  return (
    <div className="mt-6 ">
      <div className="font-semibold">Experience Details</div>
      <div></div>
      {experiances.map((experiance, index) => {
        return (
          <div className="mt-6" key={experiance.id}>
            <div className="flex w-fit items-center">
              <div className="w-[40px] h-[40px] flex justify-center items-center text-sm rounded-md border-2 border-gray-300">
                0{index + 1}
              </div>
              <div className="border-t-2 border-gray-300 w-[300px] h-1 -z-10" />
              <div
                className="w-[40px] h-[40px] flex justify-center items-center rounded-md border-[2px] border-gray-300 cursor-pointer"
                onClick={() => {
                  const newDetails = experiances.filter((_, i) => i !== index);
                  setExperiances(newDetails);
                }}
              >
                <FaTrash />
              </div>
            </div>
            <div className="grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 mt-5 ">
              {experianceFields.map((field) => {
                return (
                  <div key={field.name}>
                    <label htmlFor={field.name} className="text-xs font-medium">
                      {field.label}
                      {field.required && "*"}
                    </label>
                    <TextInput
                      type="text"
                      inputName={field.name}
                      isRequired={field.required}
                      placeholderText={field.placeholder}
                    />
                  </div>
                );
              })}

              <div className="md:col-span-2">
                <label htmlFor="duration" className="text-xs font-medium">
                  Work Duration*
                </label>
                <div className="flex w-full items-center border-2 border-gray-200 rounded-xl ">
                  <TextInput
                    type="month"
                    inputName="expFromDate"
                    innerLabel="From"
                    inputValue={experiance.fromDate}
                    isRequired={true}
                  />
                  <div className={working.includes(index) ? "opacity-30" : ""}>
                    <TextInput
                      type="month"
                      inputName="expToDate"
                      innerLabel="To"
                      inputValue={experiance.toDate}
                      isRequired={working.includes(index) ? false : true}
                    />
                  </div>
                </div>
              </div>
              <div className="flex  items-center ml-4 space-x-3">
                <label htmlFor="working" className="cursor-pointer font-medium">
                  I currently work here
                </label>
                <input
                  className="w-5 h-5 mr-2 rounded-md border-2 border-gray-300 bg-gray-200 focus:ring-0 cursor-pointer"
                  type="checkbox"
                  id="working"
                  name="working"
                  onChange={(e) => {
                    if (e.target.checked) {
                      setWorking([...working, index]);
                    } else {
                      setWorking(working.filter((i) => i !== index));
                    }
                  }}
                />
              </div>
            </div>
          </div>
        );
      })}
      <div className="flex space-x-4 mt-5">
        {/* <Button text="Test-Save" version="secondary" handleClick={testSave} /> */}

        <Button
          text=""
          version="outline-primary"
          handleClick={() => setExperiances([...experiances, { id: uniqueId }])}
        >
          <div className="flex items-center gap-2">
            <span className="font-semibold text-lg">+</span>
            Add Experiance Detail
          </div>
        </Button>
      </div>
    </div>
  );
};

export default ExperienceDetails;
