import React, { useState } from "react";
import { FaRegEdit, FaSleigh } from "react-icons/fa";
import { MdDeleteOutline } from "react-icons/md";
import { FaRegClone } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import { Button, Popup } from "../../components";
import { addDoc, collection, Timestamp } from "firebase/firestore";
import { db } from "../../config/firebase";
// import UpdateAssessmentPopUp from "./UpdateAssessmentPopUp";

const AssessmentBox = ({
  assessment,
  setOpenAssessmentView,
  setDeleteAssessmentId,
  index,
}) => {
  const navigate = useNavigate();
  const [menuOpen, setMenuOpen] = useState(false);
  // const [openUpdateAssessment, setOpenUpdateAssessment] = useState(false);
  const [clonePopUp, setClonePopUp] = useState(false);
  console.log(assessment);
  // console.log(openUpdateAssessment);

  const cloneAssessment = async () => {
    const cloneAssessmentData = {
      availability: assessment.availability,
      companyName: assessment.companyName,
      createdBy: assessment.createdBy,
      domain: assessment.domain,
      duration: assessment.duration,
      experience: assessment.experience,
      isDeleted: false,
      questions: assessment.questions,
      skills: assessment.skills,
      totalQuestions: assessment.totalQuestions,
      type: assessment.type,
      name: `${assessment.name} - Copy`,
      position: assessment.position,
      createdAt: Timestamp.fromDate(new Date()),
    };
    // console.log(cloneAssessmentData);
    try {
      await addDoc(collection(db, "assessments"), cloneAssessmentData);
      setClonePopUp(false);
    } catch (error) {
      console.log(error);
    }
  };
  console.log(assessment);
  return (
    <div>
      <div className="relative bg-gray-100 shadow-md p-5 h-full rounded-xl space-y-2">
        <h1 className="font-bold text-lg capitalize">{assessment?.name}</h1>

        <div className="text-sm">
          Position{" : "}
          <span className="font-semibold capitalize">
            {assessment?.position}
          </span>
        </div>
        <div className="text-sm">
          Skill Set : {""}
          <span className="font-semibold capitalize">
            {assessment?.skills?.map((s) => s?.skillName).join(", ")}.
          </span>
        </div>
        <div className="text-sm">
          Questionnaire Type : {""}
          <span className="font-semibold capitalize">
            {assessment?.type === "custom"
              ? "Custom Questions"
              : assessment?.type === "library"
              ? "Questions from Library"
              : "-"}
            .
          </span>
        </div>

        <ul className="flex gap-5 items-center text-sm justify-between  list-outside flex-wrap px-4 py-2 list-disc">
          <li className="list-item">
            Experience :<br />
            <span className="font-semibold">{assessment?.experience}</span>
          </li>
          <li className="list-item">
            No. of Ques :<br />
            <span className="font-semibold">{assessment?.totalQuestions}</span>
          </li>
          <li className="list-items">
            Duration :<br />
            <span className="font-semibold">
              {Math.floor(assessment?.duration / 60)} Min{" "}
              {assessment?.duration % 60 === 0
                ? ""
                : `${assessment?.duration % 60} Sec`}
            </span>
          </li>
        </ul>
        <div className="text-xs text-gray-400 font-medium">
          <span className="border-r-2 border-gray-400/60 px-2 py-[2px]">
            {new Date(
              assessment?.createdAt?.seconds * 1000
            )?.toLocaleDateString("en-in", {
              day: "numeric",
              month: "short",
              year: "numeric",
            })}
          </span>
          <span className="px-2 py-[2px] capitalize">
            By {assessment?.createdBy}
          </span>
        </div>

        <div className="absolute bottom-3 right-4">
          <img
            src="/img/open.svg"
            alt="open"
            className="w-5 h-5 cursor-pointer"
            onClick={() => {
              const id = assessment?.id;
              setOpenAssessmentView(true);
              navigate(`/assessments?id=${id}`);
            }}
          />
        </div>

        <div className="absolute top-0 right-4">
          <button
            className="cursor-pointer w-6 h-6 font-bold text-xl"
            onClick={() => setMenuOpen(!menuOpen)}
          >
            ⁝
          </button>
          <ul
            className={`absolute top-8 bg-white shadow-md py-2 z-10 rounded-xl text-sm 
              ${(index + 1) % 3 === 0 ? "right-0" : "left-0"}
              ${menuOpen ? "flex flex-col" : "hidden"}
            `}
          >
            <li
              className="flex gap-3 items-center cursor-pointer hover:bg-[#558BE4]/10 px-4 py-[6px] font-medium text-gray-700"
              onClick={() => {
                navigate(
                  `/newAssessment?type=${assessment.type}&position=${assessment.position}&name=${assessment.name}&experience=${assessment.experience}&domain=${assessment.domain}&editId=${assessment.id}`
                );
                setMenuOpen(!menuOpen);
              }}
            >
              <FaRegEdit className="w-5 h-5" />
              Edit
            </li>
            <li
              className="flex gap-3 items-center cursor-pointer hover:bg-[#558BE4]/10 px-4 py-[6px] font-medium text-gray-700"
              onClick={() => {
                setClonePopUp(true);
                setMenuOpen(!menuOpen);
              }}
            >
              <FaRegClone className="w-5 h-5" />
              Clone
            </li>
            <li
              className="flex gap-3 items-center cursor-pointer hover:bg-[#558BE4]/10 px-4 py-[6px] font-medium text-red-500"
              onClick={() => {
                setMenuOpen(!menuOpen);
                setDeleteAssessmentId(assessment.id);
                setMenuOpen(!menuOpen);
              }}
            >
              <MdDeleteOutline className="w-5 h-5" />
              Delete
            </li>
          </ul>
        </div>
      </div>
      {/* {openUpdateAssessment && (
        <div className="absolute top-0 right-0 w-full h-full opacity-100 bg-slate-100 z-20">
          <UpdateAssessmentPopUp
            setOpenUpdateAssessment={setOpenUpdateAssessment}
            assessmentDetails={assessment}
          />
        </div>
      )} */}
      {clonePopUp && (
        <Popup
          closePopup={() => {
            // setShowSuccessModule(false);
            setClonePopUp(false);
            // navigate("/assessments");
          }}
        >
          <div className="flex items-center justify-center flex-col gap-2 w-80">
            {/* <img src="/img/working.png" className="w-24 mb-4" alt="" /> */}
            <h1 className="text-2xl font-semibold">Clone</h1>
            <p className="font-medium text-center">
              {/* {assessmentDetails?.editDocId */}
              Do you want to Clone the Assessment {assessment?.position}
              {/* : "The Assessment has been created successfully."} */}
            </p>
            <Button text="Continue" handleClick={cloneAssessment} />
          </div>
        </Popup>
      )}
    </div>
  );
};

export default AssessmentBox;
