// this email is sent to admin when a candidate cleared the final round

const emailTemplate = ({candidateName, jobTitle, companyName}) => {
    //content to be changed
    const subject =  `${candidateName} - Successful Completion of all Assessment Rounds for ${jobTitle}`
    const body = `
    Dear [Hiring Manager Name],

    This email is to inform you that ${candidateName} has successfully completed the assessment rounds for the ${jobTitle} position.

    We believe ${candidateName} would be a valuable asset to the ${companyName} team.

    Best regards,
    Team Talentigo
    `
    return { subject, body }
}

export default emailTemplate