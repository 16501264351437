import React, { useState, useEffect } from "react";
import { Button, TextInput } from "../../components";
import { FaTrash } from "react-icons/fa6";
import { Timestamp, doc, updateDoc } from "firebase/firestore";
import { db } from "../../config/firebase";

const ExperienceDetails = ({ setStep, userDb }) => {
  const initialExpDetails = {
    title: "",
    companyName: "",
    ctc: "",
    fromDate: "",
    toDate: "",
    currentlyWorking: false
  };

  const [experiances, setexperiances] = useState([]);
  const [newExperiance, setNewExperiance] = useState(initialExpDetails);
  const [addExperianceOpen, setAddExperianceOpen] = useState(true);
  const [deleting, setDeleting] = useState();

  useEffect(() => {
    const setInitailProjects = async () => {
      userDb?.experiances && setexperiances(userDb.experiances);
      userDb?.experiances?.length > 0
        ? setAddExperianceOpen(false)
        : setAddExperianceOpen(true);
    };
    setInitailProjects();
  }, [userDb]);

  const handleSave = async (e) => {
    e.preventDefault();

    const newExperianceDetails = {
      ...newExperiance,
      duration: {
        from: Timestamp.fromDate(new Date(newExperiance.fromDate)),
        currentlyWorking: newExperiance.currentlyWorking,
        to: newExperiance.currentlyWorking
          ? null
          : Timestamp.fromDate(new Date(newExperiance.toDate))
      }
    };
    try {
      await updateDoc(doc(db, "candidateProfile", userDb.id), {
        experiances: [...experiances, newExperianceDetails]
      });
      setAddExperianceOpen(false);
      setNewExperiance(initialExpDetails);
    } catch (error) {
      console.log(error);
    }
  };
  const handleDelete = async (index) => {
    const updatedProject = experiances.filter((_, i) => i !== index);
    setDeleting(index);
    try {
      await updateDoc(doc(db, "candidateProfile", userDb.id), {
        experiances: updatedProject
      });
      setDeleting();
    } catch (error) {
      console.log(error);
      setDeleting();
    }
  };
  return (
    <div className="space-y-6 mb-5">
      {experiances?.length > 0 && (
        <div className="grid md:grid-cols-2 gap-x-4 gap-y-2">
          {experiances?.map((experiance, index) => (
            <div
              key={index}
              className={`relative bg-gray-200 border-2 border-gray-300 py-2 px-4 rounded-xl 
                ${deleting === index && "opacity-50"} 
              `}
            >
              <div className="absolute top-4 right-4">
                <div className="flex items-center gap-2">
                  {/* <FaEdit
                      className="text-blue-400 cursor-pointer"
                      onClick={() => {
                        edit experiance
                      }}
                    /> */}
                  <FaTrash
                    className="text-red-400 cursor-pointer"
                    onClick={() => {
                      handleDelete(index);
                    }}
                  />
                </div>
              </div>
              <h1 className="font-semibold text-lg capitalize">
                {experiance.companyName}
              </h1>
              <div className="flex items-center gap-1 text-sm">
                <p className="text-sm">
                  {new Date(
                    experiance?.duration?.from?.seconds * 1000
                  ).toLocaleDateString("en-in", {
                    day: "numeric",
                    month: "short",
                    year: "numeric"
                  })}
                </p>
                -
                <p className="text-sm capitalize">
                  {experiance.currentlyWorking
                    ? "present"
                    : new Date(
                        experiance?.duration?.to?.seconds * 1000
                      ).toLocaleDateString("en-in", {
                        day: "numeric",
                        month: "short",
                        year: "numeric"
                      })}
                </p>
              </div>
            </div>
          ))}
        </div>
      )}

      {addExperianceOpen && (
        <form
          onSubmit={handleSave}
          className="relative border-2 rounded-lg py-5 px-5 md:px-10"
        >
          <div className="absolute top-[-10px] left-5 text-sm bg-white px-2">
            Add Experiance
          </div>
          <div className="grid md:grid-cols-3 gap-4">
            <div>
              <label className="font-medium text-xs leading-[12px] mb-2">
                Job Tilte*
              </label>
              <TextInput
                type="text"
                placeholderText="Job Title"
                inputName="title"
                inputValue={newExperiance.title}
                isRequired={true}
                handleChange={(e) => {
                  setNewExperiance({
                    ...newExperiance,
                    title: e.target.value
                  });
                }}
              />
            </div>
            <div>
              <label className="font-medium text-xs leading-[12px] mb-2">
                Compant Name*
              </label>
              <TextInput
                type="text"
                placeholderText="Company Name"
                inputName="companyName"
                inputValue={newExperiance.companyName}
                isRequired={true}
                handleChange={(e) => {
                  setNewExperiance({
                    ...newExperiance,
                    companyName: e.target.value
                  });
                }}
              />
            </div>
            <div>
              <label className="font-medium text-xs leading-[12px] mb-2">
                Your CTC
              </label>
              <TextInput
                type="text"
                placeholderText="CTC"
                inputName="ctc"
                inputValue={newExperiance.ctc}
                handleChange={(e) => {
                  setNewExperiance({
                    ...newExperiance,
                    ctc: e.target.value
                  });
                }}
              />
            </div>
            <div className="md:col-span-3">
              <label className="font-medium text-xs leading-[12px] mb-2">
                Work Duration*
              </label>
              <div className="flex gap-4 items-center">
                <div className="w-full">
                  <TextInput
                    type="date"
                    innerLabel="From-"
                    inputName="fromDate"
                    inputValue={newExperiance.fromDate}
                    isRequired={true}
                    handleChange={(e) => {
                      setNewExperiance({
                        ...newExperiance,
                        fromDate: e.target.value
                      });
                    }}
                  />
                </div>
                <div className="w-full">
                  <div
                    className={
                      newExperiance.currentlyWorking ? "opacity-50" : ""
                    }
                  >
                    <TextInput
                      type="date"
                      innerLabel="To-"
                      inputName="toDate"
                      isRequired={!newExperiance.currentlyWorking}
                      isDisabled={newExperiance.currentlyWorking}
                      inputValue={newExperiance.toDate}
                      handleChange={(e) => {
                        setNewExperiance({
                          ...newExperiance,
                          toDate: e.target.value
                        });
                      }}
                    />
                  </div>
                </div>
                <div className="flex items-center ml-4 space-x-3 w-full">
                  <label
                    htmlFor="pursuing"
                    className="cursor-pointer font-medium flex flex-nowrap"
                  >
                    Currently Pursuing
                  </label>
                  <input
                    id="pursuing"
                    className="w-5 h-5 mr-2 rounded-md border-2 border-gray-300 bg-gray-200 focus:ring-0 cursor-pointer"
                    type="checkbox"
                    name="pursuing"
                    onChange={(e) => {
                      setNewExperiance({
                        ...newExperiance,
                        currentlyWorking: e.target.checked
                      });
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="mt-4 flex justify-end gap-3">
            <Button
              text="Cancel"
              version="secondary"
              width="100px"
              handleClick={() => setAddExperianceOpen(false)}
            />
            <Button type="submit" text="Save" version="primary" width="100px" />
          </div>
        </form>
      )}

      {!addExperianceOpen && (
        <div>
          <Button
            text=""
            width="10rem"
            version="small-outline-primary"
            handleClick={() => setAddExperianceOpen(true)}
          >
            <div className="flex items-center">
              <span className="text-xl font-semibold mr-2">+</span>
              <span className="text-sm">Add Experiance</span>
            </div>
          </Button>
        </div>
      )}

      <div className="flex w-full items-center justify-between gap-8">
        <div>
          <Button
            version="secondary"
            text="Previous"
            width="160px"
            handleClick={() => setStep((prev) => prev - 1)}
          />
        </div>
        <div className="flex gap-4">
          {/* <Button
            version="primary"
            text="Save"
            width="160px"
            handleClick={handleSubmit}
          /> */}
          <Button
            version="secondary"
            handleClick={() => setStep((prev) => prev + 1)}
            text="Next"
            width="160px"
          />
        </div>
      </div>
    </div>
  );
};

export default ExperienceDetails;
