import React from "react";
import { TextInput } from "../../../components";

const OtherInfo = () => {
  const otherInformationFields = [
    {
      name: "candidateStatus",
      label: "Candidate Status",
      inputValue: "New",
      required: true,
      type: "text",
    },
    // {
    //   name: "candidateOwner",
    //   label: "Candidate Owner",
    //   required: true,
    //   type: "text",
    //   placeholder: "Candidate Owner"
    // },
    {
      name: "source",
      label: "Source",
      required: true,
      type: "select",
      placeholder: "Source",
      selectedOption: ["Employee Referral", "Resume Inbox", "Email", "By Hr"],
    },
  ];

  return (
    <div>
      <div className="font-semibold">Other Information</div>
      <div className="grid md:grid-cols-2 lg:grid-cols-3  xl:grid-cols-4 gap-4 mt-4">
        {otherInformationFields.map((field, index) => {
          return (
            <div key={index}>
              <label htmlFor={field.name} className="text-xs font-medium">
                {field.label}
                {field.required && "*"}
              </label>
              <TextInput
                type={field.type}
                inputValue={field.inputValue && field.inputValue}
                isRequired={field.required}
                inputName={field.name}
                selectOptions={field.selectedOption}
                placeholderText={field.placeholder}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default OtherInfo;
