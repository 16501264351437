import AssessmentBox from "./AssessmentBox";

const AssessmentsListing = ({
  loading,
  assessments,
  setOpenAssessmentView,setDeleteAssessmentId
}) => {
  // console.log(assessments)
  return (
    <>
      <div className="h-full w-full flex overflow-y-scroll scrollable">
        {loading ? (
          <div className="w-full flex items-center justify-center font-semibold text-2xl text-gray-400">
            Loading...
          </div>
        ) : assessments?.length === 0 ? (
          <div className="w-full flex items-center justify-center font-semibold text-2xl text-gray-400">
            No Assessments Added
          </div>
        ) : (
          <div className="grid grid-cols-1 md:grid-cols-2  lg:grid-cols-3 gap-x-5 gap-y-8 w-full h-fit">
            {assessments?.map((assessment, index) => (
              <AssessmentBox
                key={index}
                index={index}
                assessment={assessment}
                setDeleteAssessmentId ={setDeleteAssessmentId}
                setOpenAssessmentView={setOpenAssessmentView}
              />
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default AssessmentsListing;
