import React from "react";
import { FaHeart, FaRegHeart } from "react-icons/fa";

const JobList = ({
  jobs,
  userDetails,
  handleFav,
  openJobDetails,
  setOpenJob,
  selectedTab,
  myJobsTab,
}) => {
  return (
    <div className="h-[98%] w-1/3 pr-4 pb-2 overflow-y-scroll scrollable">
      {!jobs?.length ? (
        <div className="text-[#777] text-xl font-semibold">
          {selectedTab === "my jobs"
            ? myJobsTab === "applied"
              ? "You haven't applied to any job yet"
              : "You haven't marked any job as favourite"
            : "No Job to display"}
        </div>
      ) : (
        jobs?.map((job) => {
          return (
            <>
              <div
                key={job.id}
                className={`relative p-4 px-5 rounded-xl cursor-pointer ${
                  openJobDetails === job ? "bg-[#8A24F1]/10" : ""
                }`}
                onClick={() => setOpenJob(job.id)}
              >
                <div className="flex items-center gap-4">
                  <div className="w-16 h-16 bg-[#8A24F1]/20 rounded-full flex items-center justify-center">
                    <span className="text-4xl text-[#8A24F1] font-bold">
                      {job?.companyName?.slice(0, 1)}
                    </span>
                  </div>
                  <div className="flex flex-col">
                    <h1 className="font-bold text-lg capitalize">
                      {job.positionTitle}
                    </h1>
                    <div className="text-gray-500 font-medium text-sm">
                      {job?.companyName}
                    </div>
                    <div className="text-gray-500 font-medium capitalize text-sm">
                      {job?.jobPrefence === "wfh"
                        ? "remote"
                        : `${job?.address?.city}, ${job?.address?.state}`}
                    </div>
                  </div>
                </div>

                <div
                  className="absolute top-3 right-6 cursor-pointer"
                  onClick={(e) => handleFav(e, job?.id)}
                >
                  {userDetails?.favJobs?.includes(job?.id) ? (
                    <FaHeart className="h-5 w-5 text-[#8A24F1]" />
                  ) : (
                    <FaRegHeart className="h-5 w-5" />
                  )}
                </div>
                <div className="absolute bottom-3 right-6 cursor-pointer text-xs text-gray-500">
                  {new Date(
                    job?.jobPostedDate?.seconds * 1000
                  )?.toLocaleDateString("en-IN", {
                    year: "numeric",
                    month: "short",
                    day: "numeric",
                  })}
                </div>
              </div>
              <div className="w-[94%] h-[2px] bg-gray-200 mx-auto" />
            </>
          );
        })
      )}
    </div>
  );
};

export default JobList;
