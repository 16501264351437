import React from "react";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../../config/firebase";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";


const JobMenu = ({ setMenuOpen, jobData, setOpenJobView }) => {

  console.log(jobData)

  const navigate = useNavigate()
  const menuOptions = [
    // "schedule interview",
    "edit job",
    // "publish job",
    // "clone",
    // "print preview",
    "delete",
  ];
  

  // todo  apply edit job, clone , print oreview
  const deleteJob = async () => {
    const documentId = jobData?.jobOpeningId;
    const documentRef = doc(db, "jobsPosted", documentId);
    setMenuOpen(false);
    setOpenJobView(false);
    try {
      await updateDoc(documentRef, { isDeleted: true });
      toast.success("Success! Job has been deleted");
    } catch (error) {
      toast.error(`Error! ${error.message}`);
    }
  };
  const action = (e) => {
    if (e.target.innerHTML === "delete") deleteJob();
    if (e.target.innerHTML === "edit job")navigate(`/newJob?editJobId=${jobData?.jobOpeningId}`)

  };
  // newJob

  return (
    <div className="absolute top-8 right-0 flex flex-col justify-center rounded-xl overflow-hidden border-2 bg-white z-50 w-[180px]">
      {menuOptions.map((option, index) => (
        <div
          key={index}
          className={`cursor-pointer text-sm hover:bg-gray-100 p-2 px-4 font-medium w-full capitalize ${
            option === "delete" ? "text-red-500" : ""
          }`}
          onClick={action}
        >
          {option}
        </div>
      ))}
    </div>
  );
};

export default JobMenu;
