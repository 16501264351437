import React, { useEffect, useState } from "react";
import { Button, Logo, TextInput } from "../../components";
import { useAuthState } from "react-firebase-hooks/auth";
import {
  signInWithEmailAndPassword,
  isSignInWithEmailLink,
  sendSignInLinkToEmail,
  signInWithEmailLink,
} from "firebase/auth";
import { auth } from "../../config/firebase";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { validateEmail } from "../../utils/validate";

const CandidateLogin = () => {
  const [user] = useAuthState(auth);
  const navigate = useNavigate();
  const location = useLocation();
  const { search } = location;

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordSignIn, setPasswordSignIn] = useState(false);
  const [loading, setLoading] = useState(false);
  const [initialLoading, setInitialLoading] = useState(false);
  const [initialError, setInitialError] = useState("");
  const [error, setError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passError, setPassError] = useState("");
  const [emailSentMsg, setEmailSentMsg] = useState("");
  const [passwordType, setPasswordType] = useState("password");

  useEffect(() => {
    const userString = localStorage.getItem("user");
    const localStorageUser = JSON.parse(userString);
    setEmail(localStorageUser?.email);
  }, []);

  useEffect(() => {
    if (user) {
      // user is already signed in
      navigate("/candidateDashboard");
    } else {
      // user is not signed in but the link is valid
      if (isSignInWithEmailLink(auth, window.location.href)) {
        // now in case user clicks the email link on a different device, we will ask for email confirmation
        let email = localStorage.getItem("candidateEmailForAuth");
        if (!email) {
          email = window.prompt("Please provide your email");
        }
        // after that we will complete the login process
        setInitialLoading(true);
        signInWithEmailLink(
          auth,
          localStorage.getItem("candidateEmailForAuth"),
          window.location.href
        )
          .then((result) => {
            // we can get the user from result.user but no need in this case
            console.log(result.user);
            localStorage.removeItem("candidateEmailForAuth");
            setInitialLoading(false);
            setInitialError("");
            navigate("/candidateDashboard");
          })
          .catch((err) => {
            setInitialLoading(false);
            setInitialError(err.message);
            navigate("/candidateLogin");
          });
      } else {
        // console.log("enter email and sign in");
      }
    }
  }, [user, search, navigate]);

  const login = async (e) => {
    setEmailSentMsg("");
    setError("");
    setEmailError("");
    setPassError("");
    e.preventDefault();

    // validations and error handling
    if (email === "") setEmailError("Email is required");
    if (!validateEmail(email)) setEmailError("Invalid Email");

    setLoading(true);
    if (passwordSignIn) {
      if (password === "") setPassError("Password is required");
      if (!validateEmail(email) || password === "") return setLoading(false);
      try {
        await signInWithEmailAndPassword(auth, email, password);
        // navigate to dashboard
        navigate("/candidateDashboard");
        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    } else {
      if (!validateEmail(email)) return setLoading(false);
      try {
        await sendSignInLinkToEmail(auth, email, {
          url: `${process.env.REACT_APP_URL}candidateLogin`,
          handleCodeInApp: true,
        });
        localStorage.setItem("candidateEmailForAuth", email);
        setLoading(false);
        setEmailSentMsg("An email is sent on you email with link to sign in.");
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    }
  };

  return (
    <div className="admin-main">
      <div className="admin-card flex justify-center items-center h-full sm:py-5 w-full gradient2">
        <div className="h-full w-full flex flex-col justify-center items-center p-10 sm:px-14 md:px-20 gap-2">
          <Logo width="100%" />
          <h1 className="text-3xl font-semibold">Login</h1>
          <form className="flex flex-col gap-2 w-full" onSubmit={login}>
            <div className="flex flex-col mb-[14px]">
              <label
                htmlFor="otp"
                className="font-medium text-sm leading-[12px] mb-2"
              >
                Email Id
              </label>
              <TextInput
                type="email"
                idName="email"
                placeholderText="Enter Email id"
                inputValue={email}
                handleChange={(e) => setEmail(e.target.value)}
                isRequired={true}
                isDisabled={loading}
              />
              {emailError && (
                <div className="font-medium text-xs leading-3 h-4 text-[#f00] mt-1">
                  {emailError}
                </div>
              )}
            </div>

            <div className="flex flex-col mb-[14px] forgot-field password-field">
              {passwordSignIn && (
                <>
                  <label
                    htmlFor="otp"
                    className="font-medium text-sm leading-[12px] mb-2"
                  >
                    Password
                  </label>
                  <TextInput
                    type={passwordType}
                    idName="password"
                    placeholderText="Enter Password"
                    inputValue={password}
                    handleChange={(e) => setPassword(e.target.value)}
                  />
                  <img
                    src={`./img/eye-${
                      passwordType === "text" ? "open" : "close"
                    }.png`}
                    alt="eye-icon"
                    onClick={() =>
                      passwordType === "password"
                        ? setPasswordType("text")
                        : setPasswordType("password")
                    }
                  />
                  {passError && (
                    <div className="font-medium text-xs leading-3 h-4 text-[#f00] mt-1">
                      {passError}
                    </div>
                  )}
                  {/* <div className="forgot-txt text-sm w-full font-medium text-right hover:underline mt-1">
                    Forgot Password
                  </div> */}
                </>
              )}
              <div
                className={`absolute text-sm text-blue-500 cursor-pointer ${
                  passwordSignIn ? "bottom-[-25px]" : "bottom-0"
                }`}
                onClick={() => setPasswordSignIn(!passwordSignIn)}
              >
                {passwordSignIn
                  ? "Login without password"
                  : "Login with password"}
              </div>
            </div>

            {emailSentMsg !== "" && (
              <div className="w-full flex items-center justify-center bg-green-100 p-2 rounded-md">
                <p className="text-sm text-green-800">{emailSentMsg}</p>
              </div>
            )}
            <div className="flex flex-col items-center justify-center mt-5">
              {error && (
                <div className="font-medium text-xs leading-3 h-4 text-[#f00] mt-1">
                  {error}
                </div>
              )}
              <Button
                text={loading ? "Please Wait" : "Login"}
                type="submit"
                isDisabled={loading}
              />
            </div>
          </form>
          <div className="mt-4 flex flex-col justify-center items-center gap-4 w-full">
            <p className="text-sm">OR</p>
            <div className="flex justify-center gap-2 w-full">
              Don't have an account?
              <Link
                to="/candidateRegistration"
                className="font-medium hover:underline"
              >
                Sign Up
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CandidateLogin;
