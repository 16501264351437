import { FaUserAlt } from "react-icons/fa";
import { FaUserFriends } from "react-icons/fa";
const AccountType = () => {
  const types = [
    {
      id: 1,
      name: "solo",
      label: "I m Solo Creater",
      desc: "I m setting up for mySelf",
    },
    {
      id: 2,
      name: "company",
      label: "I m part of Company",
      desc: "I m setting up for company",
    },
  ];

  return (
    <div className="flex flex-col gap-4 w-full">
      <div className="text-xl font-semibold">Choose Account Type</div>
      <div className="space-y-3">
        {types?.map((account) => (
          <div
            key={account.name}
            className="flex justify-between items-center border-[3px] p-2 px-5 rounded-md"
          >
            <label
              htmlFor={account.name}
              className="flex items-center gap-4 cursor-pointer"
            >
              <div className="text-3xl">
                {account.name === "company" ? (
                  <FaUserFriends className="w-5 h-5" />
                ) : (
                  <FaUserAlt className="w-5 h-5" />
                )}
              </div>
              <div>
                <p className="text-sm font-semibold">{account.label}</p>
                <p className="text-xs">{account.desc}</p>
              </div>
            </label>

            <div className="">
              <input
                required
                type="radio"
                name="accountType"
                value={account.name}
                id={account.name}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AccountType;
