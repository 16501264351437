import React, { useRef, useState } from "react";
import { auth } from "../config/firebase";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";

import { Button, TextInput } from "../components";

const Signup = () => {
  const [loading, setLoading] = useState(false);
  const recaptchaRef = useRef(null);
  const [phone, setPhone] = useState("");
  const [otp, setOtp] = useState("");
  const [confirmationResult, setConfirmationResult] = useState(null);
  const [flag, setFlag] = useState(true);
  const [error, setError] = useState("");

  const handleDestroyRecaptcha = () => {
    if (recaptchaRef.current) {
      try {
        recaptchaRef.current.clear(); // Try resetting instead of clearing
      } catch (error) {
        console.error("Error resetting reCAPTCHA:", error);
      }
    }
  };

  // const handleDestroyRecaptcha = () => {
  //   if (recaptchaRef.current) {
  //     try {
  //       recaptchaRef.current.reset(); // Try resetting instead of clearing
  //     } catch (error) {
  //       console.error("Error resetting reCAPTCHA:", error);
  //     }
  //   }
  // };

  const handleSubmit = async () => {
    setLoading(true);
    // recaptchaRef.current.innerHTML = ""; // Clear the container
    // Send OTP to phone number
    const phoneNumber = `+91${phone}`;

    if(!window.recaptchaVerifier){
      // console.log(window.recaptchaVerifier)
      window.recaptchaVerifier =new RecaptchaVerifier(auth, recaptchaRef.current, {
        size: "invisible",
        // size: "small"

      });
      // console.log("new reptcha created",window.recaptchaVerifier)
    }
    window.recaptchaVerifier.render();
   const appVerifier = window.recaptchaVerifier
    try {
      const result = await signInWithPhoneNumber(
        auth,
        phoneNumber,
        appVerifier
      );
      console.log(result);
      setConfirmationResult(result);
      setLoading(false);
      setFlag(false);
      // setTimeout(() => {
      //   setConfirmationResult(null);
      // }, 5 * 60 * 1000);

      // return () => {
      //   appVerifier.current.clear(); // Destroy the recaptcha instance

      // };
      // appVerifier.clear();
      console.log(appVerifier)
    } catch (error) {
      console.log(error.message);
      setError(error.message);
      // handleDestroyRecaptcha();
    } finally {
      // appVerifier.clear();
      // recaptchaRef.clear();
    }

    setLoading(false);
  };

  const verifyOtp = async () => {
    setError("");
    try {
      console.log(otp);
      const response = await confirmationResult.confirm(otp);

      console.log(response);
    } catch (error) {
      console.log(error);
      setFlag(true);

      //  window.location.reload()
      // handleDestroyRecaptcha();
      setError(error.message);
      setOtp("")

      // recaptchaRef.current.reset() // Clear the container
    }
  };

  return (
    <>
      <section className="common-form w-full h-full flex items-center justify-center">
        <div className="container bg-gray-300 w-1/3 p-10 rounded-xl">
          <div className="col-md-10">
            <form className="flex flex-col gap-4">
              {flag ? (
                <div className="flex flex-col md:flex-row md:gap-4">
                  <div className="flex-1 form-group">
                    <label htmlFor="phone" className="text-sm">
                      Phone Number<sup>*</sup>
                    </label>
                    <TextInput
                      type="tel"
                      id="phone"
                      inputName="phone"
                      inputValue={phone}
                      placeholder="Enter Phone Number"
                      handleChange={(e) => setPhone(e.target.value)}
                    />
                  </div>
                </div>
              ) : (
                <div className="flex flex-col md:flex-row md:gap-4">
                  <div className="flex-1 form-group">
                    <label htmlFor="otp" className="text-sm">
                      Enter Otp<sup>*</sup>
                    </label>
                    <TextInput
                      type="text"
                      id="otp"
                      inputName="otp"
                      inputValue={otp}
                      placeholder="Enter Otp"
                      handleChange={(e) => setOtp(e.target.value)}
                    />
                  </div>
                </div>
              )}
              <div ref={recaptchaRef} />
              <div className="text-sm text-red-600">{error}</div>

              <div className="flex flex-col">
                <Button
                  version="secondary"
                  text={
                    loading ? "Processing" : flag ? "Register" : "Enter Otp"
                  }
                  type="button"
                  handleClick={flag ? handleSubmit : verifyOtp}
                  inputId="signupButton"
                  isDisabled={loading ? true : false}
                />
              </div>
            </form>
          </div>
        </div>
      </section>
    </>
  );
};

export default Signup;
