import React,{useEffect, useState} from "react";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../../config/firebase";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";


const QuestionMenu = ({ setMenuOpen, question, setViewQuestion,libraryType,contentToPrintRef,setQuestionEditOpen}) => {
  const [collectionName,setCollectionName]=useState("customQuestionLibrary");
  const navigate = useNavigate()
  const menuOptions = [
    // "schedule interview",
    "edit question",
    // "publish Question",
    // "clone",
    // "print preview",
    "delete",
  ];

  // todo apply clone,publish Question ,scheduled interview

  useEffect(() => {
    // setlibraryType(libraryTypeParam || "Global");
    setCollectionName(
      libraryType === "Custom"
        ? "customQuestionLibrary"
        : "questionLibrary"
    );
  }, [libraryType]);
  console.log(question,libraryType)
  // const handlePrint = useReactToPrint({
  //   documentTitle: "Print This Document",
  //   onBeforePrint: () => console.log("before printing..."),
  //   onAfterPrint: () => console.log("after printing..."),
  //   removeAfterPrint: true,
  // });

  const deleteJob = async () => {
    const documentId = question?.id;
    const documentRef = doc(db, collectionName, documentId);
    setMenuOpen(false);
    setViewQuestion(false);
    try {
      await updateDoc(documentRef, { isDeleted: true });
      toast.success("Success! Job has been deleted");
    } catch (error) {
      toast.error(`Error! ${error.message}`);
    }
    // console.log("delete Working")
  };
  const action = (e) => {
    if (e.target.innerHTML === "delete" ) deleteJob();
    // if (e.target.innerHTML === "edit question" ) navigate(`/addQuestion?libraryType=${libraryType}&editDocid=${question.id}`);setMenuOpen(false);
    if (e.target.innerHTML === "edit question" ) setQuestionEditOpen(true);setMenuOpen(false);

    // if (e.target.innerHTML === "print preview" ) setMenuOpen(false); handlePrint(null, () => contentToPrintRef.current)


  };

  return (
    <div className="absolute top-8 right-0 flex flex-col justify-center rounded-xl overflow-hidden border-2 bg-white z-50 w-[180px]">
      {menuOptions.map((option, index) => (
        <div
          key={index}
          className={`cursor-pointer text-sm hover:bg-gray-100 p-2 px-4 font-medium w-full capitalize ${
            option === "delete" ? "text-red-500" : ""
          }`}
          onClick={action}
        >
          {option}
        </div>
      ))}
    </div>
  );
};

export default QuestionMenu;