import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import InternetMeter from "./InternetMeter";
import { Button } from "../../components";
export const InternetLeft = ({
  hasInternetConnection,
  setHasInternetConnection,
}) => {
  const [speed, setSpeed] = useState(0);

  useEffect(() => {
    if (speed) {
      setHasInternetConnection(true);
    }
  });

  return (
    <div className="flex flex-col justify-center items-center">
      <motion.div
        className="z-10"
        transition={{ ease: "linear", duration: 0.5 }}
        initial={{ scale: 1.6 }}
        animate={{ scale: 0.8 }}
      >
        <img src="img/internet.png" alt="internet" width="120px" />
      </motion.div>

      <motion.div
        transition={{ ease: "linear", duration: 0.5 }}
        initial={{ scale: 0, y: 0 }}
        animate={{ scale: 1, y: "-10px" }}
      >
        <div className="flex it{ems-center justify-center">
          <InternetMeter speed={speed} setSpeed={setSpeed} />
        </div>
      </motion.div>
    </div>
  );
};
