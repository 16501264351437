import { useState } from "react";
import JobRow from "./JobRow";
import { BsLayoutThreeColumns } from "react-icons/bs";
import ColumnEditMenu from "./ColumnEditMenu";
import { LuChevronsUpDown } from "react-icons/lu";
import { IoIosArrowUp } from "react-icons/io";
import { IoIosArrowDown } from "react-icons/io";

const JobListing = ({
  setOpenJobView,
  postedJobs,
  jobLoading,
  setOpenedJobId,
  setSortValue,
}) => {
  const columns = [
    {
      name: "jobOpeningId",
      label: "Job ID",
      width: "150px",
      open: true,
      sort: true,
      sortType: "",
    },
    {
      name: "positionTitle",
      label: "Position Title",
      width: "200px",
      open: true,
      sort: true,
      sortType: "",
    },
    {
      name: "assignedRecruiter",
      label: "Assigned Recruiter",
      width: "200px",
      open: true,
      sort: false,
    },
    {
      name: "jobPostedDate",
      label: "Posted Date",
      width: "180px",
      open: true,
      sort: true,
      sortType: "asc",
    },
    {
      name: "lastDateToApply",
      label: "Last Date to Apply",
      width: "180px",
      open: true,
      sort: true,
      sortType: "",
    },
    {
      name: "targetDate",
      label: "Target Date",
      width: "180px",
      open: true,
      sort: true,
      sortType: "",
    },
    {
      name: "departmentManager",
      label: "Department Manager",
      width: "200px",
      open: true,
      sort: false,
    },
    {
      name: "jobType",
      label: "Job Type",
      width: "120px",
      open: true,
      sort: false,
    },
    {
      name: "jobPrefence",
      label: "Job Preference",
      width: "150px",
      open: true,
      sort: false,
    },
    {
      name: "jobStatus",
      label: "Job Status",
      width: "120px",
      open: true,
      sort: false,
    },
  ];

  const [openColumnEdit, setOpenColumnEdit] = useState(false);
  const [columnList, setColumnList] = useState(columns);

  return (
    <>
      <div className="w-fit h-full flex flex-col">
        <div className="flex w-full overflow-x-auto py-5 pl-5 bg-[#E9EAEB] font-medium text-sm text-black md:rounded-t-xl">
          <div className="w-[50px] flex items-center">
            <BsLayoutThreeColumns
              className="w-5 h-5 cursor-pointer"
              onClick={(e) => {
                console.log(e.target);
                setOpenColumnEdit(!openColumnEdit);
              }}
            />
          </div>
          {columnList.map((column, index) => {
            const width = column.open ? column.width : "0";
            const opacity = column.open ? "1" : "0";
            return (
              <div
                key={index}
                className="flex items-center gap-1 transition-all duration-200"
                style={{ width, opacity }}
              >
                {column.open && (
                  <div className="flex ic">
                    <span>{column.label}</span>
                    {column.sort && (
                      <div
                        className="ml-1 cursor-pointer"
                        onClick={() => {
                          setSortValue({
                            fieldName: column.name,
                            fieldValue: column.sortType
                              ? column.sortType
                              : "asc",
                          });
                          columnList[index].sortType =
                            column.sortType === ""
                              ? "desc"
                              : column.sortType === "desc"
                              ? "asc"
                              : "desc";

                          columnList.map((_, i) => {
                            if (index !== i) {
                              columnList[i].sortType = "";
                            }
                            return "";
                          });
                        }}
                      >
                        {column.sortType === "desc" ? (
                          <IoIosArrowUp className="w-4 h-4" />
                        ) : column.sortType === "asc" ? (
                          <IoIosArrowDown className="w-4 h-4" />
                        ) : (
                          <LuChevronsUpDown className="w-4 h-4" />
                        )}
                      </div>
                    )}
                  </div>
                )}
              </div>
            );
          })}
        </div>

        <div className="relative h-full overflow-hidden">
          {jobLoading ? (
            <div className="text-center font-semibold text-xl text-gray-400 mt-4">
              Loading...
            </div>
          ) : postedJobs?.length === 0 ? (
            <div className="text-center font-semibold text-xl text-gray-400 mt-4">
              No Jobs Posted Yet
            </div>
          ) : (
            postedJobs?.map((jobData, index) => (
              <JobRow
                key={jobData.jobOpeningId}
                index={index}
                columnList={columnList}
                setOpenJobView={setOpenJobView}
                jobData={jobData}
                setOpenedJobId={setOpenedJobId}
              />
            ))
          )}

          <ColumnEditMenu
            openColumnEdit={openColumnEdit}
            setOpenColumnEdit={setOpenColumnEdit}
            columnList={columnList}
            setColumnList={setColumnList}
          />
        </div>
      </div>
    </>
  );
};

export default JobListing;
