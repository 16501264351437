import React, { useEffect, useState } from "react";
import { collection, query, where, getDocs } from "firebase/firestore";
import { Timestamp } from "firebase/firestore";
import { db } from "../../../config/firebase";

const JobTimeline = ({ jobData }) => {
  const [mergeTimeLineData, setMergeTimeLineData] = useState([]);
  console.log(jobData)

  useEffect(() => {
    const fetchDataFromCandidateApplication = async () => {
      const q = query(
        collection(db, "candidateApplications"),
        where("appliedJobId", "==", jobData.jobOpeningId)
      );
      let appliedJobData = [];
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        appliedJobData.push(doc.data());
      });
      const appliedAtArray = appliedJobData?.map((data) => {
        let convertTimestamp = data.appliedAt.toDate();

        return `${convertTimestamp.getFullYear()}-${(
          convertTimestamp.getMonth() + 1
        )
          .toString()
          .padStart(2, "0")}-${convertTimestamp
          .getDate()
          .toString()
          .padStart(2, "0")} `;
      });
      const occurrences = appliedAtArray.reduce((acc, curr) => {
        acc[curr] = (acc[curr] || 0) + 1;
        return acc;
      }, {});

      let occurrencesArray = Object.entries(occurrences).map(
        ([value, occur]) => ({
          time: Timestamp.fromDate(new Date(value)),
          name: occur,
        })
      );
      setMergeTimeLineData([...jobData?.timeline, ...occurrencesArray]);
    };

    fetchDataFromCandidateApplication();
  }, [jobData]);

  const timeLineData = mergeTimeLineData?.sort((a, b) => {
    const firstTime = a.time.toDate().getTime();
    const seconTime = b.time.toDate().getTime();
    return firstTime - seconTime;
  });
  return (
    <div className="w-full py-5 px-20">
      {timeLineData.map((data, i) => {
        return (
          <div key={i} className="flex space-x-16 h-[60px]">
            <div className="w-[120px] flex items-center justify-end">
              <div className="text-xs text-end font-medium">
                <div>
                  {new Date(data.time.seconds * 1000).toLocaleDateString(
                    "en-in",
                    { day: "numeric", month: "short", year: "numeric" }
                  )}
                </div>
                {!Number.isInteger(data.name) && (
                  <div>
                    {new Date(data.time.seconds * 1000).toLocaleTimeString(
                      "en-in",
                      { hour: "numeric", minute: "numeric", hour12: true }
                    )}
                  </div>
                )}
              </div>
            </div>

            <div
              className={`relative border-l-4 
                ${
                  data.time.seconds * 1000 < new Date().getTime()
                    ? "border-green-300"
                    : "border-gray-300"
                }
              `}
            >
              <div
                className={`absolute bottom-5 left-[-12px] w-[20px] h-[20px] rounded-full 
                  ${
                    data.time.seconds * 1000 < new Date().getTime()
                      ? "border-4 border-green-400 bg-green-200"
                      : "border-4 border-gray-300 bg-white"
                  }
                `}
              />
            </div>

            <div className="flex items-center justify-start w-[200px] font-medium text-sm capitalize">
              {data.name === "in-progress"
                ? "job opened"
                : Number.isInteger(data.name)
                ? `${data.name} Candidate${data.name > 1 ? "s" : ""} Applied`
                : data.name}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default JobTimeline;
