import React from "react";

import { Logo } from "../";

const Topbar = () => {
  return (
    <div className="flex justify-between mt-5 w-[95%] mx-auto">
      <Logo width={"10rem"} />
      {/* <Button
          version="outline-primary"
          text="Practice now"
          width="fit-content"
        /> */}
    </div>
  );
};

export default Topbar;
