import React, { useEffect, useState } from "react";
import { Button, TextInput } from "../../components";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  collection,
  onSnapshot,
  query,
  where,
  orderBy
} from "firebase/firestore";
import { db } from "../../config/firebase";
// import Select from "react-select";
import QuestionsListing from "./QuestionsListing";
import { SelectInput } from "../../components";
// import { useLocation } from "react-router-dom";

const QuestionLibrary = ({ admin }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const urlparams = new URLSearchParams(location.search);
  const categoryParam = urlparams.get("category");
  const skillParam = urlparams.get("skill");
  const libraryTypeParam = urlparams.get("libraryType");

  const [categories, setCategories] = useState([]);
  const [libraryType, setlibraryType] = useState("");
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [collectionName, setCollectionName] = useState("questionLibrary");

  const [categorySkills, setCategorySkills] = useState([]);
  const [selectedSkill, setSelectedSkill] = useState("");

  const [skillTopics, setSkillTopics] = useState([]);
  const [selectedTopic, setSelectedTopic] = useState("");

  const [loading, setLoading] = useState();

  const tabs = [
    { label: "Global", collection: "questionLibrary" },
    { label: "Custom", collection: "customQuestionLibrary" }
  ];

  useEffect(() => {
    setlibraryType(libraryTypeParam || "Global");
    setCollectionName(
      libraryTypeParam === "Custom"
        ? "customQuestionLibrary"
        : "questionLibrary"
    );
  }, [libraryTypeParam]);
  console.log(collectionName);
  // const [searchQueryData, setSearchQueryData] = useState([]);
  // const [searchValue, setSearchValue] = useState(null);

  // --------------fetch questionLibraryCategory list------------
  useEffect(() => {
    const fetchQuestionCategoryData = async () => {
      const q = query(collection(db, "questionLibraryCategories"));
      onSnapshot(q, (querySnapshot) => {
        const categorydata = [];
        querySnapshot.forEach((doc) => {
          categorydata.push({ id: doc.id, ...doc.data() });
        });
        setCategories(categorydata);
        // console.log(categorydata)
        // let allCategories = [];
        // let allSkills = [];
        // let allTopics = [];
        // for (let i = 0; i < categorydata.length; i++) {
        //   allCategories.push(categorydata[i].name);
        //   for (let j = 0; j < categorydata[i].skills.length; j++) {
        //     allSkills.push(categorydata[i].skills[j].skill);
        //     // console.log(categories[i].skills[j].skill)
        //     allTopics.push(...categorydata[i].skills[j].topics);
        //     // console.log(...categories[i].skills[j].topics)
        //   }
        // }
        // // console.log(allCategories);
        // // console.log(allSkills);
        // // console.log([...new Set(allTopics)]);
        // setSearchQueryData([
        //   ...allCategories,
        //   ...new Set(allSkills),
        //   ...new Set(allTopics)
        // ]);
        setCategoryOptions(
          categorydata.map((data) => {
            return {
              value: data.name,
              label: data.name.charAt(0).toUpperCase() + data.name.slice(1)
            };
          })
        );
      });
    };

    fetchQuestionCategoryData();
  }, [categoryParam]);

  // ...............fetch QuestionLibrary collection Data .....................

  const [questions, setQuestions] = useState();
  const [filterQuestion, setFilterQuestion] = useState();
  const [filterValue, setFilterValue] = useState("");
  const [sortValue, setSortValue] = useState();

  const fetchQuestions = () => {
    setLoading(true);
    let q = query(
      collection(db, collectionName),
      where("isDeleted", "==", false)
    );

    // if (sortValue?.fieldValue) {
    //   q = query(q, orderBy(sortValue?.fieldName, sortValue?.fieldValue));
    //   console.log("sortvalue");
    // }
    if (selectedCategory && selectedCategory?.value !== "all") {
      console.log(selectedCategory.value);
      q = query(
        q,
        where("category", "array-contains-any", [selectedCategory?.value])
      );

      if (selectedSkill && selectedSkill.value !== "all") {
        // console.log(selectedSkill.value);
        q = query(
          q,
          where("skills", "array-contains-any", [selectedSkill?.value])
        );

        if (selectedTopic && selectedTopic !== "all") {
          q = query(q, where("topic", "array-contains-any", [selectedTopic]));
          // console.log("selected Topic");
        }
      }
    }

    // ........ query on the basic of search............................

    // if (searchValue) {
    // const searched = searchValue.value;
    // // console.log(searched)
    // console.log(categories);
    // let searchTopic = "";
    // for (let i = 0; i < categories.length; i++) {
    //   if (categories[i].name === searched) {
    //     // searchTopic.push("category");
    //     searchTopic = "category";
    //   } else {
    //     for (let j = 0; j < categories[i].skills.length; j++) {
    //       if (categories[i].skills[j].skill === searched) {
    //         // searchTopic.push("topic");
    //         searchTopic = "topic";
    //       }
    //     }
    //   }
    // }
    // console.log(searchTopic);

    // if (searchTopic === "category") {
    //   q = query(q, where("category", "array-contains-any", [searched]));
    // } else if (searchTopic === "topic") {
    //   q = query(q, where("skills", "array-contains-any", [searched]));
    // } else {
    //   q = query(q, where("topic", "array-contains-any", [searched]));
    // }

    // }

    const querySnapshot = onSnapshot(q, (querySnapshot) => {
      const questionsData = [];
      querySnapshot.forEach((doc) => {
        questionsData.push({ id: doc.id, ...doc.data() });
      });
      setQuestions(questionsData);
    });
    setLoading(false);
    return querySnapshot;
  };
  useEffect(() => {
    fetchQuestions();
  }, [
    sortValue,
    selectedCategory,
    selectedSkill,
    selectedTopic,
    collectionName
  ]);

  // get Filter value
  useEffect(() => {
    const newFilterQuestionData = questions?.filter((data) => {
      return (
        data.category.some((cat) => cat.includes(filterValue)) ||
        data.skills.some((skill) => skill.includes(filterValue)) ||
        data.topic.some((topic) => topic.includes(filterValue))
      );
    });
    setFilterQuestion(newFilterQuestionData);
  }, [filterValue, questions]);

  // useEffect(() => {
  //   if (categoryParam) {
  //     setSelectedCategory(categoryParam);
  //   } else {
  //     setSelectedCategory(categories[0]?.name);
  //   }
  // }, [categoryParam, categories]);

  // useEffect(() => {
  //   // use selected skill value
  //   const skillData = categories?.filter(
  //     (data) => data.name === selectedCategory.value
  //   )[0]?.skills;
  //   setCategorySkills(skillData);
  // }, [categories, selectedCategory]);

  // set selected category and skill  from url

  useEffect(() => {
    console.log(categoryParam);
    if (categoryParam) {
      setSelectedCategory({
        value: categoryParam,
        label: categoryParam.charAt(0).toUpperCase() + categoryParam.slice(1)
      });
    }
    if (skillParam) {
      setSelectedSkill({
        value: skillParam,
        label: skillParam.charAt(0).toUpperCase() + skillParam.slice(1)
      });
    }
  }, [categoryParam, categories]);

  // useEffect(()=>{
  //     if (skillParam) {
  //     setSelectedSkill({value:skillParam,label:skillParam.charAt(0).toUpperCase() + skillParam.slice(1) });
  //   }
  // },[skillParam])

  useEffect(() => {
    // use selected skill value
    if (selectedCategory?.label === "Undefined") {
      setSelectedCategory(null);
    }

    const skillData = categories?.filter(
      (data) => data.name === selectedCategory?.value
    )[0]?.skills;
    setCategorySkills(
      skillData?.map((data) => {
        return {
          label: data.skill.charAt(0).toUpperCase() + data.skill.slice(1),
          value: data.skill
        };
      })
    );
  }, [selectedCategory, categoryParam]);

  // useEffect(() => {
  //   if (skillParam) {
  //     setSelectedSkill(skillParam);
  //   } else {
  //     setSelectedSkill(categorySkills && categorySkills[0]?.skill);
  //   }
  // }, [skillParam, categorySkills]);

  // useEffect(() => {
  //   const topics =
  //     categorySkills &&
  //     categorySkills
  //       ?.filter((skill) => skill.skill === selectedSkill)[0]
  //       ?.topics?.map((skill) => {
  //         return {
  //           value: skill,
  //           label: skill.charAt(0).toUpperCase() + skill.slice(1),
  //         };
  //       });
  //   setSkillTopics(
  //     topics ? [{ value: "all", label: "All Topics" }, ...topics] : []
  //   );
  //   setSelectedTopic("");
  // }, [skillParam, selectedSkill, categorySkills]);

  useEffect(() => {
    if (selectedSkill?.label === "Undefined") {
      setSelectedSkill(null);
    }
    const categoryskil = categories?.filter(
      (data) => data.name === selectedCategory?.value
    )[0]?.skills;
    const topics =
      categoryskil &&
      categoryskil
        ?.filter((skill) => skill.skill === selectedSkill?.value)[0]
        ?.topics?.map((skill) => {
          return {
            value: skill,
            label: skill.charAt(0).toUpperCase() + skill.slice(1)
          };
        });

    // console.log(topics);
    setSkillTopics(
      topics ? [{ value: "all", label: "All Topics" }, ...topics] : []
    );
    setSelectedTopic("");
  }, [skillParam, selectedSkill, categorySkills]);

  // console.log(questions)
  // console.log(categories);
  // console.log(categoryOptions);
  // console.log(selectedCategory);
  // // console.log(categorySkills);
  // console.log(selectedSkill);
  // // console.log(selectedTopic)

  // console.log(searchQueryData);

  // console.log(searchValue);
  // console.log();

  // const updateSearchData = (searchValue) => {
  //   const newFilterQuestionData = questions?.filter((data) => {
  //     return (
  //       data.category.some((cat) => cat.includes(searchValue)) ||
  //       data.skills.some((skill) => skill.includes(searchValue)) ||
  //       data.topic.some((topic) => topic.includes(searchValue))
  //     );
  //   });
  //   setFilterQuestion(newFilterQuestionData);
  //   console.log(newFilterQuestionData);
  //   console.log(questions);
  //   console.log(searchValue);
  // };

  return (
    <div className="main-container w-full mx-auto md:ml-20 mt-1 flex flex-col lg-px-10">
      <div className="card p-2 pt-4 pr-0 w-full relative lg:h-[90vh] overflow-y-hidden flex flex-col">
      <div className="application-filter-bar flex gap-2 h-fit pr-4">
        <div className="left-item w-full flex items-center gap-3 px-4 overflow-x-auto scrollbar-none mb-4">
          {tabs?.map((libraryTypeName) => {
            return (
              <div
                onClick={() => {
                  navigate(`/questionLibrary?libraryType=${libraryTypeName.label}`);
                  setlibraryType(libraryTypeName.label);
                  setCollectionName(libraryTypeName.collection);
                  setSelectedCategory(null);
                }}
                key={libraryTypeName.label}
                id={`libraryType-${libraryTypeName.label}`}
                className={`libraryType-btn capitalize text-sm font-medium rounded-full px-8 py-3  cursor-pointer  ${
                  libraryType === libraryTypeName.label
                    ? "bg-[#8A24F1] text-white"
                    : "bg-[#E9EAEB]/60"
                }`}
              >
                {libraryTypeName.label}
              </div>
            );
          })}
        </div>
        </div>
        <div className="application-filter-bar flex gap-2 h-fit pr-4 py-2 justify-between">
          {/* <div className="left-item w-full flex items-center gap-3 px-4 overflow-x-auto scrollbar-none">
            {categories?.map((category) => {
              return (
                <div
                  onClick={() => {
                    navigate(`/questionLibrary?category=${category.name}`);
                    setSelectedCategory(category.name);
                  }}
                  key={category.name}
                  className={`libraryType-btn capitalize text-sm font-medium rounded-full px-4 py-2 cursor-pointer ${
                    selectedCategory === category.name ? "" : "not-active"
                  }`}
                >
                  {category.name}
                </div>
              );
            })}
          </div> */}
          {/* <div className="left-item w-full flex items-center gap-3 px-4 overflow-x-auto scrollbar-none"> */}

          <div className="flex justify-between w-full">
            <div className="flex space-x-3 ml-4">
              <div className="w-[250px]">
                <SelectInput
                  className="basic-single w-[260px]"
                  selectOptions={categoryOptions}
                  value={selectedCategory}
                  handleChange={(selectedValue) => {
                    setSelectedCategory(selectedValue);
                    navigate(
                      `/questionLibrary?libraryType=${libraryType}&category=${selectedValue?.value}`
                    );
                    // `/questionLibrary?libraryType=${libraryTypeName.label}`
                    setSelectedSkill(null);
                    // setSearchValue(null);
                  }}
                  placeholderText="Select Category"
                  // // defaultValue={}
                  isClearable
                  isRequired
                />
              </div>
              <div
                className={`w-[190px] ${
                  selectedCategory?.label ? "" : "opacity-50"
                } `}
              >
                <SelectInput
                  selectOptions={categorySkills} 
                  value={selectedSkill}
                  handleChange={(selectedskillValue) => {
                    setSelectedSkill(selectedskillValue);
                    navigate(
                      `/questionLibrary?libraryType=${libraryType}&category=${selectedCategory?.value}&skill=${selectedskillValue?.value}`
                    );
                  }}
                  placeholderText="Select Skill"
                  // // defaultValue={}
                  isClearable
                  isRequired
                  isDisabled={!selectedCategory?.label}
                />
              </div>
              <div
                className={`w-[170px] ${
                  selectedSkill?.value && selectedSkill?.value !== "all"
                    ? ""
                    : "opacity-50"
                } `}
              >
                <SelectInput
                  className="basic-single w-[200px]"
                  classNamePrefix="select"
                  value={skillTopics?.filter(
                    (data) => data.value === selectedTopic
                  )}
                  name="topics"
                  placeholderText="Select a topic"
                  selectOptions={skillTopics}
                  handleChange={(selectedOption) =>
                    setSelectedTopic(selectedOption?.value)
                  }
                  isDisabled={!selectedSkill?.label}
                  isRequired
                  // defaultValue={{
                  //   value: "all",
                  //   label: "All"
                  // }}
                />
              </div>
            </div>
            <div>
              <div className="flex items-center justify-center space-x-3">
                <div className="w-[250px] ">
                  <TextInput
                    type="text"
                    placeholderText="Search ..."
                    handleChange={(e) => {
                      // updateSearchData(e.target.value);
                      setFilterValue(e.target.value);
                    }}
                  />
                  {/* <Select
                    options={searchQueryData.map((data) => {
                      return {
                        label: data.charAt(0).toUpperCase() + data.slice(1),
                        value: data
                      };
                    })}
                    inputProps={{
                      // placeholder: "Search...",
                      autoFocus: true, // Optional: Automatically focus the input
                      className: "custom-input-class" // Optional: Add custom CSS class to the input
                    }}
                    placeholder="Search..."
                    value={searchValue}
                    onChange={(selectedValue) => {
                      setSearchValue(selectedValue);
                      setSelectedCategory(null);
                      setSelectedSkill(null);
                    }}
                  /> */}
                </div>
                {/* {libraryType !== "Global Library" && ( */}
                <div className="flex items-center justify-center">
                  <Button
                    className="h-full w-[200px]"
                    text=""
                    handleClick={() => navigate(`/addQuestion?libraryType=${libraryType}`)}
                  >
                    <div className="flex items-center gap-2">
                      <span className="text-xl font-semibold">+</span>
                      <span> Add Question </span>
                    </div>
                  </Button>
                  </div>
                {/* )} */}
              </div>
            </div>
          </div>
        </div>
        {/* <div className="application-filter-bar flex gap-2 h-fit pr-4 py-2 justify-between"> */}
          {/* <div className="left-item flex items-center gap-3 px-4 overflow-x-auto scrollbar-none"> */}
            {/* <div className="application-filter-bar flex h-fit ml-5">
              {categorySkills?.map((skill, index) => {
                return (
                  <Link
                    to={`/questionLibrary?category=${selectedCategory}&skill=${skill.skill}`}
                    key={index}
                    className={`capitalize text-sm font-medium px-8 py-2 cursor-pointer border-b-4 ${
                      skill.skill === selectedSkill
                        ? "border-[#8A24F1]"
                        : "border-gray-300"
                    }`}
                  >
                    {skill.skill}
                  </Link>
                );
              })}
            </div> */}
          {/* </div> */}
          {/* <div className="right-item flex items-center gap-4"> */}
            {/* <div className="flex items-center gap-2">
              <label
                htmlFor="topics"
                className="text-lg font-semibold text-gray-500"
              >
                Topics :
              </label>
              <Select
                className="basic-single w-[200px]"
                classNamePrefix="select"
                value={skillTopics?.filter(
                  (data) => data.value === selectedTopic
                )}
                name="topics"
                placeholder="Select a topic"
                options={skillTopics}
                onChange={(selectedOption) =>
                  setSelectedTopic(selectedOption?.value)
                }
                defaultValue={{
                  value: "all",
                  label: "All"
                }}
              />
            </div> */}

            {/* <div
              className="relative flex items-center gap-4 py-2"
              // onClick={() => setOpenfilterMenu(!openfilterMenu)}
            >
              <Button
                text=""
                version="outline-white"
                className="filter-btn w-32 flex items-center gap-2"
              >
                <span className="text-base">Filter</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M10.5 6h9.75M10.5 6a1.5 1.5 0 11-3 0m3 0a1.5 1.5 0 10-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-9.75 0h9.75"
                  />
                </svg>
              </Button>
            </div> */}
          {/* </div> */}
        {/* </div> */}
        {/* <div className="w-[100%] overflow-x-scroll overflow-y  h-full px-4 py-2 "> */}
        <div className="w-[100%]  h-full px-4 py-2 ">

          <QuestionsListing
            loading={loading}
            questions={filterQuestion}
            libraryType={libraryType}
            setSortValue={setSortValue}
          />
        </div>

       
      </div>
     
    </div>
  );
};

export default QuestionLibrary;
