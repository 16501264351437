import { doc, getDoc } from "firebase/firestore";
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { db } from "../../../config/firebase";
import { Button } from "../../../components";
import { HiDotsHorizontal } from "react-icons/hi";
import { FaAngleDown } from "react-icons/fa";
import AssessmentMenu from "./AssessmentMenu";
import View from "./View";
import Preview from "./Preview";
const AssessmentView = ({
  openAssessmentView,
  setOpenAssessmentView,
  setDeleteAssessmentId,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const urlparams = new URLSearchParams(location.search);
  const idParam = urlparams.get("id");

  const [openPreview, setOpenPreview] = useState(false);
  const [loading, setLoading] = useState(false);
  const [assessment, setAssessment] = useState();
  const [menuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    const getDataFromFirestore = async () => {
      const docId = idParam;
      if (!docId) return;
      const documentRef = doc(db, "assessments", docId);
      setLoading(true);
      try {
        const docSnapshot = await getDoc(documentRef);
        if (docSnapshot.exists()) {
          setAssessment({ id: docSnapshot.id, ...docSnapshot.data() });
        } else {
          setAssessment("");
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };
    getDataFromFirestore();
  }, [idParam]);

  const assessmentViewRef = useRef(null);
  const menuPopupRef = useRef(null);

  useEffect(() => {
    if (openAssessmentView || menuOpen) {
      const handleClickOutside = (event) => {
        assessmentViewRef.current.contains(event.target) ||
          setOpenAssessmentView(false);
        menuPopupRef.current.contains(event.target) || setMenuOpen(false);
      };
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }
  }, [openAssessmentView, menuOpen]);

  return (
    <div className="absolute top-0 left-0 w-full h-full bg-black/30 backdrop-blur-[2px] flex justify-end items-center z-50">
      <div
        ref={assessmentViewRef}
        className={`relative card h-full flex flex-col overflow-y-scroll scrollable transition-all duration-300 ${
          openPreview ? "w-[70%]" : "w-[40%]"
        } `}
      >
        <div className="absolute top-4 right-6 flex items-center gap-8">
          <div className="relative" ref={menuPopupRef}>
            <div
              className="text-2xl bg-gray-200 rounded-md px-2 flex items-center justify-center cursor-pointer"
              onClick={() => setMenuOpen(!menuOpen)}
            >
              {menuOpen ? <FaAngleDown /> : <HiDotsHorizontal />}
            </div>
            {menuOpen && (
              <AssessmentMenu
                setMenuOpen={setMenuOpen}
                setOpenAssessmentView={setOpenAssessmentView}
                assessment={assessment}
                setDeleteAssessmentId={setDeleteAssessmentId}
              />
            )}
          </div>
          <div
            className="text-2xl bg-gray-200 rounded-full h-8 w-8 flex items-center justify-center cursor-pointer"
            onClick={() => {
              navigate("/assessments");
              setOpenAssessmentView(false);
            }}
          >
            ×
          </div>
        </div>
        <div className="space-y-8 flex flex-col flex-grow p-10">
          <div className="space-y-1">
            <h1 className="text-3xl font-semibold capitalize">
              {assessment?.name}
            </h1>
            <h1 className="font-medium capitalize">{assessment?.position}</h1>

            <div className="text-xs text-gray-400 font-medium">
              <span className="border-r-2 border-gray-400/60 px-2 py-[2px]">
                {new Date(
                  assessment?.createdAt?.seconds * 1000
                )?.toLocaleDateString("en-in", {
                  day: "numeric",
                  month: "short",
                  year: "numeric",
                })}
              </span>
              <span className="px-2 py-[2px] capitalize">
                By {assessment?.createdBy}
              </span>
            </div>
          </div>
          {openPreview ? (
            <Preview assessment={assessment} loading={loading} />
          ) : (
            <View assessment={assessment} loading={loading} />
          )}
        </div>

        <div className="sticky bottom-0 right-0 w-full flex justify-end p-4 gap-4 bg-gray-100 shadow-md">
          {openPreview ? (
            <Button
              version="outline-primary"
              text="Back"
              handleClick={() => setOpenPreview(false)}
            />
          ) : (
            <>
              <Button
                version="outline-primary"
                text="Preview the Assessment"
                handleClick={() => setOpenPreview(true)}
              />
              <Button text="Use the Assessment" />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default AssessmentView;
