export const customStyles = {
    control: (styles) => ({
      ...styles,
      width: "100%",
      borderRadius: "8px",
      border: 0,
      padding: "0 3px", 
    //   color: "#292D32",
      fontSize: "14px",
      backgroundColor: "#F5F5F5",
      cursor: "pointer",
      ":hover": {
        outline: "none",
        border: "none",
        boxShadow: "none",
      },
      ":active" : {
        outline: "none",
        border: "none",
        boxShadow: "none",
      },
      ":focus" : {
        outline: "none",
        border: "none",
        boxShadow: "none",
      },
    }),

    option: (styles) => {
      return {
        ...styles,
        color: "#292D32",
        fontSize: "14px",
        background: "#fff",
        ":hover": {
          backgroundColor: "#eee",
          color: "#000",
          cursor: "pointer",
        },
      };
    },
    menu: (styles) => {
      return {
        ...styles,
        width: "100%",
        borderRadius: "8px",
      };
    },
  
    placeholder: (defaultStyles) => {
      return {
        ...defaultStyles,
        color: "#929292",
        fontSize: "14px",
      };
    },
  };
  