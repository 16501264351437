import React, { useEffect } from "react";
import { Button } from "../../components";
import { useNavigate } from "react-router-dom";

const MicRight = ({
  isMicWorking,
  hasMicPermission,
  sethasMicPermission,
  step,
  setStep,
}) => {
  const navigate = useNavigate();
  useEffect(() => {
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then(() => {
        sethasMicPermission(true);
      })
      .catch((err) => console.log(err));
    sethasMicPermission(false);
    // askForCameraPermission();
  }, [step, sethasMicPermission]);

  return (
    <div className="flex flex-col items-start justify-between h-full w-[80%] mx-auto">
      <div className="flex flex-col">
        <h1 className="heading1 mb-10">
          Microphone Mandatory while giving assessment test
        </h1>
        <ul className="gap-4 text-[#919397] listDisc">
          <li>Always keep your microphone ON.</li>
          <li>
            Please do not mute or disconnect the microphone during the
            assessment
          </li>
          <li>Please make sure that no background noise is detected</li>
        </ul>
      </div>

      <Button
        version="primary"
        text="Proceed"
        handleClick={() => {
          setStep((prev) => prev + 1);
        }}
        width="fit-content"
        isDisabled={!isMicWorking || !hasMicPermission}
      />
    </div>
  );
};

export default MicRight;
