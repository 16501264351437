import React from "react";
import { RiDeleteBin6Line } from "react-icons/ri";
import { SelectInput } from "../../../components";

const AddedSkillsTable = ({
  addedSkillObjects,
  setAddedSkillObjects,
  totalQuestions,
  totalSelectedQuestions,
  setSelectQuesWarning,
  error
}) => {
  const tableColumns = [
    { name: "skills", width: "15%" },
    { name: "weightage", width: "15%" },
    { name: "no. of questions", width: "12%" },
    { name: "type of questions", width: "25%" },
    { name: "difficulty level", width: "25%" },
    { name: "action", width: "8%" }
  ];
  // console.log(addedSkillObjects);
  // console.log('added skill object',addedSkillObjects)
  // console.log("total question",totalQuestions)
  return (
    <div className="px-4">
      <div className="flex w-full overflow-x-auto py-5 pl-8 gap-2 bg-[#EEEFEF] rounded-t-xl">
        {tableColumns.map((column) => (
          <div key={column?.name} style={{ width: column?.width }}>
            <span className="capitalize font-medium text-black text-sm">
              {column?.name}
            </span>
          </div>
        ))}
      </div>
      <div className="">
        {addedSkillObjects.map((skill, index) => {
          const weightage = skill?.weightage ? skill?.weightage : 0;
          return (
            <div
              key={skill.skillName}
              className="flex items-center w-full py-4 pl-8 gap-2 border-x-2 border-b-2
              capitalize font-medium text-black text-sm last:rounded-b-xl first:border-2"
            >
              <div className="w-[15%]">
                <span className="">{skill.skillName}</span>
              </div>
              <div className="w-[15%]">
                <div className="border-2 w-fit rounded-lg overflow-hidden">
                  <button
                    className="p-2 px-4 text-lg"
                    disabled={weightage < 5}
                    onClick={() => {
                      addedSkillObjects[index].weightage = weightage - 5;
                      setAddedSkillObjects([...addedSkillObjects]);
                    }}
                  >
                    -
                  </button>
                  <span className="inline-block border-x-2 py-2 w-12 text-center">
                    {weightage < 10 ? 0 : ""}
                    {weightage}%
                  </span>
                  <button
                    className="p-2 px-4 text-lg"
                    onClick={() => {
                      if (totalQuestions === 0) {
                        return setSelectQuesWarning(true);
                      }
                      if (
                        weightage > 95 ||
                        totalSelectedQuestions >= totalQuestions
                      )
                        return;
                      addedSkillObjects[index].weightage = weightage + 5;
                      setAddedSkillObjects([...addedSkillObjects]);
                    }}
                  >
                    +
                  </button>
                </div>
              </div>
              <div className="w-[12%]">
                {skill?.questions < 10 ? 0 : ""}
                {skill?.questions ? skill?.questions : 0}
              </div>
              <div className="w-[25%]">
                <div className="w-[200px]">
                  <SelectInput
                    placeholderText="Select Ques. Type"
                    selectOptions={[
                      { value: "all", label: "All Types" },
                      { value: "mcq", label: "MCQ" },
                      { value: "subjective", label: "Subjective" },
                      { value: "code", label: "Code Based" }
                    ]}
                    value={{
                      label: addedSkillObjects[index].type?addedSkillObjects[index].type:"Select Ques. Type",
                      value: addedSkillObjects[index].type
                    }}
                    handleChange={(e) => {
                      addedSkillObjects[index].type = e.value;
                      setAddedSkillObjects([...addedSkillObjects]);
                    }}
                  />
                </div>
                <>
                  <span className="text-[10px] text-red-500">
                    {error.map((e) =>
                      e.index === index && e.column === "type"
                        ? "Select Ques Type"
                        : ""
                    )}
                  </span>
                </>
              </div>
              <div className="w-[25%]">
                <div className="w-[200px]">
                  <SelectInput
                    placeholderText="Select Difficulty"
                    selectOptions={[
                      { value: 1, label: "easy" },
                      { value: 2, label: "medium" },
                      { value: 3, label: "hard" }
                    ]}
                    value={{
                      label: addedSkillObjects[index].difficulty===1?"Easy":addedSkillObjects[index].difficulty===2?"Medium":addedSkillObjects[index].difficulty===3?"Hard":'Select Difficulty',
                      value: addedSkillObjects[index].difficulty
                    }}
                    handleChange={(e) => {
                      addedSkillObjects[index].difficulty = e.value;
                      setAddedSkillObjects([...addedSkillObjects]);
                    }}
                  />
                </div>
                <>
                  <span className="text-[10px] text-red-500">
                    {error.map((e) =>
                      e.index === index && e.column === "difficulty"
                        ? "Select Difficulty Level"
                        : ""
                    )}
                  </span>
                </>
              </div>
              <div className="w-[8%]">
                <button
                  disabled={addedSkillObjects.length <= 1}
                  onClick={() => {
                    addedSkillObjects?.length > 1 &&
                      setAddedSkillObjects([
                        ...addedSkillObjects?.filter(
                          (s) => s?.skillName !== skill?.skillName
                        )
                      ]);
                  }}
                  className="text-red-500 disabled:text-red-200 flex items-center gap-1 text-xs"
                >
                  <RiDeleteBin6Line className="w-5 h-5" />
                  <span className="hidden xl:flex">Remove</span>
                </button>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default AddedSkillsTable;
