import React, { useEffect, useState } from "react";
import RoundsTabBar from "./RoundsTabBar";
import { FaCheck } from "react-icons/fa6";
import { FaRegEdit } from "react-icons/fa";
import { FaRegClock } from "react-icons/fa6";
import { PiWarningCircle } from "react-icons/pi";
import QuestionAnalyse from "./QuestionAnalyse";

const ProfileRightPannel = ({
  rounds,
  selectedRound,
  setSelectedRound,
  candidateDetails,
  results,
  notAttempted,
}) => {
  const cardsArray = [
    {
      name: "correct",
      label: "Correct Answer",
      color: "#01C851",
      bg: "#E6FAEE",
      icon: <FaCheck className="w-6 h-6" />,
    },
    {
      name: "attempted",
      label: "Attempted",
      color: "#0066FF",
      bg: "#E5EFFF",
      icon: <FaRegEdit className="w-6 h-6" />,
    },
    {
      name: "time",
      label: "Time Taken",
      color: "#C562F1",
      bg: "#FAF0FE",
      icon: <FaRegClock className="w-6 h-6" />,
    },
    {
      name: "violations",
      label: "Total Violations",
      color: "#FFB800",
      bg: "#FFF8E5",
      icon: <PiWarningCircle className="w-6 h-6" />,
    },
  ];

  const [violationsCount, setViolationsCount] = useState(0);
  useEffect(() => {
    const totalViolation = results?.resultDetails?.reduce(
      (accumulator, qus) => accumulator + qus?.violations?.length,
      0
    );
    setViolationsCount(totalViolation);
  }, [results]);
  return (
    <div className="flex flex-col h-full w-full gap-2">
      <RoundsTabBar
        rounds={rounds}
        selectedRound={selectedRound}
        setSelectedRound={setSelectedRound}
      />
      {notAttempted ? (
        <div className="flex flex-col gap-8 w-full h-full justify-center items-center card">
          <img src="img/error.png" alt="" />
          <div className="lex flex-col justify-center items-center text-center">
            <p className="mb-3">
              <p className="font  ">
                <span className="font-semibold capitalize">
                  {candidateDetails?.name}
                </span>{" "}
                has not attempted the assessment yet.
              </p>
            </p>
            <p className="text-[#888] text-sm font-medium">
              The assessment evaluation will appear here once it has been
              attempted.
            </p>
          </div>
        </div>
      ) : (
        <div className="w-full space-y-3">
          <div className="w-full grid grid-cols-2 lg:grid-cols-4 gap-3">
            {cardsArray.map((card) => (
              <div
                key={card?.name}
                className="card h-[5rem] w-full flex items-center px-4 gap-2"
              >
                <div
                  className={`dash-icon w-[3rem] h-[3rem] rounded-xl flex justify-center items-center`}
                  style={{ background: card?.bg, color: card?.color }}
                >
                  {card?.icon}
                </div>
                <div className="flex flex-col">
                  <div className="font-semibold">
                    {card?.name === "correct"
                      ? `${results?.correctAnswers} / ${results?.totalQuestions}`
                      : card?.name === "attempted"
                      ? `${results?.attemptedQuestions} / ${results?.totalQuestions}`
                      : card?.name === "time"
                      ? `${Math.floor(
                          results?.timeTaken / 1000 / 60
                        )}min ${Math.floor(
                          (results?.timeTaken / 1000) % 60
                        )}sec`
                      : card?.name === "violations"
                      ? `0${violationsCount}`
                      : ""}
                  </div>
                  <div className="text-[#757575] text-xs font-medium">
                    {card?.label}
                  </div>
                </div>
              </div>
            ))}
          </div>
          {results && (
            <div className="card w-full flex items-center justify-center overflow-hidden">
              <QuestionAnalyse results={results} />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ProfileRightPannel;
