import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import "./App.css";
import "./assets/sass/style.scss";
import { Admin, Verification, ForgotPass, ResetPass } from "./admin";
import { Login, Start, Setup, ExamInstructions, ExamPage, Test } from "./pages";
import {
  ApplyLayout,
  CandidateDashboard,
  CandidateDetailsForm,
  CandidateLogin,
  CandidateRegistration,
} from "./candidate";
import { LogoLoading } from "./components";
import { StopRecordingPopup } from "./components";
import { ToastContainer } from "react-toastify";
// import Register_New from './admin/adminPanel/register/Register';
// import Login_New from './admin/adminPanel/register/AdminLogin';
import AdminLogin from "./admin/adminPanel/register/AdminLogin";
import Register from "./admin/adminPanel/register/Register";
// import {candidateLoginWindow} from './constant/Constants';
// import { LinkedInPopUp } from 'react-linkedin-login-oauth2';

const App = () => {
  const [adminLoginLoading, setAdminLoginLoading] = useState(true);
  const [isAdminLogedIn, setIsAdminLogedIn] = useState(false);
  const [isCandidateLogedIn, setIsCandidateLogedIn] = useState(false);
  const [examExpiry, setExamExpiry] = useState();

  const [isScreenSharing, setisScreenSharing] = useState(true);
  const [recordingStopPopUp, setRecordingStopPopUp] = useState(false);

  useEffect(() => {
    const adminLogin = JSON.parse(localStorage.getItem("adminLogin"));
    if (adminLogin) {
      setIsAdminLogedIn(adminLogin.isAdminLogedIn);
    }
    setAdminLoginLoading(false);

    const details = JSON.parse(localStorage.getItem("scheduleDetails"));
    if (details) {
      setIsCandidateLogedIn(details.isCandidateLogin);
      setExamExpiry(details.expDate?.seconds * 1000);
    }
  }, []);

  useEffect(() => {
    // console.log("isScreenSharing:"+isScreenSharing)
    if (isScreenSharing) {
      setRecordingStopPopUp(false);
    } else {
      setRecordingStopPopUp(true);
    }
  }, [isScreenSharing]);

  return (
    <div className="relative flex flex-col h-[100vh] overflow-hidden">
      <ToastContainer stacked autoClose={3000} />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/test" element={<Test />} />

          {/* exam routes  */}
          <Route
            path="/start"
            element={isCandidateLogedIn ? <Start /> : <Login />}
          />

          <Route
            path="/examSetup"
            element={
              isCandidateLogedIn ? (
                examExpiry > Date.now() ? (
                  <Setup setisScreenSharing={setisScreenSharing} />
                ) : (
                  <Start />
                )
              ) : (
                <Login />
              )
            }
          />

          <Route
            path="/examInstructions"
            element={
              isCandidateLogedIn ? (
                examExpiry > new Date().getTime() ? (
                  <ExamInstructions />
                ) : (
                  <Start />
                )
              ) : (
                <Login />
              )
            }
          />

          <Route
            path="/examPage"
            element={
              isCandidateLogedIn ? (
                examExpiry > new Date().getTime() ? (
                  <ExamPage />
                ) : (
                  <Start />
                )
              ) : (
                <Login />
              )
            }
          />

          {/* candidate apply form routes  */}

          {/* <Route path="/auth/linkedin/callback" element={<LinkedInPopUp/>} /> */}

          <Route path="/candidateApply" element={<ApplyLayout step={1} />} />
          <Route
            path="/candidateApply/step2"
            element={<ApplyLayout step={2} />}
          />
          <Route
            path="/candidateApply/step3"
            element={<ApplyLayout step={3} />}
          />
          <Route
            path="/candidateRegistration"
            element={<CandidateRegistration />}
          />
          <Route path="/candidateLogin" element={<CandidateLogin />} />
          <Route path="/candidateDashboard" element={<CandidateDashboard />} />
          <Route
            path="/candidateDetailsForm"
            element={<CandidateDetailsForm />}
          />

          {/* admin routes */}
          <Route
            path="/admin"
            element={
              adminLoginLoading ? (
                <LogoLoading />
              ) : isAdminLogedIn ? (
                <Admin page="dashboard" />
              ) : (
                <AdminLogin />
              )
            }
          />
          <Route
            path="/profile"
            element={
              adminLoginLoading ? (
                <LogoLoading />
              ) : isAdminLogedIn ? (
                <Admin page="admin profile" />
              ) : (
                <AdminLogin />
              )
            }
          />
          <Route
            path="/settings"
            element={
              adminLoginLoading ? (
                <LogoLoading />
              ) : isAdminLogedIn ? (
                <Admin page="settings" />
              ) : (
                <AdminLogin />
              )
            }
          />
          <Route
            path="/notifications"
            element={
              adminLoginLoading ? (
                <LogoLoading />
              ) : isAdminLogedIn ? (
                <Admin page="notifications" />
              ) : (
                <AdminLogin />
              )
            }
          />

          <Route
            path="/invitations"
            element={
              adminLoginLoading ? (
                <LogoLoading />
              ) : isAdminLogedIn ? (
                <Admin page="applications" tab="invitations" />
              ) : (
                <AdminLogin />
              )
            }
          />
          <Route
            path="/results"
            element={
              adminLoginLoading ? (
                <LogoLoading />
              ) : isAdminLogedIn ? (
                <Admin page="applications" tab="results" />
              ) : (
                <AdminLogin />
              )
            }
          />
          <Route
            path="/coding results"
            element={
              adminLoginLoading ? (
                <LogoLoading />
              ) : isAdminLogedIn ? (
                <Admin page="applications" tab="coding results" />
              ) : (
                <AdminLogin />
              )
            }
          />
          {/* <Route
						path="/applications" 
						element= { adminLoginLoading ? 
							<LogoLoading/> : 
							(isAdminLogedIn ? 
								<Admin page="applications" tab= 'applications' /> : 
								<AdminLogin/>
							)
						} 
					/> */}
          <Route
            path="/candidateProfile"
            element={
              adminLoginLoading ? (
                <LogoLoading />
              ) : isAdminLogedIn ? (
                <Admin page="candidate profile" />
              ) : (
                <AdminLogin />
              )
            }
          />

          {/* ATS routes  */}
          <Route
            path="/applicants"
            element={
              adminLoginLoading ? (
                <LogoLoading />
              ) : isAdminLogedIn ? (
                <Admin page="ATS" />
              ) : (
                <AdminLogin />
              )
            }
          />
          <Route
            path="/newCandidate"
            element={
              adminLoginLoading ? (
                <LogoLoading />
              ) : isAdminLogedIn ? (
                <Admin page="new candidate" />
              ) : (
                <AdminLogin />
              )
            }
          />
          <Route
            path="/candidateResults"
            element={
              adminLoginLoading ? (
                <LogoLoading />
              ) : isAdminLogedIn ? (
                <Admin page="candidate results" />
              ) : (
                <AdminLogin />
              )
            }
          />

          {/* job routes */}
          <Route
            path="/jobs"
            element={
              adminLoginLoading ? (
                <LogoLoading />
              ) : isAdminLogedIn ? (
                <Admin page="jobs" />
              ) : (
                <AdminLogin />
              )
            }
          />
          <Route
            path="/newJob"
            element={
              adminLoginLoading ? (
                <LogoLoading />
              ) : isAdminLogedIn ? (
                <Admin page="new job" />
              ) : (
                <AdminLogin />
              )
            }
          />

          {/* questionLibrary Routes  */}
          <Route
            path="/questionLibrary"
            element={
              adminLoginLoading ? (
                <LogoLoading />
              ) : isAdminLogedIn ? (
                <Admin page="question library" />
              ) : (
                <AdminLogin />
              )
            }
          />
          <Route
            path="/addQuestion"
            element={
              adminLoginLoading ? (
                <LogoLoading />
              ) : isAdminLogedIn ? (
                <Admin page="add question" />
              ) : (
                <AdminLogin />
              )
            }
          />

          {/* old - question bank routes  */}
          <Route
            path="/quesBank"
            element={
              adminLoginLoading ? (
                <LogoLoading />
              ) : isAdminLogedIn ? (
                <Admin page="question bank" />
              ) : (
                <AdminLogin />
              )
            }
          />
          <Route
            path="/quesUpload"
            element={
              adminLoginLoading ? (
                <LogoLoading />
              ) : isAdminLogedIn ? (
                <Admin page="question upload" />
              ) : (
                <AdminLogin />
              )
            }
          />

          {/* assessments page routes  */}
          <Route
            path="/assessments"
            element={
              adminLoginLoading ? (
                <LogoLoading />
              ) : isAdminLogedIn ? (
                <Admin page="assessments" />
              ) : (
                <AdminLogin />
              )
            }
          />
          <Route
            path="/newAssessment"
            element={
              adminLoginLoading ? (
                <LogoLoading />
              ) : isAdminLogedIn ? (
                <Admin page="new assessment" />
              ) : (
                <AdminLogin />
              )
            }
          />

          {/* assessmentType - old  */}
          <Route
            path="/assessmentType"
            element={
              adminLoginLoading ? (
                <LogoLoading />
              ) : isAdminLogedIn ? (
                <Admin page="assessment type" />
              ) : (
                <AdminLogin />
              )
            }
          />

          {/* archive routes */}
          {/* <Route path="/archiveCandidates" element={adminLoginLoading ? <LogoLoading/> : isAdminLogedIn ? <Admin page="archive" tab='candidates' /> : <AdminLogin/>} />
					<Route path="/archiveQuestions" element={adminLoginLoading ? <LogoLoading/> : isAdminLogedIn ? <Admin page="archive" tab= 'questions'/> : <AdminLogin/>} />
					<Route path="/archiveAssessments" element={adminLoginLoading ? <LogoLoading/> : isAdminLogedIn ? <Admin page="archive" tab= 'assessments' /> : <AdminLogin/>} /> */}

          {/* post job routes */}
          {/* <Route path="/jobs" element={adminLoginLoading ? <LogoLoading/> : isAdminLogedIn ? <Admin page="jobs" /> : <AdminLogin/>} />
					<Route path="/newJob" element={adminLoginLoading ? <LogoLoading/> : isAdminLogedIn ? <Admin page="new job" /> : <AdminLogin/>} /> */}

          {/* company register routes  */}
          <Route path="/register" element={<Register />} />
          <Route path="/adminRegister" element={<Register />} />
          {/* <Route
						path="/adminLogin" 
						element={<Login_New/>}

					/> */}

          <Route
            path="/verification"
            element={
              adminLoginLoading ? (
                <LogoLoading />
              ) : isAdminLogedIn ? (
                <Admin page="dashboard" />
              ) : (
                <Verification />
              )
            }
          />
          <Route
            path="/forgotPass"
            element={
              adminLoginLoading ? (
                <LogoLoading />
              ) : isAdminLogedIn ? (
                <Admin page="dashboard" />
              ) : (
                <ForgotPass />
              )
            }
          />
          <Route
            path="/resetpass"
            element={
              adminLoginLoading ? (
                <LogoLoading />
              ) : isAdminLogedIn ? (
                <Admin page="dashboard" />
              ) : (
                <ResetPass />
              )
            }
          />

          {/* <Route path="*" element={<NoPageFound />} /> */}
        </Routes>
      </BrowserRouter>

      {recordingStopPopUp && (
        <div className="moduleCover">
          <StopRecordingPopup
            setisScreenSharing={setisScreenSharing}
            setRecordingStopPopUp={setRecordingStopPopUp}
          />
        </div>
      )}
    </div>
  );
};

export default App;
