import React from "react";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../../config/firebase";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const AssessmentMenu = ({ setMenuOpen, assessment, setOpenAssessmentView,setDeleteAssessmentId }) => {
  const menuOptions = ["edit", "clone", "delete"];
  const navigate = useNavigate();

  const deleteAssessment = async () => {
    // const documentId = assessment?.id;
    // const documentRef = doc(db, "assessments", documentId);
    setDeleteAssessmentId(assessment?.id)
    setMenuOpen(false);
    setOpenAssessmentView(false);
    navigate("/assessments");
    // try {
    //   await updateDoc(documentRef, { isDeleted: true });
    //   toast.success("Success! Your Assessment has been deleted");
    // } catch (error) {
    //   toast.error(`Error! ${error.message}`);
    // }
  };
  const action = (e) => {
    if (e.target.innerHTML === "delete") deleteAssessment();
    if (e.target.innerHTML === "edit"){ navigate(`/newAssessment?type=${assessment.type}&position=${assessment.position}&experience=${assessment.experience}&domain=${assessment.domain}&editId=${assessment.id}`)};

  };

  return (
    <div className="absolute top-8 right-0 flex flex-col justify-center rounded-xl overflow-hidden border-2 bg-white z-50 w-[100px]">
      {menuOptions.map((option, index) => (
        <div
          key={index}
          className={`cursor-pointer text-sm hover:bg-gray-100 p-2 px-4 font-medium w-full capitalize ${
            option === "delete" ? "text-red-500" : ""
          }`}
          onClick={action}
        >
          {option}
        </div>
      ))}
    </div>
  );
};

export default AssessmentMenu;
