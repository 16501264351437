import React, { useState } from "react";
import { Button, TextInput } from "../../components";

const Schedule = ({
  type,
  scheduleAssessment,
  rescheduleAssessment,
  schedule,
}) => {
  const [date, setDate] = useState();
  const [time, setTime] = useState();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const handleSubmit = async () => {
    // handle validations
    setError("");
    if (!date || !time) {
      setError("Please select a date and time");
      return;
    }
    if (new Date(`${date} ${time}`) < new Date()) {
      return setError("Please select a future date");
    }
    if (
      new Date(`${date} ${time}`) >
      new Date(new Date()?.getTime() + 1000 * 60 * 60 * 24 * 7)
    ) {
      return setError("Please select a date within next 7 days");
    }
    const scheduleDate = new Date(`${date} ${time}`);
    const expDate = new Date(scheduleDate?.getTime() + 1000 * 60 * 60 * 48);
    setLoading(true);
    try {
      if (type === "reschedule") {
        await rescheduleAssessment(scheduleDate, expDate);
      } else {
        await scheduleAssessment(scheduleDate, expDate);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };
  return (
    <div className="p-8 space-y-4 min-w-[500px]">
      <h1 className="heading2">{type} Assessment</h1>
      {type === "reschedule" && (
        <div className="">
          <p className="text-xs">Current Assessment Date</p>
          <p className="text-sm font-medium">
            <span>
              {new Date(
                schedule?.scheduleDate?.seconds * 1000
              )?.toLocaleDateString("en-IN", {
                day: "numeric",
                month: "long",
                year: "numeric",
              })}
              {", "}
              {new Date(schedule?.expDate?.seconds * 1000)?.toLocaleTimeString(
                "en-IN",
                {
                  hour: "numeric",
                  minute: "numeric",
                }
              )}
            </span>
            <span>{" to "}</span>
            <span>
              {new Date(schedule?.expDate?.seconds * 1000)?.toLocaleDateString(
                "en-IN",
                {
                  day: "numeric",
                  month: "long",
                  year: "numeric",
                }
              )}
              {", "}
              {new Date(
                schedule?.scheduleDate?.seconds * 1000
              )?.toLocaleTimeString("en-IN", {
                hour: "numeric",
                minute: "numeric",
              })}
            </span>
          </p>
        </div>
      )}
      <div>
        <label className="text-xs font-medium">
          Select Assessment Date and Time :
        </label>
        <div className="flex border-2 rounded-xl overflow-hidden">
          <TextInput
            type="date"
            innerLabel="Date:"
            inputValue={date}
            handleChange={(e) => setDate(e.target.value)}
          />
          <TextInput
            type="time"
            innerLabel="Time:"
            inputValue={time}
            handleChange={(e) => setTime(e.target.value)}
          />
        </div>
      </div>
      <div className="flex w-full justify-end">
        {error && (
          <p className="text-red-500 text-xs self-center w-full">{error}</p>
        )}
        <Button handleClick={handleSubmit} loading={loading}>
          <span className="capitalize">{type}</span>
        </Button>
      </div>
    </div>
  );
};

export default Schedule;
