import React, { useEffect, useState } from "react";
import { Button, Popup, Topbar2 } from "../../components";
import { useNavigate } from "react-router-dom";
import Schedule from "../../admin/ats/Schedule";
import { Timestamp, doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "../../config/firebase";
import { toast } from "react-toastify";
import rescheduleEmailTemplate from "../../utils/emailTemplates/roundRescheduled";
import axios from "axios";

const Start = () => {
  const [scheduleDetails, setScheduleDetails] = useState();
  const [candidateDetails, setCandidateDetails] = useState();
  const navigate = useNavigate();
  useEffect(() => {
    const details = JSON.parse(localStorage.getItem("scheduleDetails"));

    if (details) {
      setScheduleDetails(details);
      const fetchCandidateDetails = async () => {
        const docRef = doc(db, "candidateProfile", details?.candidateId);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          setCandidateDetails({ id: docSnap.id, ...docSnap.data() });
        }
      };
      fetchCandidateDetails();
    }
  }, []);

  const [duration, setDuration] = useState();
  const [days, setDays] = useState();
  const [hours, setHours] = useState();
  const [minutes, setMinutes] = useState();
  const [seconds, setSeconds] = useState();
  const [dateNow, setDateNow] = useState();
  useEffect(() => {
    setInterval(() => {
      setDateNow(new Date());
    }, 1000);
  });
  useEffect(() => {
    setDuration(
      Math.floor(
        (new Date(scheduleDetails?.scheduleDate?.seconds * 1000) - dateNow) /
          1000
      )
    );
  }, [scheduleDetails, dateNow]);

  useEffect(() => {
    setDays(Math.floor(duration / (60 * 60 * 24)));
    setHours(Math.floor((duration / 3600) % 24));
    setMinutes(Math.floor((duration / 60) % 60));
    setSeconds(Math.floor(duration % 60));
  }, [duration]);

  const [rescheduleModule, setRescheduleModule] = useState(false);

  const handleReschedule = async (scheduleDate, expDate) => {
    // update reschedule data to db
    const rescheduleData = {
      scheduleDate: Timestamp.fromDate(scheduleDate),
      expDate: Timestamp.fromDate(expDate),
      updatedAt: Timestamp.now(),
    };

    const dateFormat = {
      day: "numeric",
      month: "long",
      year: "numeric",
    };
    const timeFormat = { hour: "numeric", minute: "2-digit", hour12: true };

    const emailDetails = {
      candidateName: candidateDetails?.name,
      candidateEmail: candidateDetails?.email,
      examCode: scheduleDetails?.examCode,
      companyName: scheduleDetails?.companyName,
      startDate: {
        date: scheduleDate?.toLocaleDateString("en-IN", dateFormat),
        time: scheduleDate?.toLocaleTimeString("en-IN", timeFormat),
      },
      expDate: {
        date: expDate?.toLocaleDateString("en-IN", dateFormat),
        time: expDate?.toLocaleTimeString("en-IN", timeFormat),
      },
    };
    const rescheduleRef = doc(db, "assessmentTests", scheduleDetails?.id);
    try {
      // update reschedule to db
      await updateDoc(rescheduleRef, rescheduleData);
      toast.success("Success! Your Assessment has been rescheduled.");
      // send email to candidate for reschedule
      const { subject, body } = rescheduleEmailTemplate(emailDetails);
      const emailData = `${candidateDetails?.email}|${subject}|${body}`;
      await axios.post(process.env.REACT_APP_EMAIL_API, emailData);
      toast.success(
        "Success! Email has been sent with rescheduled assessment details."
      );
    } catch (error) {
      toast.success("Error! An error occured. Please try again.");
    } finally {
      setRescheduleModule(false);
      navigate("/");
    }
  };

  return (
    <div className="w-full h-full">
      <Topbar2 name={candidateDetails?.name} email={candidateDetails?.email} />
      <div className="w-[95%] h-[80%] mx-auto py-8 flex card my-6 flex-col gap-5 justify-center items-center overflow-hidden">
        {scheduleDetails?.scheduleDate?.seconds * 1000 < Date.now() &&
          scheduleDetails?.expDate?.seconds * 1000 > Date.now() && (
            <>
              <Button
                version="green"
                text="Start Assessment"
                handleClick={() => {
                  // navigate("/examSetup");
                  window.location.href = "/examSetup";
                }}
              />
              <div className="my-2">OR</div>
              <div>
                <Button
                  text="Reschedule Assessment"
                  handleClick={() => {
                    setRescheduleModule(true);
                  }}
                />
              </div>
            </>
          )}
        {scheduleDetails?.scheduleDate?.seconds * 1000 > Date.now() && (
          <>
            <div className="w-full p-10 flex absolute top-0 justify-between">
              <div>
                <p>Your Assesment is Scheduled for</p>
                <p className="flex gap-2 justify-center text-xl gradientText">
                  <span>
                    {new Date(
                      scheduleDetails?.scheduleDate?.seconds * 1000
                    )?.toLocaleDateString("en-IN", {
                      day: "numeric",
                      month: "long",
                      year: "numeric",
                    })}
                  </span>
                  {" - "}
                  {new Date(
                    scheduleDetails?.expDate?.seconds * 1000
                  )?.toLocaleTimeString()}
                </p>
              </div>

              {/* <div className="">
                <Button
                  text="Reschedule Assessment"
                  // handleClick={() => setscheduleNowLaterDialog(true)}
                />
              </div> */}
            </div>

            <div className="text-2xl font-semibold">Time Left to Start</div>
            <div className="text-lg flex gap-5">
              {days !== 0 && (
                <div className=" w-[100px]">
                  <span className="text-4xl gradientText w-[45px] inline-block font-medium">
                    {days ? (days < 10 ? `0${days}` : days) : "00"}
                  </span>{" "}
                  {days === 1 ? "Day" : "Days"}
                </div>
              )}

              {(days || hours !== 0) && (
                <div className="b w-[105px]">
                  <span className="text-4xl gradientText w-[45px] inline-block font-medium">
                    {hours ? (hours < 10 ? `0${hours}` : hours) : "00"}
                  </span>{" "}
                  {hours === 1 ? "Hour" : "Hours"}
                </div>
              )}

              {(days || hours || minutes || seconds !== 0) && (
                <>
                  <div className="w-[120px]">
                    <span className="text-4xl gradientText w-[45px] inline-block font-medium">
                      {minutes
                        ? minutes < 10
                          ? `0${minutes}`
                          : minutes
                        : "00"}
                    </span>{" "}
                    {minutes === 1 ? "Minute" : "Minutes"}
                  </div>

                  <div className="w-[128px]">
                    <span className="text-4xl gradientText w-[45px] inline-block font-medium">
                      {seconds
                        ? seconds < 10
                          ? `0${seconds}`
                          : seconds
                        : "00"}
                    </span>{" "}
                    {seconds === 1 ? "Second" : "Seconds"}
                  </div>
                </>
              )}
            </div>
          </>
        )}
        {scheduleDetails?.expDate?.seconds * 1000 < Date.now() && (
          <div className="flex flex-col gap-5 justify-center items-center">
            <div className="text-2xl font-semibold">
              Your Assesment has been expired on
            </div>
            <div className="text-4xl gradientText font-medium">
              {new Date(
                scheduleDetails?.expDate?.seconds * 1000
              ).toLocaleString("en-in", {
                weekday: "short",
                day: "numeric",
                month: "long",
                year: "numeric",
              })}
            </div>
            <div className="text-4xl gradientText font-medium">
              {new Date(
                scheduleDetails?.expDate?.seconds * 1000
              ).toLocaleString("en-in", {
                hour: "numeric",
                minute: "numeric",
              })}
            </div>
            <div className="mt-5">
              <Button
                text="Reschedule Assessment"
                handleClick={() => setRescheduleModule(true)}
              />
            </div>
            <div>OR</div>
            <div className="text-lg font-semibold">
              Contact -
              <a
                href={`mailto:${scheduleDetails?.companyEmail}`}
                className="gradientText mx-2"
              >
                {scheduleDetails?.companyName}
              </a>
            </div>
          </div>
        )}
      </div>
      {rescheduleModule && (
        <Popup closePopup={() => setRescheduleModule(false)}>
          <Schedule
            type="reschedule"
            rescheduleAssessment={handleReschedule}
            schedule={scheduleDetails}
          />
        </Popup>
      )}
    </div>
  );
};

export default Start;
