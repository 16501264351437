import React from "react";
import Select from "react-select";
import { customStyles } from "./selectStyles";

const SelectInput = ({
  placeholderText,
  className,
  selectOptions,
  inputName,
  handleChange,
  value,
  defaultValue,
  isClearable,
  isSearchable,
  isRequired,
  isDisabled,
}) => {
  return (
    <Select
      className={className}
      placeholder={placeholderText}
      options={selectOptions}
      name={inputName}
      styles={customStyles}
      value={value}
      defaultValue={defaultValue}
      onChange={handleChange}
      isClearable={isClearable}
      isSearchable={isSearchable}
      required={isRequired}
      isDisabled={isDisabled}
    />
  );
};

export default SelectInput;
