// this email is sent to candiate when he register on Talentigo
// email contain details for the scheduled first round and some instructions for the assessment

const emailTemplate = ({candidateName}) => {
    const profileCompletionLink = `${process.env.REACT_APP_URL}candidateDetailsForm`
    const talentigoHelp = 'naskaytechnologies@gmail.com'
    const subject =  `Welcome to the Talentigo Hiring Process, ${candidateName}!`
    const body = `
    Hi <b>${candidateName}</b>,<br>
    <br/>
    Welcome to the Talentigo hiring process!<br/> 
    We're thrilled you've registered on our new hiring tool and are excited to learn more about your qualifications.<br/>
    <br/>
    To help us get to know you better and streamline the application process, please take a few moments to complete your profile details. This will allow you to showcase your skills and experience to hiring team.<br/>
    <br/>
    Here's what you can expect when you complete your profile:<br/>
    <ul>
    <li> Highlight your strengths: Provide details about your education, work experience, and relevant skills.</li>
    <li> Showcase your personality: Briefly talk about your career goals and why you're interested in this role.</li>
    <li> Increase your visibility: A complete profile helps hiring managers discover your qualifications quickly.</li>
    </ul><br/>
    <b>Click here to complete your profile: <a href=${profileCompletionLink}>${profileCompletionLink}</a></b><br/>
    <br/>
    We encourage you to update your profile as soon as possible to be considered by hiring teams.<br/>
    <br/>
    Should you have any questions about the registration or profile completion process, please don't hesitate to contact us at <a href="mailto:${talentigoHelp}">${talentigoHelp}</a>.<br/>
    <br/>
    Best regards,<br/>
    Team Talentigo
    `
    return { subject, body }
}

export default emailTemplate