// this email is sent to candiate when he apply on Talentigo and firest assessment is scheduled
// email contain details for the scheduled first round and some instructions for the assessment

const emailTemplate = ({candidateName, candidateEmail, examCode, jobTitle, companyName, startDate, expDate}) => {
    const subject =  `Thank you for applying at ${companyName}`
    const body = `
    Hello <b>${candidateName}</b>,<br>
        Thank you for applying for <b>${jobTitle}</b> at <b>${companyName}</b>.<br><br>
        
        This is to inform you that you have been selected for the <b>Online Assessment Test</b>.
        <br><br>
        Kindly read the instruction and visit the link below :
        <br>
        <br><b>Instructions - </b><br>
            1. You have to attempt the test in the time window given below.<br>
            2. Make sure to join from a Laptop/PC with a working webcam and microphone.<br><br>

            <b>Assessment Date & Time - </b></br>
            ${startDate?.date} - ${startDate?.time} to ${expDate?.date} - ${expDate?.time}<br><br>
        
        <b>Test Credentials - </b><br>
            <b>Assessment Link - </b> <a href = ${process.env.REACT_APP_URL}>${process.env.REACT_APP_URL}</a> <br>
            <b>Login ID - </b> ${candidateEmail}<br>
            <b>Login Code - </b> ${examCode}<br> <br>
        
        <b>Need to make changes to this event?</b> <br>
        Login with the same credentials and reschedule the test <br> <br>
        
        <b>Please follow our LinkedIn page for Future Job Updates</b> <br>
        LinkedIn - <a href='https://www.linkedin.com/company/talentigo/'>Linkedin-Talentigo</a> <br><br>
        
        Good Luck!<br>
        <b>${companyName}</b>
    `
    return { subject, body }
}

export default emailTemplate