import React, { useEffect, useState } from "react";
import {
  collection,
  getDoc,
  onSnapshot,
  query,
  where,
} from "firebase/firestore";
import { db } from "../../config/firebase";
import { Button } from "../../components";
import { useNavigate } from "react-router-dom";

const ATSrows = ({
  index,
  columnList,
  candidate,
  setShowScheduleModule,
  setCandidateToSchedule,
  setCandidateToReschedule,
}) => {
  const navigate = useNavigate();
  const [candidateDetails, setCandidateDetails] = useState(candidate);

  useEffect(() => {
    const fetcheCandidateProfileDetails = async () => {
      if (candidate.candidateProfileRef) {
        // Fetch the document data
        const docSnapshot = await getDoc(candidate.candidateProfileRef);

        // Document exists, extract data
        if (docSnapshot.exists()) {
          const data = docSnapshot.data();
          setCandidateDetails({ ...candidate, ...data });
        }
      } else {
        setCandidateDetails(candidate);
      }
    };
    fetcheCandidateProfileDetails();
  }, [candidate]);

  const [schedules, setSchedules] = useState([]);
  useEffect(() => {
    //fetch schedule data for the application id - candidate?.id
    const fetchScheduleDetails = async () => {
      const q = query(
        collection(db, "assessmentTests"),
        where("applicationId", "==", candidate?.id),
        where("attempted", "==", false)
      );

      const querySnapshot = onSnapshot(q, (querySnapshot) => {
        const scheduleData = [];
        querySnapshot.forEach((doc) => {
          scheduleData.push({ id: doc.id, ...doc.data() });
        });
        setSchedules(scheduleData);
      });
      return querySnapshot;
    };
    fetchScheduleDetails();
  }, [candidate]);

  const openSchedulePopup = (type) => {
    // open date select popup
    setShowScheduleModule(type);
  };

  return (
    <>
      <div
        className="flex w-full overflow-x-auto py-2 pl-5 text-xs font-medium even:bg-[#E9EAEB]/60 cursor-pointer"
        onClick={() => {
          navigate(`/candidateResults?id=${candidateDetails?.id}`);
        }}
      >
        <div className="w-[50px] flex items-center">{index + 1}.</div>
        {columnList.map((column) => {
          const width = column.open ? column.width : "0";
          const opacity = column.open ? "1" : "0";
          return (
            <div
              key={column.name}
              className={`flex items-center gap-1 transition-all duration-200 
                ${column.name !== "email" && "capitalize"}
              `}
              style={{ width, opacity }}
            >
              {column.open &&
                (column.name === "appliedAt" ? (
                  new Date(
                    candidateDetails.appliedAt?.seconds * 1000
                  ).toLocaleDateString("en-in", {
                    day: "numeric",
                    month: "short",
                    year: "numeric",
                  })
                ) : column.name === "schedule" ? (
                  schedules?.length ? (
                    <Button
                      version={"outline-green"}
                      handleClick={(e) => {
                        e.stopPropagation();
                        openSchedulePopup("reschedule");
                        setCandidateToReschedule({
                          candidate: candidateDetails,
                          schedule: schedules[0],
                        });
                      }}
                    >
                      Reschedule
                    </Button>
                  ) : (
                    <Button
                      version="outline-blue"
                      handleClick={(e) => {
                        e.stopPropagation();
                        openSchedulePopup("schedule");
                        setCandidateToSchedule({ candidate: candidateDetails });
                      }}
                    >
                      Schedule
                    </Button>
                  )
                ) : candidateDetails[column.name] ? (
                  candidateDetails[column.name]
                ) : (
                  "-"
                ))}
            </div>
          );
        })}
      </div>
    </>
  );
};

export default ATSrows;
