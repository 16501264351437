import React, { useEffect, useState } from "react";
import { SelectInput } from "../../components";
import PieGraph from "./PieGraph";

const ScoreDistribution = ({ applications }) => {
  const [selectValue, setSelectValue] = useState();
  const [percentageData, setPercentageData] = useState();
  const [cumulativeData, setCumulativeData] = useState();
  const [totalGraphData, setTotalGraphData] = useState({
    percentageScored: 0,
    commulativeScored: 0,
  });

  useEffect(() => {
    let allCandidateAttemptForAssessment = [];
    if (selectValue) {
      allCandidateAttemptForAssessment = applications
        ?.filter((data) => data.assessmentsResults?.length > 0)
        .filter(
          (checkDate) =>
            checkDate.appliedAtMillisecond > selectValue.lastDateMillisecond
        );
    } else {
      allCandidateAttemptForAssessment = applications?.filter(
        (data) => data.assessmentsResults?.length > 0
      );
    }

    const allAssementResult = allCandidateAttemptForAssessment
      ?.map((data) => data.assessmentsResults)
      .flat();

    let allPass = 0;
    let allFail = 0;
    let allTerminate = 0;

    allAssementResult?.forEach((data) => {
      if (data.result === "pass") {
        allPass = allPass + 1;
      } else if (data.result === "fail") {
        allFail = allFail + 1;
      } else {
        allTerminate = allTerminate + 1;
      }
    });

    let total0To20 = 0;
    let total20To40 = 0;
    let total40To60 = 0;
    let total60To80 = 0;
    let total80To100 = 0;

    allAssementResult?.forEach((data) => {
      const percentage = (data.score / (data.totalQuestions * 10)) * 100;
      if (percentage >= 0 && percentage <= 20) {
        total0To20++;
      } else if (percentage > 20 && percentage <= 40) {
        total20To40++;
      } else if (percentage > 40 && percentage <= 60) {
        total40To60++;
      } else if (percentage > 60 && percentage <= 80) {
        total60To80++;
      } else if (percentage > 80) {
        total80To100++;
      }
    });

    setTotalGraphData({
      percentageScored: allPass + allFail + allTerminate,
      commulativeScored:
        total0To20 + total20To40 + total40To60 + total60To80 + total80To100,
    });

    setPercentageData([
      { name: "0% - 20%", value: total0To20, color: "#006E50" },
      {
        name: "20% - 40%",
        value: total20To40,
        color: "#00563E",
      },
      {
        name: "40% - 60%",
        value: total40To60,
        color: "#4E9144",
      },
      {
        name: "60% - 80%",
        value: total60To80,
        color: "#6CA031",
      },
      {
        name: "80% - 100%",
        value: total80To100,
        color: "#8DBE54",
      },
    ]);

    setCumulativeData([
      {
        name: "Passed",
        value: allPass,
        color: "#18AE00",
      },
      {
        name: "Failed",
        value: allFail,
        color: "#E11414",
      },
      {
        name: "Terminated",
        value: allTerminate,
        color: "#FFB800",
      },
    ]);
  }, [applications, selectValue]);

  const updateTimeInterval = (e) => {
    let lastTime = Number(e.days) * 24 * 60 * 60 * 1000;

    setSelectValue({
      ...e,
      currentDate: new Date(Date.now()).toLocaleDateString(),
      lastDate: new Date(Date.now() - lastTime).toLocaleDateString(),
      lastDateMillisecond: Date.now() - lastTime,
    });
  };

  const graphs = [
    {
      name: "percentageScored",
      title: "Percentage Scored",
      data: percentageData,
    },
    {
      name: "commulativeScored",
      title: "Cumulative",
      data: cumulativeData,
    },
  ];
  return (
    <div className="card w-full p-6 space-y-2">
      <div className="flex justify-between text-[16px] items-start">
        <div>
          <h3 className="font-semibold text-lg">Score Distribution</h3>
        </div>
        <div className="flex flex-col gap-1 items-center justify-center">
          <div>
            <SelectInput
              className="w-[200px]"
              selectOptions={[
                { value: "1", label: "Last 1 Weeks", days: 7 },
                { value: "2", label: "Last 2 Weeks", days: 14 },
                { value: "1", label: "Last 1 Month", days: 30 },
                { value: "2", label: "Last 3 Month", days: 91 },
                { value: "6", label: "Last 6 Month", days: 183 },
                { value: "12", label: "Last 1 year", days: 365 },
              ]}
              placeholderText="Select interval"
              handleChange={(e) => updateTimeInterval(e)}
            />
          </div>
          <div className="text-sm font-medium text-[#888]">
            {selectValue &&
              `${selectValue?.lastDate} - ${selectValue?.currentDate}`}
          </div>
        </div>
      </div>
      <div className="grid lg:grid-cols-2 gap-3">
        {graphs?.map(({ name, title, data }) => (
          <div key={name}>
            <PieGraph
              title={title}
              data={data}
              loading={!applications}
              toalData={totalGraphData[name]}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default ScoreDistribution;
