import React from "react";
import { Button } from "../../components";

const InternetRight = ({ setStep, hasInternetConnection }) => {
  return (
    <div className="flex flex-col items-start justify-between h-full w-[80%] mx-auto">
      <div className="flex flex-col">
        <h1 className="heading1 mb-10">Stable Internet Connection</h1>
        <ul className="gap-4 text-[#919397] listDisc">
          <li>Use a stable internet connection while giving the test.</li>
          <li>
            It is preferred to use a wifi connection rather than using mobile
            hotspot.
          </li>
          <li>Press “Test Camera” to test your Camera to proceed for test.</li>
        </ul>
      </div>

      <Button
        version="primary"
        text="Proceed"
        width="fit-content"
        handleClick={() => {
          setStep((prev) => prev + 1);
        }}
        isDisabled={!hasInternetConnection}
      />
    </div>
  );
};

export default InternetRight;
