import React, { useState } from "react";
import { TextInput } from "../../../components";

const DetailInfo = () => {
  const detailsFields = [
    {
      name: "firstName",
      label: "Contact Person first Name",
      placeholder: "eg. John",
      required: true,
      span: 1,
    },
    {
      name: "lastName",
      label: "Contact Person Last Name",
      placeholder: "eg. Doe",
      required: true,
      span: 1,
    },
    {
      name: "phoneNumber",
      label: "Contact Person Phone Number",
      type: "number",
      placeholder: "eg. 978327300",
      required: true,
      span: 2,
    },
    {
      name: "role",
      label: "Role",
      placeholder: "eg.HR Executive",
      required: true,
      span: 2,
    },
  ];

  const [passwordType, setPasswordType] = useState("password");
  const [rePasswordType, setRePasswordType] = useState("password");

  return (
    <div className="w-full h-full">
      <div className="text-xl font-semibold"> Fill Detail Info</div>
      <div className="grid grid-cols-2 gap-4">
        {detailsFields.map((field) => (
          <div
            key={field.name}
            style={{ gridColumn: `span ${field.span} / span ${field.span}` }}
            className={`flex flex-col 
              ${field.type === "password" ? "password-field" : ""}
            `}
          >
            <label htmlFor={field.name} className="text-xs font-medium">
              {field.label}
              {field.required && <sup>*</sup>}
            </label>
            <TextInput
              placeholderText={field.placeholder}
              inputName={field.name}
              type={field.type ? field.type : "text"}
              innerLabel={field.name === "phoneNumber" && "+91"}
            />
          </div>
        ))}

        <div className="col-span-1 flex flex-col password-field">
          <label htmlFor="password" className="text-xs font-medium">
            Enter Password<sup>*</sup>
          </label>
          <TextInput
            placeholderText="Enter your Password"
            inputName="password"
            type={passwordType}
          />

          <img
            src={`./img/eye-${passwordType === "text" ? "open" : "close"}.png`}
            alt="eye-icon"
            onClick={() => {
              passwordType === "password"
                ? setPasswordType("text")
                : setPasswordType("password");
            }}
          />
        </div>
        <div className="col-span-1 flex flex-col password-field">
          <label htmlFor="rePassword" className="text-xs font-medium">
            Re-Enter Password<sup>*</sup>
          </label>
          <TextInput
            placeholderText="Re-Enter your Password"
            inputName="rePassword"
            type={rePasswordType}
          />
          <img
            src={`./img/eye-${
              rePasswordType === "text" ? "open" : "close"
            }.png`}
            alt="eye-icon"
            onClick={() => {
              rePasswordType === "password"
                ? setRePasswordType("text")
                : setRePasswordType("password");
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default DetailInfo;
