import React from "react";
import { Cell, Pie, PieChart } from "recharts";

const PieGraph = ({ title, data, toalData, loading }) => {
  const LoadingComponent = () => (
    <div className="flex justify-center items-center w-full gap-10 p-5">
      <div className="bg-[#666]/10 w-[280px] h-[280px] rounded-full animate-pulse"></div>
      <div className="space-y-3">
        {[1, 2, 3, 4]?.map((item) => (
          <div className="bg-[#666]/10 w-[150px] h-[20px] rounded-full animate-pulse" />
        ))}
      </div>
    </div>
  );
  return (
    <div className="border-[#E4E0E0] border-[1px] rounded-2xl p-2 px-4">
      <h3 className="p-3 {text-[#11031D] capitalize text-sm font-medium">
        {title}
      </h3>

      {loading ? (
        <LoadingComponent />
      ) : toalData > 0 ? (
        <div className="flex items-center">
          <PieChart width={400} height={300}>
            <Pie
              data={data || []}
              dataKey="value"
              nameKey="name"
              cx="50%"
              cy="50%"
              innerRadius={60}
              outerRadius={110}
              fill="#aaa"
            >
              {(data || [])?.map((element, i) => (
                <Cell key={i} fill={element.color} />
              ))}
            </Pie>
          </PieChart>
          <div>
            {(data || [])?.map((elem, i) => {
              return (
                <div
                  key={i}
                  className="text-xs my-3 p-1 px-4 rounded-md w-[132px] flex justify-between "
                  style={{
                    backgroundColor: `${elem.color}40`,
                    color: elem.color,
                  }}
                >
                  <span> {elem.name}</span>
                  {" - "}
                  <span>{elem.value}</span>
                </div>
              );
            })}
          </div>
        </div>
      ) : (
        <div className="flex justify-center items-center w-full h-[320px]">
          <span className="text-gray-400 text-xl font-semibold">
            No Data Exists
          </span>
        </div>
      )}
    </div>
  );
};

export default PieGraph;
