import { doc, getDoc } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { db } from "../../../config/firebase";
import QuesRow from "./QuesRow";

const Preview = ({ loading, assessment }) => {
  const loadingStyle = "bg-gray-200 rounded-lg animate-pulse";

  const columns = [
    {
      name: "sno",
      label: "S.No",
      width: "50px",
      open: true,
      sort: true,
      sortType: "",
    },
    {
      name: "questionName",
      label: "Question",
      width: "350px",
      open: true,
      sort: true,
      sortType: "",
    },
    {
      name: "type",
      label: "Type",
      width: "100px",
      open: true,
      sort: true,
      sortType: "",
    },
    {
      name: "time",
      label: "Duration",
      width: "150px",
      open: true,
      sort: true,
      sortType: "",
    },
    {
      name: "difficulty",
      label: "Difficulty",
      width: "100px",
      open: true,
      sort: true,
      sortType: "",
    },
    {
      name: "skills",
      label: "Skills",
      width: "150px",
      open: true,
      sort: true,
      sortType: "",
    },
  ];

  const [columnList, setColumnList] = useState(columns);
  const [questionLoading, setQuestionLoading] = useState(false);
  const [openQuestion, setOpenQuestion] = useState(false);

  const [questions, setQuestions] = useState([]);

  useEffect(() => {
    if (assessment?.questions?.length) {
      setQuestionLoading(true);

      const fetchData = async (qeusId) => {
        const docRef = doc(db, "questionLibrary", qeusId);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const data = { id: docSnap.id, ...docSnap.data() };
          setQuestions((prev) => [...prev, data]);
        } else {
          const customDocRef = doc(db, "customQuestionLibrary", qeusId);
          const customDocSnap = await getDoc(customDocRef);

          if (customDocSnap.exists()) {
            const data = { id: customDocSnap.id, ...customDocSnap.data() };
            setQuestions((prev) => [...prev, data]);
          }
        }
      };

      assessment.questions.forEach(fetchData);

      setQuestionLoading(false);
    }
  }, [assessment]);

  const [mcqQuestions, setMcqQuestions] = useState(0);
  const [codingQuestions, setCodingQuestions] = useState(0);
  const [subjectiveQuestions, setSubjectiveQuestions] = useState(0);
  useEffect(() => {
    let mcq = 0;
    let coding = 0;
    let subjective = 0;
    questions?.map((ques) => {
      if (ques?.type === "mcq") {
        mcq = mcq + 1;
      } else if (ques?.type === "subjective") {
        subjective = subjective + 1;
      } else if (ques?.type === "coding") {
        coding = coding + 1;
      }
      setMcqQuestions(mcq);
      setCodingQuestions(coding);
      setSubjectiveQuestions(subjective);
      return "";
    });
  }, [questions]);

  return (
    <>
      <div className="space-y-2">
        {loading ? (
          <div className=" space-y-3">
            <div className={`w-80 h-6 ${loadingStyle}`} />
            <div className="space-y-1">
              <div className={`w-52 h-6 ${loadingStyle}`} />
              <div className={`w-52 h-6 ${loadingStyle}`} />
              <div className={`w-52 h-6 ${loadingStyle}`} />
            </div>
          </div>
        ) : (
          <>
            <h2 className="text-lg font-medium">
              Assessment Duration : {Math.floor(assessment?.duration / 60)} mins{" "}
              {assessment?.duration % 60 === 0
                ? ""
                : `${assessment?.duration % 60} Sec`}
            </h2>
            <ul className="list-disc list-inside space-y-1">
              {mcqQuestions && (
                <li className="font-medium">{mcqQuestions} MCQs</li>
              )}
              {subjectiveQuestions ? (
                <li className="font-medium">
                  {subjectiveQuestions} Subjective Questions
                </li>
              ) : (
                ""
              )}
              {codingQuestions ? (
                <li>{codingQuestions} Coding Questions</li>
              ) : (
                ""
              )}
            </ul>
          </>
        )}
        <div>
          <div className="flex w-full overflow-x-auto py-5 pl-5 bg-[#E9EAEB] font-medium text-sm text-black md:rounded-t-xl">
            <div className="w-[50px]"></div>
            {columnList.map((column) => {
              const width = column.open ? column.width : "0";
              const opacity = column.open ? "1" : "0";
              return (
                <div
                  key={column.name}
                  className="flex items-center gap-1 transition-all duration-200"
                  style={{ width, opacity }}
                >
                  {column.open && (
                    <div className="flex items-center">
                      <span>{column.label}</span>
                      {column.sort && (
                        <div
                          className="ml-1 cursor-pointer"
                          // onClick={() => {
                          //   setSortValue({
                          //     fieldName: column.name,
                          //     fieldValue: column.sortType
                          //       ? column.sortType
                          //       : "asc",
                          //   });
                          //   columnList[index].sortType =
                          //     column.sortType === ""
                          //       ? "desc"
                          //       : column.sortType === "desc"
                          //       ? "asc"
                          //       : "desc";
                          //   columnList.map((_, i) => {
                          //     if (index !== i) {
                          //       columnList[i].sortType = "";
                          //     }
                          //     return "";
                          //   });
                          // }}
                        >
                          {/* {column.sortType === "desc" ? (
                            <IoIosArrowUp className="w-4 h-4" />
                          ) : column.sortType === "asc" ? (
                            <IoIosArrowDown className="w-4 h-4" />
                          ) : (
                            <LuChevronsUpDown className="w-4 h-4" />
                          )} */}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              );
            })}
          </div>
          <div className="relative h-full overflow-hidden">
            {loading || questionLoading ? (
              <div className="text-center font-semibold text-xl text-gray-400 mt-4">
                Loading...
              </div>
            ) : questions?.length === 0 ? (
              <div className="text-center font-semibold text-xl text-gray-400 mt-4">
                No Questions Added Here
              </div>
            ) : (
              questions?.map((question, index) => (
                <QuesRow
                  key={questions.id}
                  index={index}
                  columnList={columnList}
                  question={question}
                  openQuestion={openQuestion}
                  setOpenQuestion={setOpenQuestion}
                />
              ))
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Preview;
