import React, { useEffect, useRef, useState } from "react";
import { Button, TextInput, UploadResume } from "../../components";
import { auth, db } from "../../config/firebase";
import { updateProfile } from "firebase/auth";
import { doc, updateDoc } from "firebase/firestore";
import { useAuthState } from "react-firebase-hooks/auth";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getMetadata, getStorage, ref } from "firebase/storage";

const Details = ({ setStep, userDb }) => {
  const [userAuthState] = useAuthState(auth);
  const [loading, setLoading] = useState(false);
  // console.log(userDb)

  const name = useRef("");
  const location = useRef("");
  const email = useRef("");
  const phone = useRef("");
  const [resumeUrl, setResumeUrl] = useState(null);
  const [preUploadedResume, setPreUploadedResume] = useState();

  const [dragging, setDragging] = useState(false);

  useEffect(() => {
    const setInitailDetails = async () => {
      if (userAuthState) {
        name.current.value = auth ? auth?.currentUser?.displayName : "";
        email.current.value = auth ? auth?.currentUser?.email : "";
        phone.current.value = auth
          ? auth?.currentUser?.phoneNumber?.replace("+91", "")
          : "";
        location.current.value = userDb?.location ? userDb?.location : "";

        if (userDb?.resumeUrl) {
          const storage = getStorage();
          const storageRef = ref(storage, userDb?.resumeUrl);
          getMetadata(storageRef)
            .then((metadata) => {
              setPreUploadedResume(metadata);
            })
            .catch((error) => {
              console.error(`Error getting file metadata: ${error}`);
            });
        }
      }
    };
    setInitailDetails();
  }, [userAuthState, userDb]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (name.current.value !== auth?.currentUser?.displayName) {
      try {
        await updateProfile(auth.currentUser, {
          displayName: name.current.value,
        });
      } catch (error) {
        console.log(error);
      }
    }

    try {
      await updateDoc(doc(db, "candidateProfile", userDb.id), {
        name: name.current.value,
        location: location.current.value,
        resumeUrl,
      });
      toast.success("Success! Your details have been updated.");
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  return (
    <form
      className="flex flex-col gap-6"
      onSubmit={handleSubmit}
      onDragOver={() => setDragging(true)}
      onDrop={() => setDragging(false)}
    >
      <div className="relative border-2 rounded-lg py-5 px-10 flex flex-col gap-4">
        <div className="absolute top-[-10px] left-5 text-sm bg-white px-2">
          Personal Details
        </div>
        <div className="grid md:grid-cols-2 gap-y-4 gap-x-8">
          <div className="flex flex-col w-full">
            <label className="font-medium text-xs leading-[12px] mb-2">
              Full Name*
            </label>
            <TextInput
              type="text"
              placeholderText="Your Full Name"
              inputName="name"
              isRequired={true}
              inputRef={name}
            />
          </div>
          <div className="flex flex-col w-full">
            <label className="font-medium text-xs leading-[12px] mb-2">
              Phone Number*
            </label>
            <TextInput
              type="text"
              placeholderText="Phone Number"
              inputName="phone"
              innerLabel="+91"
              inputRef={phone}
              isDisabled={true}
            />
          </div>
          <div className="flex flex-col w-full">
            <label className="font-medium text-xs leading-[12px] mb-2">
              Email Id*
            </label>
            <TextInput
              type="email"
              placeholderText="Enter your email"
              inputName="email"
              inputRef={email}
              isDisabled={true}
            />
          </div>
          <div className="flex flex-col w-full">
            <label className="font-medium text-xs leading-[12px] mb-2">
              Location
            </label>
            <TextInput
              type="text"
              placeholderText="City, State, Country"
              inputName="location"
              inputRef={location}
            />
          </div>
        </div>
      </div>
      <div className="border-2 rounded-lg py-5 px-10 flex flex-col gap-4">
        {preUploadedResume ? (
          <div>
            <div className="flex items-center gap-2">
              <span className="font-semibold text-[#777]">
                Uploaded Resume -{" "}
              </span>
              <span className="text-sm font-medium">
                {preUploadedResume?.name}
              </span>
            </div>
            <div className="my-3 w-fit py-1 px-4 cursor-pointer border-2 border-gray-400 rounded-lg hover:bg-gray-200 font-semibold text-sm" onClick={() => setPreUploadedResume()}>
              Update Resume
            </div>
          </div>
        ) : (
          <div className="md:w-1/2">
            <UploadResume
              setResumeUrl={setResumeUrl}
              dragging={dragging}
              setDragging={setDragging}
            />
          </div>
        )}
      </div>

      <div className="flex w-full items-center justify-end gap-8">
        <div className="flex gap-4">
          <Button
            type="submit"
            version="primary"
            text={loading ? "Please Wait" : "Save"}
            width="160px"
            isDisabled={loading}
          />
          <Button
            version="secondary"
            handleClick={() => setStep((prev) => prev + 1)}
            text="Next"
            width="160px"
            isDisabled={loading}
          />
        </div>
      </div>
    </form>
  );
};

export default Details;
