import React, { useState, useEffect } from "react";
import axios from "axios";
import { LuRefreshCw } from "react-icons/lu";
import { MdOutlineDelete } from "react-icons/md";
import UploadLogo from "./register/UploadLogo";
import { Timestamp, updateDoc, doc } from "firebase/firestore";
import { AiFillCloseSquare } from "react-icons/ai";
import { updatePassword } from "firebase/auth";
import { signInWithEmailAndPassword } from "firebase/auth";
import { reauthenticateWithCredential } from "firebase/auth";
import { EmailAuthProvider } from "firebase/auth";
// import promptForCredentials

import { MdClose } from "react-icons/md";
import { onSnapshot } from "firebase/firestore";
import { collection } from "firebase/firestore";
import { query, where } from "firebase/firestore";
// import { doc } from "firebase/firestore";
import { db } from "../../config/firebase";
import { FaEdit } from "react-icons/fa";
import { auth } from "../../config/firebase";
import { TextInput, Button } from "../../components";
import { PiUsersFill } from "react-icons/pi";
import { PiUserFill } from "react-icons/pi";

const AdminProfile = ({ admin }) => {
  // const [name, setName] = useState(adminName);
  // const [email, setEmail] = useState(adminEmail);
  const [logoUrl, setLogoUrl] = useState("");
  // const [companyDetail, setCompanyDetail] = useState({});
  const [editCompanyDetail, setEditCompanyDetail] = useState(false);
  const [formDetail, setFormDetail] = useState({});
  const [editPersonalDetail, setEditPersonalDetail] = useState(false);
  const [currentPassword,setCurrentPassword] = useState('')
  const [newPassword,setNewPassword] = useState('')

  // useEffect(()=>{
  //   const updateProfileLogo = async ()=>{
  //     try {
  //       const documentRef = doc(db, "adminProfile", formDetail.id);
  //       await updateDoc(documentRef,{logoUrl:logoUrl?logoUrl:""});
  //       console.log("doc Update work");
  //     } catch (error) {
  //       console.log(error.message);
  //     }

  //   }
  //   updateProfileLogo()
  // },[logoUrl])
  // useEffect(() => {
  //   if (admin) {
  //     setformDetail({
  //       companyName: admin.companyName,
  //       companyEmail: admin.companyEmail,
  //       employeeSize: admin.employeeSize,
  //       companyUrl: admin.companyUrl,
  //       accountType: admin.accountType,
  //       logoUrl:admin.logoUrl
  //     });
  //     // setLogoUrl(admin?.logoUrl);
  //     setPersonalDetail({
  //       contactPersonFirstName: admin.contactPersonFirstName,
  //       contactPersonLastName: admin.contactPersonLastName,
  //       phoneNumber: admin.phoneNumber,
  //       role: admin.role
  //     });
  //   }
  // }, [admin]);

  // const [cpass, setCpass] = useState();
  // const [npass, setNpass] = useState();
  // const [rpass, setRpass] = useState();

  // const [error, setError] = useState();
  // const [success, setSuccess] = useState();
  // const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchDocData = async () => {
      // const q = query(
      //   collection(db, "adminProfile"),
      //   where("uid", "==", auth.currentUser.uid)
      // );

      // const snapShot = onSnapshot(q, (querySnapshot) => {
      //   querySnapshot.forEach((doc) => {
      // const fetchData = { ...doc.data(), id: querySnapshot.docs[0].id };
      setFormDetail({
        companyName: admin.companyName,
        companyEmail: admin.companyEmail,
        employeeSize: admin.employeeSize,
        companyUrl: admin.companyUrl,
        accountType: admin.accountType,
        logoUrl: admin.logoUrl,
        contactPersonFirstName: admin.contactPersonFirstName,
        contactPersonLastName: admin.contactPersonLastName,
        phoneNumber: admin.phoneNumber,
        role: admin.role,
        id: admin.id
      });
      setLogoUrl(admin?.logoUrl);
      // });
      // });
    };
    // const timeoutId = setTimeout(() => {

    // }, 1000);
    // return () => {
    //   clearTimeout(timeoutId);
    // };

    admin?.uid && fetchDocData();
  }, [admin]);

  // const updatePassword = async () => {
  //   // setError("");
  //   // setSuccess("");
  //   // if (cpass && npass && rpass) {
  //   //   // console.log(cpass);
  //   //   if (npass === rpass) {
  //   //     setLoading(true);
  //   //     const data = `${admin?.companyEmail}|${cpass}|${npass}`;
  //   //     const response = await axios.post(
  //   //       process.env.REACT_APP_UPDATE_PASSWORD,
  //   //       data
  //   //     );
  //   //     if (response.data.status === "success") {
  //   //       setSuccess("Your Password has been updated");
  //   //     } else if (response.data.status === "passError") {
  //   //       setError("Corrent Password is Incorrect");
  //   //     }
  //   //     setLoading(false);
  //   //   } else {
  //   //     setError("Confirm Password not matched");
  //   //   }
  //   // } else {
  //   //   setError("Please fill all the password fields.");
  //   // }
  // };
  const updateDetail = (e) => {
    e.preventDefault();
    setFormDetail({ ...formDetail, [e.target.name]: e.target.value });
  };

  const updateFormDetail = async (e) => {
    e.preventDefault();

    const uploadFirebaseData = {
      companyName: formDetail.companyName,
      companyEmail: formDetail.companyEmail,
      employeeSize: formDetail.employeeSize,
      companyUrl: formDetail.companyUrl,
      accountType: formDetail.accountType,
      logoUrl: logoUrl ? logoUrl : "",
      contactPersonFirstName: formDetail.contactPersonFirstName,
      contactPersonLastName: formDetail.contactPersonLastName,
      phoneNumber: formDetail.phoneNumber,
      role: formDetail.role,
      modifiedAt: Timestamp.now()
    };

    try {
      const documentRef = doc(db, "adminProfile", formDetail.id);
      await updateDoc(documentRef, uploadFirebaseData);
      console.log("doc Update");
    } catch (error) {
      console.log(error.message);
    }

    // console.log(formDetail)
  };

 

  // const updatePersonalDetail = async (e) => {
  //   e.preventDefault();
  //   try {
  //     const documentRef = doc(db, "adminProfile", formDetail.id);
  //     await updateDoc(documentRef, {
  //       ...formDetail,
  //       modifiedAt: Timestamp.now()
  //     });
  //     console.log("doc Update");
  //   } catch (error) {
  //     console.log(error.message);
  //   }
  // };
  // console.log(admin);
  // console.log(personalDetail);
  // console.log(logoUrl);
  // console.log(formDetail)
  return (
    <div className="main-container">
      <div className="grid grid-cols-2 gap-1 card w-full h-full md:h-[90vh] mx-auto md:ml-20 mt-2 flex flex-col md:flex-row sm:px-4 lg-px-20 gap-5 py-4">
        {/* first grid div */}
        <div className="px-10">
          <div className="flex items-center gap-8 w-full h-fit">
            <UploadLogo
              url={logoUrl}
              setUrl={setLogoUrl}
              uploadedLogoUrl={formDetail?.logoUrl}
              adminDocId={formDetail?.id}
            />
          </div>
          <div className="flex mt-4 gap-3">
            <div className="text-xl font-semibold ">Company Details </div>
            <div
              className="text-xl pt-1 cursor-pointer "
              onClick={() => {
                setEditCompanyDetail(!editCompanyDetail);
                setEditPersonalDetail(false);
              }}
            >
              {editCompanyDetail ? (
                <AiFillCloseSquare />
              ) : (
                <FaEdit             
                />
              )}
            </div>
          </div>
          <div className="mt-4">
            <div className="text-xs font-semibold">
              Company Name<sup>*</sup>
            </div>
            <div className="w-full h-[40px] p-1">{formDetail.companyName}</div>
          </div>
          <div className="mt-2 ">
            <div className="text-xs font-semibold ">
              Official Email Id<sup>*</sup>{" "}
            </div>
            <div className="w-full h-[40px] p-1">{formDetail.companyEmail}</div>
          </div>
          <div className="mt-2 ">
            <div className="text-xs font-semibold">
              Company Employee Size<sup>*</sup>
            </div>

            {editCompanyDetail ? (
              <TextInput
                type="select"
                placeholderText="Select Employee Size"
                // inputValue={formDetail.companyName}
                inputName="employeeSize"
                isRequired={true}
                selectOptions={[
                  "0-1 Employees",
                  "2-10 Employees",
                  "11-50 Employees",
                  "51-200 Employees",
                  "201-500 Employees",
                  "501-1000 Employees",
                  "1000+ Employees"
                ]}
                inputValue={formDetail.employeeSize}
                handleChange={(e) => updateDetail(e)}
              />
            ) : (
              <div className="w-full h-[40px] p-2">
                {formDetail.employeeSize}
              </div>
            )}
          </div>

          <div className="mt-2 ">
            <div className="text-xs font-semibold ">
              Company Website <sup>*</sup>{" "}
            </div>
            {editCompanyDetail ? (
              <TextInput
                type="text"
                inputName="companyUrl"
                placeholderText="eg.www.exampleUrl.com"
                inputValue={formDetail.companyUrl}
                handleChange={(e) => updateDetail(e)}
              />
            ) : (
              <div className="w-full h-[40px] p-2">{formDetail.companyUrl}</div>
            )}
          </div>
          <div className="mt-2">
            <div className="text-xs font-semibold ">Choose Account Type</div>
            {editCompanyDetail ? (
              <TextInput
                type="select"
                placeholderText="Select Account Type"
                // inputValue={formDetail.companyName}
                inputName="accountType"
                isRequired={true}
                selectOptions={["solo", "company"]}
                inputValue={formDetail.accountType}
                handleChange={(e) => updateDetail(e)}
              />
            ) : (
              <div className="w-full h-[40px] p-2">
                {formDetail.accountType}
              </div>
            )}
          </div>
          <div className="mt-3">
            {editCompanyDetail ? (
              <div className="flex gap-3">
                <Button
                  text="Cancel"
                  version="secondary"
                  handleClick={() => setEditCompanyDetail(false)}
                />
                <Button text="Save" handleClick={updateFormDetail} />
              </div>
            ) : (
              ""
            )}
          </div>
        </div>

        {/* second grid div */}

        <div className="px-10">
          {/* 
        <div className="mt-2 ">
            <div className="text-xs font-semibold ">
              Official Email Id<sup>*</sup>{" "}
            </div>
            <div className="w-full h-[40px] p-1">
              {companyDetail.companyEmail}
            </div>
          </div> */}
          <div className="flex mt-4 gap-3">
            <div className="text-xl font-semibold ">Contact Details </div>
            <div className="text-xl pt-1 cursor-pointer"  onClick={() => {
                  setEditPersonalDetail(!editPersonalDetail);
                  setEditCompanyDetail(false);
                }}>
            {editPersonalDetail ? (
                <AiFillCloseSquare />
              ) : (
                <FaEdit             
                />
              )}
              {/* <FaEdit
                // className="cursor-pointer"
                
              />{" "} */}
            </div>
          </div>
          <div className="mt-2 ">
            <div className="text-xs font-semibold">
              Contact Person first Name<sup>*</sup>
            </div>

            {editPersonalDetail ? (
              <TextInput
                placeholderText="eg John"
                inputName="contactPersonFirstName"
                inputValue={formDetail.contactPersonFirstName}
                handleChange={(e) => updateDetail(e)}
              />
            ) : (
              <div className="w-full h-[40px] p-2">
                {formDetail.contactPersonFirstName}
              </div>
            )}
          </div>

          <div className="mt-2 ">
            <div className="text-xs font-semibold ">
              Contact Person Last Name<sup>*</sup>{" "}
            </div>
            {editPersonalDetail ? (
              <TextInput
                type="text"
                placeholderText="eg Doe"
                inputName="contactPersonLastName"
                inputValue={formDetail.contactPersonLastName}
                handleChange={(e) => updateDetail(e)}
              />
            ) : (
              <div className="w-full h-[40px] p-2">
                {formDetail.contactPersonLastName}
              </div>
            )}
          </div>
          <div className="mt-2">
            <div className="text-xs font-semibold ">
              Contact Person Phone Number
            </div>
            {editPersonalDetail ? (
              <TextInput
                type="number"
                inputName="phoneNumber"
                placeholderText="Enter your phone number"
                inputValue={formDetail.phoneNumber}
                handleChange={(e) => updateDetail(e)}
              />
            ) : (
              <div className="w-full h-[40px] p-2">
                {formDetail.phoneNumber}
              </div>
            )}
          </div>
          <div className="mt-2">
            <div className="text-xs font-semibold ">Role</div>
            {editPersonalDetail ? (
              <TextInput
                type="text"
                placeholderText="eg.HR Executive"
                inputName="role"
                inputValue={formDetail.role}
                handleChange={(e) => updateDetail(e)}
              />
            ) : (
              <div className="w-full h-[40px] p-2">{formDetail.role}</div>
            )}
          </div>
          <div className="mt-3">
            {editPersonalDetail ? (
              <div className="flex gap-3">
                <Button
                  text="Cancel"
                  version="secondary"
                  handleClick={() => setEditPersonalDetail(false)}
                />
                <Button text="Save" handleClick={updateFormDetail} />
              </div>
            ) : (
              ""
            )}
          </div>


         
     

        </div>

        {/* <div className="w-full md:w-2/5 h-full py-5 px-10 flex flex-col gap-6"> */}
        {/* <h1 className="heading2">Profile</h1> */}
        {/* <div className="flex items-center gap-8 w-full h-fit"> */}
        {/* <div className="h-40 w-40">
              {admin?.logoUrl ? (
                <img
                  src={admin?.logoUrl}
                  alt="admin img"
                  className="border-2 rounded-xl"
                />
              ) : (
                <img src="/img/adminProfilebig.png" alt="admin img" />
              )}
            </div> */}
        {/* <div className="flex flex-col items-center justify-center gap-3">
              {admin?.logoUrl ? (
                <>
                  <button className="flex items-center gap-2 rounded-md py-1 px-4 shadow-md font-medium text-sm text-violet-500 border-2 border-violet-300">
                    <LuRefreshCw className="w-4 h-4" /> Change Logo
                  </button>
                  <button className="flex items-center gap-2 rounded-lg py-1 px-4  font-medium text-sm text-red-400">
                    <MdOutlineDelete className="w-5 h-5" /> Remove Logo
                  </button>
                </>
              ) : (
                <button className="">Add Logo</button>
              )}
            </div> */}
        {/* </div> */}
        {/* <div className="w-full text-sm text-[#666] flex flex-col gap-6">
            <div className="flex flex-col gap-2 disabled-input">
              <label className="font-semibold text-base">Company Name</label>
              <TextInput type="text" inputValue={admin?.companyName} />
            </div>
            <div className="flex flex-col gap-2 disabled-input">
              <label className="font-semibold text-base">Company Email</label>
              <TextInput type="text" inputValue={admin?.companyEmail} />
            </div>
            <div className="flex flex-col gap-2 disabled-input">
              <label className="font-semibold text-base">Company URL</label>
              <TextInput type="text" inputValue={admin?.companyUrl} />
            </div>
          </div> */}
        {/* </div> */}
        {/* <div className="w-full md:w-3/5 h-full py-5 px-10 flex flex-col gap-6"> */}
        {/* <div className=" flex flex-col gap-2"> */}
        {/* <h1 className="heading2">Change Password</h1> */}
        {/* <div className="w-full lg:w-2/3 text-sm text-[#666] flex flex-col gap-4">
              <div>
                <label className="mb-2">Current password</label>
                <TextInput
                  type="password"
                  InputName="currentPassword"
                  inputValue={cpass}
                  handleChange={(e) => setCpass(e.target.value)}
                  placeholderText="Your current password"
                />
              </div>
              <div>
                <label className="mb-2">New password</label>
                <TextInput
                  type="password"
                  InputName="currentPassword"
                  inputValue={npass}
                  handleChange={(e) => setNpass(e.target.value)}
                  placeholderText="Your new password"
                />
              </div>
              <div>
                <label className="mb-2">Confirm password</label>
                <TextInput
                  type="password"
                  InputName="currentPassword"
                  inputValue={rpass}
                  handleChange={(e) => setRpass(e.target.value)}
                  placeholderText="Re-enter your password"
                />
              </div>
            </div> */}
        {/* <div className="mt-3">
              {error && (
                <div className="text-xs mb-4 text-red-600">{error}</div>
              )}
              {success && (
                <div className="text-xs mb-4 text-green-600">{success}</div>
              )}
              {loading ? (
                <div className="text-[#ccc] font-semibold">Loading ...</div>
              ) : (
                <Button
                  text="Update Password"
                  width="12rem"
                  handleClick={updatePassword}
                />
              )}
            </div> */}
        {/* </div> */}
        {/* </div> */}
      </div>
    </div>
  );
};

export default AdminProfile;
