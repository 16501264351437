import React, { useEffect, useState } from "react";
import { LuClipboard } from "react-icons/lu";
import { LuClipboardCheck } from "react-icons/lu";
import { FaRegShareFromSquare } from "react-icons/fa6";
import { Tooltip } from "react-tooltip";

const JobCreatedModule = ({ step1Data, applyLink ,editJobData}) => {
  const [link, setLink] = useState("");
  useEffect(
    () => setLink(`${process.env.REACT_APP_URL}${applyLink}`),
    [applyLink]
  );
  const [copied, setCopied] = useState(false);
  const copyLink = async () => {
    try {
      await navigator.clipboard.writeText(link);
      setCopied(true);
      setTimeout(() => setCopied(false), 3000);
    } catch (err) {
      console.error("Failed to copy: ", err);
    }
  };

  const shareLink = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: "Link to Apply for - " + step1Data?.positionTitle,
          url: link,
        });
      } catch (error) {
        console.log("Error sharing link: ", error);
      }
    } else {
      // fallback for browsers that don't support the Web Share API
      console.log("Web Share API not supported");
    }
  };

  return (
    <div className=" flex flex-col items-center justify-center gap-5">
      <div className="flex items-center justify-center bg-green-600 text-white w-16 h-16 rounded-full">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={2.5}
          stroke="currentColor"
          className="w-8"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="m4.5 12.75 6 6 9-13.5"
          />
        </svg>
      </div>
      <h1 className="text-xl font-semibold">  {editJobData?"Job Updated Successfully":"Job Created Successfully"} </h1>
      <div className="flex flex-col gap-1 items-center">
        <span className="">job id - {step1Data?.jobOpeningId}</span>
        <span className="font-medium text-xl">{step1Data?.positionTitle}</span>
        <span>
          Target Date :{" "}
          {new Date(step1Data?.targetDate)?.toLocaleDateString("en-in", {
            day: "numeric",
            month: "short",
            year: "numeric",
          })}
        </span>
        <span>Number of Positions : {step1Data?.numberOfPosition}</span>
      </div>
      <div>
        <span>Hiring Manager : {step1Data?.departmentManager}</span>
      </div>
      <div className="flex w-[30rem]">
        <input
          className="w-full p-3 pr-0 text-sm border-2 border-gray-400 rounded-l-lg focus:outline-0"
          placeholder="jobLink"
          // disabled = {true}
          value={link}
        />
        <button
          data-tooltip-id="copyTooltip"
          className={`flex items-center justify-center gap-1 px-3 border-2 border-x-0
                        ${
                          copied
                            ? "bg-green-600 hover:bg-green-600 text-white border-green-600"
                            : "bg-gray-300 border-gray-400 hover:bg-gray-300/90"
                        }`}
          onClick={copyLink}
        >
          {copied ? (
            <LuClipboardCheck className="w-6 h-6" />
          ) : (
            <LuClipboard className="w-6 h-6" />
          )}
        </button>
        <Tooltip id="copyTooltip" className="text-xs">
          {copied ? "Copied" : "Copy Link"}
        </Tooltip>
        <button
          data-tooltip-id="shareTooltip"
          className="flex items-center justify-center gap-1 px-3 text-[#fff] rounded-r-lg bg-[#8A24F1] hover:bg-[#8A24F1]/90"
          onClick={shareLink}
        >
          <FaRegShareFromSquare className="w-6 h-6" />
        </button>
        <Tooltip id="shareTooltip" className="text-xs">
          Share Link
        </Tooltip>
      </div>
    </div>
  );
};

export default JobCreatedModule;
