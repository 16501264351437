import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { motion } from "framer-motion";
import axios from "axios";
import {
  QuerySnapshot,
  getDoc,
  query,
  onSnapshot,
  collection,
  where
} from "firebase/firestore";
import { db } from "../../../config/firebase";

import SearchResults from "./SearchResults";
import NotificationPanel from "./NotificationPanel";
import ProfileMenu from "./ProfileMenu";
import Popup from "../../popup/Popup";
import ChangePassword from "../../../admin/adminPanel/ChangePassword";

const Topbar3 = ({
  admin,
  // candidates,
  // applications,
  page,
  notifications,
  sideBarOpen,
  setSideBarOpen,
  // setUpdateNotifications,
  notificationsLoading,
  isprofileMenuOpen,
  setIsprofileMenuOpen,
  isNotificationOpen,
  setIsNotificationOpen,
  isSearchOpen,
  setSearchOpen
}) => {
  const navigate = useNavigate();
  const location = useLocation().pathname;
  const params = new URLSearchParams(window.location.search);
  const listName = params.get("list");

  const [searchInput, setSearchInput] = useState("");
  const [openPasswordChange, setOpenPasswordChange] = useState(false);

  // const searchAssessmentData = candidates
  //   ?.filter((candidate) => candidate.Attempt !== -1)
  //   ?.filter(
  //     (candidate) =>
  //       candidate?.Name?.toLowerCase().includes(searchInput.toLowerCase()) ||
  //       candidate?.Email?.toLowerCase().includes(searchInput.toLowerCase())
  //   );
  //   const searchApplicationData = applications
  //     ?.filter((application) =>
  //       application?.name?.toLowerCase().includes(searchInput.toLowerCase()) ||
  //       application?.email?.toLowerCase().includes(searchInput.toLowerCase())
  // );

  //toggle search panel
  const handleSearch = (e) => {
    setSearchInput(e.target.value);
    setIsNotificationOpen(false);
    setIsprofileMenuOpen(false);
    setSearchOpen(true);
  };
  useEffect(() => {
    searchInput === "" && setSearchOpen(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchInput]);

  //toggle profile Menu
  const toggleProfileMenu = () => {
    setSearchOpen(false);
    setIsNotificationOpen(false);
    setIsprofileMenuOpen(!isprofileMenuOpen);
  };

  //toggle Notification
  const toggleNotification = () => {
    setSearchOpen(false);
    setIsprofileMenuOpen(false);
    setIsNotificationOpen(!isNotificationOpen);
  };

  // handle notifications

  const notificationSchema = [
    {
      id: "auto generated1",
      notificationFor: {
        for: "admin or candidate",
        id: "admin or candidate id"
      },
      type: {
        type: "exam submit- jobApplied -jobStatusChange",
        id: "candidate profile id"
      },
      notificationBody: "string data here",
      createdAt: "timestamp",
      read: false,
      uid:"zcvzXYFptuVGfgVpDQhZIxAImh32"
    },
    {
      id: "auto generated2",
      notificationFor: {
        for: "admin or candidate",
        id: "admin or candidate id"
      },
      type: {
        type: "exam submit- jobApplied -jobStatusChange",
        id: "candidate profile id"
      },
      notificationBody: "string",
      createdAt: "timestamp",
      read: false,
      uid:"zcvzXYFptuVGfgVpDQhZIxAImh32"
    }, {
      id: "auto generated3",
      notificationFor: {
        for: "admin or candidate",
        id: "admin or candidate id"
      },
      type: {
        type: "exam submit- jobApplied -jobStatusChange",
        id: "candidate profile id"
      },
      notificationBody: "string tou have applied for assessment for today",
      createdAt: "timestamp",
      read: false,
      uid:"zcvzXYFptuVGfgVpDQhZIxAImh32"
    }
  ];
  // const [notifications, setNotifications] = useState(notificationSchema);

 






  // const [displayNotifications, setDisplayNotifications] = useState();
  // useEffect(() => {
  //   setDisplayNotifications(notifications);
  // }, [notifications]);

  // const markRead = (id) => {
  //   let sheetId = admin?.dataSheetId;
  //   let nid = id;
  //   let attemptData = `${sheetId}|${nid}`;
  //   let api = process.env.REACT_APP_NOTIFICATION_READ_API;
  //   axios.post(api, attemptData);
  //   setDisplayNotifications(
  //     displayNotifications?.filter((notification) => notification?.id !== id)
  //   );
  //   setIsNotificationOpen(false);
  //   setUpdateNotifications((prev) => prev + 1);
  // };

  // const clearNotifications = () => {
  //   const unread = notifications?.filter(
  //     (notification) => notification?.read !== 1
  //   );
  //   let sheetId = admin?.dataSheetId;
  //   let api = process.env.REACT_APP_NOTIFICATION_READ_API;
  //   unread.map((notification) => {
  //     let nid = notification.id;
  //     let attemptData = `${sheetId}|${nid}`;
  //     setDisplayNotifications([]);
  //     setUpdateNotifications((prev) => prev + 1);
  //     return axios.post(api, attemptData);
  //   });
  // };
  // console.log(admin);
  return (
    <div className="main-header py-2 h-[3.5rem] mx-auto md:ml-20 flex items-center justify-center sm:justify-between gap-2">
      <div className="flex h-full gap-4">
        <div
          className="flex md:hidden small-card h-full px-3 justify-center items-center text-[#555] cursor-pointer z-10"
          onClick={() =>
            sideBarOpen === "none"
              ? setSideBarOpen("open")
              : setSideBarOpen("none")
          }
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={2.5}
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokelineap="round"
              strokeLinejoin="round"
              d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
            />
          </svg>
        </div>

        <div className="hidden sm:flex items-center z-10">
          {(location === "/candidateProfile" ||
            location === "/quesUpload" ||
            location === "/newCandidate" ||
            location === "/newJob") && (
            <div
              onClick={() => {
                location === "/candidateProfile"
                  ? navigate(`/${listName}`)
                  : navigate(-1);
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                className="w-8 h-8 cursor-pointer"
              >
                <path
                  fillRule="evenodd"
                  d="M15 10a.75.75 0 01-.75.75H7.612l2.158 1.96a.75.75 0 11-1.04 1.08l-3.5-3.25a.75.75 0 010-1.08l3.5-3.25a.75.75 0 111.04 1.08L7.612 9.25h6.638A.75.75 0 0115 10z"
                  clipRule="evenodd"
                />
              </svg>
            </div>
          )}
          <h1 className="heading1">{page}</h1>
        </div>
      </div>

      <div className=" h-full flex items-center justify-end gap-2">
        <div className="flex small-card items-center px-3 z-10 h-full">
          <input
            type="text"
            onChange={handleSearch}
            value={searchInput}
            className="w-full border-none text-xs outline-none rounded-xl focus:ring-0 text-[#929292]"
            placeholder="Search Candidates"
          />
          <img src="/img/search.png" alt="search icon" />
        </div>

        <SearchResults
          isSearchOpen={isSearchOpen}
          // searchAssessmentData={searchAssessmentData}
          // searchApplicationData={searchApplicationData}
          setSearchOpen={setSearchOpen}
          setSearchInput={setSearchInput}
        />

        {/* Notification Icon */}
        <motion.div
          whileTap={{ scale: 0.9 }}
          className="notification-icon flex justify-center items-center px-3 h-full small-card cursor-pointer z-10"
          onClick={toggleNotification}
        >
          <img src="/img/bell.png" alt="notification-icon" />
          {notifications?.filter((notification) => notification?.read !== true)
            .length ? (
            <div className="absolute bg-[#EA4335] border-50 border-white text-white top-[0] right-1 p-1 h-4 w-auto flex justify-center items-center rounded-full text-xs">
              {
                notifications?.filter(
                  (notification) => notification?.read !== 1
                ).length
              }
            </div>
          ) : (
            ""
          )}
        </motion.div>

        <NotificationPanel
          // dataSheetId={admin?.dataSheetId}
          isNotificationOpen={isNotificationOpen}
          setIsNotificationOpen={setIsNotificationOpen}
          notifications={notifications}
          notificationsLoading={notificationsLoading}
          // markRead={markRead}
          // clearNotifications={clearNotifications}
        />

        {/* profile Icon */}
        <motion.div
          whileTap={{ scale: 0.9 }}
          // onBlur={()=> setIsprofileMenuOpen(false)}
          className="profile-icon z-10"
        >
          <img
            src={admin?.logoUrl ? admin?.logoUrl : "/img/adminProfile.png"}
            alt="profile"
            className=""
            onClick={toggleProfileMenu}
          />
        </motion.div>

        {
          openPasswordChange && (
            // <Popup>
            <ChangePassword setOpenPasswordChange={setOpenPasswordChange} />
          )
          // </Popup>
        }

        <ProfileMenu
          isprofileMenuOpen={isprofileMenuOpen}
          setIsprofileMenuOpen={setIsprofileMenuOpen}
          admin={admin}
          setOpenPasswordChange={setOpenPasswordChange}
          // handleLogout={handleLogout}
        />
      </div>
    </div>
  );
};

export default Topbar3;
