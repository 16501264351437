import React, { useState } from "react";

import { Topbar1 } from "../../components";
import SetupLeft from "./SetupLeft";
import SetupRight from "./SetupRight";

const Setup = ({ setisScreenSharing }) => {
  const steps = ["camera", "mic", "internet", "instruction"];
  const [step, setStep] = useState(0);

  const [hasCameraPermission, sethasCameraPermission] = useState();
  const [hasMicPermission, sethasMicPermission] = useState();
  const [isMicWorking, setisMicWorking] = useState();
  const [hasInternetConnection, setHasInternetConnection] = useState();

  return (
    <div className="">
      <div className="mb-10">
        <Topbar1 />
      </div>
      <div className="card flex items-center justify-center w-[95%] h-[90%] mx-auto my-5">
        <div className="w-[50%] h-full rounded-l-[25px] flex flex-col justify-center items-center gradientPrimary">
          <SetupLeft
            step={step}
            steps={steps}
            hasCameraPermission={hasCameraPermission}
            hasMicPermission={hasMicPermission}
            setisMicWorking={setisMicWorking}
            hasInternetConnection={hasInternetConnection}
            setHasInternetConnection={setHasInternetConnection}
          />
        </div>
        <div className="w-[50%] h-[70vh] flex flex-col">
          <div className="flex gap-2.5 my-12 ml-[10%]">
            {steps.map((stp, index) => (
              <div
                key={stp}
                className="h-[5px] w-[114px]"
                style={{
                  backgroundColor: `${step === index ? "#8A24F1" : "#E4E4E4"}`,
                }}
              />
            ))}
          </div>
          <SetupRight
            step={step}
            steps={steps}
            setStep={setStep}
            sethasCameraPermission={sethasCameraPermission}
            hasCameraPermission={hasCameraPermission}
            hasMicPermission={hasMicPermission}
            sethasMicPermission={sethasMicPermission}
            setisScreenSharing={setisScreenSharing}
            isMicWorking={isMicWorking}
            hasInternetConnection={hasInternetConnection}
          />
        </div>
      </div>
    </div>
  );
};

export default Setup;
