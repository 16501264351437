import React, { useEffect, useState } from "react";
import { FaRegClock } from "react-icons/fa6";
import { FaRegStar } from "react-icons/fa";
import { FaRegCircleCheck } from "react-icons/fa6";
const SmallCards = ({ applications }) => {
  const [cardDetails, setCardDetails] = useState(null);

  useEffect(() => {
    if (applications?.length > 0) {
      let allRating = [];
      let allResult = [];
      let allTime = [];

      for (let i = 0; i < applications?.length; i++) {
        for (let j = 0; j < applications[i].assessmentsFeedbacks?.length; j++) {
          allRating.push(applications[i].assessmentsFeedbacks[j].rating);
        }
        for (let j = 0; j < applications[i].assessmentsResults?.length; j++) {
          allResult.push(applications[i].assessmentsResults[j].result);
          allTime.push(applications[i].assessmentsResults[j].timeTaken);
        }
      }
      setCardDetails({
        averageFeedbackRating:
          allRating?.reduce((a, b) => a + b, 0) / allRating.length,
        averageTimeCompletion: Math.round(
          allTime.reduce((a, b) => a + b, 0) / allTime.length / 1000
        ),
        passingRate:
          (allResult.filter((data) => data === "pass").length /
            allResult.length) *
          100,
      });
    }
  }, [applications]);

  const cards = [
    {
      title: "Pass Rate",
      value: `${cardDetails?.passingRate} %`,
      icon: <FaRegCircleCheck className="w-7 h-7" />,
    },
    {
      title: "Average Time Completion",
      value: `${Math.floor(cardDetails?.averageTimeCompletion / 60)} min ${
        cardDetails?.averageTimeCompletion % 60
      } sec`,
      icon: <FaRegClock className="w-7 h-7" />,
    },
    {
      title: "Average Feedback Rating",
      value: `${cardDetails?.averageFeedbackRating} / 10`,
      icon: <FaRegStar className="w-7 h-7" />,
      postfix: "/ 10",
    },
  ];

  const LoadingSmallComponent = () => {
    return (
      <>
        <div className="card h-[4.5rem] sm:h-[5.5rem] w-full flex items-center gap-3 p-4">
          <div className="bg-[#666]/10 w-16 h-16 flex items-center justify-center rounded-xl animate-pulse" />
          <div className="flex flex-col justify-center h-full w-full gap-2 ">
            <div className="bg-[#666]/10 w-32 h-2/3 rounded-2xl animate-pulse"></div>
            <div className="bg-[#666]/10 w-60 h-1/3 rounded-2xl animate-pulse"></div>
          </div>
        </div>
      </>
    );
  };

  return (
    <div className="w-full grid md:grid-cols-2 lg:grid-cols-3 gap-3">
      {cards?.map(({ value, postfix, icon, title }) => (
        <>
          {!cardDetails ? (
            <LoadingSmallComponent />
          ) : (
            <div className="card h-[4.5rem] sm:h-[5.5rem] w-full flex items-center gap-4 p-4">
              <div className="bg-gray-200 w-16 h-16 flex items-center justify-center rounded-xl text-gray-500">
                {icon}
              </div>
              <div className="flex flex-col justify-center">
                <span className="font-semibold text-2xl">{value}</span>
                <span className="text-[#757575] text-sm font-semibold">
                  {title}
                </span>
              </div>
            </div>
          )}
        </>
      ))}
    </div>
  );
};

export default SmallCards;
