import { Timestamp } from 'firebase/firestore'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { doc,updateDoc } from 'firebase/firestore'
import { db } from '../../config/firebase'

const Notifications = ({notificationsLoading,notifications, schedules, schedulesLoading}) => {
  const [dayArray, setDayArray] = useState([])
  useEffect(() => {
    let arr = notifications?.map(notification => notification?.createdAt.toDate().toDateString())
    // console.log(arr)
    arr = arr?.filter((item,index) => arr.indexOf(item) === index);
    setDayArray(arr)
  },[notifications])
  

const markUnread = async (documentId)=>{
  const documentRef = doc(db, "notifications", documentId);
  try {
   await updateDoc(documentRef, { read: false });
  } catch (error) {}
}


  return (
    <div className='main-container'>
      <div className='card w-full mx-auto md:ml-20 mt-2 lg:h-[90vh] flex flex-col gap-3 overflow-hidden'>
        <div className="h-full px-8 py-4 pt-10 flex flex-col gap-4">
          <div className='flex flex-col gap-4 w-full h-full notification-wrapper overflow-y-auto scrollbar-none'>
            {notificationsLoading && schedulesLoading ? (
              <div className='flex flex-col gap-3'>
                <div className='bg-[#aaa]/10 w-[20%] h-[1.5rem] rounded-2xl'/>
                <div className='bg-[#aaa]/10 w-full h-[5rem] rounded-2xl'/>
                <div className='bg-[#bbb]/10 w-full h-[5rem] rounded-2xl'/>
                <div className='bg-[#ccc]/10 w-full h-[4rem] rounded-2xl'/>
              </div>
            ) : dayArray?.map(d => (
              <div key={d} className='flex flex-col gap-2'>
                <span className='capitalize text-sm font-medium'>
                  {d === new Date().toDateString() 
                    ? 'Today'
                    : new Date().getDate() - new Date(d).getDate() === 1 
                      && new Date().getMonth() === new Date(d).getMonth() 
                      && new Date().getFullYear() === new Date(d).getFullYear()
                        ? 'Yesterday'
                        : new Date(d)?.toLocaleString('en-in',{day :'numeric', month :'short', year :'numeric'})
                  }
                  
                </span>
                <ul>
                    {notifications
                      ?.filter(notification => notification?.createdAt.toDate().toDateString() === d)
                      ?.map(notification => {
                        const notificationType = notification?.notificaitonType.type ==="examSubmit" ?"candidateResults":"OnConsiderd"
                        // const candidateName = schedules?.filter((schedule) => schedule?.UID === notification?.uid)[0]?.Name
                        // console.log(notification?.createdAt)
                        return(
                          <div className='flex flex-col gap-2'>
                          {/* { candidateName &&} */}
                          <li className='relative' key={notification.id}>
                            <Link
                               to={`/${notificationType}?id=${notification?.notificaitonType.id}`}
                              className='absolute top-0 left-0 h-full w-full'
                              onClick={() => {                   
                                markUnread(notification.id)
                                }}
                            ></Link>
                            <div className='user-profile-inline'>
                              <div className='profile-pic'>
                                <img src="/img/adminProfile.png" alt="profile pic" />
                              </div>
                              <div className='profile-content'>
                              {notification?.notificaitonType.type ==="examSubmit"?notification?.notificaitonType.candidateName:"OtherName"} 
                                <span className='detail text-sm'>
                                {`${notification?.notificaitonType.type ==="examSubmit"?notification?.notificaitonType.candidateName:"OtherName"}  ${notification?.body}`}
                                </span>
                              </div>
                            </div>
                            <div className='right-item'>
                              <div className='time'>{`${notification?.createdAt?.toDate().getHours()<= 12 ? `${notification?.createdAt?.toDate().getHours()} : ${notification?.createdAt?.toDate().getMinutes()} AM`: `${notification?.createdAt?.toDate().getHours()-12}: ${notification?.createdAt?.toDate().getMinutes()}  PM `}   ` }</div>
                            </div>
                          </li>
                          </div>
                        )
                    })
                    }
                </ul>
              </div>
            ))}
            
          </div>
        </div>
      </div>
    </div>
  )
}

export default Notifications
