import { useState, react, useEffect } from "react";

import { Button, TextInput } from "../../components";
import SuccessFeedback from "../../components/popup/SuccessFeedback";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../config/firebase";

const ExamFeedback = ({ applicationDetails }) => {
  const [selectedrating, setselectedrating] = useState("9");
  const [selectedDefficultyLevel, setselectedDefficultyLevel] = useState("0");
  const [selectedImprovement, setselectedImprovement] = useState("0");
  const [other, setOther] = useState("");
  const [showSuccess, setShowSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  const improvement = [
    {
      id: "1",
      name: "Overall Service",
    },
    {
      id: "2",
      name: "Communication",
    },
    {
      id: "3",
      name: "Interview Structure",
    },
    {
      id: "4",
      name: "Interview Preparation",
    },
    {
      id: "5",
      name: "Candidate Experience",
    },
    {
      id: "6",
      name: "Feedback Process",
    },
    {
      id: "7",
      name: "Diversity And Inclusion",
    },
    {
      id: "8",
      name: "Technical Setup",
    },
    {
      id: "9",
      name: "Interview Questations",
    },
    {
      id: "10",
      name: "Other",
    },
  ];
  const difficultyLevel = [
    {
      id: "1",
      name: "easy",
      img: "easy.png",
    },
    {
      id: "2",
      name: "medium",
      img: "medium.png",
    },
    {
      id: "3",
      name: "hard",
      img: "hard.png",
    },
  ];

  const addFeedback = async () => {
    setLoading(true);

    // add data validations

    const feedbackData = {
      round: applicationDetails?.round ? applicationDetails?.round : 0,
      rating: selectedrating + 1,
      difficulty: difficultyLevel[selectedDefficultyLevel]?.name,
      improvement:
        selectedImprovement === 9
          ? other
          : improvement[selectedImprovement]?.name,
    };
    console.log(feedbackData);

    const candidateApplicationRef = doc(
      db,
      "candidateApplications",
      applicationDetails?.id
    );

    try {
      await updateDoc(candidateApplicationRef, {
        assessmentsFeedbacks: [
          ...(applicationDetails?.assessmentsFeedbacks
            ? applicationDetails?.assessmentsFeedbacks
            : []),
          feedbackData,
        ],
      });
    } catch (error) {
      console.log(error);
    } finally {
      setShowSuccess(true);
      setLoading(false);
    }
  };

  const closeSuccessModule = () => {
    window.location.href = "/";
  };

  if (showSuccess) {
    return <SuccessFeedback closeSuccessModule={closeSuccessModule} />;
  } else {
    return (
      <div className="w-[50%] h-[80%] overflow-y-scroll scrollable mx-auto p-8 flex card flex-col gap-2 my-3 justify-center items-center">
        <h1 className="font-semibold text-2xl capitalize mx-3">Feedback</h1>
        <p className="text-lg text-[#000] font-medium">Ratings</p>
        <div className="w-full flex flex-col gap-1">
          <div className="w-[100%] h-[4rem] justify-center flex">
            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((data) => {
              return (
                <div
                  key={data}
                  onClick={() => setselectedrating(data - 1)}
                  className={`flex flex-1 justify-center items-center border-2 cursor-pointer feedback-${data}
                    ${
                      selectedrating === data - 1
                        ? `font-semibold text-xl border-[#ccc]`
                        : `border-transparent text-[#888] font-medium`
                    }
                  `}
                >
                  {data}
                </div>
              );
            })}
          </div>
          <div className="w-[100%] h-[50] flex justify-between flex-row capitalize text-sm">
            <p className="text-[#919397]">Terrible</p>
            <p className="text-[#919397]">Great</p>
          </div>
        </div>

        <div className="w-full flex flex-col gap-1 mt-2">
          <p className="text-lg text-[#000] font-medium">Difficulty level</p>
          <div className="flex gap-4 capitalize">
            {difficultyLevel.map((data) => {
              return (
                <div onClick={() => setselectedDefficultyLevel(data.id - 1)}>
                  <img
                    src={`/img/${data.img}`}
                    className={`cursor-pointer ${
                      selectedDefficultyLevel !== data.id - 1 &&
                      "filter grayscale"
                    }`}
                    alt="data.name"
                  />
                </div>
              );
            })}
          </div>
        </div>

        <div className="w-full flex flex-col gap-1 mt-2">
          <p className="text-lg text-[#000] font-medium m-3">
            Tell us what can we improved?
          </p>

          <div className="grid  grid-cols-3">
            {improvement.map((data) => {
              return (
                <div
                  onClick={() => setselectedImprovement(data.id - 1)}
                  className={`font-medium text-xs leading-[12px] mb-2 m-1 justify-center p-5 rounded-3xl cursor-pointer
                              ${
                                selectedImprovement === data.id - 1
                                  ? "bg-[#5084D7] text-[#fff]"
                                  : "bg-[#eef]"
                              } 
                            `}
                >
                  {data.name}
                </div>
              );
            })}
          </div>
          {selectedImprovement === 9 && (
            <TextInput
              type="textbox"
              placeholderText="Other Suggestions"
              inputName="otherImprovements"
              isRequired={true}
              inputValue={other}
              handleChange={(e) => setOther(e.target.value)}
            />
          )}
        </div>
        <div className="flex items-center justify-end w-full">
          <Button
            text="Submit"
            width="10rem"
            handleClick={() => {
              addFeedback();
            }}
            loading={loading}
          />
        </div>
      </div>
    );
  }
};

export default ExamFeedback;
