import React, { useEffect, useState } from "react";
import { Button, Popup } from "../../components";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ATSlisting from "./ATSlisting";
import {
  Timestamp,
  addDoc,
  collection,
  doc,
  onSnapshot,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { fetchQuery } from "./fetchQuery";
import { db } from "../../config/firebase";
import { auth } from "../../config/firebase";
import Select from "react-select";
import Schedule from "./Schedule";
import scheduleEmailTemplate from "../../utils/emailTemplates/firstRoundSchedule";
import rescheduleEmailTemplate from "../../utils/emailTemplates/roundRescheduled";
import axios from "axios";
import { toast } from "react-toastify";
import { useAuthState } from "react-firebase-hooks/auth";

const ATSMain = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const urlparams = new URLSearchParams(location.search);
  const tabParam = urlparams.get("tab");
  const roundParam = urlparams.get("round");
  const paramFilterJobId = urlparams.get("jobId");

  const [userAuthState] = useAuthState(auth);
  const [tab, setTab] = useState("");
  const [roundName, setRoundName] = useState("");
  const [sortValue, setSortValue] = useState("");
  const [filterWithJobId, setFilterWithJobId] = useState(paramFilterJobId);

  useEffect(() => setTab(tabParam || "applied"), [tabParam]);

  const [loading, setLoading] = useState();

  const [candidates, setCandidates] = useState(null);

  const tabs = ["applied", "screened", "assessed", "interviewed", "offered"];

  const [showScheduleModule, setShowScheduleModule] = useState(false);

  // --------------fetch posted jobs list------------
  const [postedJobs, setPostedJobs] = useState([]);
  const [rounds, setRounds] = useState();
  useEffect(() => {
    const fetchJobData = () => {
      const q = query(
        collection(db, "jobsPosted"),
        where("companyName", "==", auth?.currentUser?.displayName)
      );
      const querySnapshot = onSnapshot(q, (querySnapshot) => {
        const jobsData = [];
        querySnapshot.forEach((doc) => {
          jobsData.push({ ...doc.data() });
        });
        let selectData = jobsData.map((item) => {
          const jobOpeningId = item.jobOpeningId;
          const jobPositions = item.positionTitle;
          return {
            jobId: jobOpeningId,
            value: jobPositions,
            label: jobPositions.charAt(0).toUpperCase() + jobPositions.slice(1),
          };
        });
        setPostedJobs(selectData);

        const roundData = jobsData
          .filter((job) => job.jobOpeningId === filterWithJobId)
          .map((job) => job.rounds)
          .flat();
        setRounds(roundData);
      });
      return querySnapshot;
    };
    userAuthState && fetchJobData();
  }, [filterWithJobId, userAuthState]);

  useEffect(() => {
    setRoundName(
      roundParam || rounds?.filter((r) => r.roundType === "assessment")[0]?.id
    );
  }, [roundParam, rounds]);

  // --------------fetch ats candidate list------------
  useEffect(() => {
    const fetchCandidateData = () => {
      setLoading(true);

      let q = fetchQuery(
        auth?.currentUser?.displayName,
        filterWithJobId,
        sortValue
      );

      if (tab) {
        if (tab === "screened") {
          q = query(q, where("currentRound.roundType", "==", "screening"));
        } else if (tab === "assessed") {
          q = query(q, where("currentRound.roundType", "==", "assessment"));
        } else if (tab === "interviewed") {
          q = query(q, where("isInterviewed", "==", true));
        } else if (tab === "offered") {
          q = query(q, where("isOffered", "==", true));
        }
      }

      try {
        onSnapshot(q, (querySnapshot) => {
          const data = querySnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setCandidates(data);
          setLoading(false);
        });
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    };
    userAuthState && fetchCandidateData();

    // if (tab === "assessed") {
    //   navigate(`/applicants?tab=assessed`);
    // }
  }, [filterWithJobId, tab, sortValue, navigate, userAuthState]);

  useEffect(() => {
    if (roundParam) {
      setCandidates((prev) =>
        prev.filter((cand) => cand?.currentRound?.id === roundParam)
      );
    }
  }, [roundParam, rounds, candidates]);

  const [candidateToSchedule, setCandidateToSchedule] = useState(null);
  const scheduleAssessment = async (scheduleDate, expDate) => {
    // add schedule data to db 'assessmentTests'
    const examCode = (Math.random() + 1).toString(36).substring(6);
    const scheduleData = {
      candidateId: candidateToSchedule?.candidate?.candidateProfileRef.id,
      applicationId: candidateToSchedule?.candidate?.id,
      jobId: candidateToSchedule?.candidate?.appliedJobId,
      candidateEmail: candidateToSchedule?.candidate?.email,
      companyName: candidateToSchedule?.candidate?.appliedAtCompany,
      examCode,
      scheduleDate: Timestamp.fromDate(scheduleDate),
      expDate: Timestamp.fromDate(expDate),
      attempted: false,
      createdAt: Timestamp.now(),
      updatedAt: Timestamp.now(),
    };

    const dateFormat = {
      day: "numeric",
      month: "long",
      year: "numeric",
    };
    const timeFormat = { hour: "numeric", minute: "2-digit", hour12: true };
    const emailDetails = {
      candidateName: candidateToSchedule?.candidate?.name,
      candidateEmail: candidateToSchedule?.candidate?.email,
      examCode: examCode,
      jobTitle: candidateToSchedule?.candidate?.appliedPosition,
      companyName: candidateToSchedule?.candidate?.appliedAtCompany,
      startDate: {
        date: scheduleDate?.toLocaleDateString("en-IN", dateFormat),
        time: scheduleDate?.toLocaleTimeString("en-IN", timeFormat),
      },
      expDate: {
        date: expDate?.toLocaleDateString("en-IN", dateFormat),
        time: expDate?.toLocaleTimeString("en-IN", timeFormat),
      },
    };
    try {
      await addDoc(collection(db, "assessmentTests"), scheduleData);
      //send email to candidate for schedule
      const { subject, body } = scheduleEmailTemplate(emailDetails);
      const emailData = `${candidateToSchedule?.candidate?.email}|${subject}|${body}`;
      await axios.post(process.env.REACT_APP_EMAIL_API, emailData);
    } catch (error) {
      console.log(error);
    } finally {
      setShowScheduleModule(false);
      toast.success("Success! The Assessment has been scheduled.");
    }
  };

  const [candidateToReschedule, setCandidateToReschedule] = useState();
  const rescheduleAssessment = async (scheduleDate, expDate) => {
    // update reschedule data to db
    const rescheduleData = {
      scheduleDate: Timestamp.fromDate(scheduleDate),
      expDate: Timestamp.fromDate(expDate),
      updatedAt: Timestamp.now(),
    };
    const rescheduleRef = doc(
      db,
      "assessmentTests",
      candidateToReschedule?.schedule?.id
    );

    const dateFormat = {
      day: "numeric",
      month: "long",
      year: "numeric",
    };
    const timeFormat = { hour: "numeric", minute: "2-digit", hour12: true };
    const emailDetails = {
      candidateName: candidateToReschedule?.candidate?.name,
      candidateEmail: candidateToReschedule?.candidate?.email,
      examCode: candidateToReschedule?.schedule?.examCode,
      companyName: candidateToReschedule?.candidate?.appliedAtCompany,
      startDate: {
        date: scheduleDate?.toLocaleDateString("en-IN", dateFormat),
        time: scheduleDate?.toLocaleTimeString("en-IN", timeFormat),
      },
      expDate: {
        date: expDate?.toLocaleDateString("en-IN", dateFormat),
        time: expDate?.toLocaleTimeString("en-IN", timeFormat),
      },
    };
    try {
      // update reschedule to db
      await updateDoc(rescheduleRef, rescheduleData);
      toast.success("Success! Assessment has been rescheduled.");
      // send email to candidate for reschedule
      const { subject, body } = rescheduleEmailTemplate(emailDetails);
      const emailData = `${candidateToReschedule?.candidate?.email}|${subject}|${body}`;
      await axios.post(process.env.REACT_APP_EMAIL_API, emailData);

      toast.success(
        "Success! Email has been sent to candidate with rescheduled assessment details."
      );
    } catch (error) {
      console.log(error);
    } finally {
      setShowScheduleModule(false);
    }
  };

  return (
    <div className="main-container w-full mx-auto md:ml-20 mt-1 flex flex-col lg-px-10">
      <div className="card p-2 pt-4 pr-0 w-full relative lg:h-[90vh] overflow-y-hidden flex flex-col">
        <div className="application-filter-bar flex gap-2 h-fit pr-4">
          <div className="left-item w-full flex items-center gap-3 px-4 overflow-x-auto scrollbar-none">
            {tabs?.map((tabName) => {
              return (
                <div
                  onClick={() => {
                    navigate(`/applicants?tab=${tabName}`);
                    setTab(tabName);
                  }}
                  key={tabName}
                  id={`tab-${tabName}`}
                  className={`tab-btn capitalize text-sm font-medium rounded-full px-8 py-2 cursor-pointer ${
                    tab === tabName ? "" : "not-active"
                  }`}
                >
                  {tabName}
                </div>
              );
            })}
          </div>
        </div>
        <div className="application-filter-bar flex gap-2 h-fit pr-4 py-2 justify-between">
          <div className="left-item flex items-center gap-3 px-4 overflow-x-auto scrollbar-none">
            {tab === "screened" ? (
              <div className="application-filter-bar flex h-fit ml-5">
                {rounds
                  ?.filter((round) => round?.roundType === "screening")
                  ?.map((round, index) => {
                    return (
                      <Link
                        to={`/applicants?tab=${tab}&round=${round?.id}`}
                        key={index}
                        id={`tab-${index}`}
                        className={`capitalize text-sm font-medium px-8 py-2 cursor-pointer border-b-4 ${
                          Number(roundName) === round?.id
                            ? "border-[#8A24F1]"
                            : "border-gray-300"
                        }`}
                      >
                        {round?.assessment}
                      </Link>
                    );
                  })}
              </div>
            ) : tab === "assessed" ? (
              <div className="application-filter-bar flex h-fit ml-5">
                {rounds
                  ?.filter((round) => round?.roundType === "assessment")
                  ?.map((round, index) => {
                    return (
                      <Link
                        to={`/applicants?tab=${tab}&round=${round?.id}`}
                        key={index}
                        id={`tab-${index}`}
                        className={`capitalize text-sm font-medium px-8 py-2 cursor-pointer border-b-4 ${
                          Number(roundName) === round?.id
                            ? "border-[#8A24F1]"
                            : "border-gray-300"
                        }`}
                      >
                        {round?.assessment}
                      </Link>
                    );
                  })}
              </div>
            ) : (
              <h1 className="heading2">All {tab} Candidates</h1>
            )}
          </div>
          <div className="right-item flex items-center gap-4">
            <Select
              className="basic-single w-[260px]"
              classNamePrefix="select"
              name="jobPosition"
              placeholder="Select Job Position"
              options={postedJobs}
              onChange={(selectedOption) =>
                setFilterWithJobId(selectedOption?.jobId)
              }
              // defaultValue={postedJobs[0]}
            />
            {/* <div
              className="relative flex items-center gap-4 py-2"
              // onClick={() => setOpenfilterMenu(!openfilterMenu)}
            >
              <Button
                text=""
                version="outline-white"
                className="filter-btn w-32 flex items-center gap-2"
              >
                <span className="text-base">Filter</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M10.5 6h9.75M10.5 6a1.5 1.5 0 11-3 0m3 0a1.5 1.5 0 10-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-9.75 0h9.75"
                  />
                </svg>
              </Button>
            </div> */}
            <div className="flex items-center justify-center">
              <Button
                className="h-full w-[200px]"
                text=""
                handleClick={() => navigate("/newCandidate")}
              >
                <div className="flex items-center gap-2">
                  <span className="text-xl font-semibold">+</span>
                  <span> Add Candidate </span>
                </div>
              </Button>
            </div>
          </div>
        </div>

        <div className="w-[100%] overflow-x-scroll h-full px-4 py-2 scrollable">
          <ATSlisting
            candidates={candidates}
            loading={loading}
            setSortValue={setSortValue}
            sortValue={sortValue}
            setShowScheduleModule={setShowScheduleModule}
            setCandidateToSchedule={setCandidateToSchedule}
            setCandidateToReschedule={setCandidateToReschedule}
          />
        </div>
      </div>
      {showScheduleModule && (
        <Popup closePopup={() => setShowScheduleModule()}>
          <Schedule
            type={showScheduleModule}
            scheduleAssessment={scheduleAssessment}
            rescheduleAssessment={rescheduleAssessment}
            schedule={candidateToReschedule?.schedule}
          />
        </Popup>
      )}
    </div>
  );
};

export default ATSMain;
