import React from "react";

const InstructionsTopPanel = ({ assessmentDetails }) => {
  return (
    <div className="w-[95%] mx-auto mt-[100px] mb-6 p-10 card">
      <h1 className="heading2 mb-2">technical skills assesment test</h1>
      <div className="flex items-center">
        <span className="font-medium">Assessment :</span>
        {assessmentDetails ? (
          <span className="text-[#919397] capitalize ml-1">
            {assessmentDetails?.name}
          </span>
        ) : (
          <span className="text-[#919397] capitalize ml-1 text-sm">
            Loading...
          </span>
        )}
      </div>
    </div>
  );
};

export default InstructionsTopPanel;
